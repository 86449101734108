import React from 'react'
import { Box, Container, withStyles, makeStyles, createStyles, Theme } from '@material-ui/core'
import { LoadingSpinner, StandardText } from '@rse/uikit'
import { ReactComponent as LogoLight } from '@rse/uikit/lib/icons/logo-light.svg'
import { useBuildInfo } from '../../common/hooks'

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    containedSecondary: {
      backgroundColor: '#6B2EF5',
      color: palette.common.white,
    },
    iconStyle: {
      width: '100%',
      height: 'auto',
    },
    pageRoot: {
      '& .crisp-client': {
        display: 'none',
      },
    },
  })
)

export function LoadingPage({ what }: { what:string }) {
  const { iconStyle } = useStyles()
  const { buildInfo, loading: loadingBuildInfo } = useBuildInfo()
  const version = buildInfo.version

  return (
    <PageContainer>
      <Container maxWidth="xs">
          <CenteredRow>
            <LogoLight className={iconStyle} />
          </CenteredRow>
          <CenteredRow>
            <Box flexGrow={1} pt={1} pr={1}>
              <Box textAlign="center" color="#6B2EF5" pt={1}>
                <LoadingSpinner reason={what} />
                <StandardText>
                  Loading version: {loadingBuildInfo ? 'calculating' : version }
                </StandardText>
              </Box>
            </Box>
          </CenteredRow>
      </Container>
    </PageContainer>
  )
}

const containerStyles = ({ palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100vw',
      height: '100vh',
      alignItems: 'center',
      backgroundColor: palette.common.white,
    },
  })

const rowStyles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(2),
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
  })

const PageContainer = withStyles(containerStyles)(Box)

const CenteredRow = withStyles(rowStyles)(Box)
