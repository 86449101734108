import React from 'react'
import { Box, TextField } from '@material-ui/core'
import { ButtonLarge, LoadingSpinner } from '@rse/uikit'
import { DashboardBanner } from '../../../components/layout/DashboardBanner'
import { ReactComponent as PhoneIcon } from '../../Dashboard/assets/verifiedPhone.svg'
import { isValidPhone } from '../../../common/utils'

type Props = {
  title: string
  titleKey: string
  subtext: string
  subtextKey: string
  phoneNum: string
  onPhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  sendCode: (phoneNum: string) => Promise<void>
  statusPending: boolean
}

export function EnterPhoneContent({
  title,
  titleKey,
  subtext,
  subtextKey,
  phoneNum,
  onPhoneChange,
  sendCode,
  statusPending,
}: Props) {
  return (
    <DashboardBanner
      title={title}
      titleKey={titleKey}
      subtext={subtext}
      subtextKey={subtextKey}
      icon={PhoneIcon}
      viewBox="0 0 42 42"
    >
      <Box mb={2}>
        <TextField
          name="phoneNum"
          variant="outlined"
          helperText="* Please enter a valid phone number starting with country code"
          label="Phone Number"
          onChange={onPhoneChange}
          value={phoneNum}
          placeholder="+1 ### ### ####"
          type="tel"
        />
      </Box>
      <ButtonLarge
        id="send-code"
        fullWidth
        disabled={!isValidPhone(phoneNum)}
        type="submit"
        onClick={() => sendCode(phoneNum)}
      >
        {statusPending ? <LoadingSpinner height={20} color="inherit" /> : 'Next'}
      </ButtonLarge>
    </DashboardBanner>
  )
}
