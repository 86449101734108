import React from 'react'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { SectionHeader, StandardText, Spacing } from '@rse/uikit'
import { InputSelectRadioPills } from '../components/InputSelectRadioPills'
import { InputToggle } from '../components/InputToggle'
import { InputOption } from '../types/components'

export const PrefsMateGender: React.VFC = () => {
  const textContent = useTextContent()

  const options: InputOption[] = [
    {
      id: 'female',
      title: (
        <StandardText>
          <TextContent resourceKey="app-wizard.mate-gender-female" defaultValue="&#9792; Female" />
        </StandardText>
      ),
    },
    {
      id: 'male',
      title: (
        <StandardText>
          <TextContent resourceKey="app-wizard.mate-gender-male" defaultValue="&#9794; Male" />
        </StandardText>
      ),
    },
    {
      id: 'nonbinary',
      title: (
        <StandardText>
          <TextContent resourceKey="app-wizard.mate-gender-nonbinary" defaultValue="&#9893; Co-Ed" />
        </StandardText>
      ),
    },
  ]

  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent resourceKey="app-wizard.title-want-to-live-with" defaultValue="I want to live with..." />
      </SectionHeader>
      <Spacing value={20} />
      <InputSelectRadioPills id="roommateGender" options={options} />
      <Spacing value={20} />
      <InputToggle
        id="lgbtqFriendly"
        label={getTextContent(textContent, 'app-wizard.field-lgbtq-friendly', 'I\'m LGBTQ friendly')}
      />
    </section>
  )
}
