import { VersionedText } from '../../models'

// TODO import from a version controlled file that we can share with backend
const questions: VersionedText[] = [
  { id: 'guid-1', text: 'Have you ever been evicted or refused to pay your rent for any reason?' },
  { id: 'guid-2', text: 'Have you ever been convicted of a misdemeanor or felony?' },
  {
    id: 'guid-3',
    text: 'Has any civil judgment been entered against you for the collection of a debt in the last 10 years?',
  },
  { id: 'guid-4', text: 'Have you filed for bankruptcy in the last 10 years?' },
  {
    id: 'guid-5',
    text:
      'Have you ever been the subject of investigation or diciplinary action by a school, association, employer, or licensed organization alleging sexual, ethical, or any other misconduct?',
  },
  {
    id: 'guid-6',
    text:
      'Do you have any personal problems that include the overuse of alchohol, stimulants, or other habit forming drugs?',
  },
  { id: 'guid-7', text: 'Have you ever been arrested for threats of or for actual physical abuse towards others?' },
  { id: 'guid-8', text: 'Do you intend to bring, carry, or keep any firearms or weapons in your home?' },
  { id: 'guid-9', text: 'Are you licensed to carry firearms of any kind?' },
  { id: 'guid-10', text: 'Have you ever spent time in combat, or combat training situations, real or simulated?' },
]

export default questions
