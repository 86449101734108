export enum NotificationType {
  Generic = 'Generic',
  GroupJoined = 'Group Joined',
  NewMemberJoined = 'New Member Joined Group',
}

export type NotificationBase = {
  id?: string
  message?: string
  incomingDate: Date
  readDate?: Date
}

export type GenericNotification = NotificationBase & {
  type: NotificationType.Generic
  data: unknown
}

export type GroupJoinedNotification = NotificationBase & {
  type: NotificationType.GroupJoined
  groupId: string
}

export type NewMemberJoinedNotification = NotificationBase & {
  type: NotificationType.NewMemberJoined
  groupId: string
  userId: string
}

export type UserNotification =
  | GenericNotification
  | GroupJoinedNotification
  | NewMemberJoinedNotification

export function notificationTypeToString(notification: UserNotification) {
  return notification.type
}
