import { Box, styled } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { TextContent } from '../../../common/textContent'
import { LoadingSpinner, Spacing } from '@rse/uikit'
import { finalizeApplication } from '../../../firebase'
import { TextHeading } from '../components/TextHeading'
import { useProgressInfo } from '../helpers/useProgressData'

export const StepFinalizeApplication: React.VFC = () => {
  const [sending, setSending] = useState(true)
  const [error, setError] = useState<string | null>('')
  const { completeApplicationProgress } = useProgressInfo()

  useEffect(() => {
    (async () => {
      const error = await finalizeApplication()
      setSending(false)
      setError(error)

      if (!error) {
        completeApplicationProgress()
      }
    })()
  }, [])

  return (
    <section>
      <Spacing value={28} />
      <TextHeading>
        <TextContent resourceKey="app-wizard.submit-for-processing" defaultValue="Submit for processing" />
      </TextHeading>
      <Spacing value={14} />
      {sending && (
        <Box>
          <TextSubmitInfo>
            <TextContent
              resourceKey="app-wizard.submitting-application"
              defaultValue="We are submitting your application..."
            />
          </TextSubmitInfo>
          <LoadingSpinner height={90} />
        </Box>
      )}
      {!sending && error && (
        <Box>
          <TextSubmitInfo>
            {' '}
            <TextContent
              resourceKey="app-wizard.error-submitting-application"
              defaultValue="There was an error while submitting your application:"
            />
          </TextSubmitInfo>
          <TextSubmitInfo>{error}</TextSubmitInfo>
        </Box>
      )}
      {!sending && !error && (
        <TextSubmitInfo>
          <TextContent
            resourceKey="app-wizard.application-received"
            defaultValue="Your application has been received. Click 'Next' to continue."
          />
        </TextSubmitInfo>
      )}
    </section>
  )
}

const TextSubmitInfo = styled('p')(({ theme }) => ({
  margin: 0,
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '130%',
  color: theme.palette.type === 'light' ? '#351F65' : theme.palette.common.white,
}))
