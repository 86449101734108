import { Box, makeStyles, createStyles } from '@material-ui/core'
import { BuyingGroup, getThreadTitle, UserMessageThread, UserProfilePublicEntity } from '@rse/core'
import { ButtonLarge, LoadingSpinner, MiniHeader, MiniSubHeader } from '@rse/uikit'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { UserContext } from '../../../App'
import { useMessageThread } from '../../../common/hooks'
import { getTextContent, TextContent, TextContentContext } from '../../../common/textContent'
import { AppLayout, CenteredBox, Footer, Header } from '../../../components/layout'
import { BodyContainer } from '../../../components/layout/BodyContainer'
import { useBuyingGroup, useUserMessageThread } from '../../../firebase'
import { useUserPublicProfileApiSimple } from '../../../firebase/hooks/useUserPublicProfileApiSimple'


const useStyles = makeStyles(() =>
  createStyles({
    buttonGroup: {
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      gap: '1rem'
    },
  })
)



export default function LeaveThread() {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(true)
  const [isThreadApiLoading, setIsThreadApiLoading] = useState(false)
  const [threadTitle, setThreadTitle] = useState<string>('Loading..')
  const [threadGroup, setThreadGroup] = useState<BuyingGroup | undefined>(undefined)
  const [userThread, setUserThread] = useState<UserMessageThread | undefined>(undefined)
  const [threadPeerProfile, setThreadPeerProfile] = useState<UserProfilePublicEntity | undefined>(undefined)

  const history = useHistory()
  const user = useContext(UserContext)
  const { threadId } = useParams<{ threadId: string }>()

  const { document: { value: thread, loading: threadLoading, error: threadError } } = useUserMessageThread({ uid: user!.id, threadId })
  const groupId = thread?.groupId ? thread.groupId : ""

  const {
    document: { value: group, loading: groupLoading, error: groupError },
  } = useBuyingGroup(groupId, true)

  const { value: peerProfile, isLoading: peerProfileLoading } = useUserPublicProfileApiSimple(userThread?.peerId)

  const { isApiLoading, leaveThread } = useMessageThread(threadId)

  useEffect(() => {
    setIsLoading(peerProfileLoading || groupLoading)
  }, [peerProfileLoading, groupLoading])


  useEffect(() => {
    setUserThread(thread)
  }, [threadId, thread])

  useEffect(() => {
    setThreadGroup(group)
  }, [group])


  useEffect(() => {
    setThreadPeerProfile(peerProfile)
  }, [userThread, peerProfile])


  useEffect(() => {
    if (userThread) {
      const title = getThreadTitle(userThread, threadPeerProfile, threadGroup)
      setThreadTitle(title)
    }
  }, [userThread, threadPeerProfile, threadGroup])


  useEffect(() => {
    setIsThreadApiLoading(isApiLoading)
  }, [isApiLoading])

  const ArhiveThreadConfirmation = <>
    {isLoading && <LoadingSpinner reason="loading thread data" />}

    {!isLoading && <>
      <Box mb={3}>
        <MiniHeader>
          <TextContent resourceKey="archive.thread.warning" defaultValue="Are you sure you want to leave the following thread?" />
        </MiniHeader>
      </Box>
      <Box mb={3}>
        <MiniSubHeader>
          <span>Chat Thread: </span><span style={{ color: 'red' }}>{threadTitle}</span>
        </MiniSubHeader>
      </Box>
      <Box className={classes.buttonGroup}>
        <ButtonLarge fullWidth onClick={() => handleLeaveClick()}>Leave</ButtonLarge>
        <ButtonLarge fullWidth onClick={() => { history.goBack() }}>Cancel</ButtonLarge>
      </Box>
    </>
    }
  </>


  const LeavingThread = <>
    <LoadingSpinner reason="Leaving thread.." />
  </>

  const handleLeaveClick = async () => {
    await leaveThread()
    history.push('/messages')
  }

  return (
    <BodyContainer>
      <Box mt={5} mb={3}>
        <CenteredBox textAlign="center" flexGrow={0}>
          {isThreadApiLoading ? LeavingThread : ArhiveThreadConfirmation}
        </CenteredBox>
      </Box>
    </BodyContainer>
  )
}