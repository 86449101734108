import React from 'react'
import { Box } from '@material-ui/core'
import { ErrorText, LoadingSpinner } from '@rse/uikit'

import { InputSelectRadioCircles } from '../../components'
import { TextContent } from '../../../../common/textContent'
import { usePreferencesQuestions } from '../../../../common/hooks'

export const preferencesField = 'preferences'
export const preferencesPrefix = `${preferencesField}.`

export function PrefsGroundRules() {
  const { value, loading, error } = usePreferencesQuestions()

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <ErrorText>
        <TextContent
          resourceKey="errors.loading-ground-rules"
          defaultValue="Error loading home lifestyle preferences"
        />
      </ErrorText>
    )
  }

  return (
    <>
      <Box>
        {value.map((question) => {
          if (question.enabledChoices?.length) {
            return (
              <Box key={question.id} my={3}>
                <InputSelectRadioCircles
                  id={`${preferencesPrefix}${question.id}`}
                  title={question.label}
                  options={question.enabledChoices.map((item) => ({ ...item, value: item.id }))}
                />
              </Box>
            )
          }
        })}
      </Box>
    </>
  )
}
