import React from 'react'
import { Box, styled } from '@material-ui/core'
import { useBuyingGroup } from '../../firebase'

type Props = {
  groupId: string
  url?: string
}

export function GroupImagePublic({ groupId, url }: Props) {
  const {
    document: { value: group },
  } = useBuyingGroup(groupId)

  const effectiveUrl = (group && group.groupProfileImageUrl) || url

  return (
    <Box borderRadius={12} minHeight={128} overflow="hidden">
      {effectiveUrl ? <img src={effectiveUrl} width="100%" /> : <EmptyBox />}
    </Box>
  )
}

const EmptyBox = styled('div')({
  width: '200px',
  height: '130px',
  background: 'lightgray',
})
