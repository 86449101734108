import { TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { QuestionComponentProps } from '.'
import { validateSingleField } from '../../../../pages/Application/helpers/validation'

export default function TextInput({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const [textValue, setTextValue] = useState('')
  const [helperText, setHelperText] = useState('')
  const [isQuestionAnswerValid, setIsQuestionAnswerValid] = useState(true)

  const inputType = (QuestionConfig.DataType || 'text').toLowerCase()

  useEffect(() => {
    let currentAnswer = ''

    if (Answer && Answer[0]) {
      currentAnswer = Answer[0]
    }

    if (inputType == 'date') {
      if (!currentAnswer) {
        currentAnswer = '1980-12-01'
      }
    }

    setTextValue(currentAnswer)

    setErrorState(currentAnswer)
  }, [])

  const isRequired: boolean = QuestionConfig.IsRequired || false

  otherProps.variant = otherProps.variant || 'outlined'
  otherProps.fullWidth = otherProps.fullWidth || true

  const setErrorState = (currentValue: string) => {
    const errors = validateSingleField(currentValue, QuestionConfig.QuestionName)
    const isValid = errors ? false : true

    ValidStatusUpdateHandler(isValid)
    setIsQuestionAnswerValid(isValid)
    if (!isValid) {
      setHelperText(errors || '')
    } else {
      setHelperText('')
    }
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextValue(e.target.value)
    AnswerUpdateHandler([e.target.value])

    setErrorState(e.target.value)
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      <TextField
        type={inputType}
        name={QuestionConfig.QuestionName}
        value={textValue || ''}
        onChange={changeHandler}
        onBlur={changeHandler}
        error={!isQuestionAnswerValid}
        helperText={displayQuestionErrors ? helperText : ''}
        placeholder={QuestionConfig.Title}
        {...otherProps}
      />
    </>
  )
}
