import React from 'react'
import { NavLink } from 'react-router-dom'
import { MenuItem, Divider, makeStyles, createStyles, Theme } from '@material-ui/core'
import { TextContent } from '../../../../common/textContent'

interface ILink {
  url: string
  label: string
  labelResource?: string
  handler: (e: React.SyntheticEvent<Element>) => void
  divider?: boolean
  icon?: JSX.Element
}

export function ProfileMenuLink({ url, label, labelResource, handler, divider, icon }: ILink) {
  const styles = useStyles()

  return (
    <>
      <MenuItem onClick={handler}>
        <NavLink to={url} className={styles.link}>
          <span className={styles.icon}>{icon}</span>
          {labelResource ? <TextContent resourceKey={labelResource} defaultValue={label} /> : <>{label}</>}
        </NavLink>
      </MenuItem>
      {divider && <Divider />}
    </>
  )
}

const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 500,
      color: '#8C8AAC',
      width: '100%',
    },
    icon: {
      display: 'inline-flex',
      width: '18px',
      height: '18px',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#8C8AAC',
      marginRight: '10px',
      '& > svg': {
        maxWidth: '18px',
        maxHeight: '18px',
      },
    },
  })
)
