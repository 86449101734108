import React from 'react'
import { UserNotification, NotificationType } from '../../../models'
import { Add, ArrowForward } from '@material-ui/icons'

const acceptIcon = <Add />
const requestIcon = <ArrowForward />

export function getNotificationIcon(data: UserNotification) {
  switch (data.type) {
    case NotificationType.Generic:
      return
    // TODO implement this notification type
    //case NotificationType.MessageRequest:
      //return requestIcon
    case NotificationType.GroupJoined:
      return acceptIcon
    case NotificationType.NewMemberJoined:
      return acceptIcon
    default:
      const remaining: never = data // will show TS error if not all cases exhausted
      console.warn('unknown notification type', remaining)
      throw new Error('Unexpected notification type')
  }
}
