import React, { useContext } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { AppLayout, Header, Footer } from '../components/layout'
import { UserContext } from './'
import {
  DashboardNav,
  SMSVerifyNav,
  URL_CLUB_MEMBERS,
  URL_EQUITY_CALC,
  URL_GROUPS,
  URL_MEMBERSHIP_WIZARD,
  URL_MESSAGES,
  URL_NOTIFICATIONS,
  URL_SANDBOX,
  URL_PRIVACY_POLICY,
  URL_TERMS_OF_SERVICE,
  URL_DASHBOARD,
} from '../common/constants/routes'
import { FirebaseAuthUser } from '../services'
import EquityCalculatorPage from '../pages/EquityCalculator'
import MessagingPage from '../pages/Messaging/MessagingPage'
import NotificationsPage from '../pages/Notifications/NotificationsPage'
import { SettingsPageRoot } from '../pages/Settings'
import SandboxPage from '../pages/Sandbox'
import { Application } from '../pages/Application'
import { Chat } from '../pages/Messaging/Chat'
import StylesDemoPage from '../pages/StylesDemo'
import ClubMembersPage from '../pages/ClubMembers'
import BuyingGroups from '../pages/BuyingGroups'
import { AppRoutes, ProtectedRoute } from './routeUtils'
import { Box, Container, useTheme } from '@material-ui/core'
import { HeaderAvatar } from '../components/layout/appLayout/Header/HeaderAvatar'
import { Subtitle1, AppLink } from '@rse/uikit'
import TermsOfServicePage from '../pages/LegalPages/TermsOfServicePage'
import PrivacyPolicyPage from '../pages/LegalPages/PrivacyPolicyPage'
import { getTextContent, TextContentContext } from '../common/textContent'
import NotFoundPage from '../pages/NotFound'

function DefaultRouteForUser({ user }: { user: FirebaseAuthUser }) {
  if (!user.isEmailVerified) return <Redirect to={DashboardNav.path} />
  if (!user.isPhoneVerified) return <Redirect to={SMSVerifyNav.path} />

  switch (user.initApplicationStatus) {
    case 'New':
      return <Redirect to={URL_DASHBOARD} />
    case 'Member':
      // If member of a buying group, they browse members by default, otherwise they browse groups they might join
      return <Redirect to={user.buyingGroupId ? URL_CLUB_MEMBERS : URL_GROUPS} />
    default:
      return <Redirect to={URL_MEMBERSHIP_WIZARD} />
  }
}

// TODO: move the components below to appropriate places

const EquityCalculatorComponent = () => {
  const textContent = useContext(TextContentContext)
  const headerTitle = getTextContent(textContent, 'equity-calculator.page.header.title', 'Equity Calculator')
  return <AppLayout header={<Header title={headerTitle} />} body={<EquityCalculatorPage />} footer={<Footer />} />
}

const NotificationsPageComponent = () => {
  const textContent = useContext(TextContentContext)
  const headerTitle = getTextContent(textContent, 'notifications.page.header.title', 'Activity')
  return <AppLayout header={<Header title={headerTitle} />} body={<NotificationsPage />} footer={<Footer />} />
}

const ChatComponent = () => {
  const history = useHistory()
  const textContent = useContext(TextContentContext)
  const headerTitle = getTextContent(textContent, 'chat.page.header.title', 'Chat')
  return <AppLayout
    header={<Header 
      back={async () => (history.goBack())}
      title={headerTitle} />}
    body={<Chat />}
     />
}

function SandboxHeader() {
  const theme = useTheme()

  return (
    <Box bgcolor={theme.palette.primary.main} color="white">
      <Container maxWidth="xs">
        <Box display="flex" justifyContent="space-between" alignItems="center" height="56px">
          <AppLink to="/sandbox" noDecoration>
            <Subtitle1>Developer Sandbox</Subtitle1>
          </AppLink>
          <HeaderAvatar />
        </Box>
      </Container>
    </Box>
  )
}

const SandboxPageComponent = () => <AppLayout header={<SandboxHeader />} body={<SandboxPage />} footer={<Footer />} />

const RedirectToRootComponent = () => <Redirect to="/" />

// ENDTODO

export const AppRouter = () => {
  const user = useContext(UserContext)

  if (!user) {
    throw new Error('AppRouter requires authenticated user')
  }


  // FIXME this was a temporary bypass (Justis)
  const canJoin = true
  //const canJoin = user.isEmailVerified && user.isPhoneVerified && user.initApplicationStatus !== 'Member'

  // FIXME should only be 'Member', need firebase functions to work first
  const isMember = user.initApplicationStatus === 'Member' || user.initApplicationStatus === 'Approved'

  return (
    <Switch>
      { /* in case the user is still on /login route after login complete */ }
      { /* We should be able to remove this after upgrade to react-router@6 */ }
      <Route exact path="/login">
        <DefaultRouteForUser user={user} />
      </Route>
      <Route exact path="/">
        <DefaultRouteForUser user={user} />
      </Route>
      <Route {...DashboardNav} />
      {canJoin && <Route {...SMSVerifyNav} />}
      {canJoin && (
        <ProtectedRoute path={URL_MEMBERSHIP_WIZARD} component={Application} allowedRoles={['member', 'admin']} />
      )}
      {isMember && (
        <ProtectedRoute
          path={URL_PRIVACY_POLICY}
          component={PrivacyPolicyPage}
          allowedRoles={['member', 'admin']}
          exact
        />
      )}
      {isMember && (
        <ProtectedRoute
          path={URL_TERMS_OF_SERVICE}
          component={TermsOfServicePage}
          allowedRoles={['member', 'admin']}
          exact
        />
      )}
      {isMember && (
        <ProtectedRoute path={URL_CLUB_MEMBERS} component={ClubMembersPage} allowedRoles={['member', 'admin']} />
      )}
      {isMember && <ProtectedRoute path={URL_GROUPS} component={BuyingGroups} allowedRoles={['member', 'admin']} />}
      {isMember && (
        <ProtectedRoute
          path={URL_EQUITY_CALC}
          component={EquityCalculatorComponent}
          allowedRoles={['member', 'admin']}
        />
      )}
      {isMember && <ProtectedRoute path={URL_MESSAGES} component={MessagingPage} allowedRoles={['member', 'admin']} />}
      {isMember && (
        <ProtectedRoute
          exact
          path={URL_NOTIFICATIONS}
          component={NotificationsPageComponent}
          allowedRoles={['member', 'admin']}
        />
      )}
      {isMember && (
        <ProtectedRoute path={AppRoutes.settings()} component={SettingsPageRoot} allowedRoles={['member', 'admin']} />
      )}
      {isMember && (
        <ProtectedRoute path="/chat/:threadId" component={ChatComponent} allowedRoles={['member', 'admin']} />
      )}
      <ProtectedRoute path={URL_SANDBOX} component={SandboxPageComponent} allowedRoles={['admin']} />
      <ProtectedRoute exact path="/demo" component={StylesDemoPage} allowedRoles={['member', 'admin']} />
      <ProtectedRoute exact path="/signup" component={RedirectToRootComponent} />
      <Route component={NotFoundPage} />
    </Switch>
  )
}
