import { useEffect, useState } from 'react'
import { getDateFromFirestoreTimestampOrString } from '../utils'
import { useActivePreferences } from '.'
import { UserProfilePublicEntity } from '@rse/core'
import { useCurrentUserId } from '../../common/hooks'
import settings from '../../settings'
import { firebaseInstance } from '../firebaseInstance'
import { useApiFetch } from '@rse/frontend-dal'






function setUserPublicProfileBirthday(value: UserProfilePublicEntity): UserProfilePublicEntity {
  if (value) {
    value.birthday = getDateFromFirestoreTimestampOrString(value.birthday)
  }

  return value
}

export function useUserPublicProfileApi(uid: string, refinePreferences = false, preloadedProfile: UserProfilePublicEntity | undefined = undefined) {
  const currentUid = useCurrentUserId()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [userPublicProfileData, setUserPublicProfileData] = useState<UserProfilePublicEntity | undefined>(undefined)
  const { error, loading, value, isActivePrefsSet } = useActivePreferences<UserProfilePublicEntity>({ object: userPublicProfileData, loadingObject: isLoading, errorLoadingObject: undefined, refinePreferences })
  
  const loadUserProfilePublicApi = useApiFetch(`${settings.firebaseFunctionsBaseUrl}/api/user-profile/public`, true, 'GET', true, firebaseInstance.auth)
  const loadData = async () => {
    setIsLoading(true)
    let profile: UserProfilePublicEntity | null = await loadUserProfilePublicApi.callApi(undefined, uid)
    if (profile) {
      profile = setUserPublicProfileBirthday(profile)
      setUserPublicProfileData(profile)
    } else {
      setUserPublicProfileData(undefined)
    }
    setIsLoading(false)
  }


  useEffect(() => {
    const fetchData = async () => {
      await loadData()
    }
    if (!preloadedProfile) {
      fetchData().catch(console.error)
    } else {
      setUserPublicProfileData(preloadedProfile)
    }

  }, [])

  useEffect(() => {
    if(isActivePrefsSet){
      setUserPublicProfileData(value)
    }
  }, [isActivePrefsSet])



  const saveUserProfilePublicApi = useApiFetch(`${settings.firebaseFunctionsBaseUrl}/api/user-profile/public`, true, 'POST', true, firebaseInstance.auth)
  const saveUserProfilePublic = async (userProfilePublic: Partial<UserProfilePublicEntity>) => {
    userProfilePublic.id = currentUid
    setIsSaving(true)
    await saveUserProfilePublicApi.callApi(userProfilePublic)
    setIsSaving(false)
  }


  return { value: userPublicProfileData, loading, error, saveUserProfilePublic, isSaving, loadData }
}
