import React, { useContext, useEffect, useState } from 'react'
import { Box, Container, Fade, IconButton, SvgIcon } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { BuyingGroup } from '@rse/core'
import { LoadingSpinner, Spacing, MiniHeader, SectionHeader, ButtonLarge } from '@rse/uikit'
import { useCurrentUserId, useQueryFromLocation, useErrorHandler } from '../../../common/hooks'
import { useGetBuyingGroupsFiltered } from '../hooks/useGetBuyingGroupsFiltered'
import { BuyingGroupListCard } from './BuyingGroupListCard'
import { MemberGroupsNavToggle } from '../../../components/inputs/MemberGroupsNavToggle'
import { AppRoutes } from '../../../App/routeUtils'
import { getTextContent, TextContent, TextContentContext } from '../../../common/textContent'
import { AppLayout, Header, Footer } from '../../../components/layout'
import { Favorite, FavoriteBorderOutlined } from '@material-ui/icons'
import { FilterIcon } from '../../../components/icons'
import { UserContext } from '../../../App'
import { useInView } from 'react-intersection-observer'

export const BuyingGroupList = () => {
  const history = useHistory()
  const query = useQueryFromLocation()
  const favoritesOnly = query.get('fav') === 'true'
  const userId = useCurrentUserId()
  const user = useContext(UserContext)
  const { errorHandler } = useErrorHandler('loading group list')
  const { data: groups, loading, error, loadNextData, userBuyingGroupData } = useGetBuyingGroupsFiltered(userId, favoritesOnly, 15, true, false, user?.buyingGroupId)
  const status = groups?.length ? '' : 'No groups found'
  const [pinnedGroup, setPinnedGroup] = useState<BuyingGroup>()

  const textContent = useContext(TextContentContext)

  const loadData = (inView: boolean, entry: IntersectionObserverEntry) => {
    if (inView) {
      loadNextData()
    }
  }

  const { ref, inView } = useInView({
    threshold: 0, onChange: loadData
  })

  useEffect(() => {
    if (error) errorHandler(error)
  }, [error])

  useEffect(() => {
    setPinnedGroup(userBuyingGroupData)
  }, [userBuyingGroupData])

  const header = (
    <Header title={getTextContent(textContent, 'connect.page.header.title', 'Connect')}>
      {/* <IconButton color="inherit" className="preferences" onClick={() => undefined}>
        <SvgIcon component={FilterIcon} viewBox="0 0 24 24" />
      </IconButton> */}
      <IconButton
        color="inherit"
        className="favorite"
        onClick={() => history.push(`${AppRoutes.groupRoot()}?fav=${favoritesOnly ? 'false' : 'true'}`)}
      >
        {favoritesOnly ? <Favorite /> : <FavoriteBorderOutlined />}
      </IconButton>
    </Header>
  )

  return (
    <AppLayout
      header={header}
      body={
        <Container maxWidth="xs">
          <MemberGroupsNavToggle />
          <Fade in={groups != null} >
            <Box style={{ position: 'relative', top: '48px' }}>
              {!loading && <MiniHeader align="center">{status}</MiniHeader>}
              {pinnedGroup && <BuyingGroupListCard group={pinnedGroup} pinned={true} />}
              {groups &&
                groups
                  .filter((group) => group !== pinnedGroup)
                  .map((group) => (
                    <Box key={group.id}>
                      <BuyingGroupListCard key={group.id} group={group} />
                    </Box>
                  ))}
              <div id="scrollLoadTriggerSensor" ref={ref}></div>
              {loading && <LoadingSpinner height={100} />}
              {user && !user.buyingGroupId && !loading && (
                <>
                  <SectionHeader>
                    <TextContent
                      resourceKey="create-group.people-preferences"
                      defaultValue="Don’t see what you are looking for? 👀"
                    />
                  </SectionHeader>
                  <Spacing />
                  <ButtonLarge fullWidth isUppercase={false} onClick={() => history.push(AppRoutes.groupCreate())}>
                    <TextContent resourceKey="buttons.become-a-host" defaultValue="Become a host" />
                  </ButtonLarge>
                  <Spacing />
                </>
              )}
            </Box>
          </Fade>
        </Container>
      }
      footer={<Footer />}
    />
  )
}
