import React from 'react'
import { Paper, makeStyles, createStyles, Theme } from '@material-ui/core'

interface IScrollProps {
  children: React.ReactNode
  scrollHandler: React.UIEventHandler<HTMLElement>
}

const useStyles = makeStyles(({ spacing, palette, shape }: Theme) =>
  createStyles({
    root: {
      maxHeight: 215,
      overflowY: 'scroll',
      padding: spacing(1),
      border: `2px solid ${palette.grey[300]}`,
      textAlign: 'left',
      '&::-webkit-scrollbar': {
        width: '0.3em',
        borderRadius: 2,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        borderRadius: shape.borderRadius,
      },
    },
  })
)

export function ScrollContainer(props: IScrollProps) {
  const styles = useStyles()

  return (
    <Paper elevation={0} className={styles.root} onScroll={(e) => props.scrollHandler(e)}>
      {props.children}
    </Paper>
  )
}
