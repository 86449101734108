import { Box, Button, createStyles, makeStyles } from '@material-ui/core'
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { TextContent } from '../../../../common/textContent'
import { useProgressInfo } from '../../helpers/useProgressData'
import { ApplicationProgressDataContext } from '../ApplicationFormProgressProvider'

export const StepButtons = () => {
  const { currentStep } = useProgressInfo()
  const [{ nextButtonBlocked, unblockNextButton, onClickPrevButton, onClickNextButton }] = useContext(ApplicationProgressDataContext)

  const history = useHistory()
  const cls = makeStyles(classes)()
  const prev = () => {
    unblockNextButton()
    history.push(`/application/${currentStep - 1}`)
  }
  return (
    <>
      <Box borderTop="1px solid #EFEFF3" mb={4} />
      <Box display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          color="primary"
          onClick={(ev) => {
            if (onClickPrevButton && typeof onClickPrevButton === 'function' && !onClickPrevButton()) {
              ev.preventDefault()
              console.log('blocked prev button')
            } else {
              prev()
            }
          }}
          startIcon={<ArrowBack />}
          size="large"
          disabled={currentStep <= 1}
          className={cls.root}
        >
          <TextContent resourceKey="buttons.back" defaultValue="Back" />
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForward />}
          type="submit"
          size="large"
          className={cls.root}
          disabled={nextButtonBlocked}
          onClick={(ev) => {
            if (onClickNextButton && typeof onClickNextButton === 'function' && !onClickNextButton()) {
              ev.preventDefault()
              console.log('blocked next button')
            }
          }}
        >
          <TextContent resourceKey="buttons.next" defaultValue="Next" />
        </Button>
      </Box>
    </>
  )
}

const classes = createStyles({
  root: {
    textTransform: 'none',
    fontSize: '14px',
    letterSpacing: 0.4,
    height: '36px',
  },
})
