import { Box } from '@material-ui/core'
import React from 'react'
import { StandardText } from '@rse/uikit'

export function Loading() {
  return (
    <Box m={2}>
      <StandardText>Loading...</StandardText>
    </Box>
  )
}
