import React, { useEffect } from 'react'
import { FormikErrors } from 'formik'

type Props = {
  formik: {
    isSubmitting: boolean
    isValidating: boolean
    errors: FormikErrors<any>
  }
}

export function FormikScrollToError({ formik }: Props) {
  useEffect(() => {
    if (formik.isSubmitting && !formik.isValidating) {
      const keys = Object.keys(formik.errors)
      if (keys.length > 0) {
        const key = keys[0]
        const selector = Array.isArray(formik.errors[key])
          ? `[name='${key}[${(formik.errors[key] as string[]).findIndex(Boolean)}]']`
          : `[name=${key}]`
        const errorElement = document.querySelector(selector) as HTMLElement
        if (errorElement) {
          errorElement.focus()
        }
      }
    }
  }, [formik.errors, formik.isSubmitting, formik.isValidating])

  return null
}
