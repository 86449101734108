import { Box, styled } from '@material-ui/core'
import React from 'react'
import { InputLabelProp } from '../types/components'

export const BoxSummaryItem: React.FC<InputLabelProp> = ({ children, label }) => {
  return (
    <Box mt="15px">
      <SummaryLabel>{label}</SummaryLabel>
      <SummaryValue>{children}</SummaryValue>
    </Box>
  )
}

const SummaryLabel = styled('p')(({ theme }) => ({
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '120%',
  color: theme.palette.type === 'light' ? '#8C8AAC' : theme.palette.common.white,
  margin: '0',
  marginBottom: 5,
}))

const SummaryValue = styled('p')(({ theme }) => ({
  fontFamily: 'Quicksand',
  fontWeight: 'bold',
  fontSize: '16px',
  lineHeight: '120%',
  color: theme.palette.type === 'light' ? '#351F65' : theme.palette.common.white,
  margin: '0',
}))
