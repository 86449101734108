import React from 'react'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { SectionHeader, StandardText, Spacing } from '@rse/uikit'
import { InputSelectRadioPills } from '../components/InputSelectRadioPills'
import { InputText } from '../components/InputText'
import { useFormData } from '../helpers/useFormData'

export const ProfileGender: React.FC = () => {
  const textContent = useTextContent()
  const { formData } = useFormData()

  const options = [
    {
      id: 'female',
      title: (
        <StandardText>
          <TextContent resourceKey="app-wizard.profile-gender-female" defaultValue="&#9792; Female" />
        </StandardText>
      ),
    },
    {
      id: 'male',
      title: (
        <StandardText>
          <TextContent resourceKey="app-wizard.profile-gender-male" defaultValue="&#9794; Male" />
        </StandardText>
      ),
    },
    {
      id: 'nonbinary',
      title: (
        <StandardText>
          <TextContent resourceKey="app-wizard.profile-gender-other" defaultValue="... Other" />
        </StandardText>
      ),
    },
  ]

  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent resourceKey="app-wizard.title-gender" defaultValue="Gender" />
      </SectionHeader>
      <Spacing value={20} />
      <InputSelectRadioPills id="sex" options={options} />
      <div hidden={formData.sex !== 'nonbinary'}>
        <Spacing value={20} />
        <InputText id="gender" label={getTextContent(textContent, 'app-wizard.field-gender', 'Gender (optional)')} />
      </div>
    </section>
  )
}
