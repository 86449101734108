import { useEffect, useState } from 'react'
import { firebaseCollectionNames, firebaseGetChatThread, firebaseInstance, useBuyingGroup } from '../../../../firebase'
import { ChatThread } from '../../../../models'

export function useChatThread(threadId: string) {
  // TODO: refactor to use firebase hooks
  const [found, setFound] = useState<boolean|'loading'>('loading')
  const [state, setState] = useState<ChatThread>({
    loading: true,
    sections: [],
    type: 'GroupCourtship',
  })
  const { document: { value: group, loading: groupLoading, error: groupError }} = useBuyingGroup(state.groupId || 'NOBODY')

  const loadMessages = async (status?: { cancelled: boolean }) => {
    if (threadId) {
      const threadState = await firebaseGetChatThread(threadId)
      if (threadState === null) setFound(false)
      else setFound(true)

      if (!status?.cancelled) {
        setState((state) => ({ ...state, ...threadState, loading: false }))
      }
    } else {
      if (!status?.cancelled) {
        setState((state) => ({ ...state, data: [], loading: false }))
      }
    }
  }

  useEffect(() => {
    if (state.type === 'GroupCourtship' && !groupLoading) {
      setState(state => ({ ...state, title: group?.headline || 'Unknown Group'}))
    }

  }, [state.type, group, groupLoading])

  useEffect(() => {
    const status = { cancelled: false }
    const threadPath = `${firebaseCollectionNames.messageThreads}/${threadId}/${firebaseCollectionNames.messages}`
    const detach = firebaseInstance.db.collection(threadPath).onSnapshot(() => {
      !status.cancelled && loadMessages(status)
    })
    return () => {
      detach()
      status.cancelled = true
    }
  }, [threadId])

  return { ...state, loadMessages, found }
}
