import React from 'react'
import { Container, Paper, makeStyles, createStyles, Theme } from '@material-ui/core'
import { AppLayout, Header, Footer } from '../../components/layout'
import { LegalPageContent, LegalContentType } from '.'

const useStyles = makeStyles(({ spacing, shape }: Theme) =>
  createStyles({
    scrollBox: {
      maxHeight: 'calc(100vh - 160px)',
      scrollX: 'none',
      overflowY: 'auto',
      padding: spacing(2),
      borderRadius: shape.borderRadius,
      wordWrap: 'break-word',
    },
  })
)

type Props = {
  contentType: LegalContentType
}

export function LegalPage({ contentType }: Props) {
  const { scrollBox } = useStyles()

  return (
    <AppLayout
      header={<Header />}
      body={
        <Container maxWidth="xs">
          <Paper elevation={0} className={scrollBox}>
            <LegalPageContent contentType={contentType} />
          </Paper>
        </Container>
      }
      footer={<Footer />}
    />
  )
}
