import React, { useContext } from 'react'
import { Box, useTheme } from '@material-ui/core'
import { ThemeContext } from '../../../../common/theme/ThemeContext'
import { LogoutButton } from '../../../inputs'
import { UserContext } from '../../../../App'
import { HeaderBox } from './HeaderBox'
import { Header } from '../../../../components/layout'

export function SignUpHeader() {
  const { palette } = useTheme()
  const user = useContext(UserContext)
  const { type } = useContext(ThemeContext)
  const bgColor = type === 'light' ? palette.primary.main : 'transparent'
  const logoColor = type === 'light' ? 'white' : palette.primary.main

  return (
    <HeaderBox bgcolor={bgColor}>
      <Header title="Sign Up" hideMenu={true}>
        <Box display="flex" alignItems="center" color={logoColor}>
          {user && <LogoutButton buttonType="icon" />}
        </Box>
      </Header>
    </HeaderBox>
  )
}
