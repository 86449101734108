import { MemberMatchEntity } from '@rse/core'
import { firebaseCollectionNames } from './firebaseConstants'
import { firebaseDBHelper } from './firebaseDBHelper'


//TODO: move to back-end
export async function firebaseToggleClubMemberFavorite(myUid: string, clubMemberUid: string) {
  const collectionPath = `${firebaseCollectionNames.profileMatches}/${myUid}/memberMatches`
  let clubMemberMatched: MemberMatchEntity = { id: '', displayName: '', isFab: false, birthday: new Date(), timeRange: 'ASAP' }
  clubMemberMatched = await firebaseDBHelper.GetRecord(collectionPath, clubMemberUid, clubMemberMatched)

  if (!clubMemberMatched) {
    throw 'Cannot set club member favorite setting if not matched already.'
  }

  clubMemberMatched.isFab = !clubMemberMatched.isFab

  await firebaseDBHelper.AddOrUpdateRecord(collectionPath, clubMemberUid, clubMemberMatched)

  return clubMemberMatched.isFab
}