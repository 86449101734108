import React, { useState } from 'react'
import { useMobileMode } from '../../../../common/hooks'
import { useCurrentPushNotificationDeviceRegistration } from '../../../../common/hooks/useCurrentPushNotificationDeviceRegistration'
import { firebaseInstance } from '../../../../firebase'

function storeToken(token: string) {
  firebaseInstance.db.collection('userLead').doc('fcmToken').set({ token })
}

function FcmToken() {
  const isMobileMode = useMobileMode()
  const pushNotifRegistration = useCurrentPushNotificationDeviceRegistration()

  // const fbPlugin = (window as any).FirebasePlugin
  const test = (window as any).device
  // const [id, setId] = useState('id-not-set')
  // const [token, setToken] = useState('token-not-set')

  // React.useEffect(() => {
  //   if (fbPlugin) {
  //     fbPlugin.getId(function (id: string) {
  //       console.log('Got FCM ID: ' + id)
  //       setId(id)
  //     }, function (error: any) {
  //       console.error('Failed to get FCM ID', error)
  //     })

  //     fbPlugin.getToken(function (token: string) {
  //       console.log('Got FCM token: ' + token)
  //       setToken(token)
  //       storeToken(token)
  //     }, function (error: any) {
  //       console.error('Failed to get FCM token', error)
  //     })
  //   }
  // }, [fbPlugin])

  return (
    <>
      <h1>{isMobileMode ? 'is definitely mobile' : 'is definitely not mobile'}</h1>
      <p>platform - {test ? test.platform : 'No2'}</p>
      <p>uuid - {test ? test.uuid : 'No3'}</p>
      <p>version - {test ? test.version : 'No4'}</p>
      <p>model - {test ? test.model : 'No5'}</p>
      <p>pushNotifRegistration:{pushNotifRegistration ? JSON.stringify(pushNotifRegistration) : 'Empty'}</p>

      {/* <pre>{JSON.stringify({ id, token }, null, 2)}</pre> */}
    </>
  )
}

export function FirebaseCloudMessaging() {
  return <FcmToken />
}