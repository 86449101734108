import React from 'react'
import { TextField, makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    numberInput: {
      width: 56,
      '& input': {
        textAlign: 'center',
        fontWeight: 700,
        fontSize: '2em',
        '-moz-appearance': 'textfield',
      },
      '& input::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  })
)

interface DigitFieldsProps {
  onChange: (v: string) => void
  value: string
}
export function DigitFields({ value, onChange }: DigitFieldsProps) {
  const { numberInput } = useStyles()

  return (
    <>
      {'     '.split(' ').map((num, i) => (
        <TextField
          key={`${Date.now()}${i}`}
          className={numberInput}
          name={`code[${i}]`}
          type="number"
          variant="outlined"
          value={value.length > i ? value[i] : ''}
          onKeyDown={(e) => {
            if (e.key === 'Backspace' && value.length > 0 && value.length <= i) {
              e.stopPropagation()
              const newValue = value.substr(0, value.length - 1)
              onChange(newValue)
            }
          }}
          onChange={(e) => {
            const inputs = value.split('')
            inputs[i] = e.target.value.length > 1 ? e.target.value[1] : e.target.value[0]
            const newValue = inputs.join('')
            onChange(newValue)
          }}
          inputProps={{ maxLength: 1 }}
          autoFocus={i === value.length || (i === 5 && value.length === 6)}
        />
      ))}
    </>
  )
}
