import React from 'react'
import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useBuyingGroup } from '../../firebase'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {}, // use defaults
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  })
)
interface Props {
  groupId: string
  size?: 'small' | 'medium' | 'large'
}

export function GroupAvatarPublic({ groupId, size = 'medium' }: Props) {
  const classes = useStyles()
  const {
    document: { value: group },
  } = useBuyingGroup(groupId)

  return <Avatar alt={group?.headline} src={group?.groupProfileImageUrl} className={classes[size]} />
}
