import app from 'firebase/app'
import { firebaseInstance } from './firebaseInstance'
import { GetError, ErrorType, firebaseOnCallFunctions } from '../common/constants'
import { useMobileBuildInfo, useMobileMode } from '../common/hooks'
import settings from '../settings'

export function getActionCodeSettings(currentUser: app.User): app.auth.ActionCodeSettings {
  const email = currentUser.email
  const defaultUrl = `${settings.siteHostUrl}/dashboard?email=${email}`

  const isMobileMode = useMobileMode()
  if (isMobileMode) {
    const mobileBuildInfo = useMobileBuildInfo()!

    return {
      url: `${settings.firebaseAuthActionCodeSettingsUrlForMobile || defaultUrl}`,
      iOS: {
        bundleId: mobileBuildInfo.packageName
      },
      android: {
        packageName: mobileBuildInfo.packageName,
        installApp: true
      },
      handleCodeInApp: false
      // When multiple custom dynamic link domains are defined, specify which one to use.
      // dynamicLinkDomain: 'socialequityintegration.page.link'
    }
  }

  return {
    url: defaultUrl
  }
}
class FirebaseAuth {

  private static readonly AuthInvalidEmail = 'auth/invalid-email'
  private static readonly AuthUserDisabled = 'auth/user-disabled'
  private static readonly AuthUserNotFound = 'auth/user-not-found'
  private static readonly AuthWrongPassword = 'auth/wrong-password'
  private static readonly AuthTooManyRequests = 'auth/too-many-requests'
  private static readonly AuthWeakPassword = 'auth/weak-password'
  private static readonly AuthEmailAlreadyInUse = 'auth/email-already-in-use'

  public async Login(email: string, password: string): Promise<app.auth.UserCredential> {
    try {
      return await firebaseInstance.auth.signInWithEmailAndPassword(email, password)
    } catch (error) {
      console.error('Login error', error)
      return this.handleAuthenticationError(error)
    }
  }

  public async SendResetPasswordEmail(email: string): Promise<void> {
    const actionCodeSettings = {
      url: `${settings.siteHostUrl}/login`,
    }

    try {
      return await firebaseInstance.auth.sendPasswordResetEmail(email, actionCodeSettings)
    } catch (error: any) {
      console.error('sending password reset email', error)
      switch (error.code) {
        // TODO: looks like a breach that allows to find out if the email is registered or not
        case FirebaseAuth.AuthInvalidEmail:
          throw GetError(ErrorType.InvalidEmail)
        case FirebaseAuth.AuthUserNotFound:
          throw GetError(ErrorType.UserNotFound)
        default:
          throw GetError(ErrorType.AuthServiceError)
      }
    }
  }

  public async SignUp(
    { displayName, email, password }
      : { displayName: string, email: string, password: string })
    : Promise<void> {
    try {
      // TODO make sure currentUser is updated to newly created user
      await firebaseInstance.auth.createUserWithEmailAndPassword(email, password)
      await firebaseInstance.auth.currentUser?.updateProfile({ displayName })
    } catch (error: any) {
      console.log(error)
      switch (error.code) {
        case FirebaseAuth.AuthEmailAlreadyInUse:
          throw GetError(ErrorType.UserAlreadyExists)
        case FirebaseAuth.AuthWeakPassword:
          throw GetError(ErrorType.WeakPassword)
        default:
          throw GetError(ErrorType.SignUpServiceError)
      }
    }
  }

  public async ReAuthenticate(password: string): Promise<app.auth.UserCredential> {
    const currentUser = firebaseInstance.auth.currentUser
    if (!currentUser) {
      throw GetError(ErrorType.UserNotLoggedIn)
    }

    try {
      const authCredential = app.auth.EmailAuthProvider.credential(currentUser.email!, password)
      return await currentUser.reauthenticateWithCredential(authCredential)
    } catch (error) {
      return this.handleAuthenticationError(error, {
        [FirebaseAuth.AuthWrongPassword]: ErrorType.WrongPassword
      })
    }
  }

  public async ChangePassword(newPassword: string): Promise<void> {
    const currentUser = firebaseInstance.auth.currentUser
    if (!currentUser) {
      throw GetError(ErrorType.UserNotLoggedIn)
    }

    try {
      return await currentUser.updatePassword(newPassword)
    } catch (error) {
      return this.handleAuthenticationError(error, {
        [FirebaseAuth.AuthWeakPassword]: ErrorType.WeakPassword
      })
    }
  }

  private handleAuthenticationError<T>(error: any, overrides?: { [key: string]: ErrorType }): Promise<T> {
    const errorCode = error.code
    let result = ErrorType.AuthServiceError

    if (errorCode != null && typeof errorCode === 'string') {
      if (overrides && errorCode in overrides && overrides[errorCode]) {
        result = overrides[errorCode]
      } else {
        switch (errorCode) {
          case FirebaseAuth.AuthInvalidEmail:
          case FirebaseAuth.AuthUserDisabled:
          case FirebaseAuth.AuthUserNotFound:
          case FirebaseAuth.AuthWrongPassword: {
            result = ErrorType.AuthServiceInvalidLogin
            break
          }
          case FirebaseAuth.AuthTooManyRequests: {
            result = ErrorType.AuthServiceTooManyRequests
            break
          }
        }
      }
    }

    return Promise.reject(GetError(result))
  }

  public async ValidateNewPassword(password: string): Promise<void> {
    let validationErrors: string[] | null | undefined

    try {
      const functionCallable = firebaseInstance.firebaseFunction.httpsCallable(firebaseOnCallFunctions.validateNewPassword)
      const functionResult = await functionCallable({ password })
      validationErrors = functionResult?.data
    } catch (error) {
      throw GetError(ErrorType.AuthServiceError)
    }

    if (validationErrors?.length) {
      throw GetError(ErrorType.WeakPassword, validationErrors.join(' '))
    }
  }

}

export const firebaseAuth = new FirebaseAuth()