import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { URL_MESSAGES, URL_MESSAGES_ARCHIVES } from '../../common/constants/routes'
import ArchivesContent from './Archives/ArchivesContent'
import { ChatList } from './ChatList'
import { ChatShortcut } from './ChatShortcut'
import { AppLayout, Header, Footer } from '../../components/layout'
import LeaveThread from './Archives/LeaveThread'

export default function MessagingPage() {
  return (
    <>
      <Switch>
        <Route path={URL_MESSAGES} exact>
          <ChatList />
        </Route>
        <Route path="/messages/peer/user/:peerId">
          <AppLayout header={<Header title="Connecting to user chat..." />} body={<ChatShortcut isGroup={false} />} footer={<Footer />} />
        </Route>
        <Route path="/messages/peer/group/:peerId">
          <AppLayout header={<Header title="Connecting to group chat..." />} body={<ChatShortcut isGroup={true} />} footer={<Footer />} />
        </Route>
        <Route path={URL_MESSAGES_ARCHIVES} exact>
          <AppLayout header={<Header title="Archived Chat Threads" />} body={<ArchivesContent />} footer={<Footer />} />
        </Route>
        <Route path="/messages/leave/:threadId" exact>
          <AppLayout header={<Header back={'history'} title="Leave Chat Thread" />} body={<LeaveThread />} footer={<Footer />} />
        </Route>        
      </Switch>
    </>
  )
}
