import { styled, TextField, TextFieldProps } from '@material-ui/core'
import React from 'react'
import { useFormData } from '../helpers/useFormData'
import { validateFields } from '../helpers/validation'
import type { InputId, InputOmits } from '../types/components'

type Props = InputId & Omit<TextFieldProps, InputOmits>

export const InputText: React.FC<Props> = ({
  id,
  fullWidth = true,
  variant = 'outlined',
  multiline = false,
  rows = 10,
  inputProps = { style: { fontWeight: 700 } },
  ...rest
}) => {
  const { formData, formErrors, onFieldChange, onValidationChange: onValidationErrors } = useFormData()
  const handleBlur = () => {
    onValidationErrors(validateFields(formData, [id]))
  }
  return (
    <TextField
      name={id}
      value={formData[id] || ''}
      onChange={(e) => onFieldChange(id, e.target.value)}
      onBlur={handleBlur}
      variant={variant}
      fullWidth={fullWidth}
      error={!!formErrors[id]}
      helperText={formErrors[id]}
      multiline={multiline}
      rows={rows}
      inputProps={inputProps}
      {...rest}
    />
  )
}
