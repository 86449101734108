import React from 'react'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'
import { ReactComponent as EmailIcon } from '../../assets/verifiedMail.svg'
import { SMSVerifyNav } from '../../../../common/constants/routes'

export function EmailVerifiedContent() {
  return (
    <DashboardBanner
      icon={EmailIcon}
      viewBox="0 0 42 42"
      title="Email Verified!"
      titleKey="dashboard.email-verified-title"
      subtext="Next Step: Verifying your phone number"
      subtextKey="dashboard.email-verified-subtext"
      linkTitle="Next"
      linkTitleKey="dashboard.phone-verify-link"
      linkURL={SMSVerifyNav.path}
    ></DashboardBanner>
  )
}
