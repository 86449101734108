import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { AppRoutes } from '../../App/routeUtils'

import { ChangePasswordPage } from './ChangePasswordPage'
import { ChangePhonePage } from './ChangePhonePage'
import { SettingsPage } from './SettingsPage'

export function SettingsPageRoot() {
  return (
    <Switch>
      <Route path={AppRoutes.changePassword()} exact>
        <ChangePasswordPage />
      </Route>
      <Route path={AppRoutes.changePhone()} exact>
        <ChangePhonePage />
      </Route>
      <Route path={AppRoutes.settings()}>
        <SettingsPage />
      </Route>
    </Switch>
  )
}
