import { styled } from '@material-ui/core'

export const TextNotice = styled('div')(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.type === 'light' ? theme.palette.text.primary : theme.palette.background.default,
  margin: 0,
  lineHeight: '19.6px',
  letterSpacing: '0.15px',
  marginBottom: 5,
}))
