import React from 'react'
import { styled } from '@material-ui/core/styles'
import { Box, Chip } from '@material-ui/core'
import { StandardText } from '@rse/uikit'

type Props = {
  description?: string
  tags?: string[]
}

const CmUserAboutMe = ({ description, tags = [] }: Props) => {
  return (
    <>
      <StandardText>{description}</StandardText>
      <Box mt={2}>
        {tags.map((tag: string) => (
          <ChipStyled key={tag} label={tag?.toUpperCase()} size="small" variant="outlined" />
        ))}
      </Box>
    </>
  )
}

const ChipStyled = styled(Chip)({
  margin: '5px',
  backgroundColor: 'transparent',
  fontWeight: 'bold',
})

export default CmUserAboutMe
