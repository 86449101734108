import { Box, Checkbox, FormControlLabel, styled } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { QuestionComponentProps } from '.'
import { TextContent } from '../../../../common/textContent'
import { TextError } from '../../../../pages/Application/components/TextError'
import { ScrollContainer } from '../../../inputs'
import { StandardText } from '@rse/uikit'
import { Dictionary } from '../../types'

export default function TermsAcceptInput({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  QuestionChoices,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const resourceKey: string = otherProps.resourceKey || 'notconfigured'
  const defaultValue: string = otherProps.defaultValue || 'This text has not been configured...'
  const options = QuestionChoices || []
  const color = otherProps.color || 'primary'

  const [scrollComplete, setScrollComplete] = useState(true)
  const [isValid, setIsValid] = useState(true)
  const [choiceValues, setChoiceValues] = useState<Dictionary<boolean>>({})
  const [currentAnswer, setCurrentAnwser] = useState(false)
  const [choicesLoaded, setChoicesLoaded] = useState(false)

  useEffect(() => {
    let initAnswer = false
    const currentchoinces: Dictionary<boolean> = {}
    if (Answer && Answer.length > 0) {
      if (Answer[0].toLowerCase() == 'true') {
        initAnswer = true
      }

      options.map((x, i) => (currentchoinces[i] = Answer.includes(x.Value)))
      setChoiceValues(currentchoinces)
    }
    setCurrentAnwser(initAnswer)
    setChoicesLoaded(true)
    setErrorState(scrollComplete, currentchoinces)
  }, [])

  // use target object to calculate if scrolled to bottom of div
  function elementIsScrolledToBottom(e: React.UIEvent<HTMLElement>): boolean {
    const { clientHeight, scrollTop, scrollHeight } = e.currentTarget,
      calc = clientHeight + scrollTop

    return calc >= scrollHeight - 5 // allow threshold for browser calcs
  }

  function scrollHandler(e: React.UIEvent<HTMLElement, UIEvent>) {
    const done = elementIsScrolledToBottom(e)

    if (!scrollComplete) {
      setScrollComplete(true)
    }

    setErrorState(done, choiceValues)
  }

  function setErrorState(scrollDone: boolean, currentChoiceValues: Dictionary<boolean>) {
    let isCurrentValuesValid = true
    const newAnswers: string[] = []

    options.forEach((x, i) => {
      if (currentChoiceValues[i]) {
        newAnswers.push(x.Value)
      }
    })

    if (newAnswers.length != options.length) {
      isCurrentValuesValid = false
    }

    if (!scrollDone) {
      isCurrentValuesValid = false
    }

    setIsValid(isCurrentValuesValid)
    ValidStatusUpdateHandler(isValid)
    if (isValid) {
      AnswerUpdateHandler(['true'])
    } else {
      AnswerUpdateHandler(['false'])
    }
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const clonedChoiceValues = Object.assign({}, choiceValues)
    clonedChoiceValues[e.target.value] = e.target.checked
    setChoiceValues(clonedChoiceValues)

    setErrorState(scrollComplete, clonedChoiceValues)
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      <Box py={2}>
        <ScrollContainer scrollHandler={scrollHandler}>
          <StandardText>
            <TextContent defaultValue={defaultValue} resourceKey={resourceKey} />
          </StandardText>
        </ScrollContainer>
      </Box>

      {choicesLoaded &&
        options.map((x, i) => (
          <Box key={`db${QuestionConfig.QuestionId}_${i}`}>
            <FormControlLabel
              control={
                <Checkbox
                  id={`cb${QuestionConfig.QuestionId}_${i}`}
                  name={`cb${QuestionConfig.QuestionId}_${i}`}
                  value={i}
                  onChange={changeHandler}
                  checked={choiceValues[i]}
                  color={color}
                  {...otherProps}
                />
              }
              label={<LabelText>{x.Label}</LabelText>}
            />
          </Box>
        ))}

      {!isValid && displayQuestionErrors && <TextError>Must read terms and check all items to proceed.</TextError>}
    </>
  )
}

const LabelText = styled('span')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary,
}))
