import { firebaseInstance } from './firebaseInstance'
import { UserNotification } from '../models'
import { firebaseCollectionNames } from './firebaseConstants'
import { NotificationType } from '../models'

export async function firebaseSetUserNotificationRead(userId: string, notificationId: string) {
  const data = (
    await firebaseInstance.db
      .collection(firebaseCollectionNames.userProfiles)
      .doc(userId)
      .collection(firebaseCollectionNames.notifications)
      .doc(notificationId)
      .get()
  ).data() as UserNotification

  if (!data.readDate) {
    await firebaseInstance.db
      .collection(firebaseCollectionNames.userProfiles)
      .doc(userId)
      .collection(firebaseCollectionNames.notifications)
      .doc(notificationId)
      .update({ ...data, readDate: new Date() })
  }
}

export async function firebaseSendGroupJoinedNotification(userId: string, groupId: string) {
  await firebaseInstance.db
    .collection(firebaseCollectionNames.userProfiles)
    .doc(userId)
    .collection(firebaseCollectionNames.notifications)
    .add({
      type: NotificationType.GroupJoined,
      incomingDate: new Date(),
      groupId,
    })
}

export async function firebaseSendGroupJoinedNotificationToMembers(
  membersId: string[],
  groupId: string,
  userId: string
) {
  for (const uid of membersId) {
    await firebaseInstance.db
      .collection(firebaseCollectionNames.userProfiles)
      .doc(uid)
      .collection(firebaseCollectionNames.notifications)
      .add({
        type: NotificationType.NewMemberJoined,
        incomingDate: new Date(),
        groupId,
        userId,
      })
  }
}
