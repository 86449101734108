import React, { useState } from 'react'
import { NotificationDevice, NotificationDeviceRegistration } from '@rse/core'
import settings from '../../settings'
import { useBuildInfo } from './useBuildInfo'
import { useApiFetch } from '@rse/frontend-dal'
import { firebaseInstance } from '../../firebase'

export function usePushNotificationDeviceRegistration(currentUserId: string) {
  const [errors, setErrors] = useState<string>('')
  const [fcmToken, setFcmToken] = useState<string>('')
  const [appId, setAppId] = useState<string>('')
  const [pushNotificationDeviceRegistration, setPushNotificationDeviceRegistration] = useState<NotificationDeviceRegistration | undefined>()
  const { buildInfo } = useBuildInfo()

  const registerDeviceApi = useApiFetch(`${settings.firebaseFunctionsBaseUrl}api/pushNotifications/registerDevice`, true, 'POST', true, firebaseInstance.auth)

  let tokenCheckCounter = 0

  const storeToken = async (userId: string, token: string, firebaseAppId: string) => {
    const devicePlugin = (window as any).device
    let currentDevice: NotificationDevice | undefined = undefined
    if (devicePlugin) {
      currentDevice = {
        UUId: devicePlugin.uuid,
        FirebaseAppID: firebaseAppId,
        Model: devicePlugin.model,
        OS: devicePlugin.platform,
        OSVersion: devicePlugin.version
      }
    }

    const deviceRegistration: NotificationDeviceRegistration = {
      FCMToken: token,
      ClientVersion: buildInfo.version,
      UserId: userId,
      Device: currentDevice
    }

    setPushNotificationDeviceRegistration(deviceRegistration)
    await registerDeviceApi.callApi(deviceRegistration)
    console.log(deviceRegistration)
  }


  const grantPermissionCallback = function () {
    checkNotificationPermission(true)
  }

  const checkNotificationPermission = function (requested: boolean) {
    const FirebasePlugin = (window as any).FirebasePlugin
    FirebasePlugin.hasPermission(function (hasPermission: boolean) {
      if (hasPermission) {
        // Granted
        console.log('Remote notifications permission granted')
      } else if (!requested) {
        // Request permission
        console.log('Requesting remote notifications permission')
        FirebasePlugin.grantPermission(grantPermissionCallback)
      } else {
        // Denied
        console.log('Notifications won\'t be shown as permission is denied')
      }
    })
  }

  const getTokenAndAppId = () => {
    const fbPlugin = (window as any).FirebasePlugin
    if (fbPlugin) {
      fbPlugin.getToken(function (token: string) {
        setFcmToken(token)
      }, function (error: string) {
        setErrors(error)
      })

      fbPlugin.getId(function (id: string) {
        setAppId(id)
      }, function (error: string) {
        setErrors(error)
      })
    }
  }

  const pollFcmToken = () => {
    if (fcmToken || tokenCheckCounter > 30) {
      clearTimeout(pluginCheckerTimeout)
    }

    getTokenAndAppId()

    tokenCheckCounter = tokenCheckCounter + 1
  }

  const pluginCheckerTimeout = setTimeout(pollFcmToken, 500)

  React.useEffect(() => {
    const fbPlugin = (window as any).FirebasePlugin

    if (fbPlugin) {
      fbPlugin.onTokenRefresh(function () {
        getTokenAndAppId()
      }, function (error: string) {
        setErrors(error)
      })

      checkNotificationPermission(false)
    }
  }, [])

  React.useEffect(() => {
    if ((window as any).FirebasePlugin) {
      storeToken(currentUserId, fcmToken, appId)
    }
  }, [currentUserId, appId, fcmToken])

  return { pushNotificationDeviceRegistration, fcmToken, errors }
}