/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useContext, useState } from 'react'
import { Box, styled, createStyles, makeStyles, Divider, Container, Avatar, Theme, Button } from '@material-ui/core'
import { DateRange, LocationOn, AttachMoney } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import { BuyingGroupMember } from '@rse/core'

import {
  BuyingGroupMembersView,
  BuyingGroupFavIcon,
  TagsView,
  PreferenceListView,
  PreferenceListItem,
} from '../../../components/common'

import {
  Popup,
  useLoadingIndicator,
  usePopup,
  PreviewTitle,
  SectionHeader,
  StandardText,
  ButtonLarge,
  ButtonLargeSecondary,
  LoadingSpinner,
} from '@rse/uikit'

import { getLgbtqFriendlyTitle, getRoommateGenderTitle } from '../../../common/constants'
import { TextContent } from '../../../common/textContent'
import { BuyingGroupWithFab } from '../models'

import { useCurrentUserId, useErrorHandler, usePreferencesQuestions } from '../../../common/hooks'
import { AppLayout, Footer, Header } from '../../../components/layout'
import { BuyingGroupDatailsEdit } from './BuyingGroupDatailsEdit'
import { UserContext } from '../../../App'
import { useHistory } from 'react-router-dom'
import { BGActions } from '../BuyingGroupDetails/components/BGActions'

import exclamationIcon from '@rse/uikit/lib/icons/exclamation.svg'
import leaveWhiteIcon from '@rse/uikit/lib/icons/leaveWhite.svg'

import SettingsSubnav from './SettingsSubnav'
import { AppRoutes } from '../../../App/routeUtils'
import { BodyContainer } from '../../../components/layout/BodyContainer'

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    imageStyle: {
      width: 60,
      height: 60,
      marginRight: spacing(1),
    },
    iconStyle: {
      height: 13,
      width: 'auto',
      marginRight: spacing(1),
    },
  })
)

// TODO get site root dynamically
// const rootUrl = 'https://int.getsocialequity.com'
// const customerSuccessAddress = 'Jennie Marie Staton <jennie@realsocialequity.com>'
// const ccList = ['galina@getsocialequity.com', 'justis@getsocialequity.com', 'justis.peters@gmail.com']
// async function finalizeGroup(group: BuyingGroup) {
//   const url = `${rootUrl}/groups/${group.id}/view`
//   await firebaseInstance.db.collection('mail').add({
//     to: customerSuccessAddress,
//     cc: ccList,
//     message: {
//       subject: `Group finalized: ${group.headline}`,
//       text: `The "${group.headline}" group is finalized!
//       You can see their details at ${url}.`,
//       html: `The "${group.headline}" group is finalized!
//       You can see their details at <a href="${url}">${url}</a>.`,
//     },
//   })
// }

interface BuyingGroupDetailProps {
  group: BuyingGroupWithFab
  groupMembers: BuyingGroupMember[]
  leaveGroup?: () => Promise<void>
  createGroup?: () => void
  deleteGroup?: () => Promise<void>
  finalizeGroup?: () => Promise<void>
  previewMode?: boolean
  previewGoBack?: () => void
}

export default function BuyingGroupDetail({
  group,
  groupMembers,
  leaveGroup,
  createGroup,
  deleteGroup,
  finalizeGroup,
  previewMode = false,
  previewGoBack,
}: BuyingGroupDetailProps) {
  const { imageStyle, iconStyle } = useStyles()
  const userId = useCurrentUserId()
  const user = useContext(UserContext)
  const history = useHistory()

  const {
    value: preferencesQuestions,
    loading: loadingQuestions,
    error: errorLoadingQuestions,
  } = usePreferencesQuestions()

  const { errorHandler: loadQuestionsErrorHandler } = useErrorHandler('loading preferences questions')

  const [editMode, setEditMode] = useState<boolean>(false)

  const { Popup: DeleteConfirmationPopup, setPopupOpen: setDeleteGroupPopupOpen } = usePopup()
  const [leaveGroupPopupOpen, setLeaveGroupPopupOpen] = useState<boolean>(false)

  const { wrapPromise } = useLoadingIndicator()

  const { errorHandler: deleteGroupErrorHandler } = useErrorHandler('deleting buying group')
  const { errorHandler: leaveGroupErrorHandler } = useErrorHandler('leaving buying group')

  const [minPrice, maxPrice] = group.priceRange
  const [minAge, maxAge] = group.ageRange
  const [minSize, maxSize] = group.roommates
  const imgSrc = group.groupProfileImageUrl
  const userIsGroupHost = (group.hostId == userId) ?? false
  const hostIsTheOnlyMember = groupMembers.length === 1

  const homeMatePreferenceListItems: PreferenceListItem[] = [
    { name: 'Age Range', value: `${minAge}-${maxAge}` },
    { name: 'Gender', value: getRoommateGenderTitle(group.gender) },
    { name: 'LGBTQ Friendly', value: getLgbtqFriendlyTitle(group.lgbtqFriendly) },
    { name: 'Group Size', value: `${minSize}-${maxSize}` },
  ]

  if (!user) {
    throw new Error('User must be authorized')
  }

  if (loadingQuestions) {
    return <BodyContainer><LoadingSpinner /></BodyContainer>
  }

  if (errorLoadingQuestions || !preferencesQuestions) {
    loadQuestionsErrorHandler(errorLoadingQuestions)
    return null
  }

  if (editMode) {
    return <BuyingGroupDatailsEdit group={group} cancelEdit={() => setEditMode(false)} />
  }

  const deleteGroupStartAction = !previewMode && !!deleteGroup ? async () => setDeleteGroupPopupOpen(true) : undefined
  const removeMemberStartAction =
    !previewMode && userIsGroupHost && !hostIsTheOnlyMember
      ? async () => {
        history.push(AppRoutes.groupMembers(group.id))
      }
      : undefined

  const finalizeGroupStartAction =
    !previewMode && userIsGroupHost && finalizeGroup ? async () => finalizeGroup() : undefined

  return (
    <AppLayout
      header={
        <Header
          back={async () => (previewMode && previewGoBack ? previewGoBack() : history.goBack())}
          title={previewMode ? 'Preview Group' : 'Buying Group'}
          hideMenu
        >
          <SettingsSubnav
            group={group}
            leaveGroup={leaveGroup ? async () => setLeaveGroupPopupOpen(true) : undefined}
            deleteGroup={deleteGroupStartAction}
            removeMember={removeMemberStartAction}
            finalizeGroup={finalizeGroupStartAction}
          />
        </Header>
      }
      body={
        <Container maxWidth="xs">
          <DeleteConfirmationPopup>
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <img src={exclamationIcon} alt="exclamation icon" />
              <Box my={3}>
                <Box mb={2}>
                  <TextContent
                    resourceKey="bg-removal.confirmation-copy-part-1"
                    defaultValue="All members will be removed from the group and the group will be permanently deleted."
                  />
                </Box>
                <Box style={{ fontWeight: 'bold' }}>
                  <TextContent
                    resourceKey="bg-removal.confirmation-copy-part-2"
                    defaultValue="You cannot undo this action. Are you sure you wish to proceed?"
                  />
                </Box>
              </Box>
              <Box width="100%">
                <ButtonLarge
                  fullWidth
                  isUppercase={false}
                  onClick={async () => {
                    setDeleteGroupPopupOpen(false)
                    if (deleteGroup) {
                      wrapPromise(deleteGroup()).catch(deleteGroupErrorHandler)
                    }
                  }}
                >
                  <TextContent resourceKey="buttons.yes" defaultValue="Yes" />
                </ButtonLarge>
                <Box mt={1}>
                  <ButtonLargeSecondary
                    fullWidth
                    isUppercase={false}
                    onClick={() => {
                      setDeleteGroupPopupOpen(false)
                    }}
                  >
                    <TextContent resourceKey="buttons.no" defaultValue="No" />
                  </ButtonLargeSecondary>
                </Box>
              </Box>
            </Box>
          </DeleteConfirmationPopup>

          <Popup open={leaveGroupPopupOpen} setOpen={setLeaveGroupPopupOpen}>
            <Box mb={2} display="flex" flexDirection="column" alignItems="center">
              <img src={exclamationIcon} alt="exclamation icon" />
              <Box mt={2} />
              <SectionHeader style={{ textAlign: 'center' }}>
                Are you sure you want to
                <br />
                leave the group?
              </SectionHeader>
            </Box>
            <Box mt={3}>
              <Box mb={2}>
                <ConfirmButton
                  fullWidth
                  isUppercase={false}
                  onClick={async () => {
                    setLeaveGroupPopupOpen(false)
                    if (leaveGroup) {
                      wrapPromise(leaveGroup()).catch(leaveGroupErrorHandler)
                    }
                  }}
                >
                  <img src={leaveWhiteIcon} alt="group leave icon" />
                  <span>Leave Group</span>
                </ConfirmButton>
              </Box>
              <CancelButton
                fullWidth
                isUppercase={false}
                onClick={() => {
                  setLeaveGroupPopupOpen(false)
                }}
              >
                Cancel
              </CancelButton>
            </Box>
          </Popup>

          <Box mt={4} mb={2}>
            <Box display="flex" mb={2}>
              <Box display="flex" flexDirection="column" width="100%" position="relative">
                <Box display="flex" justifyContent="space-between" pr={5} mb={2} width="100%">
                  <PreviewTitle>{group.headline}</PreviewTitle>
                  <Box position="absolute" right={0} top={-14}>
                    <BuyingGroupFavIcon groupId={group.id} isFab={group.isFab} />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <StandardText>
                    <DateRange className={iconStyle} />
                    {group.timeRange}
                  </StandardText>
                  {group.location?.length && (
                    <StandardText>
                      <LocationOn className={iconStyle} />
                      {group.location.join(' | ')}
                    </StandardText>
                  )}
                  <StandardText>
                    <AttachMoney className={iconStyle} />
                    {minPrice}-{maxPrice}
                  </StandardText>
                </Box>
              </Box>
            </Box>
            <Box>
              {group.groupProfileImageUrl ? (
                <Box borderRadius={12} overflow="hidden">
                  <img src={group.groupProfileImageUrl} height={225} width="100%" style={{ objectFit: 'cover' }} />
                </Box>
              ) : <></>}
            </Box>
            {userIsGroupHost && (
              <Box>
                {/* TODO: extract as common component */}
                <Button style={{ width: '100%', background: '#F0F0F4' }} onClick={() => setEditMode(true)}>
                  <EditIcon fontSize="small" />
                  &nbsp;&nbsp;
                  <span>Edit group</span>
                </Button>
              </Box>
            )}
          </Box>

          {!previewMode && (
            <Box my={3}>
              <BGActions userId={userId} group={group} />
            </Box>
          )}

          <Box mb={3}>
            <StandardText>{group.description}</StandardText>
            <Box mb={2}>
              <BuyingGroupMembersView group={group} />
            </Box>

            <Box my={2}>
              <TagsView tags={group.tags} />
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <PreviewTitle>
              <TextContent resourceKey="view-bg.title-people-preferences" defaultValue="HouseMate preferences" />
            </PreviewTitle>
            <PreferenceListView ariaLabel="Home Mate Preferences" rows={homeMatePreferenceListItems} />
          </Box>
          <Box mt={2}>
            <PreviewTitle>
              <TextContent resourceKey="view-bg.title-ground-rules" defaultValue="Home Lifestyle preferences" />
            </PreviewTitle>
            <PreferenceListView ariaLabel="Home Preferences" rows={group.activePreferences || []} />
          </Box>
          {previewMode && (
            <Box mt={3}>
              <ButtonLarge onClick={createGroup}>Create Group</ButtonLarge>
            </Box>
          )}
        </Container>
      }
      footer={<Footer />}
    />
  )
}

// TODO: consider making common
const ConfirmButton = styled(ButtonLarge)({
  backgroundColor: 'rgba(244, 67, 54, 0.5)',
  height: 50,
  '&:hover': {
    backgroundColor: 'rgba(248, 128, 120, 1)',
  },
  '& img': {
    marginRight: 10,
  },
})

// TODO: consider making common
const CancelButton = styled(ButtonLarge)({
  backgroundColor: '#FFFFFF',
  color: '#1A0154',
  height: 50,
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
})
