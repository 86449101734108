import React, { useContext } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { ChatTread } from './components/ChatTread'
//import { ChatThreadHeader } from './components/ChatThreadHeader'
import { useChatThread } from './hooks/useChatThread'
import { ChatInput } from './components/ChatInput'
import { BodyContainer } from '../../../components/layout/BodyContainer'
import { UserContext } from '../../../App'
import { LoadingSpinner } from '@rse/uikit'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
//import { useChatActions } from '../../../common/hooks'
import { TextContent } from '../../../common/textContent'
import { ChatLinkCard } from '../ChatList'
import { UserOnlineStatus } from '../../../models'
import { useUserMessageThread } from '../../../firebase'
import { useMessageThread } from '../../../common/hooks'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
  createStyles({
    palmButton: {
      cursor: 'pointer',
    },
    buttonGroup: {
      '& .Mui-selected': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,

        '&:hover': {
          background: '#9B2EF5',
        },
      },
    },
  })
)

export const Chat = () => {
  const history = useHistory()
  const user = useContext(UserContext)
  const { threadId } = useParams<{ threadId: string }>()
  // This is the new interface, will replace useChatThread below
  const { consent: consentToMessage, consentToGroup } = useMessageThread(threadId)
  const { sections, type, loading, found, loadMessages } = useChatThread(threadId)
  const { document: { value: thread, loading: threadLoading, error: threadError } } = useUserMessageThread({ uid: user!.id, threadId })
  //const [showVotingPopup, setShowVotingPopup] = useState(false)
  //const { leaveGroup } = useChatActions(groupId || UNAVAILABLE_DOC_ID)

  if (loading || threadLoading || !thread) return <BodyContainer><LoadingSpinner reason="loading messages" /></BodyContainer>
  // TODO style this message
  else if (found === false) return <BodyContainer><h1>The requested chat thread cannot be found.</h1></BodyContainer>
  else return (
    <BodyContainer>
      { /*
      <Popup
        open={showVotingPopup}
        setOpen={setShowVotingPopup}
        align={PopupAlign.BottomMiddle}
        borderBottomLeftRadius="0"
        borderBottomRightRadius="0"
      >
          popup goes here
      </Popup>
      */}

      <Box display="flex" flexDirection="column" height="100%">
        <ChatLinkCard
          threadId={threadId}
          title={thread.title}
          isPinned={false}
          type={thread.type}
          userStatus={UserOnlineStatus.Offline}
          unreadMessagesCount={0}
          peerId={thread.peerId}
          groupId={thread.groupId}
        />
        { /*
        <ChatThreadHeader
          title={title}
          subtitle={subtitle}
          leaveGroup={() => leaveGroup(groupId || UNAVAILABLE_DOC_ID)}
          isGroupHost={!!user!.isGroupHost && user!.buyingGroupId === groupId}
          iconControls={
            type === 'GroupCourtship' && (
              <Box className={styles.palmButton} onClick={() => setShowVotingPopup(true)}>
                Join Group
                <img src={palmIcon} alt="palm icon" />
              </Box>
            )
          }
        />
        */}
        <Box flexGrow={1} overflow="auto">
          <ChatTread sections={sections} type={type} threadId={threadId} />
        </Box>
        <Box bottom={0} position="sticky" zIndex={99}>
          {(thread.selfConsentsToMessage && type === 'GroupCourtship') && (
            <>
              <Box height={70} width="100%" display="flex" justifyContent="center" alignItems="center">
                <p>You are exploring compatibility to buy a home together. When you know each other well enough, express your interest below to join together!</p>
              </Box>
              <Box height={70} width="100%" display="flex" justifyContent="center" alignItems="center">
                <ToggleButtonGroup
                  value={thread.selfConsentsToGroup}
                  exclusive
                  onChange={(event, value) => {
                    console.log({ event, value })
                    consentToGroup(value)
                    //setConsentsToGroup(value)
                  }}
                >
                  <ToggleButton color="primary" value={true}>
                    <TextContent resourceKey="inputs.voting-in-chat-yes" defaultValue="Interested" />
                  </ToggleButton>
                  <ToggleButton value={false}>
                    <TextContent resourceKey="inputs.voting-in-chat-no" defaultValue="Not Interested" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </>
          )}
          {(!thread.selfConsentsToMessage) && (
            <>
              <Box height={70} width="100%" display="flex" justifyContent="center" alignItems="center">
                <p>This user has sent you a message request. If you reply or click 'accept', you will be able to message each other freely.</p>
              </Box>
              <Box height={70} width="100%" display="flex" justifyContent="center" alignItems="center">
                <ToggleButtonGroup
                  value={thread.selfConsentsToMessage}
                  exclusive
                  onChange={(event, value) => {
                    console.log({ event, value })
                    consentToMessage(value)
                    if(!value){
                      history.push('/messages')
                    }
                  }}
                >
                  <ToggleButton color="primary" value={true}>
                    <TextContent resourceKey="inputs.consent-to-message-yes" defaultValue="Accept" />
                  </ToggleButton>
                  <ToggleButton value={false}>
                    <TextContent resourceKey="inputs.consent-to-message-no" defaultValue="Ignore" />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </>
          )}
          <Box height={70} width="100%" display="flex" justifyContent="center" alignItems="center">
            <ChatInput threadId={threadId} loadMessages={loadMessages} />
          </Box>
        </Box>
      </Box>
    </BodyContainer>
  )
}
