import React from 'react'
import { Badge, Box, createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    unreadCount: {
      '& span.MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle': {
        position: 'initial',
        transform: 'translate(0px, 0px)',
      },
    },
  })
)

type Props = {
  count: number
}

export function UnreadMessagesCount({ count }: Props) {
  const classes = useStyles()
  return (
    <Box className={classes.unreadCount}>
      <Badge color="primary" badgeContent={count} />
    </Box>
  )
}
