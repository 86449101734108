import React, { createContext, ReactNode, useState } from 'react'
import { MuiThemeProvider, PaletteType } from '@material-ui/core'
import { createMuiThemeExtended, darkThemeData, lightThemeData } from './basetheme'

const InitialThemeType: PaletteType = 'light'

type ThemeContextParams = {
  type: PaletteType
  toggle: () => void
  setLight: () => void
  setDark: () => void
}

export const ThemeContext = createContext<ThemeContextParams>({
  type: InitialThemeType,
  toggle: () => {},
  setLight: () => {},
  setDark: () => {},
})

export const ThemeContextProvider = ({ children }: { children: ReactNode }) => {
  const [globalThemeType, setGlobalThemeType] = useState<PaletteType>(InitialThemeType)

  const lightTheme = createMuiThemeExtended(lightThemeData)
  lightTheme.overrides = {
    ...lightTheme.overrides,
    MuiSlider: {
      root: {
        color: lightTheme.palette.primary.main,
      },
      markLabel: {
        color: lightTheme.palette.text.primary,
      },
      valueLabel: {
        color: lightTheme.palette.primary.main,
      },
    },
  }

  const darkTheme = createMuiThemeExtended(darkThemeData)
  darkTheme.overrides = {
    ...darkTheme.overrides,
    MuiCssBaseline: {
      '@global': {
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
        // Remove increment arrows from number inputs by default
        'input[type=number]': {
          '&::-webkit-inner-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            margin: 0,
          },
        },

        '.MuiOutlinedInput-input:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 100px ${darkTheme.palette.background.default} inset !important`,
          '-webkit-text-fill-color': '#ffffff !important',
        },
      },
    },
    MuiSlider: {
      root: {
        color: darkTheme.palette.primary.main,
      },
      markLabel: {
        color: darkTheme.palette.text.primary,
      },
      valueLabel: {
        color: darkTheme.palette.primary.main,
      },
    },
  }

  const toggle = () => setGlobalThemeType((t) => (t === 'light' ? 'dark' : 'light'))
  const setLight = () => setGlobalThemeType('light')
  const setDark = () => setGlobalThemeType('dark')

  return (
    <ThemeContext.Provider value={{ type: globalThemeType, toggle, setLight, setDark }}>
      <ThemeContext.Consumer>
        {({ type: themeType }) => (
          <MuiThemeProvider theme={themeType === 'light' ? lightTheme : darkTheme}>{children}</MuiThemeProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeContext.Provider>
  )
}
