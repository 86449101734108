export enum ErrorType {
  APIBadRequest,
  APIInternalError,
  AuthServiceError,
  AuthServiceInvalidLogin,
  AuthServiceTooManyRequests,
  FieldIsRequired,
  InvalidEmail,
  NewEmailEqualsToSource,
  QuotaExceeded,
  PasswordEqualsToCurrent,
  PasswordsNotMatch,
  ServiceNotInitialized,
  SignUpServiceError,
  StorageError,
  SubmitContactUsError,
  SubmitUserLeadError,
  UserAlreadyExists,
  UserDataReadError,
  UserDataWriteError,
  UserNotFound,
  UserNotLoggedIn,
  WeakPassword,
  WrongPassword,
}

export const AppErrorMessages: Record<ErrorType, string> = {
  [ErrorType.APIBadRequest]: 'API bad request',
  [ErrorType.APIInternalError]: 'Internal API error.',
  [ErrorType.AuthServiceError]: 'Authentication service error.',
  [ErrorType.AuthServiceInvalidLogin]: 'Invalid login',
  [ErrorType.AuthServiceTooManyRequests]: 'Too many failed login attempts. Reset your password or try again later.',
  [ErrorType.FieldIsRequired]: 'This field is required',
  [ErrorType.InvalidEmail]: 'An invalid email was provided.',
  [ErrorType.NewEmailEqualsToSource]: 'New email must be different from the source email.',
  [ErrorType.QuotaExceeded]: 'Quota exceeded.',
  [ErrorType.PasswordEqualsToCurrent]: 'New password must be different from existing.',
  [ErrorType.PasswordsNotMatch]: 'Passwords must match',
  [ErrorType.ServiceNotInitialized]: 'The system attempted to use a service that has not been initialized.',
  [ErrorType.SignUpServiceError]: 'Could not sign up user.',
  [ErrorType.StorageError]: 'Storage Error.',
  [ErrorType.SubmitContactUsError]: 'Could not submit "Contact Us" data.',
  [ErrorType.SubmitUserLeadError]: 'Could not submit user lead data.',
  [ErrorType.UserAlreadyExists]: 'User already exists.',
  [ErrorType.UserDataReadError]: 'Could not load user data.',
  [ErrorType.UserDataWriteError]: 'Could not save user data.',
  [ErrorType.UserNotFound]: 'User not found.',
  [ErrorType.UserNotLoggedIn]: 'User not logged in.',
  [ErrorType.WeakPassword]: 'Weak password.',
  [ErrorType.WrongPassword]: 'Wrong password.',
}

export function GetError(errorType: ErrorType, customMessage?: string): Error {
  const message = customMessage ?? AppErrorMessages[errorType]
  const name = ErrorType[errorType]

  const result = new Error(message)
  result.name = name

  return result
}
