import settings from '../settings'

export const firebaseConfig = {
  apiKey: settings.firebaseApiKey,
  authDomain: settings.firebaseAuthDomain,
  databaseURL: settings.firebaseDBUrl,
  projectId: settings.firebaseProjectID,
  storageBucket: settings.firebaseStorageBucket,
  messagingSenderId: settings.firebaseMessagingSenderID,
}
