import React, { FC } from 'react'
import { Container, Typography, Box, useTheme, Divider, List } from '@material-ui/core'
import { compareDesc, sub } from 'date-fns'
import { Route, Switch } from 'react-router-dom'

import { CenteredBox } from '../../components/layout'
import { NoNotificationsView } from './views'
import { Notification } from './Notification'
import { useCurrentUserId } from '../../common/hooks'
import { UserNotification } from '../../models'
import { useUserNotifications } from '../../firebase'

export default function NotificationsPage() {
  const userId = useCurrentUserId()
  const now = new Date(),
    oneDay = sub(now, { days: 1 }),
    oneWeek = sub(now, { days: 7 })
  const { data: notifications } = useUserNotifications(userId)

  const sortedNotifications = notifications?.sort((a, b) =>
    compareDesc(a.incomingDate, b.incomingDate)
  )

  const groupedNotifications: Array<Array<UserNotification>> = [[], [], []]

  sortedNotifications?.forEach((item: any) => {
    console.log(item)
    const date = item.incomingDate
    const dateNum = date.getTime()

    if (dateNum < oneWeek.getTime()) {
      groupedNotifications[2].push(item)
    } else if (dateNum < oneDay.getTime()) {
      groupedNotifications[1].push(item)
    } else {
      groupedNotifications[0].push(item)
    }
  })

  const NotificationSubsection = ({ title, items }: { title: string, items: UserNotification[] }) => {
    if (!items?.length) {
      return null
    }

    return (
      <>
        <SectionDivider>{title}</SectionDivider>
        <Box mx={-3}>
          <List disablePadding>
            {items.map((item, i) => (
              <>
                <Notification key={i} item={item} userId={userId} />
                {!(i === items.length - 1) && <Divider />}
              </>
            ))}
          </List>
        </Box>
      </>
    )
  }

  // console.log(groupedNotifications)

  return (
    <Container maxWidth="xs">
      <Switch>
        <Route exact path={'/notifications'}>
          {sortedNotifications?.length ? (
            <>
              <NotificationSubsection title="TODAY" items={groupedNotifications[0]} />
              <NotificationSubsection title="THIS WEEK" items={groupedNotifications[1]} />
              <NotificationSubsection title="EARLIER" items={groupedNotifications[2]} />
            </>
          ) : (
            <CenteredBox height="100%">
              <NoNotificationsView />
            </CenteredBox>
          )}
        </Route>
      </Switch>
    </Container>
  )
}

// TODO: create semantic typography component for the 'overline' style
const SectionDivider: FC = (props) => {
  const { palette } = useTheme()

  return (
    <Box mx={-3} px={3} bgcolor={palette.divider}>
      <Typography variant="overline">{props.children}</Typography>
    </Box>
  )
}
