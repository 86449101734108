import React, { useState, useRef } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import DirectionsIcon from '@material-ui/icons/Directions'
//import AttachFileIcon from '@material-ui/icons/AttachFile'
//import SentimentSatisfiedSharpIcon from '@material-ui/icons/SentimentSatisfiedSharp'
import { useCurrentUserId, useErrorHandler, useMessageThread } from '../../../../common/hooks'

type Props = {
  threadId: string
  loadMessages: () => Promise<void>
}

export const ChatInput: React.VFC<Props> = ({ threadId, loadMessages }) => {
  const { errorHandler } = useErrorHandler('sending message')
  const classes = useStyles()
  const uid = useCurrentUserId()
  const thread = useMessageThread(threadId)
  const [value, setValue] = useState('')
  const [disabled, setDisabled] = useState(false)
  const newMessageInput = useRef<HTMLInputElement>(null)

  const onChange = (val: string) => {
    setValue(val)
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (value) {
      setDisabled(true)
      try {
        // TODO different error handler for send and load
        await thread.sendMessage(value)
        await loadMessages()
      } catch (err) {
        errorHandler(err)
      } finally {
        setValue('')
        setDisabled(false)

        if (newMessageInput.current) {
          newMessageInput.current.focus()
        }
      }
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Paper className={classes.root} variant="outlined">
        {/*
        <IconButton className={classes.iconButton} aria-label="menu">
          <AttachFileIcon />
        </IconButton>
        */}
        <InputBase
          className={classes.input}
          placeholder="Type a message here"
          inputProps={{ 'aria-label': 'Type a message here' }}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          autoFocus
          inputRef={newMessageInput}
        />
        {/*
        <IconButton className={classes.iconButton} aria-label="search" size="small">
          <SentimentSatisfiedSharpIcon />
        </IconButton>
        */}
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton type="submit" color="primary" className={classes.iconButton} aria-label="directions" size="small">
          <DirectionsIcon />
        </IconButton>
      </Paper>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 375,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 5,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
)
