import { Box } from '@material-ui/core'
import React from 'react'
import { BoxNoticeArea } from '../components/BoxNoticeArea'
import { InputCheckbox } from '../components/InputCheckbox'
import { Spacing } from '@rse/uikit'
import { TextNotice } from '../components/TextNotice'
import { TextStandard } from '../components/TextStandard'
import { TextHeading } from '../components/TextHeading'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'

export const StepVerification: React.FC = () => {
  const textContent = useTextContent()

  return (
    <section>
      <Spacing value={28} />
      <TextHeading>
        <TextContent resourceKey="app-wizard.verification-title" defaultValue="We are starting verification" />
      </TextHeading>
      <Spacing value={11} />
      <TextStandard>
        <TextContent
          resourceKey="app-wizard.verification-description"
          defaultValue={`Don’t worry about minor implications, we just want to know that you are who you say you are, and that you’re
        safe to live with.`}
        />
      </TextStandard>
      <Spacing value={20} />
      <Box height={260}>
        <BoxNoticeArea>
          <TextNotice>
            <strong>
              <TextContent resourceKey="app-wizard.identification-title" defaultValue="Identification" />
            </strong>
          </TextNotice>
          <TextNotice>
            <TextContent
              resourceKey="app-wizard.identification-description"
              defaultValue={`Sit quia dolore et autem voluptate. Enim esse numquam eos. Aliquam est est numquam mollitia quo maxime
            laboriosam ut. Est necessitatibus animi illum similique labore. Totam nemo expedita consequatur. Voluptates
            magni assumenda quas ex cupiditate. Sit quia dolore et autem voluptate. Enim esse numquam eos. Aliquam est
            est numquam mollitia quo maxime laboriosam ut. Est necessitatibus animi illum similique labore. Totam nemo
            expedita consequatur. Voluptates magni assumenda quas ex cupiditate.`}
            />
          </TextNotice>
        </BoxNoticeArea>
      </Box>
      <Spacing value={20} />
      <Box>
        <InputCheckbox
          id="IDCheckAuthorized"
          label={getTextContent(
            textContent,
            'app-wizard.i-authorize-identification-check',
            'I authorize Social Equity to perform my identity, credit and background checks.'
          )}
          errorMessage={getTextContent(
            textContent,
            'errors.you-must-accept-this-check',
            'You must accept this check to proceed'
          )}
        />
      </Box>
    </section>
  )
}
