import React, { useEffect, useState, useReducer } from 'react'
import { BrowserRouter, HashRouter, Switch, Route } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { LoadingIndicatorProvider, LoadingSpinner } from '@rse/uikit'
import { FirebaseAuthService, FirebaseAuthUser } from '../services'
import { firebaseInstance, FirebaseInstance } from '../firebase'
import { SnackbarStyled } from '../components/common/SnackbarStyled/SnackbarStyled'
import { SnackbarStyledProvider } from '../components/common/SnackbarStyled/useSnackbar'
import { AuthenticatedErrorBoundary, ErrorBoundary } from './ErrorBoundary'
import { AppRouter } from './AppRouter'
import LandingPage from '../pages/LandingPage'
import { LoginNav, ResetPasswordNav, SignUpNav } from '../common/constants/routes'
import { TextContentProvider } from '../common/textContent'
import { useAuthService, useMobileMode, usePushNotificationReceiver } from '../common/hooks'
import { useClientVersionSupported } from '../common/hooks/useClientVersionSupported'
import { usePushNotificationDeviceRegistration } from '../common/hooks/usePushNotificationDeviceRegistration'
import { MemberAppConfig, NotificationDeviceRegistration } from '@rse/core'
import NotFoundPage from '../pages/NotFound'
import { NotificationTempView } from '../pages/Notifications'
import { UpgradeRequiredPage } from '../pages/UpgradeRequired'
import { useUser } from '../firebase'
import { User as FirebaseUser } from '@firebase/auth-types'
import { LoadingPage } from '../pages'
import { AppConfigContext, DEFAULT_CONFIG } from './config'
import { useConfig } from '@rse/frontend-dal'
import settings from '../settings'
const nobodyUid = 'NOBODY'

type StragglingSignUpData = { firstName: string, lastName: string, displayName: string, allowExtraEmails: boolean, location: string }

type AuthState = {
  fb: FirebaseInstance
  initialized: boolean
  uid: string
  // a workaround for data we cannot save during user creation
  stragglingSignUpData?: StragglingSignUpData
  user?: FirebaseAuthUser
  firebaseUser: FirebaseUser | null
  authenticated: boolean
  service: FirebaseAuthService
  postLogoutUrl?: string
}

type LogoutAction = {
  type: 'logout'
  postLogoutUrl: string
}

type SetInitializedStateAction = {
  type: 'setInitializedState'
  initialized: boolean
}

type SetFirebaseUserAction = {
  type: 'setFirebaseUser'
  user: FirebaseUser | null
}

type SetUserAction = {
  type: 'setUser'
  user?: FirebaseAuthUser
}

type SetStragglingSignUpData = {
  type: 'setStragglingSignUpData'
  data?: StragglingSignUpData
}

type SetPostLogoutUrl = {
  type: 'setPostLogoutUrl'
  postLogoutUrl?: string
}

type AuthAction = LogoutAction | SetInitializedStateAction | SetFirebaseUserAction | SetUserAction | SetStragglingSignUpData | SetPostLogoutUrl

function authReducer(state: AuthState, action: AuthAction) {
  switch (action.type) {
    case 'setInitializedState':
      return { ...state, initialized: action.initialized }
    case 'setStragglingSignUpData':
      return { ...state, stragglingSignUpData: action.data }
    case 'setUser':
      return { ...state, user: action.user, authenticated: true }
    case 'setFirebaseUser':
      return { ...state, authenticated: true, firebaseUser: action.user, uid: action.user?.uid || nobodyUid }
    case 'logout':
      return { ...state, firebaseUser: null, user: undefined, authenticated: false, postLogoutUrl: action.postLogoutUrl }
    case 'setPostLogoutUrl':
      return { ...state, postLogoutUrl: action.postLogoutUrl }
    default:
      throw new Error('Unknown action for authReducer')
  }
}

const initialAuthState: AuthState = {
  fb: firebaseInstance,
  initialized: false,
  uid: nobodyUid,
  firebaseUser: null,
  authenticated: false,
  service: new FirebaseAuthService()
}

const fallbackAuthDispatch: React.Dispatch<AuthAction> = (action: AuthAction) => {
  console.error('Auth dispatch called outside of AuthServiceContext provider', { action })
  console.trace('fallbackAuthDispatch, nothing initialized')
}

const AuthServiceContext = React.createContext({ authState: initialAuthState, authDispatch: fallbackAuthDispatch })
const UserContext = React.createContext<FirebaseAuthUser | undefined>(undefined)
const UserIdContext = React.createContext<string>(nobodyUid)
const PushNotificationDeviceRegistrationContext = React.createContext<NotificationDeviceRegistration | undefined>(undefined)

function App() {
  const { CONFIG_URI: uri } = window.env
  const { config, loading: configLoading } = useConfig<MemberAppConfig>({ uri, defaultConfig: DEFAULT_CONFIG })
  useEffect(() => { console.log('App Config changed', config) }, [config])

  return (
    <>
      <CssBaseline />
      {configLoading ? <LoadingPage what="loading configuration" /> : <AppConfigured config={config} />}
    </>
  )
}

function AppConfigured({ config }: { config: any }) {
  const { notification, clearNotification } = usePushNotificationReceiver()
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState)
  const { pushNotificationDeviceRegistration } = usePushNotificationDeviceRegistration(authState.uid)


  useEffect(() => {
    if (authState.initialized) return
    let unwatch: () => void = () => console.debug('useAuthService unmounted before init')

    console.log('app auth service initialized')
    unwatch = authState.fb.auth.onAuthStateChanged(async (user: FirebaseUser | null) => {
      console.debug('app user changed', { user, uid: user?.uid })
      authDispatch({ type: 'setFirebaseUser', user })
    })

    const { currentUser } = authState.fb.auth
    if (currentUser) {
      console.log('somebody is already logged in, setting as current user', currentUser.email, currentUser.uid)
      authDispatch({ type: 'setFirebaseUser', user: currentUser })
    }

    authDispatch({ type: 'setInitializedState', initialized: true })

    return () => {
      console.log('unmount auth service')
      unwatch()
    }
  }, [authState.initialized])

  useEffect(() => {
    console.debug('App authState', authState)
  }, [authState])

  if (!authState.initialized) return <LoadingPage what="initializing authentication engine" />

  return (
    <AppConfigContext.Provider value={config}>
      <ErrorBoundary>
        <LoadingIndicatorProvider>
          <SnackbarStyledProvider>
            <TextContentProvider loadingIndicator={<LoadingPage what="dynamic text bundles" />}>
              <AuthServiceContext.Provider value={{ authState, authDispatch }}>
                <UserIdContext.Provider value={authState.uid}>
                  <PushNotificationDeviceRegistrationContext.Provider value={pushNotificationDeviceRegistration}>
                    {
                      notification !== false
                        ? <NotificationTempView notification={notification} back={async () => clearNotification()} />
                        : <Router><AppMain {...{ authState }} /></Router>
                    }
                  </PushNotificationDeviceRegistrationContext.Provider>
                  <SnackbarStyled />
                </UserIdContext.Provider>
              </AuthServiceContext.Provider>
            </TextContentProvider>
          </SnackbarStyledProvider>
        </LoadingIndicatorProvider>
      </ErrorBoundary>
    </AppConfigContext.Provider>
  )
}

const NOBODY: FirebaseAuthUser = {
  id: 'NOBODY',
  roles: [],
  displayName: 'NOBODY',
  email: 'nobody@realsocialequity.com',
  homeBuyingGroupStatus: 'New',
  initApplicationStatus: 'New',
  isPhoneVerified: false,
  TimeStamp: '',
  firstName: 'NOBODY',
  lastName: '',
  isEmailVerified: false,
}

type AppMainProps = {
  authState: AuthState
}
function AppMain({ authState }: AppMainProps) {
  const { uid, firebaseUser, authenticated } = authState
  const { saveStragglingData } = useAuthService()
  const versionChecker = useClientVersionSupported()
  // const versionChecker = { isLoading: false, isClientVersionSupported: true }
  const [user, setUser] = useState<FirebaseAuthUser>(NOBODY)
  const { loading: userLoading, value: userRecord } = useUser(uid)

  useEffect(() => {
    if (userLoading) return
    if (!firebaseUser) return
    if (!userRecord) return
    console.log('merge user', { firebaseUser, userRecord })
    const mergedUser: FirebaseAuthUser = {
      id: firebaseUser.uid,
      isEmailVerified: firebaseUser.emailVerified || settings.firebaseUseEmulators,
      email: userRecord.email,
      roles: userRecord.roles,
      displayName: userRecord.displayName,
      homeBuyingGroupStatus: userRecord.homeBuyingGroupStatus,
      initApplicationStatus: userRecord.initApplicationStatus,
      isPhoneVerified: userRecord.isPhoneVerified,
      TimeStamp: userRecord.TimeStamp,
      firstName: userRecord.firstName,
      lastName: userRecord.lastName,
      location: userRecord.location,
      buyingGroupId: userRecord.buyingGroupId,
      isGroupHost: userRecord.isGroupHost
    }
    setUser(mergedUser)
  }, [userLoading, userRecord, authenticated, firebaseUser])

  useEffect(() => {
    if (user == NOBODY) return
    if (authState.stragglingSignUpData) saveStragglingData()
  }, [user, authState.stragglingSignUpData])

  useEffect(() => {
    console.debug('AppMain authState changed', authState)
  }, [authState])

  useEffect(() => {
    console.debug('AppMain user changed', { user })
  }, [user])

  if (!authState.initialized) return <LoadingPage what="initializing authentication engine" />
  if (versionChecker.isLoading) return <LoadingPage what="checking version compatibility" />
  if (!versionChecker.isClientVersionSupported) return <UpgradeRequiredPage />

  return (
    <UserContext.Provider value={user}>
      <AuthenticatedErrorBoundary>
        {authenticated ? <AppRouter /> : <PreauthRouter />}
      </AuthenticatedErrorBoundary>
    </UserContext.Provider>
  )
}

function PreauthRouter() {
  return (
    <Switch>
      <Route path="/" exact>
        <LandingPage />
      </Route>
      <Route exact path={ResetPasswordNav.path} component={ResetPasswordNav.component} />
      <Route exact path={LoginNav.path} component={LoginNav.component} />
      <Route exact path={SignUpNav.path} component={SignUpNav.component} />
      <Route component={NotFoundPage} />
    </Switch>
  )
}

function Router({ children }: { children: JSX.Element }) {
  const isMobileMode = useMobileMode()

  return isMobileMode ? <HashRouter>{children}</HashRouter> : <BrowserRouter>{children}</BrowserRouter>
}

export default App
export { AppConfigContext, AuthServiceContext, UserContext, UserIdContext, PushNotificationDeviceRegistrationContext }
