import React from 'react'
import { TextContent } from '../../../common/textContent'
import { SectionHeader, Spacing } from '@rse/uikit'
import { InputRangeSlider } from '../components/InputRangeSlider'

const marks = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6+' },
]

export const PrefsMatesCount: React.VFC = () => {
  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent
          resourceKey="app-wizard.title-how-many-roommates"
          defaultValue="How many home mates do you want to live with?"
        />
      </SectionHeader>
      <InputRangeSlider
        id="roommates"
        defaultValue={[3, 5]}
        valueLabelDisplay="on"
        step={1}
        min={1}
        max={6}
        marks={marks}
      />
    </section>
  )
}
