export interface Person {
  id?: string
  firstName: string
  lastName: string
  birthday: Date
  gender: 'male' | 'female' | 'nonbinary'
  avatar?: string
}

export const people: Person[] = [
  {
    id: '1',
    firstName: 'Lucy',
    lastName: 'Liu',
    birthday: new Date('1979-09-21'),
    gender: 'female',
  },
  {
    id: '2',
    firstName: 'Bob',
    lastName: 'Blanton',
    birthday: new Date('1987-04-20'),
    gender: 'male',
  },
  {
    id: '3',
    firstName: 'Billy',
    lastName: 'Smith',
    birthday: new Date('1983-03-30'),
    gender: 'male',
  },
  {
    id: '4',
    firstName: 'Sally',
    lastName: 'Smith',
    birthday: new Date('1983-03-30'),
    gender: 'female',
  },
  {
    id: '5',
    firstName: 'Sarah',
    lastName: 'Fawcett',
    birthday: new Date('1989-09-10'),
    gender: 'female',
  },
]
