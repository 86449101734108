import React, { useContext } from 'react'
import { Box, Container, useTheme } from '@material-ui/core'
import { HeaderAvatar } from './HeaderAvatar'
import { UserContext } from '../../../../App'
import { NavBack } from '../../../inputs'
import { Subtitle1 } from '@rse/uikit'
import { HeaderBox } from './HeaderBox'

interface HeaderProps {
  title?: string
  children?: React.ReactNode
  back?: 'history' | (() => Promise<void>)
  hideMenu?: Boolean
}

export function Header({ title, back, children, hideMenu }: HeaderProps) {
  const theme = useTheme()
  const user = useContext(UserContext)

  return (
    <HeaderBox bgcolor={theme.palette.primary.main} color="white">
      <Container maxWidth="xs">
        <Box display="flex" justifyContent="space-between" alignItems="center" height="56px" minHeight="0">
          {back && <NavBack color="white" back={back} />}
          {title && <Subtitle1>{title}</Subtitle1>}
          <Box display="flex" ml="auto">
            {children && children}
          </Box>
          {user && !hideMenu && <HeaderAvatar />}
        </Box>
      </Container>
    </HeaderBox>
  )
}
