import React from 'react'
import { DashboardBanner } from '../../../components/layout/DashboardBanner'
import { ReactComponent as RingIcon } from '@rse/uikit/lib/icons/alarm-clock.svg'

export function NoNotificationsView() {
  return (
    <DashboardBanner
      icon={RingIcon}
      viewBox="0 0 41 46"
      title="You have no notifications yet 👀"
      titleKey="notifications.no-notifications-title"
    />
  )
}
