import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { UserContext } from '../../../../App'
import { AppRoutes } from '../../../../App/routeUtils'
import { IconHouse } from '../../../../components/icons'
import { StandardText } from '@rse/uikit'
import { useBuyingGroup } from '../../../../firebase'

type Props = {
  isGroupHost: boolean | undefined,
  buyingGroupID: string | undefined
}

const CmUserBuyingGroup = ({ isGroupHost, buyingGroupID }: Props) => {
  if (!buyingGroupID) {
    return null
  }

  const {
    document: { value: buyingGroup, loading, error },
  } = useBuyingGroup(buyingGroupID)
  if (loading || error || buyingGroup == null) {
    return null
  }

  return (
    <Box display="flex" mb="7px" px="1px">
      <StandardText color="primary">
        <Link to={AppRoutes.group(buyingGroup.id)}>
          <IconHouse /> {isGroupHost ? 'Host' : 'Member'} of {buyingGroup.headline}
        </Link>
      </StandardText>
    </Box>
  )
}

export default CmUserBuyingGroup
