import { MemberMatchEntity } from '@rse/core'
import { usePersistentCollectionItem } from '.'

export function useClubMemberFavStatus(myUid: string, memberUid: string) {

  const member = usePersistentCollectionItem<MemberMatchEntity>(`profileMatches/${myUid}/memberMatches/${memberUid}`)
  const isFav = member?.document?.value?.isFab || false

  return isFav
}
