import { UserProfileEntity } from '@rse/core'

import { firebaseCollectionNames } from '../firebaseConstants'
import { usePersistentCollectionItem } from './usePersistentCollectionItem'

export function useUserProfile(uid: string) {
  const { document } = usePersistentCollectionItem<UserProfileEntity>(`${firebaseCollectionNames.userProfiles}/${uid}`)

  return document?.value
}
