import firebase from 'firebase/app'
import 'firebase/firestore'
import { format } from 'date-fns'
import { Message, MessageThread } from '@rse/core'

import { ChatThread, ChatThreadSection } from '../models'
import { firebaseCollectionNames } from './firebaseConstants'
import { firebaseInstance } from './firebaseInstance'
import { groupBy } from '../common/utils'
import { chatThreadSections } from '../mocks'

export async function firebaseGetChatThread(threadId: string): Promise<ChatThread | null> {
  const threadPath = `${firebaseCollectionNames.messageThreads}/${threadId}`
  const threadSnapshot = await firebaseInstance.db.doc(threadPath).get()
  if (threadSnapshot.exists) {
    const thread = threadSnapshot.data() as MessageThread | undefined

    if (thread) {
      const messagesSnapshot = await firebaseInstance.db
        .collection(`${threadPath}/${firebaseCollectionNames.messages}`)
        .orderBy('sentDate', 'asc')
        .limit(50) // dangerous!!
        .get()

      console.log('messagesSnapshot.size', messagesSnapshot.size)
      const sections = new Array<ChatThreadSection>()

      if (messagesSnapshot.size > 0) {
        const messages = readMessages(messagesSnapshot)
        console.log('messages', messages)
        groupMessages(messages, sections)
      }
      return {
        title: thread.title,
        subtitle: (thread.type == 'GroupCourtship') ? 'Exploring Compatbility' : undefined,
        //subtitle: 'active 5 hours ago',
        type: thread.type,
        sections: sections,
        groupId: thread.groupId,
      }
    }
  }

  return null
}

export async function getChatThread_mock(threadId: string): Promise<ChatThread> {
  console.log('getMessageThreadState', threadId)

  return new Promise((res) =>
    setTimeout(
      () =>
        res({
          title: 'Brandon Ricci',
          //subtitle: 'active 5 hours ago',
          sections: chatThreadSections,
          type: 'GroupCourtship',
        }),
      1200
    )
  )
}

type MessageWithDateInfo = {
  dateString?: string
  timeString?: string
} & Message

const readMessages = (
  messagesSnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
): MessageWithDateInfo[] => {
  const messages: MessageWithDateInfo[] = []

  messagesSnapshot.forEach((doc) => {
    const msg = doc.data() as MessageWithDateInfo
    msg.sentDate = (msg.sentDate as any).toDate()
    msg.dateString = format(msg.sentDate, 'MMMM d') // November 6
    msg.timeString = format(msg.sentDate, 'HH:mm') // 19:23
    messages.push(msg)
  })

  return messages
}

const groupMessages = (messages: MessageWithDateInfo[], sections: ChatThreadSection[]) => {
  const grouppedByDate = groupBy(messages, 'dateString')

  for (const entry of grouppedByDate) {
    const section: ChatThreadSection = {
      sectionTitle: entry[0] || 'error reading date',
      sectionType: 'date',
      messages: entry[1].map((m) => ({
        uid: m.uid,
        message: m.message,
        dateString: m.timeString || 'error reading time',
        avatarUrl: m.avatarUrl,
        status: 2,
      })),
    }
    sections.push(section)
  }
}
