import React from 'react'
import { Box } from '@material-ui/core'
import { TextContent } from '../../../../common/textContent/TextContent'
import { ButtonMedium } from '@rse/uikit'
import { useHistory } from 'react-router-dom'

interface CmProfileActionsProps {
  currentUid: string
  profileUid: string
}

export function CmProfileActions({ currentUid, profileUid }: CmProfileActionsProps) {
  const history = useHistory()
  const userIsViewingSelf = currentUid === profileUid

  function chatWithMemberAction() {
    history.push(`/messages/peer/user/${profileUid}`)
  }

  if (userIsViewingSelf) {
    return null
  }

  return (
    <>
      <Box m={1}>
        <ButtonMedium onClick={chatWithMemberAction}>
          <TextContent resourceKey="buttons.chat" defaultValue="Chat" />
        </ButtonMedium>
      </Box>
    </>
  )
}
