import { UserInitApplicationEntity } from '@rse/core'
import { useContext } from 'react'
import { UserContext } from '../../App'
import { getDateFromFirestoreTimestampOrString, useFirebaseFunctionCall } from '../../firebase'
import { firebaseOnCallFunctions } from '../constants/firebaseOnCallFunctions'

export function useJoinUpData() {
  const user = useContext(UserContext)
  const getInitApplicationDataHook = useFirebaseFunctionCall(firebaseOnCallFunctions.getInitApplicationData, true)

  async function getJoinUpData() {
    const jsonResult = await getInitApplicationDataHook.fetchDataFromFunction('')
    let result = JSON.parse(jsonResult) as UserInitApplicationEntity
    if (result) {
      result.firstName = result.firstName ? result.firstName : user?.firstName
      result.lastName = result.lastName ? result.lastName : user?.lastName      
      result.birthday = getDateFromFirestoreTimestampOrString(result.birthday)
    }else{
      result =  {
        id: user?.id ? user?.id : '',
        firstName: user?.firstName,
        lastName: user?.lastName,
        preferences: {}         
      }
    }
    return result
  }

  return { getJoinUpData }
}
