import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { QuestionComponentProps } from '.'
import { TextError } from '../../../../pages/Application/components/TextError'
import { validateSingleField } from '../../../../pages/Application/helpers/validation'
import { QuestionChoice } from '../../types'

export default function DropDownSelectInput({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const classes = useStyles()
  const [textValue, setTextValue] = useState('')
  const [isQuestionAnswerValid, setIsQuestionAnswerValid] = useState(true)

  const labelId = `${QuestionConfig.QuestionName}-label`
  otherProps.variant = otherProps.variant || 'outlined'
  otherProps.fullWidth = otherProps.fullWidth || true
  const isRequired: boolean = QuestionConfig.IsRequired || false

  useEffect(() => {
    let currentAnswer = ''
    if (Answer && Answer[0]) {
      currentAnswer = Answer[0]
    }

    setTextValue(currentAnswer)
    setErrorState(currentAnswer)
  }, [])

  const setErrorState = (currentValue: string) => {
    let isValid = true
    if (!currentValue) {
      if (isRequired) {
        isValid = false
      }
    }

    ValidStatusUpdateHandler(isValid)
    setIsQuestionAnswerValid(isValid)
  }

  const changeHandler = (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
    const currentAnswer = event.target.value as string
    setTextValue(currentAnswer)
    AnswerUpdateHandler([currentAnswer])
    setErrorState(currentAnswer)
  }

  const handleBlur = () => {
    setErrorState(textValue)
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id={labelId}>{QuestionConfig.Title}</InputLabel>
        <Select
          labelId={labelId}
          name={QuestionConfig.QuestionName}
          value={textValue || ''}
          onChange={changeHandler}
          onBlur={handleBlur}
          label={QuestionConfig.Title}
          error={isQuestionAnswerValid}
          {...otherProps}
        >
          {QuestionConfig.QuestionChoices &&
            QuestionConfig.QuestionChoices.map((o: QuestionChoice, i: number) => (
              <MenuItem key={`${QuestionConfig.QuestionName}-${QuestionConfig.Id}-${i}`} value={o.Value}>
                {o.Label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {!isQuestionAnswerValid && displayQuestionErrors && <TextError>Required</TextError>}
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 150,
    },
  })
)
