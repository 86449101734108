import React, { ReactChild } from 'react'
import { Box, useTheme } from '@material-ui/core'

interface AppLayoutProps {
  header?: ReactChild
  body: ReactChild
  footer?: ReactChild
}

function AppLayout({ header, body, footer }: AppLayoutProps) {
  const { palette } = useTheme()
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh" component="main">
      {header && (
        <Box position="sticky" top="0" width="100%" zIndex={99}>
          {header}
        </Box>
      )}
      <Box zIndex={1} display="flex" flexGrow={1}>
        {body}
      </Box>
      {footer && (
        <Box position="sticky" bottom="0" zIndex={99} boxShadow={1}>
          {footer}
        </Box>
      )}
    </Box>
  )
}

export { AppLayout }
