import { firebaseInstance } from './firebaseInstance'
import { ErrorType, GetError } from '../common/constants/errorTypes'

class FirebaseStorage {
  
  public async UploadPicture(file: File): Promise<string> {
    if (!firebaseInstance.auth.currentUser) {
      throw GetError(ErrorType.UserNotLoggedIn)
    }

    try {
      const snapshot = await firebaseInstance.storage
        .ref('users/' + firebaseInstance.auth.currentUser.uid + '/profile.jpg')
        .put(file)

      const downloadUrl = await snapshot.ref.getDownloadURL()
      console.log(`file uploaded ${downloadUrl}`)
      return downloadUrl
    } catch (error) {
      console.error('error uploading picture', error)
      throw GetError(ErrorType.StorageError)
    }
  }


  public async getProfileImageUrlByUser(userId: string): Promise<string | undefined> {
    try {
      return await firebaseInstance.storage.ref(`users/${userId}/profile.jpg`).getDownloadURL()
    } catch {
      // image does not exist
    }
  }
}

export const firebaseStorage = new FirebaseStorage()
