import React from 'react'
import { Link } from '@material-ui/core'
import { StandardText } from '@rse/uikit'

interface Props {
  openLink: React.Dispatch<React.SetStateAction<false | 'tos' | 'privacyPolicy'>>
}

export function LegalDocsLinks({ openLink }: Props) {
  function clickHandler(linkName: 'tos' | 'privacyPolicy') {
    return (e: React.BaseSyntheticEvent) => {
      e.stopPropagation()
      e.preventDefault()
      openLink(linkName)
    }
  }

  return (
    <StandardText>
      I understand and agree to the <Link onClick={clickHandler('tos')}>Terms &amp; Conditions</Link> and{' '}
      <Link onClick={clickHandler('privacyPolicy')}>Privacy Policy</Link>
    </StandardText>
  )
}
