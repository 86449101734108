import { useState } from 'react'
import { firebaseGetUserPublicProfilePath } from '../firebaseUserPublicProfiles'
import { usePersistentCollectionItem } from './usePersistentCollectionItem'
import { getDateFromFirestoreTimestampOrString } from '../utils'
import { useActivePreferences } from '.'
import { UserProfilePublicEntity } from '@rse/core'
import { useCurrentUserId } from '../../common/hooks'
import settings from '../../settings'
import { useApiFetch } from '@rse/frontend-dal'
import { firebaseInstance } from '../../firebase'


export function setUserPublicProfileBirthday(value: UserProfilePublicEntity): UserProfilePublicEntity {
  if (value) {
    value.birthday = getDateFromFirestoreTimestampOrString(value.birthday)
  }

  return value
}

export function useUserPublicProfile(uid: string, refinePreferences = false) {
  const currentUid = useCurrentUserId()
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const { document } = usePersistentCollectionItem<UserProfilePublicEntity>(
    firebaseGetUserPublicProfilePath(uid),
    setUserPublicProfileBirthday
  )

  const { value: object, loading: loadingObject, error: errorLoadingObject } = document



  const saveUserProfilePublicApi = useApiFetch(`${settings.firebaseFunctionsBaseUrl}/api/user-profile/public`, true, 'POST', true, firebaseInstance.auth)

  const saveUserProfilePublic = async (userProfilePublic: Partial<UserProfilePublicEntity>) => {
    userProfilePublic.id = currentUid
    setIsSaving(true)
    await saveUserProfilePublicApi.callApi(userProfilePublic)
    setIsSaving(false)
  }

  const activePrefs = useActivePreferences<UserProfilePublicEntity>({ object, loadingObject, errorLoadingObject, refinePreferences })
  return { ...activePrefs, saveUserProfilePublic, isSaving }
}
