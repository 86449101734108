import React from 'react'
import settings from '../settings'
import { MemberAppConfig } from '@rse/core'

const DEFAULT_CONFIG:MemberAppConfig = {
  api: {
    startup: {
      prefix: `${settings.firebaseFunctionsBaseUrl}/api/startup`
    },
    chat: {
      prefix: `${settings.firebaseFunctionsBaseUrl}/api/chat`
    },
    buyingGroup: {
      prefix: `${settings.firebaseFunctionsBaseUrl}/api/buying-group`
    },
    user: {
      prefix: `${settings.firebaseFunctionsBaseUrl}/api/user`
    }
  }
}

const AppConfigContext = React.createContext<MemberAppConfig>(DEFAULT_CONFIG)

export { AppConfigContext, DEFAULT_CONFIG }