import { FormikErrors } from 'formik'
import { AppErrorMessages, ErrorType } from '../../../../common/constants'

export type FormValues = {
  currentPassword: string
  newPassword: string
  newPassword2: string
}

export const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {}
  if (!values.currentPassword) {
    errors.currentPassword = AppErrorMessages[ErrorType.FieldIsRequired]
  }

  if (!values.newPassword) {
    errors.newPassword = AppErrorMessages[ErrorType.FieldIsRequired]
  } else if (values.newPassword === values.currentPassword) {
    errors.newPassword = AppErrorMessages[ErrorType.PasswordEqualsToCurrent]
  }

  if (!values.newPassword2) {
    errors.newPassword2 = AppErrorMessages[ErrorType.FieldIsRequired]
  } else if (values.newPassword !== values.newPassword2) {
    errors.newPassword2 = AppErrorMessages[ErrorType.PasswordsNotMatch]
  }

  return errors
}
