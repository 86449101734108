import { useCallback, useContext, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { getTextContent, useTextContent } from '../../../common/textContent'
import { ApplicationProgressDataContext } from '../components/ApplicationFormProgressProvider'
import { StepId } from '../types/components'

export function useProgressInfo() {
  const textContent = useTextContent()
  const stepNameMap = {
    1: getTextContent(textContent, 'app-wizard.step-profile', 'Your Profile'),
    2: getTextContent(textContent, 'app-wizard.step-preferences', 'Preferences'),
    3: getTextContent(textContent, 'app-wizard.step-importance-factors', 'Importance Factors'),
    4: getTextContent(textContent, 'app-wizard.step-verification', 'Verification'),
    5: getTextContent(textContent, 'app-wizard.step-identity-verification', 'Identity Verification'),
    6: getTextContent(textContent, 'app-wizard.step-mortgage-pre-approval', 'Mortgage pre-approval'),
    7: getTextContent(textContent, 'app-wizard.step-application-summary', 'Application summary'),
    8: getTextContent(textContent, 'app-wizard.step-finalize-application', 'Finalize Application'),
  }
  const stepsCount = Object.keys(stepNameMap).length
  const [{ currentStep, latestStep, latestProgress }, setState] = useContext(ApplicationProgressDataContext)
  const match = useRouteMatch<{ step?: string }>('/application/:step')
  const isLastStep = currentStep === stepsCount
  const currentStepName = stepNameMap[currentStep]

  const completeApplicationProgress = useCallback(() => {
    setState &&
      setState((state) => ({
        ...state,
        latestProgress: 100,
      }))
  }, [])

  useEffect(() => {
    setState &&
      setState((state) => ({
        ...state,
        currentStep: Number.parseInt(match?.params.step || '1') as StepId,
      }))
  }, [match?.params.step])
  useEffect(() => {
    setState &&
      setState((state) => ({
        ...state,
        latestStep: Math.max(currentStep, latestStep) as StepId,
      }))
  }, [currentStep, latestStep])
  useEffect(() => {
    setState &&
      setState((state) => ({
        ...state,
        latestProgress: Math.ceil((100 / stepsCount) * (latestStep - 1)),
      }))
  }, [latestStep])

  return {
    currentStep,
    currentStepName,
    latestStep,
    latestProgress,
    isLastStep,
    stepsCount,
    completeApplicationProgress,
  }
}
