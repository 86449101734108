import { useContext, useCallback } from 'react'
import { ApplicationFormDataContext } from '../components/ApplicationFormDataProvider'
import { ApplicationFormKey } from '../types/application'
import { InputValue } from '../types/components'
import { FormErrors } from '../types/validation'

export function useFormData() {
  const [formState, setFormState] = useContext(ApplicationFormDataContext)

  const onValidationChange = useCallback(
    (errors: FormErrors) => {
      setFormState && setFormState((state) => ({ ...state, formErrors: { ...state.formErrors, ...errors } }))
    },
    [setFormState]
  )

  const onFieldChange = useCallback(
    (id: ApplicationFormKey, value: InputValue) => {
      setFormState && setFormState((state) => ({ ...state, formData: { ...state.formData, [id]: value } }))
    },
    [setFormState]
  )

  return { ...formState, onFieldChange, onValidationChange }
}
