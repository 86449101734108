import React, { useContext } from 'react'
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined'
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined'
import { styled } from '@material-ui/core'
import { ThemeContext } from '../../common/theme/ThemeContext'

export function ThemeToggle() {
  const { type: state, setLight, setDark } = useContext(ThemeContext)

  if (state === 'light') {
    return (
      <LightContainer>
        <OptionLightSun onClick={() => setLight()}>
          <WbSunnyOutlinedIcon fontSize="inherit" />
        </OptionLightSun>
        <OptionLightMoon onClick={() => setDark()}>
          <Brightness2OutlinedIcon fontSize="inherit" />
        </OptionLightMoon>
      </LightContainer>
    )
  }

  return (
    <DarkContainer>
      <OptionDarkSun onClick={() => setLight()}>
        <WbSunnyOutlinedIcon fontSize="inherit" />
      </OptionDarkSun>
      <OptionDarkMoon onClick={() => setDark()}>
        <Brightness2OutlinedIcon fontSize="inherit" />
      </OptionDarkMoon>
    </DarkContainer>
  )
}

const BaseContainer = styled('div')({
  fontSize: '1.1em',
  padding: '4px',
  borderRadius: '6px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const LightContainer = styled(BaseContainer)({
  background: '#ebe7f0',
})

const DarkContainer = styled(BaseContainer)({
  background: '#4d17b9',
})

const ToggleOption = styled('div')({
  borderRadius: '6px',
  padding: '5px 8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
})

const OptionLightSun = styled(ToggleOption)({
  background: '#ffffff',
  color: '#6B2EF5',
})

const OptionLightMoon = styled(ToggleOption)({
  color: '#e1d3f8',
})

const OptionDarkSun = styled(ToggleOption)({
  color: '#a78bf2',
})

const OptionDarkMoon = styled(ToggleOption)({
  background: '#a78bf2',
  color: '#ffffff',
})
