import React, { useEffect, useState } from 'react'
import { useCurrentUserId } from '../../../common/hooks'
import {
  getWizardConfig as getBasicWizardConfig,
  mockInitialAllPageAnswers as basicInitialAnswers,
} from '../mocks/basicWIzardConfig'
import {
  getWizardConfig as getGroupViewWizardConfig,
  mockInitialAllPageAnswers as groupViewInitialAnswers,
} from '../mocks/groupViewConfig'
import { Dictionary, DynamicWizardConfigData, DynamicWizardUserData } from '../types'
import { usePersistentCollectionItem } from '../../../firebase'

export function useDynamicWizard(configID: string) {
  const [configLoaded, setConfigLoaded] = useState(false)
  const [userDataLoaded, setUserDataLoaded] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const currentUserUid = useCurrentUserId()
  const wizardConfigInfo = usePersistentCollectionItem<DynamicWizardConfigData>(`dynamicWizardConfig/${configID}`)

  const responseID = wizardConfigInfo.document.value?.ResponseID
  const userResponse = usePersistentCollectionItem<DynamicWizardUserData>(
    `dynamicWizardResponse/${currentUserUid}/Response/${responseID}`
  )

  useEffect(() => {
    const loaded = configLoaded && userDataLoaded
    setIsLoaded(loaded)
  }, [configLoaded, userDataLoaded])

  useEffect(() => {
    if (wizardConfigInfo.document.loading) {
      setConfigLoaded(false)
    } else {
      setConfigLoaded(true)
      if (!wizardConfigInfo.document.value) {
        setSampleConfig()
      }
    }

    if (userResponse.document.loading) {
      setUserDataLoaded(false)
    } else {
      setUserDataLoaded(true)
    }

    async function setSampleConfig() {
      const sample = GetSampleConfigData()
      await wizardConfigInfo.commonHandlers.setItem(sample)
    }
  }, [configID, currentUserUid, userResponse.document.loading, wizardConfigInfo.document.loading])

  const GetSampleConfigData = configID === 'Sample' ? getBasicWizardConfig : getGroupViewWizardConfig

  const SavePageAnswersAction = async (pageIndex: number, pageAnswers: Dictionary<string[]>) => {
    for (const [key, value] of Object.entries(pageAnswers)) {
      if (!pageAnswers[key]) {
        pageAnswers[key] = []
      }
    }

    await userResponse.commonHandlers.setItemMerge({ lastPageIndex: pageIndex, AllPageAnswers: pageAnswers })
  }

  const configDocument = wizardConfigInfo.document.value as DynamicWizardConfigData

  let userData = userResponse.document.value as DynamicWizardUserData

  if (!userData) {
    userData =
      configID === 'Sample'
        ? { lastPageIndex: 0, AllPageAnswers: basicInitialAnswers }
        : { lastPageIndex: 0, AllPageAnswers: groupViewInitialAnswers }
  }

  return { isLoaded, userData, configDocument, SavePageAnswersAction }
}
