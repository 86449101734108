import React, { useState } from 'react'
import { styled, IconButton, Menu, MenuItem, Box } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { BuyingGroup } from '@rse/core'
import leaveIcon from '@rse/uikit/lib/icons/leave.svg'
import removeIcon from '@rse/uikit/lib/icons/remove.svg'
import deleteIcon from '@rse/uikit/lib/icons/delete.svg'
import finalizeIcon from '@rse/uikit/lib/icons/finalize.svg'
import { useErrorHandler } from '../../../common/hooks'

interface SettingsSubnavProps {
  group: BuyingGroup
  leaveGroup?: () => Promise<void>
  deleteGroup?: () => Promise<void>
  removeMember?: () => Promise<void>
  finalizeGroup?: () => Promise<void>
}

export default function SettingsSubnav({
  group,
  leaveGroup,
  deleteGroup,
  removeMember,
  finalizeGroup,
}: SettingsSubnavProps) {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)

  const { errorHandler: finalizeGroupErrorHandler } = useErrorHandler('finalizing buying group')
  const { errorHandler: leaveGroupErrorHandler } = useErrorHandler('leaving buying group')
  const { errorHandler: removeMemberErrorHandler } = useErrorHandler('removing buying group member')
  const { errorHandler: deleteGroupErrorHandler } = useErrorHandler('deleting buying group')

  const handleClick = (event: React.SyntheticEvent<Element>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!finalizeGroup && !leaveGroup && !removeMember && !deleteGroup) {
    return null // menu is empty
  }

  return (
    <>
      <Box width="100%" display="flex" justifyContent="flex-end" color="white">
        <IconButton aria-label="Group Settings Menu" aria-haspopup="true" onClick={handleClick} color="inherit">
          <MoreVert color="inherit" />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {finalizeGroup && (
          <MenuItem
            onClick={async () => {
              handleClose()
              finalizeGroup().catch(finalizeGroupErrorHandler)
            }}
          >
            <Box display="flex" alignItems="center">
              <MenuIcon src={finalizeIcon} alt="finalize group icon" />
              <Box ml={1}>Finalize Group</Box>
            </Box>
          </MenuItem>
        )}
        {leaveGroup && (
          <MenuItem
            onClick={async () => {
              handleClose()
              leaveGroup().catch(leaveGroupErrorHandler)
            }}
          >
            <Box display="flex" alignItems="center">
              <MenuIcon src={leaveIcon} alt="group leave icon" />
              <Box ml={1} color="red">
                Leave Group
              </Box>
            </Box>
          </MenuItem>
        )}
        {removeMember && (
          <MenuItem
            onClick={async () => {
              handleClose()
              removeMember().catch(removeMemberErrorHandler)
            }}
          >
            <Box display="flex" alignItems="center">
              <MenuIcon src={removeIcon} alt="remove icon" />
              <Box ml={1} color="red">
                Remove Member
              </Box>
            </Box>
          </MenuItem>
        )}
        {deleteGroup && (
          <MenuItem
            onClick={async () => {
              handleClose()
              deleteGroup().catch(deleteGroupErrorHandler)
            }}
          >
            <Box display="flex" alignItems="center">
              <MenuIcon src={deleteIcon} alt="delete icon" />
              <Box ml={1} color="red">
                Delete Group
              </Box>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

const MenuIcon = styled('img')({
  display: 'inline-block',
  width: 14,
  height: 14,
})
