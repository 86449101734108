import React, { useState } from 'react'
import { Avatar, TextField, Link, Grid, Box, Typography, Container, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/core/styles'
import * as EmailValidator from 'email-validator'
import { useForm } from 'react-hook-form'
import { ErrorType, AppErrorMessages } from '../../common/constants/errorTypes'
import { ButtonMedium, StandardText } from '@rse/uikit'
import { useAuthService } from '../../common/hooks'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.GetSocialEquity.com/">
        Social Equity
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

type formData = {
  email: string
}

function ResetPassword() {
  const { sendResetPasswordEmail } = useAuthService()

  const history = useHistory()

  const [isResetSentScreen, setIsResetSentScreen] = useState(false)
  const { register, handleSubmit, errors } = useForm<formData>()
  const [isPending, setIsPending] = useState(false)
  const [isResetError, setIsResetError] = useState(false)
  const [resetErrorMessage, setResetErrorMessage] = useState('')

  const classes = useStyles()

  const handleLoginClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    history.push('/login')
  }

  const onSubmit = async (data: formData) => {
    setIsPending(true)

    try {
      await sendResetPasswordEmail(data.email)
      setIsResetSentScreen(true)
    } catch (error:any) {
      if (error.message) {
        // TODO determine if type coercion is necessary from != vs !==
        // eslint-disable-next-line
        if (error.message != ErrorType[ErrorType.UserNotFound]) {
          setIsResetSentScreen(true)
        } else {
          setResetErrorMessage(error.message)
          setIsResetError(true)
        }
      } else {
        setResetErrorMessage(AppErrorMessages[ErrorType.AuthServiceError])
        setIsResetError(true)
      }
    }

    setIsPending(false)
  }

  const isEmail = (email: string) => {
    return EmailValidator.validate(email)
  }

  const resetSentScreen = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textPrimary">
            Password reset request received
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StandardText>
            You will be receiving an email within the next few minutes that will guide you to recover your account.
          </StandardText>
        </Grid>
      </Grid>
      <ButtonMedium type="submit" fullWidth className={classes.submit} onClick={handleLoginClick}>
        LOGIN
      </ButtonMedium>
    </>
  )

  const resetScreen = (
    <>
      <Typography component="h1" variant="h5">
        Password Reset
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              inputRef={register({ required: true, validate: isEmail })}
            />
            {errors.email && errors.email.type === 'required' && (
              <Typography component="div" variant="body1" color="error">
                Email is required
              </Typography>
            )}
            {errors.email && errors.email.type === 'validate' && (
              <Typography component="div" variant="body1" color="error">
                Email is invalid
              </Typography>
            )}
          </Grid>
        </Grid>
        <ButtonMedium type="submit" fullWidth className={classes.submit}>
          Reset Password
        </ButtonMedium>
        {isResetError && (
          <Typography component="div" variant="body1" color="error">
            {resetErrorMessage}
          </Typography>
        )}
      </form>
    </>
  )

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {isResetSentScreen ? <>{resetSentScreen}</> : <>{resetScreen}</>}
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      {isPending && <CircularProgress color="secondary" />}
    </Container>
  )
}

export default ResetPassword
