import React from 'react'
import { Box, BoxProps } from '@material-ui/core'

export function CenteredBox(props: BoxProps) {
  return (
    <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center" flexDirection="column" {...props}>
      {props.children}
    </Box>
  )
}
