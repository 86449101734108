import React, { useEffect, useState } from 'react'
import { useLoadingIndicator } from '@rse/uikit'
import { ApplicationFormData } from '../types/application'
import { FormErrors } from '../types/validation'
import { preferencesField, preferencesPrefix } from '../fieldsets/PrefsGroundRules'

export type ApplicationFormState = {
  formData: ApplicationFormData
  formErrors: FormErrors
  loaded: boolean
  loading: boolean
}

const initialFormState = { formData: {}, formErrors: {}, loaded: false, loading: true }

export const ApplicationFormDataContext = React.createContext<
  [ApplicationFormState, React.Dispatch<React.SetStateAction<ApplicationFormState>> | undefined]
>([initialFormState, undefined])

interface Props {
  getData: () => Promise<ApplicationFormData | null | undefined>
  onSetState?: (formState: ApplicationFormState) => void
}

export const ApplicationFormDataProvider: React.FC<Props> = ({ children, getData, onSetState }) => {
  const [formState, setFormState] = useState<ApplicationFormState>(initialFormState)
  const loader = useLoadingIndicator()

  const setFormStateWithCallback: React.Dispatch<React.SetStateAction<ApplicationFormState>> = (value) => {
    setFormState(value)
    onSetState && onSetState(formState)
  }

  useEffect(() => {
    setFormState((state) => ({ ...state, loaded: false, loading: true }))
    let cancelled = false

    const loaderFunc = async () => {
      try {
        const applicationData = await getData()

        if (applicationData && !cancelled) {
          setFormState((state) => {
            const formData = { ...state.formData }

            Object.keys(applicationData).forEach((field) => {
              if (field === preferencesField) {
                Object.keys(applicationData[preferencesField]).forEach((pref) => {
                  formData[`${preferencesPrefix}${pref}`] = applicationData[preferencesField][pref]
                })
              } else {
                formData[field] = applicationData[field]
              }
            })

            return {
              ...state,
              formData,
              loaded: true,
              loading: false,
            }
          })
        }
      } catch (error: unknown) {
        console.error('Error loading application', error)
        setFormState((state) => ({ ...state, loaded: false, loading: false }))
      }
    }

    loader.wrapPromise(loaderFunc())

    return () => {
      cancelled = true
    }
  }, [])

  return (
    <ApplicationFormDataContext.Provider value={[formState, setFormStateWithCallback]}>
      {children}
    </ApplicationFormDataContext.Provider>
  )
}
