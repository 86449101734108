import React, { useEffect } from 'react'
import { Link } from '@material-ui/core'
import { LoadingSpinner, StandardText } from '@rse/uikit'
import { ReactComponent as EmailIcon } from '../../assets/sendMail.svg'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'
import { useAuthService, useErrorHandler } from '../../../../common/hooks'

export function UnverifiedEmail() {
  const { sendVerificationEmail, loading } = useAuthService()
  const { errorHandler } = useErrorHandler('sending verification email')

  useEffect(() => {
    sendVerificationEmail().catch(errorHandler)
  }, [])

  const handleSendVerificationEmail = () => {
    sendVerificationEmail().catch(errorHandler)
  }

  if (loading) return <LoadingSpinner />
  else return (
    <DashboardBanner
      icon={EmailIcon}
      viewBox="0 0 50 30"
      title="Email Sent"
      titleKey="dashboard.verify-email-sent"
      subtext="Click the link in email to complete verifying"
      subtextKey="dashboard.verify-email-directions"
    >
      <StandardText>
        Didn't receive an email?{' '}
        <Link href="#" onClick={() => handleSendVerificationEmail()}>
          Resend Email
        </Link>
      </StandardText>
    </DashboardBanner>
  )
}