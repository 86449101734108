import { useState, useEffect } from 'react'
import { Question, QuestionChoice } from '@rse/core'

import { firebaseCollectionNames } from '../firebaseConstants'
import { usePersistentCollection } from './usePersistentCollection'
import { firebaseInstance } from '..'

export function useQuestions(loadChoices?: boolean) {
  const [value, setValue] = useState<Question[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()

  const {
    collection: { value: questions, loading: questionsLoading, error: questionsError }
  } = usePersistentCollection<Question>(`${firebaseCollectionNames.questions}`)

  useEffect(() => {
    if (!loadChoices) {
      setValue(questions)
      setError(questionsError)
      setLoading(questionsLoading)
      return
    }

    if (questionsError || questionsLoading) {
      if (questionsError && !error) {
        // error
        setError(questionsError)
        setLoading(false)
      } else if (questionsLoading && !loading) {
        // loading
        setLoading(true)
      }
    } else {
      // loaded with required lazy-loading
      const questionsCopy = [...questions!]
      fillChoicesOfQuestions(questionsCopy)
        .then(() => {
          setValue(questionsCopy)
          setError(undefined)
          setLoading(false)
        })
        .catch(() => {
          setValue(undefined)
          setError(new Error('loading error'))
          setLoading(false)
        })
    }
  }, [loadChoices, questions, questionsLoading, questionsError])

  return {
    value,
    loading,
    error
  }
}

async function fillChoicesOfQuestions(questions: Question[]) {
  const promises = questions.map(async (question) => {
    question.choices = []
    question.enabledChoices = []

    const choices = await firebaseInstance.db.collection(`${firebaseCollectionNames.questions}/${question.id}/choices`).get()
    choices.docs.forEach(doc => {
      const questionChoice: QuestionChoice = { ...doc.data() as QuestionChoice, id: doc.id }
      question.choices?.push(questionChoice)
      if (question.enabledChoiceIds.includes(questionChoice.id)) {
        question.enabledChoices?.push(questionChoice)
      }
    })

    question.enabledChoices.sort((a, b) => question.enabledChoiceIds.indexOf(a.id) - question.enabledChoiceIds.indexOf(b.id))
  })

  await Promise.all(promises)
}
