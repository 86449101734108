import React from 'react'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'
import { ReactComponent as ClockIcon } from '../../assets/clock.svg'

export function ApplicationError() {
  return (
    <DashboardBanner
      icon={ClockIcon}
      viewBox="0 0 50 50"
      title="Application error"
      titleKey="dashboard.application-error-title"
      subtext={'Something went wrong. Please try again later or reach our "Happiness Heroes".'}
      subtextKey="dashboard.application-error-subtext"
    ></DashboardBanner>
  )
}
