import { styled } from '@material-ui/core'
import { TextError } from '@rse/uikit'
import React, { useEffect, useState } from 'react'
import { useFormData } from '../helpers/useFormData'

const id = 'input-image'

type Props = {
  id?: string
  getImage: () => Promise<string | undefined>
  saveImage: (file: File) => Promise<string>
  onChangeImgValue?: (imgUrl: string) => Promise<void>
}

export const InputImage: React.FC<Props> = ({ id, children, getImage, saveImage, onChangeImgValue }) => {
  const { onFieldChange } = useFormData()
  const [currentImageUrl, setCurrentImageUrl] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState<boolean>(false)

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      setLoading(true)
      setIsError(false)
      try {
        const imgUrl = await saveImage(file)
        setCurrentImageUrl(URL.createObjectURL(file))
        if (id) {
          onFieldChange(id, imgUrl)
          if (onChangeImgValue) {
            await onChangeImgValue(imgUrl)
          }
        }
      } catch (error) {
        setIsError(true)
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const profileImage = await getImage()
        setCurrentImageUrl(profileImage)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <div>
      <StyledLabel htmlFor={id}>
        {loading && 'Loading...'}
        {!loading && !currentImageUrl && children}
        {!loading && currentImageUrl && (
          <img alt="Avatar" style={{ maxWidth: '100px', maxHeight: '100px' }} src={currentImageUrl} />
        )}
      </StyledLabel>
      {isError && <TextError>Invalid file selected.  Must be jpeg or png image file and less than 10Mb.</TextError>}

      <input accept="image/*" style={{ height: 0 }} id={id} type="file" onChange={handleFileChange} />
    </div>
  )
}

const StyledLabel = styled('label')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed rgba(43, 39, 97, 0.38)',
  borderRadius: '4px',
  minHeight: '60px',
  fontSize: '20px',
  fontWeight: 700,
  cursor: 'pointer',
  padding: '10px',
})
