import { makeStyles, createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    listItemStyle: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },
    dotStyle: {
      backgroundColor: palette.primary.main,
      borderRadius: '50%',
      width: 10,
      height: 10,
      margin: '0 20px',
    },
    badgeIconStyle: (props: { badgeBG: string }) => ({
      '& .MuiBadge-badge': {
        border: '1px solid white',
        padding: 0,
        right: 20,
        bottom: 10,
        backgroundColor: props.badgeBG,
        '& .MuiSvgIcon-root': {
          right: 20,
          width: 14,
          height: 14,
          fill: palette.common.white,
        },
      },
    }),
  })
)
