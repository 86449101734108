import { Box, createStyles, makeStyles } from '@material-ui/core'
import React from 'react'
import { UserOnlineStatus } from '../../models'

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    userStatusMark: {
      position: 'absolute',
      background: palette.common.white,
      padding: 2,
      borderRadius: '50%',
      right: 0,
      bottom: 0,

      '& div': {
        width: 8,
        height: 8,
        borderRadius: '50%',
      },
    },
  })
)

type Props = {
  userStatus?: UserOnlineStatus
}

export function UserOnlineStatusLabel({ userStatus = UserOnlineStatus.Online }: Props) {
  const classes = useStyles()

  return (
    <Box className={classes.userStatusMark}>
      <div style={{ background: userStatus === UserOnlineStatus.Online ? '#4CAF50' : '#CCCCCC' }}></div>
    </Box>
  )
}
