import { Box, Slider, SliderTypeMap, styled, withStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useFormData } from '../helpers/useFormData'
import { InputOmits, InputId } from '../types/components'
import { TextError } from './TextError'

type InputRangeSliderProps = {
  bottomMarks?: [string, string]
} & InputId
type Props = Omit<SliderTypeMap<InputRangeSliderProps, 'span'>['props'], InputOmits> & InputRangeSliderProps

export const InputRangeSlider: React.VFC<Props> = ({ id, defaultValue, bottomMarks, ...rest }) => {
  const { formData, formErrors, onFieldChange } = useFormData()

  useEffect(() => {
    const value = formData[id]
    // need to update form state with def value - just setting it does not get it into state
    if ((!value || (Array.isArray(value) && value.length === 0)) && defaultValue) {
      onFieldChange(id, defaultValue)
    }
  }, [defaultValue, formData, onFieldChange])

  return (
    <Box position="relative">
      <Box px={1}>
        <StyledSlider
          onChange={(_, val: number | number[]) => {
            onFieldChange(id, val)
          }}
          name={id}
          value={(formData[id] as number[] | number | undefined) || []}
          {...rest}
        />
      </Box>
      {bottomMarks && (
        <Box display="flex" justifyContent="space-between">
          <BottomMark>{bottomMarks[0]}</BottomMark>
          <BottomMark>{bottomMarks[1]}</BottomMark>
        </Box>
      )}
      {formErrors[id] && <TextError>{formErrors[id]}</TextError>}
    </Box>
  )
}

const BottomMark = styled('div')({
  fontSize: '14px',
  lineHeight: '143%',
  color: '#8C8AAC',
})

const StyledSlider = withStyles({
  root: {
    marginTop: '35px',
  },
  active: {},
  valueLabel: {
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#6B2EF5',
      fontSize: '14px',
    },
  },
  rail: {
    opacity: 0.1,
  },
  markActive: {
    opacity: 1,
    color: 'red',
    backgroundColor: 'currentColor',
  },
})(Slider)
