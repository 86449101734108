import React, { useContext } from 'react'
import { Box, Hidden, useTheme, Link } from '@material-ui/core'
import { AccountCircle as LoginIcon } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import ProfileMenu from '../Navigation/ProfileMenu'
import { UserContext } from '../../../../App'

export function HeaderAvatar() {
  const user = useContext(UserContext)
  const theme = useTheme()
  const history = useHistory()

  const handleFormLogin = () => history.push('/login')

  if (user)
    return (
      <Box display="flex" alignItems="center" ml={1}>
        <ProfileMenu uid={user.id} />
      </Box>
    )

  return (
    <Link href="#" onClick={handleFormLogin}>
      <Box display="flex" alignItems="center">
        <Hidden xsDown>Login</Hidden>
        <LoginIcon style={{ width: 30, height: 30, fill: theme.palette.grey[500] }} />
      </Box>
    </Link>
  )
}
