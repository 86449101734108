import React from 'react'

const EXCERPT_LENGTH = 80

type Props = {
  name: string
  message: string
}

export function MessageExcerpt({ name, message }: Props) {
  const fullString = `${name}: ${message}`

  const shortenedMessage =
    fullString.length > EXCERPT_LENGTH ? message.substring(0, EXCERPT_LENGTH - name.length - 5) : message

  const elipsis = fullString.length > EXCERPT_LENGTH ? '...' : ''

  return (
    <>
      <strong>{name}:</strong>&nbsp;{`${shortenedMessage}${elipsis}`}
    </>
  )
}
