import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Container } from '@material-ui/core'
import { ReactComponent as PhoneIcon } from '../../Dashboard/assets/verifiedPhone.svg'
import { useSmsVerify } from '../../../common/hooks'
import { cleanPhoneValue } from '../../../common/utils'
import { DashboardBanner } from '../../../components/layout/DashboardBanner'
import { ButtonLarge } from '@rse/uikit'
import { AppLayout, CenteredBox, Footer, Header } from '../../../components/layout'
import { AppRoutes } from '../../../App/routeUtils'
import { VerifyNumberContent } from '.'
import { EnterPhoneContent } from './EnterPhoneContent'

export function ChangePhonePage() {
  const history = useHistory()
  const [phoneNum, setPhoneNum] = useState('')
  const [inputCode, setInputCode] = useState('')

  const { statusPending, verificationError, codeSent, invalidCodeSent, smsVerified, sendCode, verifyCode } =
    useSmsVerify()

  function onPhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    const input = e.target.value
    const cleaned = cleanPhoneValue(input)
    setPhoneNum(cleaned)
  }

  const PhoneChangedContent = () => (
    <>
      <DashboardBanner
        title="Phone changed!"
        titleKey="change-phone.phone-changed-title"
        icon={PhoneIcon}
        viewBox="0 0 42 42"
      >
        <Box mb={2}>
          <ButtonLarge
            fullWidth
            onClick={() => {
              history.push(AppRoutes.settings())
            }}
          >
            To settings
          </ButtonLarge>
        </Box>
      </DashboardBanner>
    </>
  )

  return (
    <AppLayout
      header={<Header title="Change Phone" back="history" />}
      footer={<Footer />}
      body={
        <CenteredBox textAlign="center">
          <Container maxWidth="xs">
            {!codeSent && (
              <EnterPhoneContent
                title="Change your phone"
                titleKey="change-phone.send-text-title"
                subtext="Enter your new phone number"
                subtextKey="change-phone.send-text-subtext"
                phoneNum={phoneNum}
                onPhoneChange={onPhoneChange}
                sendCode={sendCode}
                statusPending={statusPending}
              />
            )}
            {codeSent && !smsVerified && (
              <VerifyNumberContent
                titleKey={'change-phone.title'}
                subtextKey={'change-phone.subtext'}
                inputCode={inputCode}
                setInputCode={setInputCode}
                verificationError={verificationError}
                verifyCode={verifyCode}
                statusPending={statusPending}
                invalidCodeSent={invalidCodeSent}
              />
            )}
            {smsVerified && <PhoneChangedContent />}
          </Container>
        </CenteredBox>
      }
    />
  )
}
