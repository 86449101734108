import { INavItem } from '../../models'
import SignUpPage from '../../pages/SignUp'
import LoginPage from '../../pages/Login'
import DashboardPage from '../../pages/Dashboard'
import ResetPassword from '../../pages/ResetPassword'
import EquityCalculatorPage from '../../pages/EquityCalculator'
import MessagingPage from '../../pages/Messaging/MessagingPage'
import SMSVerifyPage from '../../pages/SMSVerify'

export const URL_MESSAGES = '/messages'
export const URL_MESSAGES_ARCHIVES = '/messages/archives'
export const URL_GROUPS = '/groups'
export const URL_CLUB_MEMBERS = '/club-members'
export const URL_NOTIFICATIONS = '/notifications'
export const URL_MEMBERSHIP_WIZARD = '/application'
export const URL_CHAT = '/chat'
export const URL_SETTINGS = '/settings'
export const URL_DASHBOARD = '/dashboard'
export const URL_EQUITY_CALC = '/equityCalculator'
export const URL_SANDBOX = '/sandbox'
export const URL_TERMS_OF_SERVICE = '/terms-of-service'
export const URL_PRIVACY_POLICY = '/privacy-policy'

// !!!!!!!!!!!! NOTICE !!!!!!!!!!!!!!!!
// Use of INavItem is deprecated, please don't add to this
export const MessagingNav: INavItem = {
  path: URL_MESSAGES,
  exact: false,
  component: MessagingPage,
}

export const SignUpNav: INavItem = {
  path: '/signup',
  exact: true,
  bypassLayout: true,
  component: SignUpPage,
}

export const LoginNav: INavItem = {
  path: '/login',
  exact: true,
  bypassLayout: true,
  component: LoginPage,
}

export const DashboardNav: INavItem = {
  path: URL_DASHBOARD,
  exact: false,
  component: DashboardPage,
}

export const ResetPasswordNav: INavItem = {
  path: '/resetpassword',
  exact: false,
  component: ResetPassword,
}

export const EquityCalculatorNav: INavItem = {
  path: URL_EQUITY_CALC,
  exact: false,
  component: EquityCalculatorPage,
}

export const SMSVerifyNav: INavItem = {
  path: '/phoneVerification',
  exact: true,
  component: SMSVerifyPage,
}
