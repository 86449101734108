import React from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { PersonOutline } from '@material-ui/icons'
import { BuyingGroup, BuyingGroupMember } from '@rse/core'

import { useBuyingGroupMembers, useUserPublicProfile } from '../../firebase'
import { UNAVAILABLE_DOC_ID } from '../../common/constants/stubs'
import { UserAvatarPublic } from '.'
import { AppRoutes } from '../../App/routeUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    memberAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
)
interface BuyingGroupMembersViewProps {
  group: BuyingGroup
  previewMode?: boolean
}

class GroupMemberInfo {
  constructor(readonly committed: boolean, readonly id?: string, readonly profileImageUrl?: string) { }
}

export const BuyingGroupMembersView = ({ group, previewMode = false }: BuyingGroupMembersViewProps) => {
  // TODO confirm this is best guard against a BuyingGroup that hasn't been saved yet
  const {
    collection: { value: groupMembers = [], loading, error },
  } = useBuyingGroupMembers(group.id || UNAVAILABLE_DOC_ID)
  const [, maxSize] = group.roommates

  const { value: owner } = useUserPublicProfile(group.id)
  const memberCount = previewMode ? 1 : loading || error ? 0 : groupMembers?.length || 0
  const members = previewMode
    ? [new GroupMemberInfo(true, group.id, owner?.profileImageUrl)]
    : groupMembers.map((item: BuyingGroupMember) => new GroupMemberInfo(true, item.id, item.profileImageUrl))

  for (let i = memberCount; i < maxSize; i++) {
    members.push(new GroupMemberInfo(false))
  }

  return (
    <Box display="flex" my={1}>
      {members?.map((member, index) => (
        <BuyingGroupMemberIcon key={`${member.id || index}`} {...member} />
      ))}
    </Box>
  )
}

const BuyingGroupMemberIcon = ({ committed, id }: GroupMemberInfo) => {
  const classes = useStyles()
  
  const avatarLinkHandler = (e: any) => {
    e.stopPropagation()
  }

  if (committed && id) {
    return (
      <Link to={AppRoutes.clubMember(id)} style={{ marginRight: 4 }} onClick={avatarLinkHandler}>
        <UserAvatarPublic uid={id} size="small" />
      </Link>
    )
  }

  return <PersonOutline color="disabled" className={classes.memberAvatar} />
}
