/* eslint-disable @typescript-eslint/no-explicit-any */
import app from 'firebase/app'

import { firebaseInstance } from './firebaseInstance'
import { ErrorType, GetError } from '../common/constants/errorTypes'

export class FirebaseDBHelper {
  private getValueTypeName(obj: any) {
    if (obj === undefined) return 'undefined'
    if (obj === null) return 'null'

    const typeString = Object.prototype.toString.call(obj)
    return typeString.slice(typeString.indexOf(' ') + 1, -1)
  }

  private assignDefined(target: any, ...sources: any) {
    for (const source of sources) {
      for (const key of Object.keys(source)) {
        let val = source[key]
        if (val !== undefined) {
          const typeName = this.getValueTypeName(val)
          if (typeName !== 'FileList') {
            if (typeName === 'Object' && val.toDate !== undefined) {
              val = val.toDate()
            }
            target[key] = val
          }
        }
      }
    }
    return target
  }

  public async GetRecordByCurrentUserId(collectionName: string, targetObject?: any): Promise<any | null> {
    const currentUser = firebaseInstance.auth.currentUser
    if (!currentUser) {
      throw GetError(ErrorType.UserNotLoggedIn)
    }

    const docId = currentUser.uid
    return this.GetRecord(collectionName, docId, targetObject)
  }

  public async GetRecord(collectionName: string, docId: string, targetObject?: any): Promise<any | null> {
    const doc = await firebaseInstance.db.doc(`${collectionName}/${docId}`).get()
    if (doc.exists) {
      const docData = doc.data()
      if (targetObject) {
        targetObject = this.assignDefined(targetObject, docData)
        return targetObject
      }
      return docData
    } else {
      return null
    }
  }

  public async AddRecord(collectionName: string, record: any): Promise<void> {
    let setData = { TimeStamp: app.firestore.FieldValue.serverTimestamp() }
    setData = this.assignDefined(setData, record)

    await firebaseInstance.db.collection(collectionName).add(setData)
  }

  public async AddOrUpdateRecordByCurrentUserId(collectionName: string, record: any): Promise<void> {
    const currentUser = firebaseInstance.auth.currentUser
    if (!currentUser) {
      throw GetError(ErrorType.UserNotLoggedIn)
    }

    const docId = currentUser.uid
    return this.AddOrUpdateRecord(collectionName, docId, record)
  }

  public async AddOrUpdateRecord(collectionName: string, docId: string, record: any): Promise<void> {
    let setData = { TimeStamp: app.firestore.FieldValue.serverTimestamp() }
    setData = this.assignDefined(setData, record)

    await firebaseInstance.db.collection(collectionName).doc(docId).set(setData)
  }
}

export const firebaseDBHelper = new FirebaseDBHelper()
