import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import useTheme from '@material-ui/core/styles/useTheme'
import { Subtitle1 } from '@rse/uikit'
import React, { Component } from 'react'
import { useHistory } from 'react-router-dom'
import { LogoutButton, NavBack } from '../../components/inputs'
import { AppLayout } from '../../components/layout'
import { HeaderBox } from '../../components/layout/appLayout/Header/HeaderBox'
import { ErrorMessage } from '../ErrorMessage'

type Props = {
  children?: React.ReactNode
  text?: string
}

type State = {
  hasError: boolean
}

function ErrorHeader({ title, callBack }: { title: string, callBack?: () => void }) {
  const theme = useTheme()
  const history = useHistory()

  async function back() {
    if (callBack) callBack()
    history.push('/')
  }

  return (
    <HeaderBox bgcolor={theme.palette.primary.main} color="white">
      <Container maxWidth="xs">
        <Box display="flex" justifyContent="space-between" alignItems="center" height="56px" minHeight="0">
          <NavBack color="white" back={back} />
          {title && <Subtitle1>{title}</Subtitle1>}
          <LogoutButton buttonType="icon" callBack={callBack} />
        </Box>
      </Container>
    </HeaderBox>
  )
}

export class AuthenticatedErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
  }

  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any): void {
    // TODO send log server-side
    console.log(error, errorInfo)
    this.setState({ hasError: true })
  }

  clearError = () => {
    this.setState({ hasError: false })
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <AppLayout
        header={<ErrorHeader title="Site Error" callBack={this.clearError} />}
        body={<ErrorMessage text={this.props.text} />}
      />
    }

    return this.props.children
  }
}