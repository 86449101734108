import React from 'react'
import { QuestionComponentProps } from '.'
import { createStyles, IconButton, makeStyles } from '@material-ui/core'
import { Favorite as FavoriteIcon, FavoriteBorderOutlined } from '@material-ui/icons'

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      padding: 0,
    },
  })
)

export default function Favorite({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const styles = useStyles()
  const isFav = Array.isArray(Answer) && Answer[0] === 'true'

  function toggleFaorite() {
    AnswerUpdateHandler(isFav ? ['false'] : ['true'])
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      <IconButton className={styles.iconButton} onClick={() => toggleFaorite()}>
        {isFav ? <FavoriteIcon color="primary" /> : <FavoriteBorderOutlined color="disabled" />}
      </IconButton>
    </>
  )
}
