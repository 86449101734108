import React, { useContext } from 'react'
import { TextContentContext, TextContentCulture, TextContentType } from '.'

type Props = {
  resourceKey: string
  defaultValue: string
  culture?: TextContentCulture
}

const DEFAULT_CULTURE = 'en'

export function TextContent({ resourceKey, defaultValue, culture }: Props) {
  const textContent = useContext(TextContentContext)
  const stringContent: string = getTextContent(textContent, resourceKey, defaultValue, culture)

  return <>{stringContent}</>
}

export function getTextContent(
  textContent: TextContentType[],
  resourceKey: string,
  defaultValue = '',
  culture: TextContentCulture = 'en'
) {
  let stringContent: string = defaultValue

  if (textContent) {
    const record = textContent.find((item) => item.id === resourceKey)
    if (record && record.value) {
      stringContent = record.value[culture || DEFAULT_CULTURE]
    }
  }

  return stringContent
}
