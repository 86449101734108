import React from 'react'
import { Box, TextField } from '@material-ui/core'
import { useState } from 'react'
import { BuyingGroup } from '@rse/core'
import { TextContent } from '../../../../common/textContent/TextContent'
import {
  Popup,
  ButtonAcceptCR,
  ButtonDeleteCR,
  ButtonLarge,
  ButtonMedium,
  SectionHeader,
  Subtitle1,
} from '@rse/uikit'
import { useHistory } from 'react-router-dom'

type Props = {
  userId: string
  group: BuyingGroup
}

export const BGActions = ({ userId, group }: Props) => {
  const history = useHistory()
  const [showRespondGroupToMemberPopup, setShowRespondGroupToMemberPopup] = useState(false)
  const [showJoinGroupPopup, setShowJoinGroupPopup] = useState(false)
  const [answeredQuestions, setAnsweredQuestions] = useState<string[]>([])

  return (
    <>
      <Popup open={showRespondGroupToMemberPopup} setOpen={setShowRespondGroupToMemberPopup}>
        <Box mb={2}>
          <SectionHeader>Please respond to group request:</SectionHeader>
        </Box>
        {group && group.questions && (
          <Box my={2} width="100%">
            {group.questions
              ?.filter((q) => q.title)
              .map((question, index) => (
                <Box key={question.id} mb={1}>
                  <TextField
                    variant="outlined"
                    name={`question-${question.id}`}
                    fullWidth
                    label={question.title}
                    value={answeredQuestions[index]}
                    onChange={(e) => {
                      e.persist()

                      setAnsweredQuestions((q) => {
                        const newAnswers = [...q]
                        newAnswers[index] = e.target?.value
                        return newAnswers
                      })
                    }}
                  />
                </Box>
              ))}
          </Box>
        )}
        <Box display="flex" justifyContent="center" mt={3}>
          <ButtonDeleteCR
            onClick={async () => {
              setShowRespondGroupToMemberPopup(false)
              //await memberToGroupConnection.rejectGroupToMemberReqAction()
            }}
          >
            <TextContent resourceKey="buttons.delete-connection-request" defaultValue="Delete" />
          </ButtonDeleteCR>
          <Box display="inline-block" ml={1}>
            <ButtonAcceptCR
              disabled={
                group.questions &&
                group.questions
                  .filter((q) => q.title)
                  .every(
                    (q, index) =>
                      answeredQuestions && answeredQuestions[index] && answeredQuestions[index].trim().length > 0
                  )
                  ? false
                  : true
              }
              onClick={async () => {
                setShowRespondGroupToMemberPopup(false)
                //await memberToGroupConnection.acceptGroupToMemberReqAction(answeredQuestions.filter((a) => a))
              }}
            >
              <TextContent resourceKey="buttons.accept-connection-request" defaultValue="Accept" />
            </ButtonAcceptCR>
          </Box>
        </Box>
      </Popup>

      <Popup open={showJoinGroupPopup} setOpen={setShowJoinGroupPopup}>
        <Box mb={2}>
          <Subtitle1>Please answer icebreaker questions:</Subtitle1>
        </Box>
        {group && group.questions && (
          <Box my={2} width="100%">
            {group.questions
              .filter((q) => q.title)
              .map((question, index) => (
                <Box key={question.id} mb={1}>
                  <TextField
                    variant="outlined"
                    name={`question-${question.id}`}
                    fullWidth
                    label={question.title}
                    value={answeredQuestions[index]}
                    onChange={(e) => {
                      e.persist()

                      setAnsweredQuestions((q) => {
                        const newAnswers = [...q]
                        newAnswers[index] = e.target?.value
                        return newAnswers
                      })
                    }}
                  />
                </Box>
              ))}
          </Box>
        )}
        <Box>
          <ButtonLarge
            fullWidth
            disabled={
              group.questions &&
              group.questions
                .filter((q) => q.title)
                .every(
                  (q, index) =>
                    answeredQuestions && answeredQuestions[index] && answeredQuestions[index].trim().length > 0
                )
                ? false
                : true
            }
            onClick={async () => {
              setShowJoinGroupPopup(false)
              //await memberToGroupConnection.joinGroupRequestAction(answeredQuestions)
            }}
          >
            <TextContent resourceKey="buttons.accept-connection-request" defaultValue="Send" />
          </ButtonLarge>
        </Box>
      </Popup>

      <Box m={1}>
        <ButtonMedium onClick={() => { history.push(`/messages/peer/group/${group.id}`) }}>
          <TextContent resourceKey="buttons.chat-with-group" defaultValue="Chat with Group" />
        </ButtonMedium>
      </Box>
    </>
  )
}
