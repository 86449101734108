import React from 'react'
import { makeStyles, createStyles, TableContainer, Table, TableBody, TableCell, TableRow, Box } from '@material-ui/core'
import { PreferenceListItem } from './types'
import { TextContent } from '../../../common/textContent'

const useStyles = makeStyles(() =>
  createStyles({
    tableCellName: {
      borderBottom: 'none',
      paddingLeft: 0,
    },
    tableCellValue: {
      borderBottom: 'none',
      fontWeight: 700,
      paddingRight: 0,
    },
  })
)

export function PreferenceListView({
  ariaLabel,
  rows,
}: {
  ariaLabel?: string
  rows: PreferenceListItem[]
}): JSX.Element {
  const styles = useStyles()

  return (
    <>
      {rows.length ? (
        <TableContainer>
          <Table size="small" aria-label={ariaLabel}>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell className={styles.tableCellName} scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell className={styles.tableCellValue} align="right">
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box mt={1}>
          <TextContent
            resourceKey="preference-list-view.no-items"
            defaultValue="This user has not set their preferences."
          />
        </Box>
      )}
    </>
  )
}
