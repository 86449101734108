import React from 'react'
import { makeStyles, Tabs, Tab, Theme } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { PreviewTitle } from '@rse/uikit'
import { URL_CLUB_MEMBERS, URL_GROUPS } from '../../common/constants/routes'


const useStyles = makeStyles((theme: Theme) => ({
  tabStyle: {
    textTransform: 'none',
  },
  tabRoot: {
    position: 'fixed',
    zIndex: 1000,
    backgroundColor: theme.palette.background.default,
    width: '396px'    
  }
}))

export const MemberGroupsNavToggle = () => {
  const location = useLocation(),
    history = useHistory(),
    selectedTab = location.pathname === URL_GROUPS ? 1 : 0,
    { tabStyle, tabRoot } = useStyles()

  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    value === 1 ? history.push(URL_GROUPS) : history.push(URL_CLUB_MEMBERS)
  }

  return (
    <Tabs className={tabRoot} indicatorColor="primary" textColor="primary" variant="fullWidth" value={selectedTab} onChange={handleChange}>
      <Tab className={tabStyle} label={<PreviewTitle>People</PreviewTitle>} />
      <Tab className={tabStyle} label={<PreviewTitle>Groups</PreviewTitle>} />
    </Tabs>
  )
}
