import { Radio, RadioGroup, styled } from '@material-ui/core'
import React from 'react'

export type InputOption = {
  id: string | number
  title: React.ReactNode
}

export type Props = {
  name: string
  options: InputOption[]
  value: string
  onChange: (e: React.ChangeEvent) => void
}

export const InputSelectRadioPills: React.VFC<Props> = ({ name, options, value, onChange }) => {
  const style = {
    flexBasis: `${Math.floor(100 / options.length) - 1}%`,
  }

  return (
    <>
      <StyledRadioGroup name={name} onChange={onChange} value={value}>
        {options.map((o) => (
          <StyledRadio key={o.id} value={o.id} icon={o.title} checkedIcon={o.title} style={style} />
        ))}
      </StyledRadioGroup>
    </>
  )
}

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const StyledRadio = styled(Radio)(({ theme: { palette } }) => ({
  borderRadius: '5px',
  backgroundColor: 'rgba(43, 39, 97, 0.07)',
  transition: 'background-color .1s',
  color: '#8C8AAC',
  height: 50,
  '&:hover': {
    backgroundColor: palette.primary.dark,
    color: palette.type === 'light' ? palette.common.white : palette.common.black,
    transition: 'background-color .1s',
  },
  '& .MuiIconButton-label': {
    display: 'flex',
  },
  '&.Mui-checked.MuiButtonBase-root': {
    backgroundColor: palette.primary.main,
    transition: 'background-color .1s',
    color: palette.type === 'light' ? palette.common.white : palette.common.black,
  },
}))
