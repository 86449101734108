import { BuyingGroup, BuyingGroupMatch } from '@rse/core'
import { firebaseCollectionNames } from './firebaseConstants'
import { firebaseInstance } from './firebaseInstance'
import { firebaseGetUserPublicProfile } from './firebaseUserPublicProfiles'
import {
  firebaseDBHelper,
  firebaseSendGroupJoinedNotification,
} from '.'
import { firebaseOnCallFunctions } from '../common/constants'

export async function firebaseGetBuyingGroups() {
  return await firebaseInstance.db.collection(firebaseCollectionNames.buyingGroups).get()
}

export async function firebaseGetBuyingGroupById(groupId: string) {
  return await (await firebaseInstance.db.collection(firebaseCollectionNames.buyingGroups).doc(groupId).get()).data()
}

export async function firebaseToggleGroupFavorite(uid: string, groupId: string) {
  const collectionPath = `${firebaseCollectionNames.profileMatches}/${uid}/${firebaseCollectionNames.buyingGroupMatches}`
  let buyingGroupMatched: BuyingGroupMatch = {id:'',headline:'' }
  buyingGroupMatched  = await firebaseDBHelper.GetRecord(collectionPath, groupId, buyingGroupMatched)

  if(!buyingGroupMatched){
    throw 'Cannot set buying group favorite for group that has not been matched yet.'
  }

  buyingGroupMatched.isFab = !buyingGroupMatched.isFab

  await firebaseDBHelper.AddOrUpdateRecord(collectionPath,groupId,buyingGroupMatched)

  return buyingGroupMatched.isFab
}

export async function firebaseGetGroupsMembers(): Promise<Record<string, string[]>> {
  const buyingGroups = await firebaseGetBuyingGroups()

  const members: Record<string, string[]> = {}
  for (const doc of buyingGroups?.docs) {
    const membersList = await doc.ref.collection(firebaseCollectionNames.buyingGroupMembers).get()
    members[doc.id] = membersList.docs.map((item) => item.id)
  }

  return members || {}
}

// FIXME this has been neutered just to remember what happened after the user gets added
export async function firebaseVoteForUser(
  uid: string,
  groupId: string,
  candidateId: string,
) {
  console.log(`everybody has successfully voted, so adding ${candidateId} to group ${groupId}`)
  await firebaseAddNewGroupMember(groupId, candidateId)

  try {
    await firebaseSendGroupJoinedNotification(candidateId, groupId)
  } catch (e) {
    console.log(e)
  }
}

export async function firebaseAddNewGroupMember(groupId: string, candidateId: string) {
  // TODO: read from UserInitApplication.preferredName
  const userPublicProfile = await firebaseGetUserPublicProfile(candidateId)
  const displayName = `${userPublicProfile?.firstName || ''} ${userPublicProfile?.lastName || ''}`.trim()

  try {
    firebaseInstance.db
      .collection(firebaseCollectionNames.buyingGroups)
      .doc(groupId)
      .collection(firebaseCollectionNames.buyingGroupMembers)
      .doc(candidateId)
      .set({
        displayName,
      })
    console.log('User added to group')
  } catch (e) {
    console.log(e)
  }
}

export async function firebasePublishBuyingGroup(uid: string, data: BuyingGroup) {
  const newGroup: BuyingGroup = Object.assign({}, data)
  newGroup.id = uid
  if (newGroup.headline == null) {
    newGroup.headline = ''
  }

  const func = firebaseInstance.firebaseFunction.httpsCallable(firebaseOnCallFunctions.createBuyingGroup)
  const result = await func(newGroup)
  console.log('new buying group created', result)
}

export async function firebaseUploadGroupImage(groupId: string, file: File) {
  const snapshot = await firebaseInstance.storage
    .ref(`${firebaseCollectionNames.buyingGroups}/${groupId}/profile.jpg`)
    .put(file)

  console.log(`file uploaded ${snapshot.metadata.fullPath}`)

  return await firebaseGetGroupImageUrl(groupId)
}

async function firebaseGetGroupImageUrl(groupId: string) {
  return await firebaseInstance.storage
    .ref(`${firebaseCollectionNames.buyingGroups}/${groupId}/profile.jpg`)
    .getDownloadURL()
}

export async function firebaseUpdateGroupData(group: BuyingGroup) {
  console.log('Updating BG data', group)
  await firebaseInstance.db.collection(firebaseCollectionNames.buyingGroups).doc(group.id).update(group)
}
