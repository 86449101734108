/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router-dom'
import DynamicWizardBody from './components/DynamicWizardBody'
import { useDynamicWizard } from './hooks/useDynamicWizard'

type urlParams = {
  groupId: string
}
type Props = {
  ConfigID: string
  Slots?: Record<string, React.ReactElement>
}

export function BuyingGroupViewWizard({ ConfigID, Slots }: Props) {
  const { groupId } = useParams<urlParams>()
  const dynamicWizardService = useDynamicWizard(ConfigID)

  console.log('group id:', groupId)
  console.log(dynamicWizardService)

  return (
    <>
      {!dynamicWizardService.isLoaded && <p>Loading..</p>}

      {dynamicWizardService.isLoaded && (
        <Container maxWidth="xs">
          {/* TODO: the above container is to be removed when the component is included into real page*/}
          <DynamicWizardBody
            userData={dynamicWizardService.userData}
            configData={dynamicWizardService.configDocument}
            savePageDataAction={dynamicWizardService.SavePageAnswersAction}
            Slots={Slots}
          />
        </Container>
      )}
    </>
  )
}
