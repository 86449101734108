/* eslint-disable react/display-name */
import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'

const useStyles = makeStyles(({ palette, spacing, shape }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        marginBottom: spacing(1),
      },
    },
    styledButton: {
      padding: spacing(1, 2),
      color: 'white',
      width: '100%',
      border: 'none',
      textTransform: 'uppercase',
      backgroundColor: palette.primary.main,
      boxShadow: '0 5px 5px rgba(0,0,0,.2), 0 8px 10px rgba(0,0,0,.14), 0 3px 14px rgba(0,0,0,.12)',
      '&.Mui-selected': {
        backgroundColor: palette.primary.dark,
        color: 'white',
      },
      '@media (hover: hover)': {
        '&:hover, &.Mui-selected:hover': {
          backgroundColor: palette.primary.light,
        },
      },
      '@media (hover: none)': {
        '&.Mui-selected:hover': {
          backgroundColor: palette.primary.dark,
        },
      },
      '&:hover': {
        '@media (hover: none)': {
          backgroundColor: palette.primary.main,
        },
      },
      '&:not(:first-child)': {
        borderRadius: shape.borderRadius,
      },
      '&:first-child': {
        borderRadius: shape.borderRadius,
      },
      '& .MuiToggleButton-label': {
        fontWeight: 700,
      },
    },
  })
)

export type StyledButtonGroupValueType = (string | number)[] | (string | number)

interface ButtonProps {
  name?: string
  value: StyledButtonGroupValueType
  buttonValues: Record<string, StyledButtonGroupValueType>
  title?: string
  onChange(event: React.MouseEvent<HTMLElement, MouseEvent>, value: StyledButtonGroupValueType): void
  disabled?: boolean
  exclusive?: boolean
}

export const StyledButtonGroup = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const styles = useStyles()
  const { value, buttonValues, onChange, title, disabled, exclusive } = props

  const buttons = []

  for (const label in buttonValues) {
    buttons.push(
      <ToggleButton
        key={label}
        value={buttonValues[label]}
        aria-label={label}
        className={styles.styledButton}
        disabled={disabled}
      >
        {label}
      </ToggleButton>
    )
  }

  return (
    <ToggleButtonGroup
      value={value}
      onChange={onChange}
      aria-label={title}
      className={styles.root}
      exclusive={exclusive}
      ref={ref}
    >
      {buttons}
    </ToggleButtonGroup>
  )
})
