import { useMobileMode } from '.'
import { MobileDevice } from '../models'

declare const device: MobileDevice | null | undefined

export function useMobileDevice() {
  const isMobileMode = useMobileMode()

  return isMobileMode
    ? {
      device,
      isAndroid: device?.platform === 'Android',
      isIOS: device?.platform === 'iOS',
    }
    : undefined
}
