import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BuyingGroup } from '@rse/core'

import BuyingGroupDetail from '../components/BuyingGroupDetail'
import { AppRoutes } from '../../../App/routeUtils'
import { PreviewGroupRedirect } from './PreviewGroupRedirect'

type Props = {
  groupData: BuyingGroup
  createGroup: () => void
  previewGoBack: () => void
}

export default function BuyingGroupDetailsPreview({ groupData, createGroup, previewGoBack }: Props) {
  const history = useHistory()
  const [redirectStageMode, setRedirectStageMode] = useState<boolean>(false)

  if (redirectStageMode) {
    return <PreviewGroupRedirect onRedirect={() => history.push(AppRoutes.clubMemberRoot())} />
  }

  return (
    <BuyingGroupDetail
      group={groupData}
      groupMembers={[]}
      previewMode={true}
      previewGoBack={previewGoBack}
      leaveGroup={undefined}
      finalizeGroup={undefined}
      createGroup={async () => {
        await createGroup()
        setRedirectStageMode(true)
      }}
    />
  )
}
