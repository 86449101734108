import React from 'react'
import format from 'date-fns/format'
import { Typography } from '@material-ui/core'

type Props = {
  time: Date
}

const isSameDay = (d1: Date, d2: Date): boolean =>
  d1.getUTCFullYear() === d2.getUTCFullYear() &&
  d1.getUTCMonth() === d2.getUTCMonth() &&
  d1.getUTCDate() === d2.getUTCDate()

export function TimeLabel({ time }: Props) {
  const today = new Date()

  return <Typography variant="h6" color="textSecondary">{isSameDay(time, today) ? format(time, 'HH:mm') : format(time, 'dd-MMM')}</Typography>
}
