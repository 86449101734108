import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useParams } from 'react-router-dom'
import { DividerSpaced } from '../../../components/common'
import { useClubMemberFavStatus } from '../../../firebase'
import { useCurrentUserId } from '../../../common/hooks'
import CmUserAboutMe from './components/CmUserAboutMe'
import CmUserPrefs from './components/CmUserPrefs'
import { AppLayout, Footer, Header } from '../../../components/layout'
import ProfileHeader from './components/ProfileHeader'
import { CmProfileActions } from './components/CmProfileActions'
import { CmUserProfileEditButton } from './components/CmUserProfileEditButton'
import { BodyContainer } from '../../../components/layout/BodyContainer'
import { LoadingSpinner } from '@rse/uikit'
import { useUserPublicProfileApi } from '../../../firebase/hooks/useUserPublicProfileApi'
import { selectedPublicProfileContext } from '../selectedPublicProfileContext'
import { UserProfilePublicEntity } from '@rse/core'

export const ClubMemberDetails = () => {
  const [member, setMember] = useState<UserProfilePublicEntity | undefined>(undefined)
  const { selectedPublicProfile, setSelectedPublicProfile, loadValueFromContext, setLoadValueFromContext } = useContext(selectedPublicProfileContext)
  const { id } = useParams<{ id: string }>()
  const { value: currentMember, loading } = useUserPublicProfileApi(id, true, member)




  useEffect(() => {
    if (loadValueFromContext) {
      setMember(selectedPublicProfile)
      if (setLoadValueFromContext) {
        setLoadValueFromContext(false)
      }
    } else {
      setMember(currentMember)
    }
  }, [currentMember])




  const myUid = useCurrentUserId()
  const isFab = useClubMemberFavStatus(myUid, id)

  const userIsViewingSelf = id === myUid
  const disableActions = userIsViewingSelf
  const title = userIsViewingSelf ? 'Your Profile' : 'Member Profile'
  const backStatus = 'history'

  if (loading && !member) {
    return (
      <AppLayout
        header={<Header back="history" title="Profile loading" />}
        footer={<Footer />}
        body={
          <BodyContainer>
            <Box display="flex" justifyContent="center" alignItems="center"><LoadingSpinner reason="profile data.." /></Box>
          </BodyContainer>
        }
      />
    )
  }

  if (!member) {
    return (
      <AppLayout
        header={<Header back="history" title="Profile not found" />}
        footer={<Footer />}
        body={
          <BodyContainer>
            <Box pt={2}>
              <Alert severity="error">No data found</Alert>
            </Box>
          </BodyContainer>
        } // TODO: abstract to a common component
      />
    )
  }

  member.id = id // TODO: inestigate why there is no Id

  return (
    <AppLayout
      header={<Header back={backStatus} title={title} hideMenu={!userIsViewingSelf} />}
      body={
        <BodyContainer>
          <Box mt={2} mb={3}>
            <ProfileHeader {...member} isFab={isFab} id={id} userViewingSelf={userIsViewingSelf} />

            {!disableActions && (
              <Box my={3}>
                <CmProfileActions currentUid={myUid} profileUid={id} />
              </Box>
            )}

            {userIsViewingSelf && <CmUserProfileEditButton />}

            {member.description && (
              <>
                <CmUserAboutMe description={member.description} tags={member.whatsImportant} />
                <DividerSpaced />
              </>
            )}

            <CmUserPrefs {...member} />
          </Box>
        </BodyContainer>
      }
      footer={<Footer />}
    />
  )
}
