import { Radio, RadioGroup, styled, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { QuestionComponentProps } from '.'
import { TextError } from '../../../../pages/Application/components/TextError'
import { StandardText } from '@rse/uikit'
import { Spacer } from '../layoutComponents/Spacer'

export default function PillsInput({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const lastOptionIsOther: boolean = otherProps?.lastOptionIsOther == 'true' ? true : false
  const [currentValue, setCurrentValue] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [lastOptionSelected, setLastOptionSelected] = useState(false)
  const [lastOptionText, setLastOptionText] = useState('')

  useEffect(() => {
    let currentIsValid = true
    if (Answer && Answer[0]) {
      setCurrentValue(Answer[0])
      currentIsValid = isValidAnswer(Answer[0])
      setIsValid(currentIsValid)
      if (QuestionConfig.QuestionChoices) {
        const lastOptionValue = QuestionConfig.QuestionChoices[QuestionConfig.QuestionChoices.length - 1].Value
        const allOptions = QuestionConfig.QuestionChoices.map((x) => x.Value)
        if (!allOptions.includes(Answer[0]) || Answer[0] == lastOptionValue) {
          setLastOptionSelected(true)
          setCurrentValue(lastOptionValue)
          setLastOptionText(Answer[0])
        } else {
          setLastOptionSelected(false)
          setLastOptionText('')
        }
      }
    } else {
      currentIsValid = isValidAnswer('')
      setIsValid(currentIsValid)
      setCurrentValue('')
    }

    ValidStatusUpdateHandler(currentIsValid)
  }, [])

  const selectionChangeHandler = (selectedValue: string) => {
    setCurrentValue(selectedValue)
    const isCurrentSelectionValid = isValidAnswer(selectedValue)
    setIsValid(isCurrentSelectionValid)
    ValidStatusUpdateHandler(isCurrentSelectionValid)

    let currentLastOptionSelected = false
    if (QuestionConfig.QuestionChoices) {
      const lastOptionValue = QuestionConfig.QuestionChoices[QuestionConfig.QuestionChoices.length - 1].Value
      if (selectedValue == lastOptionValue) {
        currentLastOptionSelected = true
      } else {
        currentLastOptionSelected = false
      }
    }
    setLastOptionSelected(currentLastOptionSelected)
    if (!setLastOptionSelected) {
      AnswerUpdateHandler([selectedValue])
    } else {
      if (lastOptionText) {
        AnswerUpdateHandler([lastOptionText])
      } else {
        AnswerUpdateHandler([selectedValue])
      }
    }
  }

  const optionChoincesLength = QuestionConfig.QuestionChoices?.length || 1
  const id = `q${QuestionConfig.QuestionId}`

  const style = {
    flexBasis: `${Math.floor(100 / optionChoincesLength) - 1}%`,
  }

  const isValidAnswer = (currentVal: string) => {
    if (!currentVal) {
      if (QuestionConfig.IsRequired) {
        return false
      }
    }

    return true
  }

  const getChoiceComponent = (choiceTitle: string) => {
    return <StandardText>{choiceTitle}</StandardText>
  }

  const lastOptionTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const currentLastOptionText = e.target.value
    setLastOptionText(currentLastOptionText)

    let lastOptionValue = 'Undefined'
    if (QuestionConfig.QuestionChoices) {
      lastOptionValue = QuestionConfig.QuestionChoices[QuestionConfig.QuestionChoices.length - 1].Value
    }

    if (!currentLastOptionText) {
      AnswerUpdateHandler([lastOptionValue])
    } else {
      AnswerUpdateHandler([currentLastOptionText])
    }
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}
      {optionChoincesLength > 1 && QuestionConfig.QuestionChoices && (
        <>
          <StyledRadioGroup
            name={id}
            onChange={(e) => selectionChangeHandler(e.target.value)}
            value={currentValue || ''}
          >
            {QuestionConfig.QuestionChoices.map((o) => (
              <StyledRadio
                key={`qChoice${QuestionConfig.QuestionId}${o.Value}`}
                value={o.Value}
                icon={getChoiceComponent(o.Label)}
                checkedIcon={<StandardText>{getChoiceComponent(o.Label)}</StandardText>}
                style={style}
              />
            ))}
          </StyledRadioGroup>
          <div hidden={!lastOptionIsOther || !lastOptionSelected}>
            <Spacer height={20} />
            <TextField
              type={'text'}
              name={QuestionConfig.QuestionName + 'Detail'}
              value={lastOptionText}
              onChange={lastOptionTextChangeHandler}
              onBlur={lastOptionTextChangeHandler}
              placeholder={QuestionConfig.Title + ' (optional)'}
            />
          </div>
          {!isValid && displayQuestionErrors && <TextError>Selection is required</TextError>}
        </>
      )}
      {optionChoincesLength <= 1 && <TextError>Invalid configuration</TextError>}
    </>
  )
}

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const StyledRadio = styled(Radio)(({ theme: { palette } }) => ({
  borderRadius: '5px',
  backgroundColor: 'rgba(43, 39, 97, 0.07)',
  transition: 'background-color .1s',
  color: '#8C8AAC',
  height: 50,
  '&:hover': {
    backgroundColor: palette.primary.dark,
    color: palette.type === 'light' ? palette.common.white : palette.common.black,
    transition: 'background-color .1s',
  },
  '& .MuiIconButton-label': {
    display: 'flex',
  },
  '&.Mui-checked.MuiButtonBase-root': {
    backgroundColor: palette.primary.main,
    transition: 'background-color .1s',
    color: palette.type === 'light' ? palette.common.white : palette.common.black,
  },
}))
