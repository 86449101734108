import React from 'react'
import { ProfileBirthday } from '../fieldsets/ProfileBirthday'
import { ProfileCurrentRent } from '../fieldsets/ProfileCurrentRent'
import { ProfileGender } from '../fieldsets/ProfileGender'
import { ProfileLegalName } from '../fieldsets/ProfileLegalName'
import { ProfileComfyPay } from '../fieldsets/ProfileComfyPay'
import { ProfileUploadPhoto } from '../fieldsets/ProfileUploadPhoto'
import { ProfileMoveInPeriod } from '../fieldsets/ProfileMoveInPeriod'
import { ProfileBriefDescription } from '../fieldsets/ProfileBriefDescription'


export const StepProfile: React.FC = () => {
  return (
    <div>
      <ProfileLegalName showSectionHeader={true} />
      <ProfileBriefDescription showSectionHeader={true}/>
      <ProfileUploadPhoto />
      <ProfileBirthday />
      <ProfileGender />
      <ProfileMoveInPeriod />
      <ProfileCurrentRent />
      <ProfileComfyPay />
    </div>
  )
}
