import { ValidationSchema } from '../types/validation'
import { z } from 'zod'
import { sub, isAfter } from 'date-fns'

export const validationSchema: ValidationSchema = {
  firstName: z.string().min(2, { message: 'Must be 2 or more characters long' }),
  lastName: z.string().min(2, { message: 'Must be 2 or more characters long' }),
  birthday: z
    .date({ invalid_type_error: 'Please enter valid date' })
    .refine((val) => isAfter(sub(new Date(), { years: 20 }), val), {
      message: 'You must be older then 20 y.o.',
    }),
  currentRent: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val > 0, { message: 'Please enter valid rent amount' }),
  sex: z.string(),
  timeRange: z.string(),
  preferredName: z.string(),
  mobilePhone: z.string().min(11, { message: 'Please enter valid phone number' }),
  locations: z.string(),
  roommates: z.array(z.number()),
  preferenceField: z.string({
    required_error: 'Please choose your preference',
  }),
  ageRange: z.array(z.number()),
  roommateGender: z.string(),
  priceRange: z.array(z.number()),
  street: z.string().min(1, { message: 'Please enter valid street name' }),
  zipCode: z.string().min(5),
  city: z.string(),
  state: z.string(),
  incomeDuration: z
    .string({
      required_error: 'Please select income duration',
      invalid_type_error: 'Please select income duration',
    })
    .min(1, {
      message: 'Please select income duration',
    }),
  maxDownpayment: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val > 10, { message: 'Please enter valid max downpayment' }),
  monthlyIncome: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val > 10, { message: 'Please enter valid total monthly income' }),
  currentDeposits: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val > 0, { message: 'Please enter valid bank deposits' }),
  employmentTypes: z.string().array().min(1, {
    message: 'Please select employment type',
  }),
  payments0: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val >= 0, { message: 'Please enter valid credit card payments' }),
  payments1: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val >= 0, { message: 'Please enter valid car payments' }),
  payments2: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val >= 0, { message: 'Please enter valid other payments' }),
  ssn: z.string(),

  IDCheckAuthorized: z.literal(true),
  agreeToClubMemberRules: z.literal(true),
}
