import React, { useState, useEffect } from 'react'
import marked from 'marked'
import DOMPurify from 'dompurify'
import { getTextContent, useTextContent } from '../../common/textContent'
import { LegalContentType } from './types'
import { LoadingSpinner } from '@rse/uikit'

type Props = {
  contentType: LegalContentType
}

export function LegalPageContent({ contentType }: Props) {
  const [legalText, setLegalText] = useState<string | null>(null)
  const textContent = useTextContent()

  useEffect(() => {
    if (textContent.length === 0) {
      return
    }

    const getDocument = async () => {
      try {
        const content = getTextContent(textContent, contentType, '')
        setLegalText(content)
      } catch (err) {
        setLegalText('Error loading legal document, please reload the page')
      }
    }

    getDocument()
  }, [textContent])

  return (
    <>
      {legalText === null ? (
        <LoadingSpinner />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(legalText)) }} />
      )}
    </>
  )
}
