import React from 'react'
import { Box, Typography, makeStyles, createStyles } from '@material-ui/core'
import { MessageThreadType } from '@rse/core'
import { PreviewTitle } from '@rse/uikit'
import pinnedIcon from '@rse/uikit/lib/icons/pinned.svg'
import { ReactComponent as LeaveIcon } from '@rse/uikit/lib/icons/leave.svg'
import { ReactComponent as HouseIcon } from '@rse/uikit/lib/icons/house.svg'
import { ReactComponent as PersonIcon } from '@rse/uikit/lib/icons/person.svg'
import { Link } from 'react-router-dom'
import { UserOnlineStatus } from '../../../models'
import {
  BuyingGroupMembersView,
  GroupAvatarPublic,
  MessageExcerpt,
  TimeLabel,
  UnreadMessagesCount,
  UserAvatarPublic,
  UserOnlineStatusLabel,
} from '../../../components/common'
import { useBuyingGroup, useBuyingGroupMembers, useUserPublicProfile } from '../../../firebase'
import { UNAVAILABLE_DOC_ID } from '../../../common/constants/stubs'
import { useCurrentUserId } from '../../../common/hooks'

type ChatLinkCardProps = {
  threadId: string
  type: MessageThreadType
  title: string
  isPinned: boolean
  userStatus: UserOnlineStatus
  lastMessageId?: string
  lastMessageDisplayName?: string
  lastMessageMessage?: string
  lastMessageSentDate?: Date
  unreadMessagesCount?: number
  peerId?: string
  groupId?: string
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      padding: '1em',
      display: 'grid',
      gridTemplate: 'repeat(4, auto) / 60px auto 50px',
    },
    pinnedMark: {
      gridArea: '1 / 2 / 2 / 3',
      fontSize: 13,
    },
    avatarArea: {
      gridArea: '2 / 1 / 5 / 2',
      alignSelf: 'start',
      justifySelf: 'start',
      position: 'relative',
      marginRight: 20,
    },
    cardTitle: {
      gridArea: '2 / 2 / 3 / 3',
      marginBottom: 10,
      display: 'flex',

      '& img': {
        marginLeft: 10,
      },
    },
    groupMembers: {
      gridArea: '3 / 2 / 4/ 3',
      height: 40,
    },

    rightSide: {
      gridArea: '2 / 3 / 5 / 4',
      alignSelf: 'start',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    timeLabel: {
      textAlign: 'right',
      marginBottom: 10,
    },
    unreadCount: {
      marginBottom: 10,
    },
    message: {
      gridArea: '4 / 2 / 5 / 3',
      overflow: 'auto',
    },
    iconStyle: {
      fill: palette.primary.main,
      minWidth: '15px',
      maxWidth: '15px',
      marginLeft: '0.5em',

      '& + &': {
        marginLeft: '0.2em',
      },
    },
  })
)

export default function ChatLinkCard({
  threadId,
  type,
  title,
  isPinned,
  userStatus,
  lastMessageDisplayName,
  lastMessageMessage,
  lastMessageSentDate,
  unreadMessagesCount,
  peerId,
  groupId
}: ChatLinkCardProps) {
  const { container, pinnedMark, avatarArea, cardTitle, groupMembers, rightSide, timeLabel, message, iconStyle, unreadCount } =
    useStyles()


  //TODO: THIS IS SUPER INEFFICIENT.
  //We are trying to load data from DB even for cases where we do not need to load them.  E.g. no need to load peer data for group thread    
  const {
    value: peerMember,
    loading: peerMemberLoading
  } = useUserPublicProfile(peerId || UNAVAILABLE_DOC_ID)

  const {
    document: { value: group, loading: groupLoading },
  } = useBuyingGroup(groupId || UNAVAILABLE_DOC_ID)

  const myUid = useCurrentUserId()

  // const {
  //   collection: { value: members, loading: membersLoading },
  // } = useBuyingGroupMembers(peerId || UNAVAILABLE_DOC_ID)

  // const iAmMember = members && members.map((member) => member.id).includes(myUid)

  let chatTitle = 'Loading...'
  if (!peerMemberLoading && !groupLoading) {
    if (type === 'Group') {
      chatTitle = group?.headline || 'Untitled Group'
    } else if (type === 'GroupCourtship') {
      if (peerMember) {
        chatTitle = `Exploring: ${peerMember?.firstName} ${peerMember?.lastName}`
      } else {
        chatTitle = `Exploring: ${group?.headline}`
      }
    } else if (type === 'UserToUser') {
      chatTitle = `${peerMember?.firstName} ${peerMember?.lastName}`
    }
  }

  return (
    <Box className={container}>
      <Box className={avatarArea}>
        {group?.id &&
          (type === 'Group' || type === 'GroupCourtship') && (
            <GroupAvatarPublic groupId={group.id} />
          )}
        {peerId &&
          (type === 'UserToUser' || type === 'GroupCourtship') && (
            <>
              <UserAvatarPublic uid={peerId} />
              <UserOnlineStatusLabel userStatus={userStatus} />
            </>
          )}
      </Box>
      <Box className={pinnedMark}>
        {isPinned && (
          <Box display="flex" mb={1}>
            <img src={pinnedIcon} alt="pinned" />
            <Box ml={1}>
              <Typography>Pinned</Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={cardTitle}>
        <Box display="flex" alignItems="center">
          <PreviewTitle>{chatTitle}</PreviewTitle>
          {type !== 'Group' && <PersonIcon className={iconStyle} />}
          {['Group', 'GroupCourtship'].includes(type) && (
            <HouseIcon className={iconStyle} />
          )}
        </Box>
      </Box>
      {
      /* type === 'GroupCourtship' && group && (
        <Box className={groupMembers}>
          <BuyingGroupMembersView group={group} />
        </Box>
      )
      */}
      <Box className={rightSide}>
        <Box className={timeLabel}>
          {lastMessageSentDate && (
            <Typography variant="body2">
              <TimeLabel time={lastMessageSentDate} />
            </Typography>
          )}
        </Box>
        {!!unreadMessagesCount && <Box className={unreadCount}><UnreadMessagesCount count={unreadMessagesCount} /></Box>}
        {
          type != 'Group' && <Link to={`/messages/leave/${threadId}`}><LeaveIcon className={iconStyle} /></Link>
        }

      </Box>
      <Box className={message}>
        {lastMessageMessage && lastMessageDisplayName && (
          <Typography variant="body2">
            <MessageExcerpt name={lastMessageDisplayName} message={lastMessageMessage} />
          </Typography>
        )}
      </Box>
    </Box>
  )
}
