import { useState, useEffect } from 'react'
import { useFirebaseFunctionCall, functionCallStatus } from '../../firebase/hooks'
import { firebaseOnCallFunctions } from '../constants/firebaseOnCallFunctions'

/*
 * This hook is supposed to be used in authorized paths only, otherwise will throw an exception
 */

export function usePhoneVerification() {
  const sendPhoneCodeHook = useFirebaseFunctionCall(firebaseOnCallFunctions.sendPhoneCode, true)
  const verifycodeHook = useFirebaseFunctionCall(firebaseOnCallFunctions.verifyCode, true)
  const [sendPhoneCodeHookError, setSendPhoneCodeHookError] = useState(false)
  const [verifycodeHookError, setVerifycodeHookError] = useState(false)

  const [codeSentToPhone, setCodeSentToPhone] = useState(false)
  const [phoneCodeSendInProgress, setPhoneCodeSendInProgress] = useState(false)

  const [codeSentForVerification, setCodeSentForVerification] = useState(false)
  const [codeVerificationInProgress, setCodeVerificationInProgress] = useState(false)
  const [codeValid, setCodeValid] = useState(false)

  useEffect(() => {
    console.log(sendPhoneCodeHook.status)
    if (sendPhoneCodeHook.status == functionCallStatus.Loading) {
      setPhoneCodeSendInProgress(true)
    } else {
      setPhoneCodeSendInProgress(false)
    }

    if (sendPhoneCodeHook.status == functionCallStatus.Error) {
      setSendPhoneCodeHookError(true)
    } else {
      setSendPhoneCodeHookError(false)
    }

    if (sendPhoneCodeHook.status === functionCallStatus.Fetched) {
      setCodeSentToPhone(true)
    }
  }, [sendPhoneCodeHook.status])

  useEffect(() => {
    if (verifycodeHook.status == functionCallStatus.Loading) {
      setCodeVerificationInProgress(true)
    } else {
      setCodeVerificationInProgress(false)
    }

    if (verifycodeHook.status == functionCallStatus.Error) {
      setVerifycodeHookError(true)
    } else {
      setVerifycodeHookError(false)
    }
  }, [verifycodeHook.status])

  const sendVerificationCode = async (phone: string) => {
    await sendPhoneCodeHook.fetchDataFromFunction({ phone }) // TODO: this should better throw an exception...
  }

  const verifycode = async (code: string) => {
    const verificationResponse = await verifycodeHook.fetchDataFromFunction({ code: code })
    setCodeSentForVerification(true)

    setCodeValid(verificationResponse)
    return verificationResponse
  }

  const resetStatusCodeSentToPhone = () => {
    setCodeSentToPhone(false)
  }

  const resetStatuscodeSentForVerification = () => {
    setCodeSentForVerification(false)
    setCodeValid(false)
  }

  return {
    status: {
      sendPhoneCodeHookError,
      verifycodeHookError,
      codeSentToPhone,
      phoneCodeSendInProgress,
      codeSentForVerification,
      codeVerificationInProgress,
      codeValid: codeValid,
    },
    sendVerificationCode,
    verifycode,
    resetStatusCodeSentToPhone,
    resetStatuscodeSentForVerification,
  }
}
