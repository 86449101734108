import React, { useEffect, useState } from 'react'
import { getRoommateGenderTitle } from '../../../common/constants'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { BoxSummaryItem } from '../components/BoxSummaryItem'
import { Spacing } from '@rse/uikit'
import { TextHeading } from '../components/TextHeading'
import { useFormData } from '../helpers/useFormData'
import { preferencesPrefix } from './PrefsGroundRules'
import { usePreferencesQuestions } from '../../../common/hooks'

export const SummaryPreferences: React.VFC = () => {
  const textContent = useTextContent()
  const { formData } = useFormData()
  const [preferences, setPreferences] = useState<Record<string, any>>({})
  const {
    value: preferencesQuestions,
    loading: loadingQuestions,
    error: errorLoadingQuestions,
  } = usePreferencesQuestions()

  useEffect(() => {
    if (loadingQuestions || errorLoadingQuestions || !preferencesQuestions) return

    const prefs: Record<string, any> = {}

    Object.keys(formData).forEach((field) => {
      if (field.startsWith(preferencesPrefix)) {
        prefs[field.substring(preferencesPrefix.length)] = formData[field]
      }
    })

    setPreferences(
      Object.fromEntries(
        preferencesQuestions
          .map((q) => [q.label, q.enabledChoices?.find((ch) => ch.id === prefs[q.id])?.label])
          .filter((item) => item[1])
      )
    )
  }, [formData, loadingQuestions, errorLoadingQuestions, preferencesQuestions])

  return (
    <section>
      <Spacing value={28} />
      <TextHeading>
        <TextContent resourceKey="app-wizard.title-summary-preferences" defaultValue="Preferences" />
      </TextHeading>
      <Spacing value={11} />
      <BoxSummaryItem
        label={getTextContent(textContent, 'app-wizard.field-summary-size-of-group', 'Size of Buying group')}
      >
        {formData.roommates?.join('-')}
      </BoxSummaryItem>
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-summary-age-range', 'Age Range')}>
        {formData.ageRange?.join('-')}
      </BoxSummaryItem>
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-summary-roommate-gender', 'Gender')}>
        {getRoommateGenderTitle(formData.roommateGender)}
      </BoxSummaryItem>
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-summary-lgbt-friendly', 'LGBT friendly')}>
        {formData.lgbtqFriendly ? 'yes' : 'no'}
      </BoxSummaryItem>

      {Object.keys(preferences).map((item: string) => (
        <BoxSummaryItem key={item} label={item}>
          {preferences[item]}
        </BoxSummaryItem>
      ))}
    </section>
  )
}
