import { ApplicationFormData, ApplicationFormKey } from '../types/application'
import { validationSchema } from './validationSchema'
import { z } from 'zod'
import { FormErrors } from '../types/validation'
import { preferencesPrefix } from '../fieldsets/PrefsGroundRules'

export const validateFields = (formData: ApplicationFormData, formKeys: ApplicationFormKey[]) => {
  const errors: FormErrors = {}

  for (const key of formKeys) {
    const schema = key.startsWith(preferencesPrefix) ? validationSchema.preferenceField : validationSchema[key]
    if (schema) {
      try {
        console.info('validating field:', key, formData[key])
        schema.parse(formData[key])
        errors[key] = undefined
      } catch (err) {
        if (err instanceof z.ZodError) {
          errors[key] = err.issues.map((i) => i.message)[0]
        }
      }
    }
  }

  return errors
}

export const validationSchemaSingle: Record<string, z.ZodFirstPartySchemaTypes> = {
  firstName: z.string().min(2, { message: 'Must be 2 or more characters long' }),
  lastName: z.string().min(2, { message: 'Must be 2 or more characters long' }),
  sex: z.string(),
  timeRange: z.string(),
  preferredName: z.string(),
  mobilePhone: z.string().min(11, { message: 'Please enter valid phone number' }),
  locations: z.string(),
  roommates: z.array(z.number()),
  ageRange: z.array(z.number()),
  roommateGender: z.string(),
  preferenceField: z.string({
    required_error: 'Please choose your preference',
  }),
  priceRange: z.array(z.number()),
  street: z.string().min(1, { message: 'Please enter valid street name' }),
  zipCode: z.string().min(5),
  city: z.string(),
  state: z.string(),
  incomeDuration: z.union([
    z.string().min(1, { message: 'Please select income duration' }),
    z.string().array().min(1, { message: 'Please select income duration' }),
  ]),
  maxDownpayment: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val > 10, { message: 'Please enter valid max downpayment' }),
  monthlyIncome: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val > 10, { message: 'Please enter valid total monthly income' }),
  currentDeposits: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val > 0, { message: 'Please enter valid bank deposits' }),
  employmentTypes: z.union([
    z.string().min(1, { message: 'Please select employment type' }),
    z.string().array().min(1, { message: 'Please select employment type' }),
  ]),
  payments0: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val >= 0, { message: 'Please enter valid credit card payments' }),
  payments1: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val >= 0, { message: 'Please enter valid car payments' }),
  payments2: z
    .string()
    .transform((val) => Number.parseInt(val, 10))
    .refine((val) => val >= 0, { message: 'Please enter valid other payments' }),
  ssn: z.string(),

  IDCheckAuthorized: z.literal(true),
  agreeToClubMemberRules: z.literal(true),
}

export const validateSingleField = (fieldValue: string | number[], fieldName: string) => {
  let errorText: string | undefined
  const schema = validationSchemaSingle[fieldName]
  if (schema) {
    try {
      console.info('validated field:', fieldName, fieldValue)
      schema.parse(fieldValue)
      errorText = undefined
    } catch (err) {
      if (err instanceof z.ZodError) {
        errorText = err.issues.map((i) => i.message)[0]
      }
    }
  }

  return errorText
}
