import { useMemo } from 'react'
import { BuyingGroupMember } from '@rse/core'

import { firebaseInstance } from '../firebaseInstance'
import { firebaseCollectionNames } from '../firebaseConstants'
import { usePersistentCollection } from './usePersistentCollection'

export function useBuyingGroupMembers(groupId: string) {
  const collectionRef = useMemo(
    () =>
      firebaseInstance.db
        .collection(firebaseCollectionNames.buyingGroups)
        .doc(groupId)
        .collection(firebaseCollectionNames.buyingGroupMembers),
    [groupId]
  )

  const { collection, commonHandlers } = usePersistentCollection<BuyingGroupMember>(collectionRef)
  return { collection, handlers: commonHandlers }
}
