import React from 'react'
import { Badge } from '@material-ui/core'
import { UNAVAILABLE_DOC_ID } from '../../common/constants/stubs'
import { useUserNotifications } from '../../firebase'
import { useCurrentUserId } from '../../common/hooks'

export const NotificationsBadge: React.FC = ({ children }) => {
  const user = useCurrentUserId()
  const { unread } = useUserNotifications(user || UNAVAILABLE_DOC_ID)

  return (
    <Badge color="error" badgeContent={unread} invisible={!unread}>
      {children}
    </Badge>
  )
}
