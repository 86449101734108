import React from 'react'
import { Box } from '@material-ui/core'
import { QuestionComponentProps } from '.'
import { TextStandard } from '../../../../pages/Application/components/TextStandard'

export default function ParamValue({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}
      <Box display="flex" justifyContent="space-between">
        <strong>{QuestionConfig.Title}</strong>
        <TextStandard>{Answer[0]}</TextStandard>
      </Box>
    </>
  )
}
