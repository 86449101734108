import React, { useContext, useRef } from 'react'
import { Box, Container, makeStyles, createStyles } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { BuyingGroupMember } from '@rse/core'
import { AppLayout, Footer, Header } from '../../../components/layout'
import { useBuyingGroup, useBuyingGroupMembers } from '../../../firebase'
import { UserAvatarPublic } from '../../../components/common'
import { UNAVAILABLE_DOC_ID } from '../../../common/constants'
import { TextContent } from '../../../common/textContent'
import exclamationIcon from '@rse/uikit/lib/icons/exclamation.svg'
import {
  AppLink,
  LoadingSpinner,
  useLoadingIndicator,
  usePopup,
  ActionButton,
  ButtonLarge,
  ButtonLargeSecondary,
} from '@rse/uikit'
import { UserContext } from '../../../App'
import { PreferenceHeading } from '../../Application/components/PreferenceHeading'
import { AppRoutes } from '../../../App/routeUtils'
import { BodyContainer } from '../../../components/layout/BodyContainer'

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    container: {
      padding: spacing(2),
    },
    memberLine: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& + &': {
        marginTop: 15,
      },
    },
  })
)

type urlParams = {
  groupId: string
}

export function BuyingGroupMembers() {
  const classes = useStyles()
  const history = useHistory()
  const user = useContext(UserContext)
  const { groupId } = useParams<urlParams>()
  const { Popup, setPopupOpen: setRemovememberPopupOpen } = usePopup()
  const memberSelected = useRef<BuyingGroupMember | undefined>(undefined)

  const hostId = user!.id // TODO: lookup real hostId instead of assuming it's the current

  const {
    document: { value: group, loading: loadingGroups },
    handlers: { removeGroupMember },
  } = useBuyingGroup(groupId || UNAVAILABLE_DOC_ID)

  const {
    collection: { value: members, loading: loadingMembers },
  } = useBuyingGroupMembers(groupId)

  const { wrapPromise } = useLoadingIndicator()

  if (loadingGroups || loadingMembers) {
    return <BodyContainer><LoadingSpinner /></BodyContainer>
  }

  if (!group || !members) {
    return <div>Group is not found</div>
  }

  if (user!.buyingGroupId !== groupId || !user!.isGroupHost) {
    // TODO: update when common permissions management task is done
    return null
  }

  if (members && members.length === 1) {
    // just a host is a member - members page is empty - redirect to group details page
    history.push(AppRoutes.group(groupId))
  }

  return (
    <AppLayout
      header={<Header back={async () => history.goBack()} title={'Members group'} hideMenu></Header>}
      body={
        <Container maxWidth="xs">
          <Popup>
            <Box mb={2} display="flex" flexDirection="column" alignItems="center">
              <img src={exclamationIcon} alt="exclamation icon" />
              <Box mt={2} />
              <PreferenceHeading style={{ textAlign: 'center' }}>
                Are you sure you want to remove
                <br />
                {memberSelected.current?.displayName} from the group?
              </PreferenceHeading>
            </Box>
            <Box mt={3}>
              <Box mb={2}>
                <ButtonLarge
                  fullWidth
                  isUppercase={false}
                  onClick={async () => {
                    setRemovememberPopupOpen(false)
                    await wrapPromise(removeGroupMember(groupId, memberSelected.current!.id))
                  }}
                >
                  <TextContent resourceKey="buttons.save" defaultValue="Save" />
                </ButtonLarge>
              </Box>
              <ButtonLargeSecondary
                fullWidth
                isUppercase={false}
                onClick={() => {
                  setRemovememberPopupOpen(false)
                }}
              >
                <TextContent resourceKey="buttons.do-not-save" defaultValue="Do not save" />
              </ButtonLargeSecondary>
            </Box>
          </Popup>

          <Box className={classes.container}>
            {members
              .filter((member) => member.id !== hostId)
              .map((member, index) => {
                return (
                  <Box className={classes.memberLine} key={index}>
                    <AppLink to={AppRoutes.clubMember(member.id!)} noDecoration={true}>
                      <Box display="flex" alignItems="center">
                        <UserAvatarPublic uid={member.id!} />
                        <Box ml={1} fontWeight={700}>
                          {member.displayName}
                        </Box>
                      </Box>
                    </AppLink>
                    <ActionButton
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        memberSelected.current = member
                        setRemovememberPopupOpen(true)
                      }}
                    >
                      <TextContent resourceKey="buttons.remove-member" defaultValue="Remove" />
                    </ActionButton>
                  </Box>
                )
              })}
          </Box>
        </Container>
      }
      footer={<Footer />}
    />
  )
}
