import React, { useState, useEffect, useContext } from 'react'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'
import { dollars } from '../../../../common/utils'
import { ButtonLarge, PageTitle, SectionHeader } from '@rse/uikit'
import { ReactComponent as ShieldIcon } from '../../assets/shield.svg'
import { firebaseOnCallFunctions, UNAVAILABLE_DOC_ID } from '../../../../common/constants'
import { UserContext } from '../../../../App'
import { useFirebaseFunctionCall, useSubmittedApplication } from '../../../../firebase'
import { Box } from '@material-ui/core'
import { useErrorHandler } from '../../../../common/hooks'

function ApprovedStep({ amount }: { amount?: number }) {
  const [estimatedPurchasingPower, setEstimatedPurchasingPower] = useState<number>(0)

  const estimatedPurchasingPowerHook = useFirebaseFunctionCall(
    firebaseOnCallFunctions.estimatePurchasingPowerForMember,
    true
  )

  const { errorHandler } = useErrorHandler('contacting our server to calculate your purchasing power')

  useEffect(() => {
    estimatedPurchasingPowerHook
      .fetchDataFromFunction({})
      .then((result) => {
        if(result.purchasingPower){
          setEstimatedPurchasingPower(result.purchasingPower)
        }        
      })
      .catch(errorHandler)
  }, [])

  return (
    <DashboardBanner
      icon={ShieldIcon}
      viewBox="0 0 50 50"
      title="Congrats! Your application has been approved!"
      titleKey="dashboard.application-approved-title"
      subtext="You're one step closer to moving in to your dream home. We're waiting for a few more people like you to get on board, so sit tight and we'll notify you when the doors are open for you to start meeting people and uniting your buying group. Help us expedite the process, and tell your friends and family about RSE."
      subtextKey="dashboard.application-approved-subtext"
    >
      { /* 
      <Box mb={1}>
        <SectionHeader>Preapproved monthly payment:</SectionHeader>
      </Box>

      <ButtonLarge disabled>
        <PageTitle>{dollars(amount)}</PageTitle>
      </ButtonLarge>

      {estimatedPurchasingPower > 0 && (
        <Box mt={2}>
          <Box mb={1}>
            <SectionHeader>Estimated contribution:</SectionHeader>
          </Box>

          <ButtonLarge disabled>
            <PageTitle>{dollars(estimatedPurchasingPower)}</PageTitle>
          </ButtonLarge>
        </Box>
      )}
  */ }
    </DashboardBanner>
  )
}

export function ApplicationApproved() {
  const user = useContext(UserContext)
  const userInitApplicationSubmitted = useSubmittedApplication(user?.id || UNAVAILABLE_DOC_ID)

  return <ApprovedStep amount={userInitApplicationSubmitted?.maxMonthlyMortgagePayment} />
}
