import React from 'react'
import { URL_MEMBERSHIP_WIZARD } from '../../../../common/constants/routes'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'
import { ReactComponent as EmailIcon } from '../../assets/verifiedPhone.svg'

export function PhoneVerifiedContent() {
  return (
    <DashboardBanner
      icon={EmailIcon}
      viewBox="0 0 42 42"
      title="Account Verified!"
      titleKey="dashboard.user-verified-title"
      subtext="Next Step: Become a Member"
      subtextKey="dashboard.user-verified-subtext"
      linkTitle="Start Membership"
      linkTitleKey="dashboard.application-link"
      linkURL={URL_MEMBERSHIP_WIZARD}
    ></DashboardBanner>
  )
}
