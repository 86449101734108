import firebase from 'firebase/app'
import 'firebase/firestore'
import { useCallback, useMemo } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { firebaseInstance } from '../firebaseInstance'

export interface PersistentCollectionFilter {
  fieldPath: string | firebase.firestore.FieldPath
  opStr: firebase.firestore.WhereFilterOp
  value: unknown
}

export interface PersistentCollectionOrderClause {
  fieldPath: string | firebase.firestore.FieldPath
  directionStr: firebase.firestore.OrderByDirection
}

export function usePersistentCollection<T>(
  collectionNameRef: string | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
  filters?: Array<PersistentCollectionFilter>,
  orderBy?: Array<PersistentCollectionOrderClause>,
  transform?: (val: any) => T
) {
  const collectionRef = useMemo(
    () =>
      typeof collectionNameRef === 'string' ? firebaseInstance.db.collection(collectionNameRef) : collectionNameRef,
    [collectionNameRef]
  )

  const filteredCollectionRef = useMemo(() => {
    let result: firebase.firestore.Query<firebase.firestore.DocumentData> = collectionRef

    const filters0 = filters || []
    filters0.forEach(({ fieldPath, opStr, value }) => {
      result = result.where(fieldPath, opStr, value)
    })

    const orderBy0 = orderBy || []
    orderBy0.forEach(({ fieldPath, directionStr }) => {
      result = result.orderBy(fieldPath, directionStr)
    })

    return result
  }, [collectionRef, filters, orderBy])

  const addItem = useCallback((item: T) => collectionRef.add(item), [collectionRef])
  const deleteItemById = useCallback((id: string) => collectionRef.doc(id).delete(), [collectionRef])
  const setItemById = useCallback((id: string, item: T) => collectionRef.doc(id).set(item), [collectionRef])
  const setItemByIdMerge = useCallback(
    (id: string, item: T) => collectionRef.doc(id).set(item, { merge: true }),
    [collectionRef]
  )
  const updateItemById = useCallback((id: string, item: T) => collectionRef.doc(id).update(item), [collectionRef])

  const commonHandlers = {
    addItem,
    deleteItemById,
    setItemById,
    setItemByIdMerge,
    updateItemById,
  }

  const [value, loading, error] = useCollectionData<T>(filteredCollectionRef, { idField: 'id', transform })

  const collection = {
    value,
    loading,
    error,
  }

  return { collection, collectionRef, commonHandlers }
}
