import React, { useState } from 'react'
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { QuestionComponentProps } from '.'
import { TextError } from '../../../../pages/Application/components/TextError'
import clsx from 'clsx'
import { QuestionChoice } from '../../types'

const useStyles = makeStyles((theme) =>
  createStyles({
    chip: {
      background: theme.palette.divider,
      color: theme.palette.text.primary,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderRadius: 30,
      cursor: 'pointer',

      '& + &': {
        marginLeft: 10,
      },

      '&.checked': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },

    chipContent: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& img': {
        marginRight: '5px',
      },
    },
  })
)

export default function ChipsInput({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
}: QuestionComponentProps & Record<string, any>) {
  const styles = useStyles()
  const { MaxValue = 1, MinValue = 1 } = QuestionConfig
  const [currentValue, setCurrentValue] = useState<string[]>([])
  const [isValid, setIsValid] = useState(true)

  // Copy Answer from props
  useEffect(() => setCurrentValue(Answer || []), [])

  useEffect(() => {
    setIsValid(isValidAnswer(currentValue))
    AnswerUpdateHandler(currentValue)
  }, [currentValue])

  // Update handler from props when validity changes
  useEffect(() => ValidStatusUpdateHandler(isValid), [isValid])

  const selectionChangeHandler = (selectedValue: string) => {
    const newCurrentValue = currentValue.includes(selectedValue)
      ? currentValue.filter((item) => item !== selectedValue)
      : [...currentValue, selectedValue]

    setCurrentValue(newCurrentValue)
  }

  const optionChoicesLength = QuestionConfig.QuestionChoices?.length || 1

  const isValidAnswer = (currentVal: string[]) => {
    const selected = currentVal.length
    return selected >= MinValue && selected <= MaxValue
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}
      {optionChoicesLength > 1 && QuestionConfig.QuestionChoices && (
        <>
          <Box>
            {QuestionConfig.QuestionChoices.map((o: QuestionChoice) => (
              <Box
                key={`qChoice${QuestionConfig.QuestionId}${o.Value}`}
                className={clsx(styles.chip, currentValue.includes(o.Value) && 'checked')}
                onClick={() => selectionChangeHandler(o.Value)}
              >
                <Box className={styles.chipContent}>
                  {/* empty alt text on purpose, to get screen reader to skip it
                      because this image just decorates the text that describes it
                  */}
                  {o.imgUrl && <img src={o.imgUrl} alt="" />}
                  <Typography variant="body1" style={{ fontWeight: 500, lineHeight: 1.4 }}>
                    {o.Value}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          {!isValid && displayQuestionErrors && (
            <TextError>
              Selection should be between {MinValue} and {MaxValue} values
            </TextError>
          )}
        </>
      )}
      {optionChoicesLength <= 1 && <TextError>Invalid configuration</TextError>}
    </>
  )
}
