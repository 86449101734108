import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Container,
  makeStyles,
  createStyles,
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Slider,
} from '@material-ui/core'
import { useFormik } from 'formik'
import { BuyingGroup } from '@rse/core'
import {
  ButtonLarge,
  InputSelectRadioPills,
  InputToggle,
  Spacing,
  useLoadingIndicator,
  PageTitle,
  SectionHeader,
  StandardText,
} from '@rse/uikit'
import { FormikScrollToError } from '../../../components/common'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { TagsSelect } from '../../../components/common'
import { useCurrentUserId, useErrorHandler } from '../../../common/hooks'
import { firebasePublishBuyingGroup, firebaseUploadGroupImage } from '../../../firebase'
import { useSubmittedApplication } from '../../../firebase/hooks'
import { InputOption } from '../../Application/types/components'
import { GroundRulesPane } from '../../../components/common/GroundRules/GroundRulesPane'
import noGroupPhotoImage from './images/no-group-photo.svg'
import { BuyingGroupDetailsPreview } from '../BuyingGroupDetailsPreview'
import { UserContext } from '../../../App'
import {
  FormValues,
  GROUP_VISION_MAX,
  GROUP_VISION_MIN,
  predefinedTags,
  roomMatesCountMarks,
  validate,
} from '../common/formAssets'
import { FormikErrorArrayField } from '../common/FormikErrorArrayField'
import { RaleighLocation } from '../../../common/constants'
import { AppLayout, Footer, Header } from '../../../components/layout'
import { AppRoutes } from '../../../App/routeUtils'

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    avatar: {
      display: 'flex',
      justifyContent: 'center',
      margin: '40px 0 20px 0',
      cursor: 'pointer',
    },
    addPhoto: {
      display: 'flex',
      marginBottom: '20px',
      justifyContent: 'center',

      '& .group-image-load-btn': {
        background: '#F0F0F4',
        color: '#8C8AAC',
        display: 'block',
        textAlign: 'center',
        margin: '0 auto',
        maxWidth: '200px',
      },
    },
    title: {},
    vision: {},
    important: {},
    tags: {},
    questions: {},
    changePreferences: {},
    validationErrors: {
      color: 'red',
    },
  })
)

const roomMateGenderOptions: InputOption[] = [
  {
    id: 'female',
    title: (
      <StandardText>
        <TextContent resourceKey="bg-create.mate-gender-female" defaultValue="&#9792; Female" />
      </StandardText>
    ),
  },
  {
    id: 'male',
    title: (
      <StandardText>
        <TextContent resourceKey="bg-create.mate-gender-male" defaultValue="&#9794; Male" />
      </StandardText>
    ),
  },
  {
    id: 'nonbinary',
    title: (
      <StandardText>
        <TextContent resourceKey="bg-create.mate-gender-nonbinary" defaultValue="&#9893; Co-Ed" />
      </StandardText>
    ),
  },
]

export function BuyingGroupCreate() {
  const classes = useStyles()
  const uid = useCurrentUserId()
  const user = useContext(UserContext)
  const textContent = useTextContent()
  const history = useHistory()
  const [tempImageUrl, setTempImageUrl] = useState<string>('')
  const [tempImageFile, setTempImageFile] = useState<File | null>(null)
  const app = useSubmittedApplication(uid)
  const [ageRange, setAgeRange] = useState<[number, number]>([21, 29])
  const [roommates, setRoommates] = useState<[number, number]>([2, 6])
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [previewMode, setPreviewMode] = useState<boolean>(false)
  const [groupData, setGroupData] = useState<BuyingGroup | null>(null)
  const loader = useLoadingIndicator()
  const { errorHandler } = useErrorHandler('creating a buying group')

  const formik = useFormik<FormValues>({
    initialValues: {
      groupTitle: '',
      groupVision: '',
      groupQuestions: ['', '', ''],
      location: [],
      roommateGender: 'female',
      lgbtqFriendly: true,
      preferences: {},
    },
    validate,
    onSubmit: async (values) => {
      const { groupTitle, groupVision, groupQuestions, location, roommateGender, lgbtqFriendly, preferences } = values

      setGroupData({
        id: uid,
        hostId: uid,
        headline: groupTitle,
        description: groupVision,
        location: location!,
        priceRange: app!.priceRange!,
        timeRange: app!.timeRange!,
        roommates,
        ageRange,
        tags: selectedTags,
        gender: roommateGender,
        lgbtqFriendly,
        questions: groupQuestions.map((question, index) => ({ id: '' + index, title: question })),
        preferences,
        groupProfileImageUrl: tempImageUrl,
      })

      setPreviewMode(true)
    },
  })

  useEffect(() => {
    if (app) {
      setAgeRange(app.ageRange!)
      setRoommates(app.roommates!)
      formik.setFieldValue('location', user?.location ? [user?.location] : [RaleighLocation.value])
      formik.setFieldValue('roommateGender', app.roommateGender)
      formik.setFieldValue('lgbtqFriendly', app.lgbtqFriendly ?? true)

      console.log(app)
      formik.setFieldValue('preferences', app.preferences ?? {})
    }
  }, [app])

  const [changePreferencesState, setChangePreferencesState] = useState<boolean>(false)

  const chooseFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    URL.revokeObjectURL(tempImageUrl)

    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setTempImageFile(file)
      setTempImageUrl(URL.createObjectURL(file))
    } else {
      setTempImageFile(null)
      setTempImageUrl('')
    }
  }

  if (previewMode && groupData) {
    return (
      <BuyingGroupDetailsPreview
        groupData={groupData}
        createGroup={async () => {
          if (tempImageFile) {
            groupData.groupProfileImageUrl = await firebaseUploadGroupImage(uid, tempImageFile)
            console.log(`Loaded image url: ${groupData.groupProfileImageUrl}`)
          }

          loader.wrapPromise(firebasePublishBuyingGroup(uid, groupData)).catch(errorHandler)
        }}
        previewGoBack={() => {
          setPreviewMode(false)
        }}
      />
    )
  }

  if (user && user.buyingGroupId) {
    return <Box>Error: you are already a member of a group and cannot create another one</Box>
  }

  return (
    <AppLayout
      header={<Header back={async () => history.push(AppRoutes.groupRoot())} title={'Create Group'}></Header>}
      body={
        <Container maxWidth="xs">
          <Box className={classes.container}>
            <form onSubmit={formik.handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
              <FormikScrollToError formik={formik} />
              <Box className={classes.addPhoto}>
                <input
                  type="file"
                  accept="image/*"
                  id="group-image-load"
                  style={{ display: 'none' }}
                  onChange={chooseFile}
                />
                <label htmlFor="group-image-load">
                  <Box className={classes.avatar}>
                    {tempImageUrl ? (
                      <Box borderRadius={12} minHeight={128} overflow="hidden">
                        <img src={tempImageUrl} width="100%" />
                      </Box>
                    ) : (
                      <img src={noGroupPhotoImage} alt="no group image photo" />
                    )}
                  </Box>
                  <Button component="span" className="group-image-load-btn">
                    <TextContent resourceKey="create-bg.add-group-photo" defaultValue="+ Add Group Photo" />
                  </Button>
                </label>
              </Box>
              <Box className={classes.title}>
                <TextField
                  variant="outlined"
                  name="groupTitle"
                  fullWidth
                  label={getTextContent(textContent, 'create-bg.field-group-title', 'Title *')}
                  value={formik.values.groupTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.groupTitle && formik.errors.groupTitle ? (
                  <Box className={classes.validationErrors}>{formik.errors.groupTitle}</Box>
                ) : null}
              </Box>
              <Spacing />
              <Box className={classes.vision}>
                <TextField
                  multiline={true}
                  placeholder={`We recommend min ${GROUP_VISION_MIN} characters (max ${GROUP_VISION_MAX})`}
                  rows={4}
                  variant="outlined"
                  name="groupVision"
                  fullWidth
                  label={getTextContent(textContent, 'create-bg.field-group-vision', 'Vision *')}
                  value={formik.values.groupVision}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.groupVision && formik.errors.groupVision ? (
                  <Box className={classes.validationErrors}>{formik.errors.groupVision}</Box>
                ) : null}
              </Box>
              <Spacing />
              <Box className={classes.tags}>
                <TagsSelect
                  // eslint-disable-next-line @typescript-eslint/quotes
                  title={getTextContent(textContent, 'create-bg.field-tags', "What's Important to Me")}
                  options={predefinedTags}
                  selectedTags={selectedTags}
                  selectTag={(tag: string) => {
                    if (!selectedTags.includes(tag)) setSelectedTags((tags) => [...tags, tag])
                  }}
                  removeTag={(tag: string) => {
                    if (selectedTags.includes(tag)) setSelectedTags((tags) => tags.filter((t) => t !== tag))
                  }}
                />
              </Box>
              <Spacing />
              <Box className={classes.questions}>
                <Box mb={1}>
                  <SectionHeader>
                    <TextContent
                      resourceKey="create-group.questions"
                      defaultValue={getTextContent(
                        textContent,
                        'create-bg.field-questions-to-answer',
                        'Questions I want new members to answer'
                      )}
                    />
                  </SectionHeader>
                </Box>
                <Box>
                  {formik.values.groupQuestions.map((question, i) => (
                    <Box key={i} mb={1}>
                      <TextField
                        name={`groupQuestions[${i}]`}
                        variant="outlined"
                        fullWidth
                        label={`Question ${i + 1}${i === 0 ? ' *' : ''}`}
                        value={question}
                        onChange={formik.handleChange}
                      />
                      {i === 0 && <FormikErrorArrayField formik={formik} field="groupQuestions" index={0} />}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Spacing />
              <Box className={classes.changePreferences}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="change my preferences"
                    name="change-preferences"
                    value={changePreferencesState ? 'change-preferences' : 'keep-preferences'}
                    onChange={(e) => setChangePreferencesState(e.target.value === 'change-preferences')}
                  >
                    <FormControlLabel
                      value="keep-preferences"
                      control={<Radio />}
                      label={getTextContent(textContent, 'create-bg.keep-my-preferences', 'Keep my preferences')}
                    />
                    <FormControlLabel
                      value="change-preferences"
                      control={<Radio />}
                      label={getTextContent(textContent, 'create-bg.change-my-preferences', 'Change my preferences')}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {changePreferencesState && (
                <Box>
                  <Spacing />
                  <PageTitle>
                    <TextContent resourceKey="create-bg.title-people-preferences" defaultValue="HouseMate preferences" />
                  </PageTitle>
                  <Box>
                    <Spacing />
                    <SectionHeader>
                      <TextContent resourceKey="create-bg.title-age-range" defaultValue="Age Range" />
                    </SectionHeader>
                    <Spacing value={50} />
                    <Slider
                      onChange={(e: React.ChangeEvent<{}>, val) => {
                        setAgeRange(val as [number, number])
                      }}
                      name="ageRange"
                      value={ageRange}
                      valueLabelDisplay="on"
                      marks={[
                        { value: 18, label: '18' },
                        { value: 70, label: '70' },
                      ]}
                      step={1}
                      max={70}
                      min={18}
                    />
                  </Box>
                  <Spacing />
                  <Box>
                    <SectionHeader>
                      <TextContent
                        resourceKey="create-bg.title-want-to-live-with"
                        defaultValue="I want to live with..."
                      />
                    </SectionHeader>
                    <Spacing value={20} />
                    <InputSelectRadioPills
                      name="roommateGender"
                      options={roomMateGenderOptions}
                      onChange={formik.handleChange}
                      value={formik.values.roommateGender}
                    />
                    <Spacing value={20} />
                    <InputToggle
                      name="lgbtqFriendly"
                      // eslint-disable-next-line @typescript-eslint/quotes
                      label={getTextContent(textContent, 'create-bg.field-lgbtq-friendly', "I'm LGBTQ friendly")}
                      value={formik.values.lgbtqFriendly}
                      onChange={formik.handleChange}
                      color="primary"
                    />
                  </Box>
                  <Spacing />
                  <Box>
                    <SectionHeader>
                      <TextContent
                        resourceKey="create-bg.title-how-many-home-mates"
                        defaultValue="How many home mates do you want to live with?"
                      />
                    </SectionHeader>
                    <Spacing value={50} />
                    <Slider
                      name="roommates"
                      onChange={(e: React.ChangeEvent<{}>, val) => {
                        setRoommates(val as [number, number])
                      }}
                      value={roommates}
                      defaultValue={[18, 50]}
                      valueLabelDisplay="on"
                      step={1}
                      min={1}
                      max={6}
                      marks={roomMatesCountMarks}
                    />
                  </Box>
                  <Spacing />
                  <Box>
                    <PageTitle>
                      <TextContent resourceKey="create-bg.title-ground-rules" defaultValue="Home Lifestyle preferences" />
                    </PageTitle>
                    <Spacing />
                    <GroundRulesPane
                      values={formik.values.preferences}
                      onChange={(question: string, value: string) => {
                        console.log(question, value)
                        formik.setFieldValue('preferences', { ...formik.values.preferences, [question]: value })
                      }}
                    />
                  </Box>
                </Box>
              )}
              <Spacing />
              <Box mb={2}>
                <ButtonLarge fullWidth type="submit">
                  <TextContent resourceKey="buttons.preview-group" defaultValue="Preview Group" />
                </ButtonLarge>
              </Box>
            </form>
          </Box>
        </Container>
      }
      footer={<Footer />}
    />
  )
}
