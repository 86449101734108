import { IconButton } from '@material-ui/core'
import { Favorite, FavoriteBorderOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useCurrentUserId, useErrorHandler } from '../../common/hooks'
import { firebaseToggleGroupFavorite } from '../../firebase'

type Props = {
  groupId: string
  isFab?: boolean
  isDark?: boolean
}

const BuyingGroupFavIconInner: React.VFC<Props> = ({ groupId, isFab }) => {
  const [isFav, setIsFav] = useState(!!isFab)
  const userId = useCurrentUserId()
  const { errorHandler } = useErrorHandler('adding / removing a group from favorites')

  const toggleFavorite = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      firebaseToggleGroupFavorite(userId, groupId)
      setIsFav((isFav) => !isFav)
    } catch (err) {
      errorHandler(err)
    }
  }

  useEffect(() => {
    setIsFav(!!isFab)
  }, [isFab])

  return (
    <IconButton onClick={toggleFavorite}>
      {isFav ? <Favorite color="primary" /> : <FavoriteBorderOutlined color="disabled" />}
    </IconButton>
  )
}

export const BuyingGroupFavIcon = React.memo(BuyingGroupFavIconInner)
