import { useEffect, useMemo, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { firebaseInstance, firebaseCollectionNames } from '../../../firebase'
import { BuyingGroupWithFab } from '../models'
import { CollectionFilter, useCollectionPaged } from '../../../firebase/hooks/useCollectionPaged'
import { BuyingGroup, BuyingGroupMatch } from '@rse/core'



export function useGetBuyingGroupsFiltered(userId: string, favoritesOnly: boolean, pageSize?: number, usePaging?: boolean, autoLoad?: boolean, userGroupId?: string) {

  if (!pageSize) pageSize = 20
  if (!usePaging) usePaging = false
  if (!autoLoad) autoLoad = false

  const pageSizeValue: number = pageSize

  const [isDetailsLoadingRequired, setIsDetailsLoadingRequired] = useState<boolean>(false)
  const [buyingGroupData, setBuyingGroupData] = useState<BuyingGroupWithFab[]>([])
  const [errorDb, setErrorDb] = useState<firebase.FirebaseError>()
  const [userBuyingGroupData, setUserBuyingGroupData] = useState<BuyingGroupWithFab>()



  async function loadUserBuyingGroupData() {
    try {
      if (!userGroupId) {
        setUserBuyingGroupData(undefined)
        return
      }

      const buyingGroupsCollectionRef = firebaseInstance.db.collection(firebaseCollectionNames.buyingGroups)
      const buyingGroupQueryResult = await buyingGroupsCollectionRef.where(firebase.firestore.FieldPath.documentId(), '==', userGroupId).get()

      if (buyingGroupQueryResult.size === 0) {
        setUserBuyingGroupData(undefined)
        return
      }

      const userBuyingGroupList = buyingGroupQueryResult.docs.map((doc) => <BuyingGroupWithFab>{ ...doc.data(), id: doc.id, isFab: false })

      setUserBuyingGroupData(userBuyingGroupList[0])

    } catch (error: any) {
      setUserBuyingGroupData(undefined)
      const err: firebase.firestore.FirestoreError = error
      setErrorDb(err)
      console.error(error)
    }
  }




  const filtersMatchedBuyingGroups = useMemo<CollectionFilter[]>(
    () => {

      let notInIDValues: string[] = []
      if (userGroupId) {
        notInIDValues = [userId, userGroupId]
      } else {
        notInIDValues = [userId]
      }

      if (!favoritesOnly) return [
        { fieldPath: 'id', opStr: 'not-in', value: notInIDValues }
      ]

      const filters: CollectionFilter[] = [
        { fieldPath: 'id', opStr: 'not-in', value: notInIDValues },
        { fieldPath: 'isFab', opStr: '==', value: true }
      ]
      return filters
    }, [favoritesOnly]
  )

  const triggerDetailsLoad = () => {
    setIsDetailsLoadingRequired(true)
  }

  const {
    currentData: currentMatchedBuyingGroups,
    isLoading: isMatchedBuyingGroupLoading,
    loadData: loadMatchedGroupData
  } = useCollectionPaged<BuyingGroupMatch>(
    `profileMatches/${userId}/buyingGroupMatches`,
    pageSizeValue,
    true,
    true,
    filtersMatchedBuyingGroups,
    undefined,
    undefined,
    triggerDetailsLoad)


  const filtersBuyingGroupDetails = useMemo(() => {
    let result: CollectionFilter[] = []
    if (isMatchedBuyingGroupLoading) {
      result = [{ fieldPath: 'id', opStr: '==', value: 'ff' }]
    }
    else {
      if (!currentMatchedBuyingGroups || currentMatchedBuyingGroups.length == 0) {
        result = [{ fieldPath: 'id', opStr: '==', value: 'ff' }]
      } else {
        const matchedBuyingGroupIds = currentMatchedBuyingGroups.map((matchedBuyingGroupItem) => matchedBuyingGroupItem.id)
        result = [{ fieldPath: 'id', opStr: 'in', value: matchedBuyingGroupIds }]
      }
    }

    return result
  }, [isMatchedBuyingGroupLoading])

  const {
    currentData: bCurrentData,    
    isLoading: IsBuyingGroupDetailsLoading,    
    loadData: buyingGroupDetailsLoadData
  } = useCollectionPaged<BuyingGroup>(
    'buyingGroups',
    undefined,
    false,
    false,
    filtersBuyingGroupDetails,
    undefined)

  useEffect(() => {
    if (isDetailsLoadingRequired) {
      buyingGroupDetailsLoadData()
      setIsDetailsLoadingRequired(false)
    }
  }, [isDetailsLoadingRequired])


  useEffect(() => {
    if (IsBuyingGroupDetailsLoading) {
      return
    }

    if (!bCurrentData || bCurrentData.length === 0) {
      return
    }

    const detailCurrentData: BuyingGroupWithFab[] = bCurrentData


    currentMatchedBuyingGroups.forEach((matchedGroup) => {
      const groupDetail = detailCurrentData.find(
        (buyingGroupDetail) => {
          return buyingGroupDetail.id === matchedGroup.id
        })

      if (groupDetail) {
        groupDetail.isFab = matchedGroup.isFab
      }
    })

    setBuyingGroupData(buyingGroupData.concat(detailCurrentData))


  }, [IsBuyingGroupDetailsLoading])



  useEffect(() => {
    loadUserBuyingGroupData()
  }, [userId, favoritesOnly, userGroupId])


  useEffect(() => {
    setBuyingGroupData([])
  }, [favoritesOnly])




  return { data: buyingGroupData, 'loading': isMatchedBuyingGroupLoading || IsBuyingGroupDetailsLoading, 'error': errorDb, loadNextData: loadMatchedGroupData, userBuyingGroupData }
}
