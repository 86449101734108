import { Color } from '@material-ui/lab'
import React, { useContext, useState } from 'react'

type SnackbarStyledContextProps = {
  open: boolean
  setOpen: (open: boolean) => void
  text: string
  setTextAndOpen: (text: string, severity?: Color) => void
  severity: Color
}
const uninitialisedFn = () => {
  throw new Error('uninitialised')
}
const defaultValue: SnackbarStyledContextProps = {
  open: false,
  setOpen: uninitialisedFn,
  text: 'uninitialised value',
  setTextAndOpen: uninitialisedFn,
  severity: 'info',
}
const SnackbarStyledContext = React.createContext<SnackbarStyledContextProps>(defaultValue)

type SnackbarStyledProviderProps = {
  children: React.ReactNode
}
export const SnackbarStyledProvider = ({ children }: SnackbarStyledProviderProps) => {
  const [text, setText] = useState('uninitialised value')
  const [severity, setSeverity] = useState<Color>('info')
  const [open, setOpen] = useState(false)
  const setTextAndOpen = (text: string, severity: Color = 'info') => {
    setOpen(false)
    setText(text)
    setSeverity(severity)
    setOpen(true)
  }

  return (
    <SnackbarStyledContext.Provider value={{ open, setOpen, text, setTextAndOpen, severity }}>
      {children}
    </SnackbarStyledContext.Provider>
  )
}

export const useSnackbar = (): SnackbarStyledContextProps => useContext(SnackbarStyledContext)
