import { useCallback } from 'react'
import { BuyingGroup } from '@rse/core'
import { firebaseCollectionNames } from '../firebaseConstants'
import { firebaseOnCallFunctions } from '../../common/constants'
import { usePersistentCollectionItem, useFirebaseFunctionCall, useActivePreferences } from '.'
import { useApiPrefix } from '../../common/hooks'
import { useApiFetch } from '@rse/frontend-dal'
import { firebaseInstance } from '../../firebase'

export function useBuyingGroup(documentId: string, refinePreferences = false) {
  const buyingGroupDocId = documentId ? documentId : "<NoDocID>"

  const deleteBuyingGroupHook = useFirebaseFunctionCall(firebaseOnCallFunctions.deleteBuyingGroup, true)
  const leaveBuyingGroupHook = useFirebaseFunctionCall(firebaseOnCallFunctions.leaveBuyingGroup, true)
  const groupApiPrefix = useApiPrefix('buyingGroup')
  const finalizeBuyingGroupApi = useApiFetch(`${groupApiPrefix}/${buyingGroupDocId}/finalize`, true, 'POST', true, firebaseInstance.auth)
  const removeGroupMemberHook = useFirebaseFunctionCall(firebaseOnCallFunctions.removeGroupMember, true)

  const deleteItem = useCallback(
    async (id: string) => await deleteBuyingGroupHook.fetchDataFromFunction({ buyingGroupId: id }),
    [deleteBuyingGroupHook]
  )
  const leaveGroup = useCallback(
    async (id: string) => await leaveBuyingGroupHook.fetchDataFromFunction({ buyingGroupId: id }),
    [leaveBuyingGroupHook]
  )

  const removeGroupMember = useCallback(
    async (groupId, memberId) => await removeGroupMemberHook.fetchDataFromFunction({ groupId, memberId }),
    [removeGroupMemberHook]
  )

  const finalizeGroup = useCallback(
    async (gid: string) => await finalizeBuyingGroupApi.callApi({ gid }),
    [finalizeBuyingGroupApi]
  )

  const { document, commonHandlers } = usePersistentCollectionItem<BuyingGroup>(
    `${firebaseCollectionNames.buyingGroups}/${buyingGroupDocId}`, undefined, (buyingGroupDocId == "<NoDocID>" ? true : false)
  )

  const { value: object, loading: loadingObject, error: errorLoadingObject } = document

  const activePreferences = useActivePreferences<BuyingGroup>({
    object,
    loadingObject,
    errorLoadingObject,
    refinePreferences,
  })

  return {
    document: {
      value: activePreferences.value,
      loading: activePreferences.loading,
      error: activePreferences.error,
    },
    handlers: {
      ...commonHandlers,
      deleteItem,
      leaveGroup,
      finalizeGroup,
      removeGroupMember,
    },
  }
}
