import React from 'react'
import { Box } from '@material-ui/core'
import { SectionHeader } from '@rse/uikit'
import { InputRangeSlider } from '../components/InputRangeSlider'
import { TextContent } from '../../../common/textContent'

export const ProfileComfyPay: React.FC = () => {
  return (
    <>
      <Box pt="28px">
        <SectionHeader>
          <TextContent
            resourceKey="app-wizard.title-comfortable-payment"
            defaultValue="What would be a comfortable monthly payment for you?"
          />
        </SectionHeader>
      </Box>
      <InputRangeSlider
        id="priceRange"
        defaultValue={[1000, 1500]}
        valueLabelDisplay="on"
        valueLabelFormat={(val) => '$' + val}
        step={100}
        max={5000}
        min={500}
        bottomMarks={['$500', '$5000']}
      />
    </>
  )
}
