import React from 'react'
import { Box, makeStyles, createStyles } from '@material-ui/core'
import { PreviewTitle, StandardText } from '@rse/uikit'
import { ClubMemberFavIcon, UserAvatarPublic } from '../../../../components/common'
import { LocationOn, DateRange, AttachMoney } from '@material-ui/icons'
import CmUserBuyingGroup from './CmUserBuyingGroup'
import { UserProfilePublicEntity } from '@rse/core'
import { calculateAge } from '../../../../common/utils'

const useStyles = makeStyles(() =>
  createStyles({
    iconStyle: {
      height: 13,
      width: 'auto',
      marginRight: 4,
    },
  })
)

interface Props extends UserProfilePublicEntity {
  isFab: boolean
  userViewingSelf?: boolean
}

const ProfileHeader = ({
  firstName,
  lastName,
  birthday,
  priceRange,
  location,
  timeRange,
  id,
  isFab,
  userViewingSelf,
  isGroupHost,
  buyingGroupID
}: Props) => {
  const { iconStyle } = useStyles()
  const from = `${priceRange && priceRange[0]}` || '$0.000'
  const to = `${priceRange && priceRange[1]}` || '$0.000'

  const age = calculateAge(birthday)

  return (
    <Box display="flex" mb={2}>
      <UserAvatarPublic uid={id} size="large" />
      <Box display="flex" flexDirection="column" ml={2} width="100%">
        <Box display="flex" justifyContent="space-between" pr={5} position="relative" mb={2}>
          <PreviewTitle>
            {firstName} {lastName}
            {birthday && `, ${age}`}
          </PreviewTitle>
          {!userViewingSelf && (
            <Box position="absolute" top={-15} right={0}>
              <ClubMemberFavIcon memberId={id} isFab={isFab} />
            </Box>
          )}
        </Box>
        <CmUserBuyingGroup isGroupHost={isGroupHost} buyingGroupID={buyingGroupID} />
        <Box display="flex" justifyContent="space-between">
          <StandardText>
            <DateRange className={iconStyle} /> {timeRange}
          </StandardText>
          <StandardText>
            <LocationOn className={iconStyle} />
            Location: {location}
          </StandardText>
          <StandardText>
            <AttachMoney className={iconStyle} />
            {from}-{to}
          </StandardText>
        </Box>
      </Box>
    </Box>
  )
}

export default ProfileHeader
