import { Box, TextField } from '@material-ui/core'
import { format, parseISO } from 'date-fns'
import React, { useMemo } from 'react'
import { getTextContent, useTextContent } from '../../../common/textContent'
import { useFormData } from '../helpers/useFormData'
import { validateFields } from '../helpers/validation'
import { ApplicationFormKey } from '../types/application'
import type { InputValue } from '../types/components'

const id: ApplicationFormKey = 'birthday'

export const ProfileBirthday: React.FC = () => {
  const textContent = useTextContent()
  const { formData, formErrors, onFieldChange, onValidationChange: onValidationErrors } = useFormData()

  const dateFormatted = useMemo(() => {
    if (formData.birthday instanceof Date) {
      try {
        return format(formData.birthday, 'yyyy-MM-dd')
      } catch (error) {
        return ''
      }
    }
  }, [formData.birthday])

  const handleDateUpdate = (val: InputValue) => {
    const date = parseISO(val as string)
    onFieldChange(id, date)
  }

  const handleBlur = () => {
    onValidationErrors(validateFields(formData, [id]))
  }

  return (
    <Box pt="20px">
      <TextField
        name={id}
        label={getTextContent(textContent, 'app-wizard.field-birthday', 'Birthday *')}
        value={dateFormatted}
        onChange={(e) => handleDateUpdate(e.target.value)}
        onBlur={handleBlur}
        type="date"
        error={!!formErrors[id]}
        InputLabelProps={{ shrink: true }}
        helperText={formErrors[id]}
        variant="outlined"
        fullWidth
      />
    </Box>
  )
}
