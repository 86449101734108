import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ThemeContextProvider } from './common/theme/ThemeContext'

import * as serviceWorker from './serviceWorker'

window.React = React
window.ReactDOM = ReactDOM

const renderReactDOM = () => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeContextProvider>
        <App />
      </ThemeContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

if ((window as any).cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDOM()
  }, false)
} else {
  renderReactDOM()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
