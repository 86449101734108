import { UserInitApplicationSubmittedEntity } from '@rse/core'

import { firebaseCollectionNames } from '../firebaseConstants'
import { usePersistentCollectionItem } from './usePersistentCollectionItem'

export function useSubmittedApplication(uid: string) {
  const { document } = usePersistentCollectionItem<UserInitApplicationSubmittedEntity>(
    `${firebaseCollectionNames.userInitApplicationSubmitted}/${uid}`
  )

  return document?.value
}
