import React, { useEffect, useState } from 'react'
import { firebaseInstance } from '../../../../firebase'

const getIdToken = async () => {
  const token = await firebaseInstance.auth.currentUser?.getIdToken(true)

  return token
}




export default function AuthTest() {
  const [currentToken, setCurrentToken] = useState<string | undefined>('')


  useEffect(() => {
    const func = async () => {
      const token = await getIdToken()
      setCurrentToken(token)
    }
    func()

  }, [])


  const handleClick = async () => {
    

    const response = await fetch('https://nodejsserviceapi.azurewebsites.net/users', {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + currentToken
      })
    })

    const data = await response.json()

    alert (JSON.stringify(data))

  }


  return <>
    <p>Current user token:</p>
    {currentToken ? <span>{`${currentToken}`}</span> : <span>No token!</span>}

    {currentToken && <button onClick={handleClick}>
      Test auth
    </button>}
  </>
}
