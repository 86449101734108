import { Container } from '@material-ui/core'
import React from 'react'
import { AppLayout, CenteredBox, SignUpHeader } from '../../../../components/layout'

export function SignupLayout({ content }: { content: React.ReactChild }) {
  return (
    <AppLayout
      header={<SignUpHeader />}
      body={
        <Container maxWidth="xs">
          <CenteredBox height="100%">{content}</CenteredBox>
        </Container>
      }
    />
  )
}