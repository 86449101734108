import { Box, styled } from '@material-ui/core'
import React from 'react'
import { useProgressInfo } from '../../helpers/useProgressData'
import { StepsProgressLine } from './StepsProgressLine'

export const StepsProgress = () => {
  const { currentStep, currentStepName, stepsCount, latestProgress } = useProgressInfo()

  return (
    <Box>
      <ProgressInfoMessage>{`Step ${currentStep}/${stepsCount} - ${currentStepName}`}</ProgressInfoMessage>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box flexGrow={1} pr={1}>
          <StepsProgressLine stepsCount={stepsCount} currentProgress={latestProgress} />
        </Box>
        <Box>{latestProgress}%</Box>
      </Box>
    </Box>
  )
}

const ProgressInfoMessage = styled('p')({
  fontSize: '16px',
  fontWeight: 'bold',
})
