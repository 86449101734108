import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { IconConnect, IconChat } from '../../../../components/icons'
import { NotificationsBadge } from '../../../../components/common/NotificationsBadge'
import { URL_CLUB_MEMBERS, URL_GROUPS, URL_MESSAGES, URL_NOTIFICATIONS } from '../../../../common/constants/routes'
import { UnreadMessagesBadge } from '../../../common'
import { getTextContent, TextContentContext } from '../../../../common/textContent'

const style = {
  borderTop: '1px solid #f0f0f4'
}

export const Footer = () => {
  const { pathname } = useLocation()
  const { push } = useHistory()
  const goto = (url: string) => () => push(url)

  const textContent = useContext(TextContentContext)
  const connectTitle = getTextContent(textContent, 'connect.bottom-navigation.button.title', 'Connect')
  const chatTitle = getTextContent(textContent, 'chats.bottom-navigation.button.title', 'Message')
  const notificationsTitle = getTextContent(textContent, 'notifications.bottom-navigation.button.title', 'Activity')

  const urlToTabMap: Record<string, number> = {
    [URL_GROUPS]: 0,
    [URL_CLUB_MEMBERS]: 0,
    [URL_MESSAGES]: 1,
    [URL_NOTIFICATIONS]: 2,
  }

  return (
    <BottomNavigation value={urlToTabMap[pathname]} showLabels style={style}>
      <BottomNavigationAction label={connectTitle} onClick={goto(URL_GROUPS)} icon={<IconConnect />} />
      <BottomNavigationAction
        label={chatTitle}
        onClick={goto(URL_MESSAGES)}
        icon={
          <UnreadMessagesBadge>
            <IconChat />
          </UnreadMessagesBadge>
        }
      />
      <BottomNavigationAction
        label={notificationsTitle}
        onClick={goto(URL_NOTIFICATIONS)}
        icon={
          <NotificationsBadge>
            <NotificationsActiveIcon />
          </NotificationsBadge>
        }
      />
    </BottomNavigation>
  )
}
