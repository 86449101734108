import React from 'react'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { dollars } from '../../../common/utils/format-data'
import { BoxSummaryItem } from '../components/BoxSummaryItem'
import { Spacing } from '@rse/uikit'
import { TextHeading } from '../components/TextHeading'
import { useFormData } from '../helpers/useFormData'

export const SummaryMortgagePreapproval: React.VFC = () => {
  const textContent = useTextContent()

  const { formData } = useFormData()
  const total = [formData.payments0, formData.payments1, formData.payments2]
    .map((num) => parseInt(num || '0'))
    .reduce((sum, current) => sum + current)
  return (
    <section>
      <Spacing value={28} />
      <TextHeading>
        <TextContent resourceKey="app-wizard.title-mortgage-preapproval" defaultValue="Mortgage Pre-approval" />
      </TextHeading>
      <Spacing value={11} />
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-mortgage-address', 'Address')}>
        {formData.street}
        <br />
        {formData.streetOptional && <div>{formData.streetOptional}</div>}
        {formData.city}, {formData.state}, {formData.zipCode}
      </BoxSummaryItem>
      <BoxSummaryItem
        label={getTextContent(textContent, 'app-wizard.field-mortgage-employment-type', 'Employment Type')}
      >
        {formData.employmentTypes?.join(', ')}
      </BoxSummaryItem>
      <BoxSummaryItem
        label={getTextContent(textContent, 'app-wizard.field-mortgage-monthly-income', 'Total monthly Income')}
      >
        {dollars(formData.monthlyIncome ?? 0)}
      </BoxSummaryItem>
      <BoxSummaryItem
        label={getTextContent(textContent, 'app-wizard.field-mortgage-length-income-status', 'Lenght of Income Status')}
      >
        {formData.incomeDuration}
      </BoxSummaryItem>
      <BoxSummaryItem
        label={getTextContent(
          textContent,
          'app-wizard.field-mortgage-amount-in-bank-account',
          'Amount in Bank Account'
        )}
      >
        {dollars(formData.currentDeposits ?? 0)}
      </BoxSummaryItem>
      <BoxSummaryItem
        label={getTextContent(
          textContent,
          'app-wizard.field-mortgage-recurring-payments',
          'Monthly Recurring Payments Total'
        )}
      >
        {dollars(total)}
      </BoxSummaryItem>
      <BoxSummaryItem
        label={getTextContent(textContent, 'app-wizard.field-mortgage-maximum-payment', 'Maximum Down Payment')}
      >
        {dollars(formData.maxDownpayment)}
      </BoxSummaryItem>
    </section>
  )
}
