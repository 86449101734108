import React from 'react'
import { SectionHeader, Spacing } from '@rse/uikit'
import { TextContent } from '../../../common/textContent'
import { InputText } from '../components/InputText'

export const PreApprovalAmountDeposited: React.VFC = () => {
  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent
          resourceKey="app-wizard.amount-deposited"
          defaultValue="Amount currently deposited in your bank accounts"
        />
      </SectionHeader>
      <Spacing value={20} />
      <InputText id="currentDeposits" label="$ *" type="number" />
    </section>
  )
}
