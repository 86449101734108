import { firebaseCollectionNames, firebaseDBHelper, firebaseInstance } from '.'
import { AppErrorMessages, ErrorType, GetError } from '../common/constants/errorTypes'
import { preferencesField, preferencesPrefix } from '../pages/Application/fieldsets/PrefsGroundRules'
import { ApplicationFormData } from '../pages/Application/types/application'

export async function saveApplication(doc: ApplicationFormData): Promise<void> {
  try {
    const fixedDoc: Record<string, any> = { [preferencesField]: {} }
    Object.keys(doc).forEach((field) => {
      if (field.startsWith(preferencesPrefix)) {
        fixedDoc[preferencesField][field.substring(preferencesPrefix.length)] = doc[field]
      } else {
        fixedDoc[field] = doc[field]
      }
    })

    await firebaseDBHelper.AddOrUpdateRecordByCurrentUserId(firebaseCollectionNames.userInitApplication, fixedDoc)
  } catch (error: unknown) {
    if (error instanceof Error && error.message === AppErrorMessages[ErrorType.UserNotLoggedIn]) {
      console.error('User not logged in', error)
      throw error
    }

    console.error('Could not save application', error)
    throw GetError(ErrorType.UserDataWriteError)
  }
}

/** returns error string */
export async function finalizeApplication(): Promise<string | null> {
  let result: string | null = null
  try {
    const finalizeFn = firebaseInstance.firebaseFunction.httpsCallable('userInitApplicationOps-userInitAppFinalize')
    await finalizeFn()
  } catch (error: any) {
    console.error(error)
    result = error.message || error
  }

  return result
}
