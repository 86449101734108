import { useCallback, useMemo } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { firebaseInstance } from '../firebaseInstance'

export function usePersistentCollectionItem<T>(documentId: string, transform?: (val: any) => T, skipLoad?: boolean) {
  const documentRef = useMemo(() => firebaseInstance.db.doc(documentId), [documentId])

  const deleteItem = useCallback(() => documentRef.delete(), [documentRef])
  const setItem = useCallback((item: T) => documentRef.set(item), [documentRef])
  const setItemMerge = useCallback((item: T) => documentRef.set(item, { merge: true }), [documentRef])
  const updateItem = useCallback((item: T) => documentRef.update(item), [documentRef])

  const commonHandlers = {
    deleteItem,
    setItem,
    setItemMerge,
    updateItem,
  }

  const [value, loading, error] = useDocumentData<T>(skipLoad ? undefined : documentRef, { idField: 'id', transform })

  const document = {
    value,
    loading,
    error,
  }

  return { document, documentRef, commonHandlers }
}
