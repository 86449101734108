import React, { useEffect, useState } from 'react'
import { Box, styled } from '@material-ui/core'
import tickImage from './images/tick.svg'

const STAGES = 3
const STAGE_CHANGE_TIMEOUT = 1500

type Props = {
  onRedirect: () => void
}

export function PreviewGroupRedirect({ onRedirect }: Props) {
  const [stage, setStage] = useState<number>(STAGES)

  useEffect(() => {
    if (stage < 1) {
      onRedirect()
    } else {
      setTimeout(() => {
        setStage((s) => s - 1)
      }, STAGE_CHANGE_TIMEOUT)
    }
  }, [stage])

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <img src={tickImage} alt="tick image" />
        <HeadingText>
          Group created 🎉
          <br />
          Let's invite some people <br /> to the group
        </HeadingText>
        <SecondaryText>Generating some matches</SecondaryText>
        <StageBox>{stage}</StageBox>
      </Box>
    </Box>
  )
}

const HeadingText = styled('div')({
  fontSize: '25px',
  fontWeight: 'bold',
  lineHeight: '27.5px',
  margin: '10px 0 15px',
})

const SecondaryText = styled('div')({
  fontSize: 20,
  lineHeight: '26px',
  fontWeight: 400,
})

const StageBox = styled('div')({
  fontSize: 50,
  fontWeight: 500,
  background: '#F9F6FD',
  borderRadius: '2px',
  padding: '26px 0',
  alignSelf: 'stretch',
  marginTop: '35px',
})
