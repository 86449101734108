import React from 'react'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'
import { ReactComponent as SmileIcon } from '../../assets/smiley.svg'

export function ApplicationProgress({ link }: { link: string }) {
  return (
    <DashboardBanner
      icon={SmileIcon}
      viewBox="0 0 40 40"
      title="Your application is in progress"
      titleKey="dashboard.application-progress-title"
      subtext="Let's pick up where you left off 🙂"
      subtextKey="dashboard.application-progress-subtext"
      linkTitle="Continue"
      linkTitleKey="dashboard.application-progress-linkLabel"
      linkURL={link}
    ></DashboardBanner>
  )
}
