import React from 'react'
import { QuestionComponentProps } from '.'
import { TagsView } from '../../../common'

export default function TagList({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const tags = (Array.isArray(Answer) && Answer[0] && Answer[0].split(/\s+/)) || []

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      <TagsView tags={tags} />
    </>
  )
}
