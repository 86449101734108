import React from 'react'
import { CheckboxProps, FormControlLabel, styled, Switch } from '@material-ui/core'

type Props = { name: string; label: string } & CheckboxProps

export const InputToggle: React.FC<Props> = ({ name, label, ...rest }) => {
  return (
    <>
      <FormControlLabel control={<Switch name={name} {...rest} />} label={<LabelText>{label}</LabelText>} />
    </>
  )
}

const LabelText = styled('span')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary,
}))
