import { NotificationMessage } from '@rse/core'
import React, { useState } from 'react'

export function usePushNotificationReceiver() {
  const fbPlugin = (window as any).FirebasePlugin
  const [ notification, setNotif ] = useState<NotificationMessage | false>(false)

  function clearNotification() {
    setNotif(false)
  }

  /* for test creation of notif on startup
  React.useEffect(() => {
    setNotif({
      uid: 'NOBODY',
      nType: NotificationType.InvitationToJoin,
      messageData: {
        foo: 'foo'
      }
    })
  }, [])
  */

  React.useEffect(() => {
    if (fbPlugin) {
      fbPlugin.onMessageReceived(function(message: any) {
        try{
          console.log('onMessageReceived')
          console.dir(message)
          if(message.messageType === 'notification'){
            const n = message as NotificationMessage
            setNotif(n)
          }else{
            console.warn('no handler yet for data messages')
          }
        }catch(e:any){
          console.error('Exception in onMessageReceived callback: '+e.message)
        }
    }, function(error: any) {
        console.error('Failed receiving FirebasePlugin message', error)
    })
    }
  }, [fbPlugin])

  return { notification, clearNotification }
}