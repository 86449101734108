import React, { useEffect, useRef, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Box, createStyles, makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import { UserProfileEntity } from '@rse/core'
import { ButtonLarge } from '@rse/uikit'

import { AppRoutes } from '../../../../App/routeUtils'
import { cleanPhoneValue, isValidPhone } from '../../../../common/utils/validation'
import { TextContent } from '../../../../common/textContent'
import { useSnackbar } from '../../../../components/common/SnackbarStyled/useSnackbar'

interface AccountFormProps {
  get: () => Promise<UserProfileEntity | undefined>
  save: (profile: UserProfileEntity) => Promise<void>
}

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
    },
    container: {},
    password: {},
    validationErrors: {
      color: 'red',
    },
  })
)

export function AccountForm({ get, save }: AccountFormProps) {
  const [profile, setProfile] = useState<UserProfileEntity>()
  const { setTextAndOpen } = useSnackbar()

  const submitForm = (value: Partial<UserProfileEntity>) => {
    console.log('submitting...', value)

    try {
      const profileUpdated = getUpdatedProfile(profile!, value)
      save(profileUpdated)
      setTextAndOpen('Profile has been successfully saved', 'success')
      setProfile(profileUpdated)
    } catch (err: any) {
      setTextAndOpen(err.message || 'Error saving profile', 'error')
      setProfile(profile)
    }
  }

  useEffect(() => {
    get().then(setProfile)
  }, [get])

  return (
    <>      
      {profile && <PersonalInformationForm data={profile} submitForm={submitForm} />}
    </>
  )
}

const getUpdatedProfile = (profile: UserProfileEntity, value: any) => {
  const adjustedValue: any = {}
  if (value) {
    Object.keys(value).forEach((key) => {
      if (value[key] !== undefined) {
        adjustedValue[key] = value[key]
      }
    })
  }
  return { ...profile, ...adjustedValue }
}

const PersonalInformationForm = ({ data, submitForm }: Props) => {
  const classes = useStyles()
  const {
    register,
    handleSubmit,
    errors: fieldsErrors,
    control,
    setValue,
  } = useForm<UserProfileEntity>({
    defaultValues: data,
  })

  // hook-form does not return errors - thought internally it stops submitting - cannot receive error message
  // console.log('fieldsErrors', fieldsErrors)
  // hook-form - renders watch twice (bug) - need only distinct updates
  const phoneNumber: string | undefined = useWatch({ name: 'mobilePhone', control: control })
  const phoneNumberRef = useRef(data.mobilePhone)

  useEffect(() => {
    // hack for hook-forms - need only distinct updates
    if (phoneNumberRef.current !== phoneNumber) {
      if (phoneNumber) {
        phoneNumberRef.current = cleanPhoneValue(phoneNumber)
        setValue('mobilePhone', phoneNumberRef.current)
      } else {
        phoneNumberRef.current = phoneNumber ?? ''
        setValue('mobilePhone', phoneNumber)
      }
    }
  }, [phoneNumber])

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box display="flex" flexDirection="column" my={2}>
        <Box mb={3} mt={0.5}>
          <StyledTextField
            label="First Name"
            name="firstName"
            error={!!fieldsErrors.firstName}
            inputRef={register({ required: true, minLength: 1 })}
          />
        </Box>
        <Box mb={3} mt={0.5}>
          <StyledTextField
            label="Last Name"
            name="lastName"
            error={!!fieldsErrors.lastName}
            inputRef={register({ required: true, minLength: 1 })}
          />
        </Box>
        <Box mb={3} mt={0.5}>
          <StyledTextField
            label="Email"
            name="Email"
            type="email"
            error={!!fieldsErrors.Email}
            inputRef={register({ required: true })}
            disabled={true}
          />
        </Box>
        <Box mb={3} mt={0.5}>
          <StyledTextField
            label="Phone Number"
            name="mobilePhone"
            error={!!fieldsErrors.mobilePhone}
            inputRef={register({ required: true, validate: isValidPhone })}
            disabled={true}
          />
          <Box mt={1}>
            <Link to={AppRoutes.changePhone()} className={classes.link}>
              <TextContent resourceKey="buttons.change-phone" defaultValue="Change Phone" />
            </Link>
          </Box>
        </Box>
      </Box>
      <Box mb={3} mt={0.5}>
        <TextField variant="outlined" fullWidth label="Password" value="********" disabled={true} />
        <Box mt={1}>
          <Link to={AppRoutes.changePassword()} className={classes.link}>
            <TextContent resourceKey="buttons.change-password" defaultValue="Change Password" />
          </Link>
        </Box>
      </Box>
      <ButtonLarge fullWidth type="submit">
        <TextContent resourceKey="buttons.save" defaultValue="Save" />
      </ButtonLarge>
    </form>
  )
}

const StyledTextField = ({ label, name, error, placeholder, inputRef, disabled }: TextFieldProps) => (
  <TextField
    variant="outlined"
    required
    fullWidth
    InputLabelProps={{ shrink: true }}
    label={label}
    name={name}
    error={error}
    placeholder={placeholder}
    inputRef={inputRef}
    disabled={disabled}
  />
)

type Props = {
  data: UserProfileEntity
  submitForm: (data: UserProfileEntity) => void
}
