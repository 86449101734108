import { Box, makeStyles, createStyles } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { UserIdentityVerificationStatus } from '@rse/core'

import { useCurrentUserId } from '../../../common/hooks'
import { Loading } from '../../../components/common'
import { firebaseInstance, useUserIdentityVerification } from '../../../firebase'
import { ApplicationProgressDataContext } from '../components/ApplicationFormProgressProvider'

function urlFunction(): Promise<{ url?: string; bypass?: boolean }> {
  const urlFunc = firebaseInstance.firebaseFunction.httpsCallable('createYotiSession')
  return urlFunc().then((response: { data: { url?: string; bypass?: boolean } }) => response.data)
}

const useStyles = makeStyles(() =>
  createStyles({
    iframe: {
      width: '100%',
      height: '400px',
      border: '1px solid black',
    },
  })
)

export const StepIdentityVerification: React.VFC = () => {
  const styles = useStyles()
  const [url, setUrl] = useState<string | null>(null)
  const [bypass, setBypass] = useState<boolean>(false)
  const uid = useCurrentUserId()
  const identityVerification = useUserIdentityVerification(uid)
  const [{ blockNextButton, unblockNextButton }, setState] = useContext(ApplicationProgressDataContext)

  useEffect(() => {
    urlFunction()
      .then((response: { url?: string; bypass?: boolean }) => {
        if (response.bypass === true) {
          setBypass(true)
        } else {
          setUrl(response.url!)
        }
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    if (bypass) {
      unblockNextButton()
      return
    }

    if (!identityVerification) {
      blockNextButton()
      return
    }

    if (
      identityVerification.status === 'Approved' ||
      identityVerification.status === 'Error' || identityVerification.status === 'In Review'
    ) {
      unblockNextButton()
    } else {
      blockNextButton()
    }
  }, [identityVerification, bypass])

  if (bypass) {
    return <Box m={3}>Identity verification is disabled by server settings. Please proceed to the next step.</Box>
  }

  if (!url) {
    return <Loading />
  }

  if (url && (!identityVerification || identityVerification.status === 'Pending')) {
    return (
      <Box m={3}>
        <iframe src={url} className={styles.iframe}></iframe>
      </Box>
    )
  }

  if (identityVerification && identityVerification.status === 'Approved') {
    return <Box m={3}>Your identity verification successful. You can proceed to the next step.</Box>
  }

  if (identityVerification && identityVerification.status === 'Error' || identityVerification && identityVerification.status === 'In Review') {
    return <Box m={3}>Your identity document is in review. You can still proceed to the next step.</Box>
  }

  if (identityVerification && identityVerification.status === 'Rejected') {
    return (
      <Box m={3}>
        Your identity verification failed. Please review the application data and try again or contact us.
      </Box>
    )
  }

  return <Box m={3}>Something went wrong. Please try again or contact us.</Box>
}
