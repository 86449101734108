import { Typography } from '@material-ui/core'
import React from 'react'

interface SearchMatchesViewProps {
  items: unknown[] | undefined
}

export function SearchMatchesView({ items }: SearchMatchesViewProps) {
  return (
    <Typography variant="h6" align="center">
      {items ? `+ ${items.length} Matches` : 'No members found'}
    </Typography>
  )
}
