import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router'
import { RouteProps } from 'react-router-dom'
import { User, UserRole } from '@rse/core'
import { UserContext } from '.'
import { EquityCalculatorNav, MessagingNav } from '../common/constants/routes'
import { IMenuItem, IMenuLink } from '../components/layout/appLayout/Header/HeaderLeftMenu'

export function menuItemsForUser(user?: User) {
  const ret: IMenuItem[] = []

  ret.push({ url: EquityCalculatorNav.path, label: 'Equity Calculator', labelResource: 'menu.equity-calculator' })
  ret.push('hr')

  const authenticatedMenuItems: IMenuLink[] = [
    { url: MessagingNav.path, label: 'Messages', labelResource: 'menu.messaging' },
    { url: '/groups', label: 'Buying Groups', labelResource: 'menu.groups' },
    { url: '/club-members', label: 'Club Members', labelResource: 'menu.club-members' },
  ]

  const adminMenuItems: IMenuItem[] = [
    { url: '/sandbox', label: 'Sandbox', labelResource: 'menu.sandbox' },
    { url: '/sandbox/icons', label: 'Icons', labelResource: 'menu.sandbox-icons' },
  ]

  if (user) {
    //if (currentUser.homeBuyingGroupStatus && currentUser.homeBuyingGroupStatus === 'Approved')
    for (const item of authenticatedMenuItems) {
      if (user.initApplicationStatus !== 'Approved') item.disabled = true
      // TODO remove this temp hack once backend approves applications correctly
      if (user.initApplicationStatus === 'In Review') item.disabled = false
      ret.push(item)
    }
  }

  ret.push('hr')
  ret.push({ url: '/sandbox', label: 'Sandbox', labelResource: 'menu.sandbox' })
  ret.push({ url: '/communityResources', label: 'Community Resources', labelResource: 'menu.community-resources' })

  if (user?.roles?.includes('admin')) {
    ret.push('hr')
    for (const item of adminMenuItems) ret.push(item)
  }

  return ret
}

export const AppRoutes = {
  group: (groupId: string) => `/groups/${groupId}/view`,
  groupRoot: () => '/groups',
  groupCreate: () => '/groups/create',
  groupMembers: (groupId: string) => `/groups/${groupId}/members`,
  clubMember: (uid: string) => `/club-members/${uid}`,
  clubMemberEdit: (uid: string) => `/club-members/${uid}/edit`,
  clubMemberRoot: () => '/club-members',
  changePassword: () => '/settings/change-password',
  changePhone: () => '/settings/change-phone',
  chat: (messageThreadId: string) => `/chat/${messageThreadId}`,
  sandboxPage: (pageSubPath: string) => `/sandbox/${pageSubPath}`,
  settings: () => '/settings',
}

interface ProtectedRouteProps extends Omit<RouteProps, 'children'> {
  allowedRoles?: UserRole | UserRole[]
}

export function ProtectedRoute({ component, allowedRoles = [], ...rest }: ProtectedRouteProps) {
  const user = useContext(UserContext)
  const allowedRolesArray = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles]

  if (!component) throw new Error('component is a required attribute')

  return (
    <Route
      {...rest}
      render={(props) => {
        const {
          match,
          location: { pathname },
          history,
        } = props

        if (!user) {
          console.log('unauthenticated user attempted to visit protected route, redirecting to login')
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }

        // if allowedRoles is empty, allow any authenticated user
        // otherwise, check if they have at least one of the allowed roles
        if (!allowedRoles.length || allowedRolesArray.some((role) => user?.roles?.includes(role))) {
          return React.createElement(component, { ...rest }, null)
        }

        return 'This screen requires permissions that your account does not have.'
      }}
    />
  )
}
