import { Box, Chip, styled, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useCallback } from 'react'
import { useFormData } from '../../../Application/helpers/useFormData'

export const WhatsImportant = () => {
  const [value, setValue] = useState('')
  const { formData, onFieldChange } = useFormData()
  const tags = formData.whatsImportant ?? []

  const handleDelete = useCallback(
    (tag: string) => {
      onFieldChange(
        'whatsImportant',
        tags.filter((t: string) => t !== tag)
      )
    },
    [tags]
  )

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement> & React.ChangeEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        const tag = e.target?.value
        if (tag) {
          onFieldChange('whatsImportant', [...tags, tag])
          setValue('')
        }
      }
    },
    [tags]
  )

  return (
    <>
      <input name="whatsImportant" value={tags} hidden />
      <TextField
        variant="outlined"
        fullWidth
        inputProps={{ style: { fontWeight: 700 } }}
        label="What's important to me"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <Box mt={2}>
        {tags.map((tag: string) => (
          <ChipStyled
            key={tag}
            label={tag?.toUpperCase()}
            size="small"
            variant="outlined"
            onDelete={() => handleDelete(tag)}
          />
        ))}
      </Box>
    </>
  )
}

const ChipStyled = styled(Chip)({
  margin: '5px',
})
