// This workaround allows us to run strict mode before MUI upgrades to v5.
// See https://stackoverflow.com/a/64135466/795280
// TODO upgrade to v5 when it's released
import {
  makeStyles,
  Theme,
  ThemeOptions,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import { ClassNameMap, Styles, WithStylesOptions } from '@material-ui/styles/withStyles'
import { Omit } from '@material-ui/types'
import { PaletteType, RadioProps } from '@material-ui/core'

export interface ShapeExtended {
  borderRadius: number[]
}

export interface PaletteExtended {
  background: TypeBackgroundExtended
}

export interface TypeBackgroundExtended {
  primary: string
  secondary: string
}

export type ShapeExtendedOptions = Partial<ShapeExtended>
export type PaletteExtendedOptions = Partial<PaletteExtended>
export type ThemeOptionsExtended = ThemeOptions & {
  shapeExtended?: ShapeExtendedOptions
  paletteExtended?: PaletteExtendedOptions
}
export type AppTheme = Theme & { shapeExtended: ShapeExtended; paletteExtended: PaletteExtended }

export function createMuiThemeExtended(options?: ThemeOptionsExtended, ...args: object[]): AppTheme {
  return {
    ...{
      shapeExtended: { borderRadius: [4, 6, 8] },
      paletteExtended: { background: { primary: '#ffffff', secondary: '#ffffff' } },
    },
    ...createMuiTheme(options, ...args),
  }
}

export function makeStylesExtended<Theme = AppTheme, Props extends object = {}, ClassKey extends string = string>(
  styles: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>
): keyof Props extends never // `makeStyles` where the passed `styles` do not depend on props
  ? (props?: any) => ClassNameMap<ClassKey> // `makeStyles` where the passed `styles` do depend on props
  : (props: Props) => ClassNameMap<ClassKey> {
  return makeStyles(styles, options)
}

const themeDataCommon = {
  shapeExtended: {
    borderRadius: [4, 6, 8],
  },
  typography: {
    fontFamily: '"Quicksand", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '33px',
      lineHeight: 1,
      letterSpacing: 0,
    },
    h2: {
      fontWeight: 700,
      fontSize: '25px',
      lineHeight: 1.11,
    },
    h3: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: 1.2,
    },
    h4: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: 1,
    },
    h5: {
      fontWeight: 700,
      fontSize: '15px',
      lineHeight: 1,
    },
    h6: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 1,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 1.4,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: 1.4,
    },
    body1: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.4,
    },
    body2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: 1.33,
    },
    button: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 1,
    },
    caption: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: 1,
    },
    overline: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: 1,
    },
  },
}

export const lightThemeData = {
  ...themeDataCommon,
  palette: {
    type: 'light' as PaletteType,
    primary: {
      light: '#A560FF',
      main: '#6B2EF5',
      dark: '#2F0B96',
    },
    secondary: {
      light: '#D7FF00',
      main: '#CEDC00',
      dark: '#A4AF1A',
    },
    text: {
      primary: '#1A0154',
      secondary: '#8C8AAC',
      disabled: '#C0C0C0',
      hint: '#BAB3CC',
    },
    divider: '#F0F0F4',
    background: {
      default: '#ffffff',
      paper: '#FCFBFA',
    },
    action: {
      disabledBackground: '#F0F0F4',
    },
    grey: {
      50: '#F8F6F3',
      100: '#EFECE6',
      200: '#D6D3CD',
      300: '#BCB9B4',
      400: '#A2A09A',
      500: '#89877F',
      600: '#716D67',
      700: '#57544D',
      800: '#3C3B33',
      900: '#242119',
    },
  },
  paletteExtended: {
    background: {
      primary: '#efeeea',
      secondary: '#efeeea',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
        // Remove increment arrows from number inputs by default
        'input[type=number]': {
          '&::-webkit-inner-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            margin: 0,
          },
        },

        '.MuiOutlinedInput-input:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 100px #ffffff inset !important',
          '-webkit-text-fill-color': '#000000 !important',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: grey[500],
      },
    },
    MuiCheckbox: {
      root: {
        color: grey[500],
      },
    },
    MuiRadio: {
      root: {
        color: grey[500],
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#F0F0F4',
      },
    },
  },
  props: {
    MuiRadio: {
      color: 'primary' as RadioProps['color'],
    },
    MuiCheckbox: {
      color: 'primary' as RadioProps['color'],
    },
  },
}

export const darkThemeData = {
  ...themeDataCommon,
  palette: {
    type: 'dark' as PaletteType,
    primary: {
      light: '#D7B6F8',
      main: '#D7FF00',
      dark: '#D7FF00', // TBD: get from designers
    },
    secondary: {
      light: '#D7FF00',
      main: '#CEDC00',
      dark: '#A4AF1A',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#8C8AAC',
    },
    background: {
      default: '#2F0B96',
    },
    action: {
      disabledBackground: '#93A345',
    },
  },
  paletteExtended: {
    background: {
      primary: '#efeeea',
      secondary: '#efeeea',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: grey[500],
      },
    },
    MuiCheckbox: {
      root: {
        color: grey[500],
      },
    },
    MuiRadio: {
      root: {
        color: grey[500],
      },
    },
  },
  props: {
    MuiRadio: {
      color: 'primary' as RadioProps['color'],
    },
  },
}
