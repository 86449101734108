import USStates from '../../../common/constants/USStates'
import { Dictionary, DynamicWizardConfigData } from '../types'

export function getWizardConfig() {
  const data: DynamicWizardConfigData = {
    Title: 'Sample Wizard',
    ResponseID: 'SampleWizard',
    ShowProgressLine: true,
    Pages: [
      {
        Id: 'p1',
        Title: 'Step 1/2 - Basic Profile',
        ShowTitle: true,
        Sections: [
          {
            Id: '1',
            Title: 'Legal Name',
            ShowTitle: true,
            Questions: [
              {
                Id: '1',
                QuestionId: '1',
                QuestionName: 'firstName',
                IsRequired: true,
                RenderControl: 'TextInput',
                RenderControlProps: {},
                Title: 'First Name',
              },
              {
                Id: '2',
                QuestionId: '2',
                QuestionName: 'middleName',
                RenderControl: 'TextInput',
                RenderControlProps: { DataType: 'Text' },
                Title: 'Middle Name',
                SkipNewLine: true,
              },
              {
                Id: '3',
                QuestionId: '3',
                QuestionName: 'lastName',
                IsRequired: true,
                RenderControl: 'TextInput',
                RenderControlProps: { DataType: 'Text' },
                Title: 'Last Name',
              },
              {
                Id: '18',
                QuestionId: '18',
                QuestionName: 'editButton',
                IsRequired: true,
                ShowTitle: false,
                RenderControl: 'Slot',
                RenderControlProps: {},
                Title: 'Dumb title',
              },
              {
                Id: '4',
                QuestionId: '4',
                QuestionName: 'ageRange',
                IsRequired: true,
                ShowTitle: true,
                RenderControl: 'RangeSliderInput',
                RenderControlProps: { leftBottomMark: 'Min', rightBottomMark: 'Max' },
                MaxValue: 100,
                MinValue: 10,
                QuestionChoices: [
                  { Label: '18', Value: '18' },
                  { Label: '25', Value: '25' },
                  { Label: '30', Value: '30' },
                  { Label: '50', Value: '50' },
                  { Label: '60', Value: '60' },
                  { Label: '80', Value: '80' },
                  { Label: '100', Value: '100' },
                ],
                Title: 'Age Range',
              },
            ],
          },
          {
            Id: '2',
            Title: 'Income',
            ShowTitle: true,
            Questions: [
              {
                Id: '5',
                QuestionId: '5',
                QuestionName: 'incomeSource',
                IsRequired: true,
                RenderControl: 'CheckBoxInput',
                RenderControlProps: {},
                Title: 'What are your income sources?',
                QuestionChoices: [
                  { Label: 'Self employment', Value: 'Self' },
                  { Label: 'Salary', Value: 'Salary' },
                  { Label: 'Other', Value: 'Other' },
                ],
              },
              {
                Id: '17',
                QuestionId: '17',
                QuestionName: 'ChipsSample',
                IsRequired: true,
                RenderControl: 'ChipsInput',
                RenderControlProps: {},
                Title: 'Chips Component Sample',
                ShowTitle: true,
                MaxValue: 3,
                MinValue: 1,
                QuestionChoices: [
                  { Label: 'One', Value: 'One', imgUrl: 'https://via.placeholder.com/15' },
                  { Label: 'Two', Value: 'Two', imgUrl: '' },
                  { Label: 'Three', Value: 'Three', imgUrl: '' },
                  { Label: 'Four', Value: 'Four', imgUrl: '' },
                  { Label: 'Five', Value: 'Five', imgUrl: '' },
                  { Label: 'Six', Value: 'Six', imgUrl: '' },
                ],
              },
            ],
          },
        ],
      },
      {
        Id: 'p2',
        Title: 'Step 2/2 - Preferences',
        ShowTitle: true,
        Sections: [
          {
            Id: '3',
            Title: 'Pets',
            ShowTitle: true,
            Questions: [
              {
                Id: '8',
                QuestionId: '8',
                QuestionName: 'indentityCheckInfo',
                RenderControl: 'InfoSection',
                RenderControlProps: {
                  resourceKey: 'app-wizard.verification-description',
                  defaultValue:
                    'Don’t worry about minor implications, we just want to know that you are who you say you are, and that you’re safe to live with.',
                },
                Title: 'Identity check information',
                ShowTitle: false,
                IsReadOnly: true,
              },
              {
                Id: '6',
                QuestionId: '6',
                QuestionName: 'pets',
                IsRequired: true,
                RenderControl: 'CheckBoxInput',
                RenderControlProps: {},
                Title: 'What kind of pets do you prefer?',
                QuestionChoices: [
                  { Label: 'Cats', Value: 'Cats' },
                  { Label: 'Dogs', Value: 'Dogs' },
                  { Label: 'Others', Value: 'Others' },
                ],
              },
              {
                Id: '7',
                QuestionId: '3',
                QuestionName: 'lastName',
                RenderControl: 'TextInput',
                RenderControlProps: { DataType: 'Text', IsRequired: 'true' },
                Title: 'Last Name',
              },
              {
                Id: '9',
                QuestionId: '9',
                QuestionName: 'profileImage',
                RenderControl: 'PhotoInput',
                RenderControlProps: {},
                Title: 'Profile Photo',
                IsRequired: true,
                ShowTitle: true,
              },
              {
                Id: '10',
                QuestionId: '10',
                QuestionName: 'moveTimeFrame',
                RenderControl: 'PillsInput',
                RenderControlProps: {},
                Title: 'When do you want to move?',
                IsRequired: true,
                ShowTitle: true,
                QuestionChoices: [
                  { Label: 'ASAP', Value: 'ASAP' },
                  { Label: '3-6M', Value: '3-6M' },
                  { Label: '6-12M', Value: '6-12M' },
                  { Label: '12M+', Value: '12M+' },
                ],
              },
              {
                Id: '11',
                QuestionId: '11',
                QuestionName: 'zipCode',
                IsRequired: true,
                RenderControl: 'TextInput',
                RenderControlProps: {},
                DataType: 'Number',
                Title: 'Zip Code',
              },
              {
                Id: '12',
                QuestionId: '12',
                QuestionName: 'birthday',
                IsRequired: true,
                RenderControl: 'TextInput',
                RenderControlProps: { label: 'Birthday *' },
                DataType: 'Date',
                Title: 'Birthday',
              },
              {
                Id: '13',
                QuestionId: '13',
                QuestionName: 'lgbtqFriendly',
                IsRequired: true,
                RenderControl: 'ToggleSwitchInput',
                RenderControlProps: {},
                Title: 'I\'m LGBTQ friendly',
              },
              {
                Id: '14',
                QuestionId: '14',
                QuestionName: 'state',
                IsRequired: true,
                RenderControl: 'DropDownSelectInput',
                RenderControlProps: {},
                Title: 'State',
                QuestionChoices: USStates.map((x) => ({ Label: x.state, Value: x.code })),
              },
              {
                Id: '15',
                QuestionId: '15',
                QuestionName: 'identityTermsAccepted',
                RenderControl: 'TermsAcceptInput',
                RenderControlProps: {
                  resourceKey: 'app-wizard.club-member-rules-terms',
                  defaultValue:
                    'By checking the box below you authorize Social Equity to order a consumer credit report and verify other credit information, including past and present mortgage and landlord references. You further authorize for Social Equity to verify your past and present employment earnings records, bank accounts, stock holdings, and other asset balances that are needed to process your stuff.',
                },
                Title: 'Identity Check terms and conditions',
                QuestionChoices: [
                  { Label: 'I agree to club member rules', Value: 'memberRules' },
                  { Label: 'I agree to sell my soul to the devil', Value: 'devilRules' },
                ],
              },
              {
                Id: '16',
                QuestionId: '16',
                QuestionName: 'Gender',
                RenderControl: 'PillsInput',
                RenderControlProps: { lastOptionIsOther: 'true' },
                Title: 'Gender',
                QuestionChoices: [
                  { Label: '\u2642 Male', Value: 'Male' },
                  { Label: '\u2640 Female', Value: 'Female' },
                  { Label: 'Other...', Value: 'Other' },
                ],
              },
            ],
          },
        ],
      },
    ],
  }
  return data
}

export const mockInitialAllPageAnswers: Dictionary<string[]> = {
  '1': [],
  '2': [],
  '3': ['Belal'],
  '4': ['20', '25'],
  '5': ['Other', 'Self'],
  '6': ['Cats'],
}
