import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

import settings from '../settings'

import { firebaseConfig } from './firebaseConfig'

export class FirebaseInstance {
  initialized = false
  auth: firebase.auth.Auth
  db: firebase.firestore.Firestore
  storage: firebase.storage.Storage
  firebaseFunction: firebase.functions.Functions

  private config: Record<string, unknown>

  constructor(config: Record<string, unknown>) {
    this.config = config
    this.initialize()
      .then(()=> console.log('firebase initialized'))
  }

  public async initialize() {
    if (this.initialized) return
    await firebase.initializeApp(this.config)
    this.auth = await firebase.auth()
    this.db = await firebase.firestore()
    this.storage = await firebase.storage()
    this.firebaseFunction = await firebase.functions()

    if (window.location.hostname === 'localhost') {
      if (settings.firebaseUseEmulators) {
        this.db.useEmulator('localhost', 8080)
        this.auth.useEmulator('http://localhost:9099/')
        this.firebaseFunction.useEmulator('localhost', 5001)
      }
    }

    this.initialized = true
  }
}

export const firebaseInstance = new FirebaseInstance(firebaseConfig)
