import { useSnackbar } from '../../components/common/SnackbarStyled/useSnackbar'

// TODO allow parent components to define a local error handler, so that not all errors are handled globally
export function useErrorHandler(context: string) {
  const { setTextAndOpen } = useSnackbar()

  function errorHandler(err: unknown) {
    let message = `Error while ${context}`
    if (err instanceof Error && err.message) {
      message += `: ${err.message}`
    }
    console.error(message, err)
    setTextAndOpen(message, 'error')
  }

  return { errorHandler }
}