export type Gender = 'male' | 'female' | 'nonbinary'

const MemberGenderTitles: Record<string, string> = {
  male: 'Male',
  female: 'Female',
  nonbinary: 'Other',
}

const RoommateGenderTitles: Record<string, string> = {
  male: 'Male',
  female: 'Female',
  nonbinary: 'Co-Ed',
}

type GenderOrString = Gender | string | null | undefined

function getGenderTitle(titles: Record<string, string>, gender: GenderOrString): string {
  return gender == null ? '' : (titles[gender] ?? gender)
}

export function getClubMemberGenderTitle(sex:  GenderOrString, gender?: GenderOrString): string {
  return sex === 'nonbinary' && gender ? gender : getGenderTitle(MemberGenderTitles, sex)
}

export function getRoommateGenderTitle(gender: GenderOrString): string {
  return getGenderTitle(RoommateGenderTitles, gender)
}

export function getLgbtqFriendlyTitle(lgbtqFriendly: boolean | string | null | undefined): string {
  return (typeof lgbtqFriendly === 'string' ? lgbtqFriendly === 'true' : !!lgbtqFriendly) ? 'yes' : 'no'
}