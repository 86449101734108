import { parseIncompletePhoneNumber, AsYouType } from 'libphonenumber-js'

export function cleanPhoneValue(input: string): string {
  if (input.length) {
    // to allow backspace over top of parens e.g. (###)
    if (input.match(/\(...$/)) input = input.slice(-3, -1)

    input = parseIncompletePhoneNumber(input)

    if (input.length) {
      const asYouType = new AsYouType('US')
      input = asYouType.input(input)
    }
  }
  return input
}

export function parsePhone(phoneValue: string) {
  const asYouType = new AsYouType('US')
  asYouType.input(phoneValue)
  return asYouType.getNumber()!
}

export function isValidPhone(phoneValue: string) {
  const asYouType = new AsYouType('US')
  asYouType.input(phoneValue)
  return asYouType.getNumber()?.isPossible()
}
