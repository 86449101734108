import React, { useState } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { BucketSet, ButtonLarge } from '@rse/uikit'

import { Bucket, CascadingBuckets, CascadingBucketsParams } from '@rse/uikit'
import clsx from 'clsx'

export type QuestionValue = { title: string; selected: boolean; hidden: boolean }

export type SimpleBucket = Bucket<QuestionValue>
export type SimpleBuckets = BucketSet<QuestionValue>
export type CascadedPreferencesProps = CascadingBucketsParams<QuestionValue>

const initialSectionData: SimpleBuckets = [
  {
    id: 'must-have',
    title: 'Must have',
    instructions: 'Choose factors that are very important to you (max 3)',
    active: true,
    values: [
      { title: 'one', selected: false, hidden: false },
      { title: 'two', selected: false, hidden: false },
      { title: 'three', selected: false, hidden: false },
    ],
  },
  {
    id: 'should-have',
    title: 'Should have',
    instructions: 'Choose factors that are very important to you (max 3)',
    active: false,
    values: [
      { title: '2-one', selected: false, hidden: false },
      { title: '2-two', selected: false, hidden: false },
      { title: '2-three', selected: false, hidden: false },
    ],
  },
  {
    id: 'nice-to-have',
    title: 'Nice to have',
    instructions: 'Choose factors that are very important to you (max 3)',
    active: false,
    values: [
      { title: '3-one', selected: false, hidden: false },
      { title: '3-two', selected: false, hidden: false },
      { title: '3-three', selected: false, hidden: false },
    ],
  },
  {
    id: 'not-important',
    title: 'Not important',
    instructions: '',
    active: false,
    values: [
      { title: '4-one', selected: false, hidden: false },
      { title: '4-two', selected: false, hidden: false },
      { title: '4-three', selected: false, hidden: false },
    ],
  },
]

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      cursor: 'pointer',
      border: '1px solid #eeeeee',
      borderRadius: '50%',
    },
    selected: {
      background: '#eeeeee',
    },
  })
)

export function SimpleCascadedApplication() {
  const [sectionData, setSectionData] = useState(initialSectionData)
  const classes = useStyles()

  console.debug(sectionData)

  return (
    <>
      <CascadingBuckets<QuestionValue>
        data={sectionData}
        defaultBucket={0}
        renderValue={({ val, onChange }) => {
          return (
            <Box
              display="inline-block"
              p={1}
              m={1}
              className={clsx(classes.item, val.selected && classes.selected)}
              onClick={() => onChange({ ...val, selected: !val.selected })}
            >
              {val.title}
            </Box>
          )
        }}
        onChange={async (buckets: SimpleBuckets) => {
          console.debug(buckets)
          setSectionData(buckets)
          return buckets
        }}
        renderControls={({ prevDisabled, nextDisabled, prev, next }) => (
          <Box display="flex">
            <ButtonLarge
              disabled={prevDisabled}
              onClick={() => {
                setSectionData(prev())
              }}
            >
              Back
            </ButtonLarge>
            <Box ml={1}>
              <ButtonLarge
                disabled={nextDisabled}
                onClick={() => {
                  setSectionData(next())
                }}
              >
                Next
              </ButtonLarge>
            </Box>
          </Box>
        )}
      />
    </>
  )
}
