import { Box } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { TextContent } from '../../../../common/textContent'
import { ButtonLarge } from '@rse/uikit'

export const ProfileFormButtons = (props: { onCancel?: () => void | Promise<void> }) => {
  const history = useHistory()

  const prev = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <>
      <Box borderTop="1px solid #EFEFF3" mb={4} />
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <ButtonLarge variant="contained" type="submit">
          <TextContent resourceKey="buttons.save" defaultValue="Save" />
        </ButtonLarge>
        <Box mb={1} />
        <ButtonLarge variant="outlined" onClick={props.onCancel ?? prev}>
          <TextContent resourceKey="buttons.cancel" defaultValue="Cancel" />
        </ButtonLarge>
      </Box>
    </>
  )
}
