import { MemberMatchEntity } from '@rse/core'
import { useEffect, useMemo, useState } from 'react'
import { getDateFromFirestoreTimestampOrString } from '../../../firebase'
import firebase from 'firebase/app'
import { CollectionFilter, useCollectionPaged } from '../../../firebase/hooks/useCollectionPaged'

export function useClubMembersFiltered(userId: string, favoritesOnly: boolean, pageSize?: number, usePaging?: boolean) {

  if (!pageSize) pageSize = 20
  if (!usePaging) usePaging = false
  
  const pageSizeValue: number = pageSize
 
  const [matchedClubMemberData, setMatchedClubMemberData] = useState<MemberMatchEntity[]>([])
  const [errorDb, setErrorDb] = useState<firebase.FirebaseError>()
  


  function transformBirthday(value: MemberMatchEntity): MemberMatchEntity {
    if (value) {
      value.birthday = getDateFromFirestoreTimestampOrString(value.birthday)
    }
  
    return value
  }

  const filtersMatchedClubMembers = useMemo(
    () => {
      if (!favoritesOnly) return []

      const filters: CollectionFilter[] = [{ fieldPath: 'isFab', opStr: '==', value: true }]
      return filters
    }, [favoritesOnly]
  )



  const {
    currentData,
    data,
    isLoading,
    isAllDataLoaded,
    loadData
  } = useCollectionPaged<MemberMatchEntity>(
    `profileMatches/${userId}/memberMatches`,
    pageSizeValue,
    true,
    true,
    filtersMatchedClubMembers,
    undefined,
    transformBirthday,
    undefined)


    useEffect(() => {
      if(!isLoading && currentData && currentData.length > 0){
        setMatchedClubMemberData(matchedClubMemberData.concat(currentData))
      }
    }, [isLoading])
  
   
  
    useEffect(() => {
      setMatchedClubMemberData([])
    }, [favoritesOnly])
  
  










  return { loading: isLoading, error: errorDb, value: matchedClubMemberData, loadNextData: loadData }
}
