import { CheckboxProps, FormControlLabel, FormControlLabelProps, styled, Switch } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useFormData } from '../helpers/useFormData'
import { validateFields } from '../helpers/validation'
import type { InputId } from '../types/components'
import { TextError } from './TextError'

type Props = InputId &
  Omit<CheckboxProps, 'checked' | 'onChange' | 'required'> &
  Pick<FormControlLabelProps, 'label'> & {
    errorMessage?: string
  }

export const InputToggle: React.FC<Props> = ({ id, label, errorMessage, color = 'primary', ...rest }) => {
  const { formData, formErrors, onFieldChange, onValidationChange } = useFormData()

  // remove error if check had one
  useEffect(() => {
    if (formErrors[id]) {
      const valRes = validateFields(formData, [id])
      onValidationChange(valRes)
    }
  }, [formData[id], formErrors[id]])

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            name={id}
            checked={!!formData[id]}
            onChange={(e) => onFieldChange(id, e.target.checked)}
            color={color}
            {...rest}
          />
        }
        label={<LabelText>{label}</LabelText>}
      />
      {formErrors[id] && <TextError>{errorMessage || formErrors[id]}</TextError>}
    </>
  )
}

const LabelText = styled('span')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary,
}))
