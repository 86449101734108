import React from 'react'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'
import { ReactComponent as ClockIcon } from '../../assets/clock.svg'

export function ApplicationLoading() {
  return (
    <DashboardBanner
      icon={ClockIcon}
      viewBox="0 0 50 50"
      title="Application is being prepared"
      titleKey="dashboard.application-loading-title"
      subtext={'It will take just few seconds...'}
      subtextKey="dashboard.application-loading-subtext"
    ></DashboardBanner>
  )
}
