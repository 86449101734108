import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../App'
import { firebaseOnCallFunctions } from '../../common/constants/firebaseOnCallFunctions'
import { firebaseInstance } from '../../firebase'

export enum functionCallStatus {
  Idle = 'idle',
  Loading = 'loading',
  Fetched = 'fetched',
  Error = 'error',
}

export const useFirebaseFunctionCall = (functionName: firebaseOnCallFunctions, requiresAuth: boolean, throwOnError?: boolean) => {
  const [status, setStatus] = useState<functionCallStatus>(functionCallStatus.Idle)
  const [error, setError] = useState<string | undefined>()
  const [data, setData] = useState<any>()
  const user = useContext(UserContext)

  useEffect(() => {
    setStatus(functionCallStatus.Idle)
  }, [])

  const fetchDataFromFunction = async (inputParams: any) => {
    if (requiresAuth && !user) {
      setStatus(functionCallStatus.Error)
      const errorMessage = 'User must be authenticated'
      setError(errorMessage)
      console.log(errorMessage)
      if (throwOnError) {
        throw new Error(errorMessage)
      }
      return
    }

    setStatus(functionCallStatus.Loading)
    setError(undefined)

    const func = firebaseInstance.firebaseFunction.httpsCallable(functionName)
    try {
      const result = await func(inputParams)
      setData(result.data)
      setStatus(functionCallStatus.Fetched)
      return result.data
    } catch (e: any) {
      setStatus(functionCallStatus.Error)
      const errorMessage = e.message || 'Unknown error'
      setError(errorMessage)
      console.log(errorMessage)
      if (throwOnError) {
        throw new Error(errorMessage)
      }
    }

    return undefined
  }

  return { status, error, data, fetchDataFromFunction }
}
