import React from 'react'
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { PersonOutline } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { QuestionComponentProps } from '.'
import { AppRoutes } from '../../../../App/routeUtils'
import { UserAvatarPublic } from '../../../common'

export default function AvatarList({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const userIds: string[] = (Array.isArray(Answer) && Answer[0] && Answer[0].split(/\s+/)) || []
  const { MaxValue = 6 } = QuestionConfig

  const memberCount = userIds.length

  for (let i = memberCount; i < MaxValue; i++) {
    userIds.push('')
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      <Box display="flex" my={1}>
        {userIds.map((id, index) => (
          <BuyingGroupMemberIcon key={index} id={id} />
        ))}
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    memberAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  })
)

type GroupMemberInfo = {
  readonly id: string
}

const BuyingGroupMemberIcon = ({ id }: GroupMemberInfo) => {
  const classes = useStyles()

  if (id) {
    return (
      <Link to={AppRoutes.clubMember(id)} style={{ marginRight: 4 }}>
        <UserAvatarPublic uid={id} size="small" />
      </Link>
    )
  }

  return <PersonOutline color="disabled" className={classes.memberAvatar} />
}
