import React from 'react'
import {
  Avatar,
  Box,
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { BuyingGroupWithFab } from '../models'
import { PreviewTitle } from '@rse/uikit'
import { AppRoutes } from '../../../App/routeUtils'
import { BuyingGroupFavIcon, BuyingGroupMembersView } from '../../../components/common'
import { CardText } from '../../../components/common/CardText'
import { useStyles as useCommonStyles } from '../../ClubMembers/ClubMemberList/ClubMemberListCard'
import { ChevronRight } from '@material-ui/icons'
import pinnedIcon from '@rse/uikit/lib/icons/pinned.svg'
import { ReactComponent as HouseIcon } from '@rse/uikit/lib/icons/house.svg'

type Props = {
  group: BuyingGroupWithFab
  pinned?: boolean
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    iconStyle: {
      fill: palette.primary.main,
      width: '15px',
      height: '15px',
      marginLeft: '0.1em',
    },
  })
)

export function BuyingGroupListCard({ group, pinned = false }: Props) {
  const styles = useStyles()
  const imgSrc = group.groupProfileImageUrl
  const history = useHistory()
  const { secondaryActionStyle, listStyle, iconStyle } = useCommonStyles()

  const buttonNav = () => {
    history.push(AppRoutes.group(group.id))
  }

  if (true) {
    return (
      <ListItem button className={listStyle} onClick={buttonNav}>
        {!pinned && (
          <ListItemSecondaryAction className={secondaryActionStyle}>
            <BuyingGroupFavIcon groupId={group.id} isFab={group.isFab} />
          </ListItemSecondaryAction>
        )}
        {imgSrc && (
          <Box pr={2} alignSelf="flex-start">
            <Avatar src={imgSrc} />
          </Box>
        )}
        <ListItemText
          disableTypography
          primary={
            <PreviewTitle gutterBottom>
              <Box display="flex" alignItems="center" position="relative" marginTop={pinned ? 1 : 0}>
                {pinned && (
                  <Box display="flex" position="absolute" top="-20px" left="0">
                    <img src={pinnedIcon} alt="pinned" />
                    <Box ml={1}>
                      <Typography variant="body2">Pinned</Typography>
                    </Box>
                  </Box>
                )}
                {group.headline}&nbsp;&nbsp;
                {pinned && <HouseIcon className={styles.iconStyle} />}
              </Box>
            </PreviewTitle>
          }
          secondary={
            <Box>
              <CardText linesMax={2}>{group.description || 'No description provided'}</CardText>
              <BuyingGroupMembersView group={group} />
            </Box>
          }
        />
        <ListItemIcon className={iconStyle}>
          <ChevronRight color="disabled" />
        </ListItemIcon>
      </ListItem>
    )
  }
}
