import React, { useEffect } from 'react'
import { QuestionComponentProps } from '.'
import { TextContent } from '../../../../common/textContent'
import { PreviewTitle, StandardText } from '@rse/uikit'

export default function InfoSection({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  useEffect(() => {}, [])

  const resourceKey: string = otherProps.resourceKey || 'notconfigured'
  const defaultValue: string = otherProps.defaultValue || 'This text has not been configured...'
  const bold: boolean = otherProps.bold === 'true'

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      {bold ? (
        <PreviewTitle>
          <TextContent resourceKey={resourceKey} defaultValue={defaultValue} />
        </PreviewTitle>
      ) : (
        <StandardText>
          <TextContent resourceKey={resourceKey} defaultValue={defaultValue} />
        </StandardText>
      )}
    </>
  )
}
