import { Dictionary, DynamicWizardConfigData } from '../types'

export function getWizardConfig() {
  const data: DynamicWizardConfigData = {
    Title: 'Buying Group View',
    ResponseID: 'BuyingGroupView',
    ShowProgressLine: false,
    Pages: [
      {
        Id: 'p1',
        Title: '',
        ShowTitle: false,
        HideNextButton: true,
        HidePrevButton: true,
        Sections: [
          {
            Id: '1',
            Title: 'Group Info',
            ShowTitle: false,
            DividerAfter: true,
            Questions: [
              {
                Id: '1',
                QuestionId: '1',
                QuestionName: 'groupTitle',
                IsRequired: true,
                RenderControl: 'InfoSection',
                RenderControlProps: {
                  resourceKey: 'group-title',
                  defaultValue: 'My Nice Group',
                  bold: 'true',
                },
                SkipNewLine: true,
                Title: '',
                FlexGrow: 1,
              },
              {
                Id: '2',
                QuestionId: '2',
                QuestionName: 'groupFavorite',
                RenderControl: 'Favorite',
                DefaultTextValue: 'true',
                RenderControlProps: {},
                Title: '',
                FlexGrow: 0,
              },
              {
                Id: '3',
                QuestionId: '3',
                QuestionName: 'timeRange',
                RenderControl: 'IconText',
                RenderControlProps: {
                  icon: 'DateRange',
                },
                Title: '',
                SkipNewLine: true,
              },
              {
                Id: '4',
                QuestionId: '4',
                QuestionName: 'groupLocation',
                RenderControl: 'IconText',
                RenderControlProps: {
                  icon: 'LocationOn',
                },
                Title: '',
                SkipNewLine: true,
              },
              {
                Id: '5',
                QuestionId: '5',
                QuestionName: 'priceRange',
                RenderControl: 'IconText',
                RenderControlProps: {
                  icon: 'AttachMoney',
                },
                Title: '',
              },

              {
                Id: '6',
                QuestionId: '6',
                QuestionName: 'editButton',
                IsRequired: true,
                ShowTitle: false,
                RenderControl: 'Slot',
                RenderControlProps: {},
                Title: 'Dumb title',
              },
              {
                Id: '7',
                QuestionId: '7',
                QuestionName: 'groupDescription',
                IsRequired: true,
                RenderControl: 'InfoSection',
                RenderControlProps: {
                  resourceKey: 'group-description',
                  defaultValue:
                    '1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890',
                },
                Title: 'Group Description',
              },
              {
                Id: '8',
                QuestionId: '8',
                QuestionName: 'avatarList',
                IsRequired: true,
                RenderControl: 'AvatarList',
                MaxValue: 6,
                MinValue: 2,
                RenderControlProps: {},
                Title: 'Group Members',
              },
              {
                Id: '9',
                QuestionId: '9',
                QuestionName: 'tagList',
                IsRequired: true,
                RenderControl: 'TagList',
                RenderControlProps: {},
                Title: 'Group Tags',
              },
            ],
          },
          {
            Id: '2',
            Title: 'HouseMate preferences',
            ShowTitle: true,
            Questions: [
              {
                Id: '10',
                QuestionId: '10',
                QuestionName: 'ageRange',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'Age Range',
              },
              {
                Id: '11',
                QuestionId: '11',
                QuestionName: 'gender',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'Gender',
              },
              {
                Id: '12',
                QuestionId: '12',
                QuestionName: 'lgbtFriendly',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'LGBTQ Friendly',
              },
              {
                Id: '13',
                QuestionId: '13',
                QuestionName: 'groupSize',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'Group Size',
              },
            ],
          },
          {
            Id: '3',
            Title: 'Home Lifestyle preferences',
            ShowTitle: true,
            Questions: [
              {
                Id: '14',
                QuestionId: '14',
                QuestionName: 'Cleanlines',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'Cleanlines',
              },
              {
                Id: '15',
                QuestionId: '15',
                QuestionName: 'Drinking',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'Drinking',
              },
              {
                Id: '16',
                QuestionId: '16',
                QuestionName: 'Smoking',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'Smoking',
              },
              {
                Id: '17',
                QuestionId: '17',
                QuestionName: 'Vaping',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'Vaping',
              },
              {
                Id: '18',
                QuestionId: '18',
                QuestionName: 'Pets',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'Pets	',
              },
              {
                Id: '19',
                QuestionId: '19',
                QuestionName: 'Noiselevels',
                IsRequired: true,
                RenderControl: 'ParamValue',
                RenderControlProps: {},
                Title: 'Noise levels',
              },
            ],
            SpacingAfter: 40,
          },
        ],
      },
    ],
  }
  return data
}

export const mockInitialAllPageAnswers: Dictionary<string[]> = {
  '1': [],
  '2': ['true'],
  '3': ['3-6 Months'],
  '4': ['Raleigh'],
  '5': ['1000-1500'],
  '6': [''],
  '7': [''],
  '8': ['hJzzRHQ4N3hpc4xXrE2Q5QFDHw22 h6C0NQvAeSaqmU2UpQcCDCCDh293 bqUxMqKR18ee6cllRb8iHTTKfCp1'],
  '9': ['tag tag tag'],
  '10': ['23-39'],
  '11': ['Male'],
  '12': ['yes'],
  '13': ['3-5'],
  '14': ['average'],
  '15': ['People can do what they want'],
  '16': ['Outdoors only'],
  '17': ['Outdoors only'],
  '18': ['Dogs only'],
  '19': ['Some noise is okay'],
}
