import React from 'react'
import { Box } from '@material-ui/core'
import { PreviewTitle } from '@rse/uikit'
import { getLgbtqFriendlyTitle, getRoommateGenderTitle } from '../../../../common/constants'
import { PreferenceListView } from '../../../../components/common'
import { TextContent } from '../../../../common/textContent'
import { UserProfilePublicEntity } from '@rse/core'

const CmUserPrefs = (member: UserProfilePublicEntity) => {
  const { ageRange, roommateGender, roommates, lgbtqFriendly } = member

  const peoplePreferences = [
    { name: 'Age Range', value: ageRange ? `${ageRange[0]}-${ageRange[1]}` : 'undefined' },
    { name: 'Gender', value: getRoommateGenderTitle(roommateGender) },
    { name: 'LGBTQ Friendly', value: getLgbtqFriendlyTitle(lgbtqFriendly) },
    { name: '# of People', value: roommates ? `${roommates[0]}-${roommates[1]}` : 'undefined' },
  ]

  return (
    <>
      <>
        <PreviewTitle gutterBottom>
          <TextContent resourceKey="profile.view.section.prefs.title" defaultValue="HouseMate preferences" />
        </PreviewTitle>
        <Box mb={1}>
          <PreferenceListView ariaLabel="Home Preferences" rows={peoplePreferences} />
        </Box>
      </>
      <>
        <PreviewTitle gutterBottom>
          <TextContent resourceKey="profile.view.section.ground-rules.title" defaultValue="Home Lifestyle preferences" />
        </PreviewTitle>
        <Box mb={1}>
          <PreferenceListView ariaLabel="Home Preferences" rows={member.activePreferences || []} />
        </Box>
      </>
    </>
  )
}

export default CmUserPrefs
