import React from 'react'
import { Box } from '@material-ui/core'
import { StandardText } from '@rse/uikit'
import { useBuildInfo } from '../../common/hooks'
import { Logout } from '../../components/icons'
import { AppLayout, Header } from '../../components/layout'
import { DashboardBanner } from '../../components/layout/DashboardBanner'

export function UpgradeRequired() {

    return <>
        <Box m="auto" mx={2}>
            <DashboardBanner
                viewBox="0 0 24 24"
                title="Upgrade Required"
                titleKey="upgradeRequired.title"
                subtext="We have built a new member app for you!"
                subtextKey="upgradeRequired.subtext"
                icon={Logout}
            />
            <StandardText align="justify">
                The version you are currently using is no longer supported.
                In order for us to best support you, please visit the Apple
                App Store or Google Play and install the newest version.
            </StandardText>
        </Box>
    </>
}

export function UpgradeRequiredPage() {
    const {buildInfo} = useBuildInfo()
    return <AppLayout
        header={<Header title="Upgrade Required" />}
        body={<UpgradeRequired />}
        footer={<StandardText align="center">Installed version: {buildInfo.version}</StandardText>}
    />
}