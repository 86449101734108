import React, { useContext, useState } from 'react'
import { Container } from '@material-ui/core'
import { UserContext } from '../../App'
import { cleanPhoneValue } from '../../common/utils/validation'
import { useSmsVerify } from '../../common/hooks'
import { AppLayout, CenteredBox, SignUpHeader } from '../../components/layout'
import { PhoneVerifiedContent } from '../Dashboard/components/signupStepViews'
import { EnterPhoneContent, VerifyNumberContent } from '../Settings/ChangePhonePage'

export default function SMSVerifyPage() {
  const user = useContext(UserContext)!

  const [phoneNum, setPhoneNum] = useState('')
  const [inputCode, setInputCode] = useState('')

  const { statusPending, smsVerified, verificationError, codeSent, invalidCodeSent, sendCode, verifyCode } = useSmsVerify()

  function onPhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    const input = e.target.value
    const cleaned = cleanPhoneValue(input)
    setPhoneNum(cleaned)
  }

  const verificationComplete = smsVerified || user!.isPhoneVerified

  return (
    <AppLayout
      header={<SignUpHeader />}
      body={
        <CenteredBox textAlign="center">
          <Container maxWidth="xs">
            {verificationComplete && <PhoneVerifiedContent />}
            {!verificationComplete && !codeSent && (
              <EnterPhoneContent
                title="Verify your phone"
                titleKey="smsVerification.send-text-title"
                subtext="Enter your phone number for text verification. Your plan's standard text rates will apply."
                subtextKey="smsVerification.send-text-subtext"
                phoneNum={phoneNum}
                onPhoneChange={onPhoneChange}
                sendCode={sendCode}
                statusPending={statusPending}
              />
            )}
            {!verificationComplete && codeSent && (
              <VerifyNumberContent
                titleKey="smsVerification.title"
                subtextKey="smsVerification.subtext"
                inputCode={inputCode}
                setInputCode={setInputCode}
                verificationError={verificationError}
                verifyCode={verifyCode}
                statusPending={statusPending}
                invalidCodeSent={invalidCodeSent}
              />
            )}
          </Container>
        </CenteredBox>
      }
    />
  )
}
