import React, { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { TextContent } from '../../common/textContent'
import { CenteredBox } from '.'
import { ButtonLarge, MiniHeader, PageTitle } from '@rse/uikit'
import DashIcon from '../../pages/Dashboard/components/DashIcon'

interface IDashboardBanner {
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  viewBox?: string
  title: string
  titleKey: string
  subtext?: string
  subtextKey?: string | undefined
  progress?: number
  children?: ReactNode
  linkURL?: string
  linkTitle?: string
  linkTitleKey?: string
}

export function DashboardBanner(props: IDashboardBanner) {
  const { title, titleKey, subtext, subtextKey, children, linkURL, linkTitle, linkTitleKey, icon, viewBox } = props

  return (
    <CenteredBox textAlign="center" flexGrow={0}>
      <CenteredBox mb={4}>
        {icon && viewBox && <DashIcon component={icon} viewBox={viewBox} />}
        <PageTitle gutterBottom>
          <TextContent resourceKey={titleKey} defaultValue={title} />
        </PageTitle>
        {subtext && subtextKey && (
          <MiniHeader>
            <TextContent resourceKey={subtextKey} defaultValue={subtext} />
          </MiniHeader>
        )}
        {linkURL && linkTitle && linkTitleKey && (
          <Box py={2} width="100%">
            <NavLink to={linkURL}>
              <ButtonLarge fullWidth>
                <TextContent resourceKey={linkTitleKey} defaultValue={linkTitle} />
              </ButtonLarge>
            </NavLink>
          </Box>
        )}
      </CenteredBox>
      {children}
    </CenteredBox>
  )
}
