import { Box, Chip } from '@material-ui/core'
import React from 'react'
import { ChatThreadSectionType } from '../../../../models'
import { ChatMessageDividerNew } from './ChatDividerLine'

type Props = {
  sectionType: ChatThreadSectionType
  sectionTitle?: string
}

export const ChatMessageSet: React.FC<Props> = ({ sectionTitle: sectionName, sectionType, children }) => {
  const div =
    sectionType === 'date' ? <Chip label={sectionName} variant="outlined" size="small" /> : <ChatMessageDividerNew />

  return (
    <div>
      <Box textAlign="center" mt={2} mb="6px">
        {div}
      </Box>
      <div>{children}</div>
    </div>
  )
}
