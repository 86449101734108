import { Box, styled } from '@material-ui/core'
import React from 'react'

const color = '#F44336'
export const ChatMessageDividerNew: React.VFC = () => {
  return (
    <Box display="flex" alignItems="center">
      <Box bgcolor={color} flexGrow={1} height="1px" width="100%" />
      <TextNew ml={1} my={0.5}>
        New
      </TextNew>
    </Box>
  )
}

const TextNew = styled(Box)({
  fontWeight: 'bold',
  fontSize: '12px',
  lineHeight: '14px',
  color: color,
})
