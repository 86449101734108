import React from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import { FormValues } from './formAssets'
import { Box, createStyles, makeStyles } from '@material-ui/core'

type Props = {
  formik: {
    errors: FormikErrors<FormValues>
    touched: FormikTouched<FormValues>
  }
  field: keyof FormValues
  index: number
}

const useStyles = makeStyles(() =>
  createStyles({
    validationErrors: {
      color: 'red',
    },
  })
)

export function FormikErrorArrayField({ formik: { errors, touched }, field, index }: Props) {
  const classes = useStyles()
  return (
    <>
      {touched[field] &&
      Array.isArray(touched[field]) &&
      (touched[field] as any)[index] &&
      errors[field] &&
      Array.isArray(errors[field]) &&
      (errors[field] as any)[index] ? (
        <Box className={classes.validationErrors}>{(errors[field] as any)[index]}</Box>
      ) : null}
    </>
  )
}
