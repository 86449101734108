import { Box } from '@material-ui/core'
import React from 'react'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { MiniHeader, SectionHeader, Spacing } from '@rse/uikit'
import { InputText } from '../components/InputText'
import { useFormData } from '../helpers/useFormData'

export const PreApprovalCurrentPayments: React.VFC = () => {
  const { formData } = useFormData()
  const textContent = useTextContent()

  const total = [formData.payments0, formData.payments1, formData.payments2]
    .map((num) => parseInt(num || '0'))
    .reduce((sum, current) => sum + current)

  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent
          resourceKey="app-wizard.title-monthly-recurring-payments"
          defaultValue="Monthly Recurring Payments"
        />
      </SectionHeader>
      <Spacing value={20} />
      <InputText
        id="payments0"
        label={getTextContent(textContent, 'app-wizard.field-credit-cards', 'Credit Cards $ *')}
        type="number"
      />
      <Spacing value={20} />
      <InputText
        id="payments1"
        label={getTextContent(textContent, 'app-wizard.field-car-payment', 'Car Payment $ *')}
        type="number"
      />
      <Spacing value={20} />
      <InputText id="payments2" label={getTextContent(textContent, 'app-wizard.field-other', 'Other')} type="number" />
      <Spacing value={20} />
      <Box display="flex" justifyContent="space-between">
        <MiniHeader>
          <TextContent resourceKey="app-wizard.recurring-payments-total" defaultValue="Total" />
        </MiniHeader>
        <MiniHeader>${total}</MiniHeader>
      </Box>
    </section>
  )
}
