import { useApiPrefix, useCurrentUserId } from '../../common/hooks'
import { useApiFetch } from '@rse/frontend-dal'
import { firebaseInstance, useUserMessageThread } from '../../firebase'
import { useEffect, useState } from 'react'
import { UserMessageThread } from '@rse/core'

export function useMessageThread(threadId: string) {
  const [isApiLoading, setIsApiLoading] = useState(false)
  const [userThread, setUserThread] = useState<UserMessageThread | undefined>(undefined)
  const currentUserId = useCurrentUserId()

  const { document: { value: thread, loading: threadLoading, error: threadError } } = useUserMessageThread({ uid: currentUserId, threadId })

  const chatApiPrefix = useApiPrefix('chat')
  const sendMessageApi = useApiFetch(`${chatApiPrefix}/thread/${threadId}/messages`, true, 'POST', true, firebaseInstance.auth)
  const consentToThreadApi = useApiFetch(`${chatApiPrefix}/thread/${threadId}/consent`, true, 'POST', true, firebaseInstance.auth)
  const consentToGroupApi = useApiFetch(`${chatApiPrefix}/thread/${threadId}/consentToGroup`, true, 'POST', true, firebaseInstance.auth)  
  const leaveThreadApi = useApiFetch(`${chatApiPrefix}/thread/${threadId}`, true, 'DELETE', true, firebaseInstance.auth)

  useEffect(() => {
    setUserThread(thread)
  }, [thread, threadId])


  async function consent(consent: boolean) {
    return consentToThreadApi.callApi({ consent })
  }

  // TODO make sure this only runs on group courtship threads
  async function consentToGroup(consent: boolean) {
    return consentToGroupApi.callApi({ consent })
  }

  async function sendMessage(message: string) {
    if (userThread?.selfConsentsToMessage) {
      await sendMessageApi.callApi({ message })
      return
    }

    const consentPromise = consent(true)
    const sendPromise = sendMessageApi.callApi({ message })
    await Promise.all([consentPromise, sendPromise])
  }

  async function leaveThread() {
    setIsApiLoading(true)
    await leaveThreadApi.callApi({})
    setIsApiLoading(false)
  }


  return { sendMessage, consent, consentToGroup, leaveThread, userThread, isApiLoading, threadLoading }
}