import React from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Fade, IconButton, List, SvgIcon } from '@material-ui/core'
import { ClubMemberCard } from './ClubMemberListCard'
import { useCurrentUserId, useQueryFromLocation, useErrorHandler } from '../../../common/hooks'
import { LoadingSpinner } from '@rse/uikit'
import { AppLayout, Header, Footer } from '../../../components/layout'
import { MemberGroupsNavToggle } from '../../../components/inputs/MemberGroupsNavToggle'
import { useClubMembersFiltered } from '../hooks'
import { Favorite, FavoriteBorderOutlined } from '@material-ui/icons'
import { FilterIcon } from '../../../components/icons'
import { AppRoutes } from '../../../App/routeUtils'
import { useInView } from 'react-intersection-observer'

const ClubMemberList = () => {
  const query = useQueryFromLocation()
  const favoritesOnly = query.get('fav') === 'true'
  const userId = useCurrentUserId()
  const { errorHandler } = useErrorHandler('loading member list')
  const { loading, error, value: members, loadNextData } = useClubMembersFiltered(userId, favoritesOnly, 20, true)
  const history = useHistory()



  const loadData = (inView: boolean, entry: IntersectionObserverEntry) => {
    if (inView) {
      loadNextData()
    }
  }

  const { ref, inView } = useInView({
    threshold: 0, onChange: loadData
  })


  React.useEffect(() => {
    if (error) errorHandler(error)
  }, [error])

  const header = (
    <Header title="Connect">
      {/* <IconButton color="inherit" className="preferences" onClick={() => undefined}>
        <SvgIcon component={FilterIcon} viewBox="0 0 17 18" />
      </IconButton> */}
      <IconButton
        color="inherit"
        className="favorite"
        onClick={() => history.push(`${AppRoutes.clubMemberRoot()}?fav=${favoritesOnly ? 'false' : 'true'}`)}
      >
        {favoritesOnly ? <Favorite /> : <FavoriteBorderOutlined />}
      </IconButton>
    </Header>
  )

  return (
    <AppLayout
      header={header}
      body={
        <Container maxWidth="xs">
          <MemberGroupsNavToggle />
          <Fade in={members != null}>
            <List aria-label="Member List" component="nav" style={{ position: 'relative', top: '48px' }}>
              {members?.map((member) => (
                <ClubMemberCard key={member.id} member={member} />
              ))}
              {loading && <LoadingSpinner height={100} />}
              <div id="scrollLoadTriggerSensor" ref={ref}></div>
            </List>
          </Fade>
        </Container>
      }
      footer={<Footer />}
    />
  )
}

export default ClubMemberList
