import React from 'react'
import { format } from 'date-fns'
import parsePhoneNumber from 'libphonenumber-js'

import { getClubMemberGenderTitle } from '../../../common/constants'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { dollars } from '../../../common/utils'
import { BoxSummaryItem } from '../components/BoxSummaryItem'
import { Spacing } from '@rse/uikit'
import { TextHeading } from '../components/TextHeading'
import { useFormData } from '../helpers/useFormData'

export const SummaryProfile: React.VFC = () => {
  const textContent = useTextContent()

  const { formData } = useFormData()

  // FIXME this was a hack to workaround bug 3572
  const birthday = ((formData.birthday) instanceof Date) ? formData.birthday : false

  return (
    <section>
      <Spacing value={28} />
      <TextHeading>
        <TextContent resourceKey="app-wizard.title-summary-profile" defaultValue="Profile" />
      </TextHeading>
      <Spacing value={11} />
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-summary-legal-name', 'Legal Name')}>
        {formData.firstName} {formData.lastName}
      </BoxSummaryItem>
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-summary-birthday', 'Birthday')}>
        {birthday && format(birthday, 'PP') || 'Error loading birthday, please go back and enter it again.'}
      </BoxSummaryItem>
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-summary-phone', 'Phone Number')}>
        {parsePhoneNumber(formData.mobilePhone || '')?.formatInternational()}
      </BoxSummaryItem>
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-summary-gender', 'Gender')}>
        {getClubMemberGenderTitle(formData.sex, formData.gender)}
      </BoxSummaryItem>
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-summary-move-in-date', 'Move-In Date')}>
        {formData.timeRange}
      </BoxSummaryItem>
      <BoxSummaryItem label={getTextContent(textContent, 'app-wizard.field-summary-current-rent', 'Current Rent')}>
        {dollars(formData.currentRent)}
      </BoxSummaryItem>
      <BoxSummaryItem
        label={getTextContent(textContent, 'app-wizard.field-summary-monthly-payment', 'Comfortable Monthly Payment')}
      >
        {formData.priceRange ? `${dollars(formData.priceRange[0])} - ${dollars(formData.priceRange[1])}` : 'unknown'}
      </BoxSummaryItem>
    </section>
  )
}
