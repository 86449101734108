import { UserProfilePublicEntity } from '@rse/core'
import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'

import { AppRoutes } from '../../App/routeUtils'
import { ClubMemberDetails } from './ClubMemberDetails'
import { ClubMemberEdit } from './ClubMemberEdit'
import { ClubMemberList } from './ClubMemberList'
import { selectedPublicProfileContext } from './selectedPublicProfileContext'


export default function ClubMembersPage() {
  const [selectedPublicProfile, setSelectedPublicProfile] = useState<UserProfilePublicEntity | undefined>(undefined)
  const [loadValueFromContext, setLoadValueFromContext] = useState<boolean>(false)

  return (

    <selectedPublicProfileContext.Provider value={{ selectedPublicProfile, setSelectedPublicProfile, loadValueFromContext, setLoadValueFromContext }}>
      <Switch>
        <Route path={AppRoutes.clubMemberRoot()} exact>
          <ClubMemberList />
        </Route>
        <Route path={AppRoutes.clubMemberEdit(':id')}>
          <ClubMemberEdit />
        </Route>
        <Route path={AppRoutes.clubMember(':id')}>
          <ClubMemberDetails />
        </Route>
      </Switch>
    </selectedPublicProfileContext.Provider>


  )
}
