import React, { useState, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Menu, ButtonBase, makeStyles, createStyles, Box, Typography } from '@material-ui/core'
import {
  URL_EQUITY_CALC,
  URL_PRIVACY_POLICY,
  URL_SANDBOX,
  URL_TERMS_OF_SERVICE,
} from '../../../../common/constants/routes'
import { UserContext } from '../../../../App'
import { AppRoutes } from '../../../../App/routeUtils'
import { UserAvatarPublic } from '../../../common'
import { TextContent } from '../../../../common/textContent'
import { ProfileMenuLink } from './ProfileMenuLink'
import MyProfileIcon from '@material-ui/icons/Person'
import SettingsIcon from '@material-ui/icons/Settings'
import { ReactComponent as CalculatorIcon } from './assets/calc.svg'
import { ReactComponent as SandboxIcon } from './assets/sandbox.svg'
import { LogoutButton } from '../../../inputs'

const useMenuItems = () => {
  const user = useContext(UserContext),
    profileRoute = user ? AppRoutes.clubMember(user?.id) : '/'

  const adminRole = user?.roles.find(r => r === 'member')

  const menuData = [
    {
      url: profileRoute,
      label: 'My profile',
      labelResource: 'menu.my-account',
      divider: true,
      icon: <MyProfileIcon />,
    },
    { url: AppRoutes.settings(), label: 'Settings', labelResource: 'menu.settings', icon: <SettingsIcon /> },
    {
      url: URL_EQUITY_CALC,
      label: 'Equity calculator',
      labelResource: 'menu.equity-calculator',
      icon: <CalculatorIcon />,
    }
  ]

  if (user?.roles.some(r => r === 'admin')) {
    menuData.push(
      {
        url: URL_SANDBOX,
        label: 'Sandbox',
        labelResource: 'menu.sandbox',
        icon: <SandboxIcon />,
      }
    )
  }

  return menuData
}

interface iProfileMenu {
  uid: string
}

export default function ProfileMenu({ uid }: iProfileMenu) {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const styles = useStyles()
  const menuItems = useMenuItems()

  const handleClick = (event: React.SyntheticEvent<Element>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ButtonBase aria-controls="User Profile Menu" aria-haspopup="true" onClick={handleClick}>
        <UserAvatarPublic uid={uid} bordered={true} />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={styles.menu}
      >
        <Box px="15px" width={300}>
          {menuItems.map((item) => (
            <ProfileMenuLink
              url={item.url}
              key={item.url}
              label={item.label}
              divider={item.divider}
              handler={handleClose}
              icon={item.icon}
            />
          ))}
          <Box mb={0}>
            <LogoutButton />
          </Box>
          <Box display="flex" justifyContent="space-around" py="15px">
            <NavLink to={URL_PRIVACY_POLICY}>
              <Typography variant="caption" color="textSecondary">
                <TextContent resourceKey="menu.privacy-policy" defaultValue="Privacy policy" />
              </Typography>
            </NavLink>
            <NavLink to={URL_TERMS_OF_SERVICE}>
              <Typography variant="caption" color="textSecondary">
                <TextContent resourceKey="menu.terms-of-service" defaultValue="Terms & Conditions" />
              </Typography>
            </NavLink>
          </Box>
        </Box>
      </Menu>
    </>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      '& .MuiMenu-paper': {
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
      },
      '& .MuiMenuItem-root': {
        minHeight: '39px',
      },
      '& .MuiListItem-gutters': {
        margin: '0 -15px',
      },
    },
  })
)
