import { firebaseCollectionNames } from '../../firebase/firebaseConstants'
import { usePersistentCollection, PersistentCollectionFilter, PersistentCollectionOrderClause } from '../../firebase/hooks/usePersistentCollection'

// TODO create reusable hook for lookup items for other collections
type LookupItem = {
  label: string
  value: string
  sortOrder?: number
}

const orderBy: PersistentCollectionOrderClause[] = [
  {
    fieldPath: 'label',
    directionStr: 'asc',
  }
]

// TODO consider filters for active/inactive locations
export function useLocations(filters?: Array<PersistentCollectionFilter>) {
  const { collection, commonHandlers } = usePersistentCollection<LookupItem>(
    firebaseCollectionNames.locations,
    filters,
    orderBy
    )
  return { collection, handlers: commonHandlers }
}