import { useEffect, useMemo, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { firebaseInstance } from '../firebaseInstance'


export interface CollectionFilter {
    fieldPath: string | firebase.firestore.FieldPath
    opStr: firebase.firestore.WhereFilterOp
    value: any
}


export interface CollectionOrderClause {
    fieldPath: string | firebase.firestore.FieldPath
    directionStr: firebase.firestore.OrderByDirection
}



export function useCollectionPaged<T>(
    collectionPath: string,
    pageSize = 20,
    usePaging?: boolean,
    autoLoad = false,
    filters?: CollectionFilter[],
    orderBy?: CollectionOrderClause[],    
    transform?: (val: any) => T,
    onDataLoadCompelted?: () => void,) {

    let isSubscribed = true
    const [currentData, setCurrentData] = useState<Array<T>>([])
    const [data, setData] = useState<Array<T>>([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAllDataLoaded, setIsAllDataLoaded] = useState(false)
    const [isReloadNeeded, setIsReloadNeeded] = useState(false)
    const [isFirstTimeLoaded, setIsFirstTimeLoaded] = useState<boolean>(false)
    const [lastDocumentRead, setLastDocumentRead] = useState<firebase.firestore.DocumentData | undefined>(undefined)

    const collectionRef = useMemo(
        () => {
            return firebaseInstance.db.collection(collectionPath)
        }, [collectionPath]
    )

    const filteredCollectionRef = useMemo(() => {
        let result: firebase.firestore.Query<firebase.firestore.DocumentData> = collectionRef
        const idFieldPath = firebase.firestore.FieldPath.documentId()

        if (filters) {
            filters.forEach(({ fieldPath, opStr, value }) => {
                //For the 'in' clause we need to perform batching of 10 items per batch
                //We will handle this filter separately
                if (opStr != 'in') {
                    result = result.where(fieldPath === 'id' ? idFieldPath : fieldPath, opStr, value)
                } else {
                    if (value.length <= 10) {
                        result = result.where(fieldPath === 'id' ? idFieldPath : fieldPath, opStr, value)
                    }
                }
            })
        }

        if (orderBy && orderBy.length > 0) {
            orderBy.forEach(({ fieldPath, directionStr }) => {
                result = result.orderBy(fieldPath === 'id' ? idFieldPath : fieldPath, directionStr)
            })
        }

        const pageSizeValue: number = pageSize ? pageSize : 20

        if (usePaging) {
            result = result.limit(pageSizeValue)
        }

        return result
    }, [collectionRef, filters, orderBy, pageSize, usePaging])

    async function loadData() {
        if (isAllDataLoaded) {
            return
        }

        setIsLoading(true)
        if (!isSubscribed) {
            setIsLoading(false)
            return
        }

        let filtersRequiringBatching: CollectionFilter[] = []
        if (filters) {
            filtersRequiringBatching = filters.filter(singleFilter => singleFilter.opStr == 'in' && singleFilter.value.length > 10)
        }

        if (filtersRequiringBatching && filtersRequiringBatching.length > 0 && usePaging) {
            if (usePaging) {
                throw 'Paging is not supported alongside batched queries.'
            }
        }

        let pagedFilteredCollectionRef = filteredCollectionRef
        if (lastDocumentRead && usePaging) {
            pagedFilteredCollectionRef = filteredCollectionRef.startAfter(lastDocumentRead)
        }

        const queryBatches: Promise<firebase.firestore.QuerySnapshot>[] = []

        if (!filtersRequiringBatching || filtersRequiringBatching.length === 0) {
            const queryBatch = pagedFilteredCollectionRef.get()
            queryBatches.push(queryBatch)
        } else {
            filtersRequiringBatching.forEach((inFilter) => {
                const filterClonedValues = [...inFilter.value]
                while (filterClonedValues.length) {
                    const filterBatch = filterClonedValues.splice(0, 10) // firestore limits batches to 10          
                    const queryBatch = collectionRef.where(inFilter.fieldPath, 'in', filterBatch).get()
                    queryBatches.push(queryBatch)
                }
            })
        }


        const collectionQueryResult = await Promise.all(queryBatches)
        let lastQueryResult: firebase.firestore.QuerySnapshot = collectionQueryResult[0]
        let lastQueryResultDocumentRead: firebase.firestore.DocumentData | undefined = undefined
        collectionQueryResult.forEach((queryResult) => {
            if (queryResult && queryResult.size > 0) {
                lastQueryResult = queryResult
                lastQueryResultDocumentRead = lastQueryResult.docs[lastQueryResult.docs.length - 1]
            }
        })

        if (!lastQueryResultDocumentRead) {
            if (isSubscribed) {
                setIsReloadNeeded(false)
                setIsFirstTimeLoaded(true)
                setCurrentData([])
                setIsLoading(false)
                setIsAllDataLoaded(true)
                if(onDataLoadCompelted){
                    onDataLoadCompelted()
                }
            }
            return
        }

        setLastDocumentRead(lastQueryResultDocumentRead)

        const currentPageData: Array<T> = []

        collectionQueryResult.forEach((queryResult) => {
            const batchResult = queryResult.docs.map((doc) => {
                const docData = doc.data()
                docData.id = doc.id
                const dataItem = transform ? transform(docData) : <T>{ ...docData }                            
                return dataItem
            })

            currentPageData.push(...batchResult)
        })

        if (isSubscribed) {
            setIsReloadNeeded(false)
            setIsFirstTimeLoaded(true)
            setCurrentData(currentPageData)
            setData(data.concat(currentPageData))
            setIsLoading(false)
            if ((currentPageData.length + 1 < pageSize) || !usePaging) {
                setIsAllDataLoaded(true)
            }
        
            if(onDataLoadCompelted){
                onDataLoadCompelted()
            }
        }
    }



    useEffect(() => {
        if (isReloadNeeded && autoLoad) {
            loadData()
        }
    }, [isReloadNeeded])


    useEffect(() => {
        setCurrentData([])
        setData([])
        setIsLoading(false)
        setIsFirstTimeLoaded(false)
        setLastDocumentRead(undefined)
        setIsAllDataLoaded(false)
        setIsReloadNeeded(true)
    }, [collectionPath, pageSize, usePaging, filters, orderBy])

    useEffect(() => {
        if (autoLoad) {
            loadData()
        }

        return () => {
            isSubscribed = false
        }
    }, [])

    return { data, currentData, isLoading, isAllDataLoaded, loadData }


}

