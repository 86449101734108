import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  List,
  ListItem,
  makeStyles,
  createStyles,
  Theme,
  Divider,
  Box,
  ClickAwayListener,
  IconButton,
} from '@material-ui/core'
import { UserMessageThread } from '@rse/core'

import { FilterIcon } from '../../../components/icons'
import ChatLinkCard from './ChatLinkCard'
import { useCurrentUserId } from '../../../common/hooks'
import { useUserMessageThreads } from '../../../firebase'
import { UserOnlineStatus } from '../../../models'
import { FilterListOption, MessageFilter } from '../../../components/common'
import { LoadingSpinner } from '@rse/uikit'
import { IconForum, IconFamilyHouse, IconAutorenew, IconPerson } from '../../../components/icons'
import { AppLayout, Header, Footer } from '../../../components/layout'
import { BodyContainer } from '../../../components/layout/BodyContainer'
import { getTextContent, TextContentContext } from '../../../common/textContent'

const useStyles = makeStyles(({ palette, shape }: Theme) =>
  createStyles({
    listItem: {
      backgroundColor: palette.background.paper,
      borderRadius: shape.borderRadius,
      marginBottom: 2,
    },
    iconPersonStyle: {
      fill: palette.primary.main,
      viewBox: 'inherit'
    }
  })
)

export default function ChatList() {
  const uid = useCurrentUserId()
  const { listItem, iconPersonStyle } = useStyles()
  const { value: messageThreads, loading } = useUserMessageThreads(uid)
  const [messageThreadsFiltered, setMessageThreadsFiltered] = useState<UserMessageThread[]>([])
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const textContent = useContext(TextContentContext)

  const StyledIconPerson = () => <IconPerson className={iconPersonStyle} />

  const initialFilterMessageOptions: FilterListOption[] = [
    {
      title: 'All chats',
      icons: [IconForum],
      checked: true,
    },
    {
      title: 'Private Buying Group chats',
      icons: [IconFamilyHouse],
      checked: false,
    },
    {
      title: 'Exploration chats',
      icons: [StyledIconPerson, IconAutorenew, IconFamilyHouse],
      checked: false,
    },
    {
      title: 'Private chats',
      icons: [StyledIconPerson],
      checked: false,
    },
    {
      title: 'Message requests',
      icons: [StyledIconPerson],
      checked: false,
    },
  ]
  const [filterMessageOptions, setFilterListOptions] = useState<FilterListOption[]>(initialFilterMessageOptions)

  const toggleFilter = () => setFilterOpen((state) => !state)

  const onFilterChange = (index: number, checked: boolean) => {
    setFilterListOptions((options) => {
      const newOptions = [...options]

      newOptions[index].checked = checked

      if (index === 0 && checked) {
        for (let i = 1; i < newOptions.length; i++) {
          newOptions[i].checked = false
        }
      } else if (index > 0 && checked) {
        newOptions[0].checked = false
      }

      return newOptions
    })
  }

  const showAllChats = filterMessageOptions[0].checked
  const showGroupChats = filterMessageOptions[1].checked
  const showExplorationChats = filterMessageOptions[2].checked
  const showUserToUserChats = filterMessageOptions[3].checked
  const showMessageRequests = filterMessageOptions[4].checked

  useEffect(() => {
    if (loading || !messageThreads || messageThreads.length === 0) {
      return
    }

    const filteredThreads = messageThreads.filter(
      ({ type, selfConsentsToMessage, hasLeftThread }) =>
        hasLeftThread != true && (
          showAllChats ||
          (showMessageRequests && !selfConsentsToMessage) ||
          (selfConsentsToMessage &&
            (
              (type === 'UserToUser' && showUserToUserChats) ||
              (type === 'Group' && showGroupChats) ||
              (type === 'GroupCourtship' && showExplorationChats)
            )
          ))
    )

    const privateThread = filteredThreads.find((thread) => thread.type === 'Group')

    setMessageThreadsFiltered(
      privateThread
        ? [privateThread, ...filteredThreads.filter((thread) => thread !== privateThread)]
        : [...filteredThreads]
    )
  }, [messageThreads, filterMessageOptions])

  if (loading) {
    return <BodyContainer><LoadingSpinner /></BodyContainer>
  }

  if (!messageThreadsFiltered) {
    return null
  }

  return (
    <AppLayout
      header={
        <Header title={getTextContent(textContent, 'chats.page.header.title', 'Message')}>
          <ClickAwayListener onClickAway={() => setFilterOpen(false)}>
            <Box display="flex" justifyContent="flex-end" position="relative">
              <IconButton color="inherit" onClick={toggleFilter}>
                <FilterIcon />
              </IconButton>
              {filterOpen && (
                <MessageFilter top={49} right={-49} options={filterMessageOptions} onChange={onFilterChange} />
              )}
            </Box>
          </ClickAwayListener>
        </Header>
      }
      body={
        <BodyContainer>
          <List disablePadding>
            {messageThreadsFiltered.map(
              (
                {
                  id,
                  title,
                  type,
                  lastMessageId,
                  lastMessageDisplayName,
                  lastMessageMessage,
                  lastMessageSentDate,
                  unreadMessagesCount,
                  peerId,
                  groupId
                },
                index
              ) => {
                const userStatus: UserOnlineStatus = index !== 0 ? UserOnlineStatus.Online : UserOnlineStatus.Offline // TODO: fetch from DB

                return (
                  <ListItem key={index} disableGutters className={listItem}>
                    <Link to={`/chat/${id}`} style={{ width: '100%' }}>
                      <ChatLinkCard
                        threadId={id}
                        title={title}
                        type={type}
                        isPinned={type === 'Group'}
                        userStatus={userStatus}
                        lastMessageId={lastMessageId}
                        lastMessageDisplayName={lastMessageDisplayName}
                        lastMessageMessage={lastMessageMessage}
                        lastMessageSentDate={lastMessageSentDate}
                        unreadMessagesCount={unreadMessagesCount}
                        peerId={peerId}
                        groupId={groupId}
                      />
                      <Divider />
                    </Link>
                  </ListItem>
                )
              }
            )}
          </List>
        </BodyContainer>
      }
      footer={<Footer />}
    />
  )
}
