import React from 'react'
import { Spacing } from '@rse/uikit'
import { TextHeading } from '../components/TextHeading'
import { PrefsMateAgeRange } from '../fieldsets/PrefsMateAgeRange'
import { PrefsGroundRules } from '../fieldsets/PrefsGroundRules'
import { PrefsMateGender } from '../fieldsets/PrefsMateGender'
import { PrefsMatesCount } from '../fieldsets/PrefsMatesCount'
import { TextContent } from '../../../common/textContent'

export const StepPreferences: React.FC = () => {
  return (
    <div>
      <Spacing value={28} />
      <TextHeading>People</TextHeading>
      <Spacing value={11} />
      <PrefsMatesCount />
      <PrefsMateAgeRange />
      <PrefsMateGender />
      <TextHeading>
        <TextContent resourceKey="app-wizard.section.ground-rules.title" defaultValue="Home Lifestyle preferences" />
      </TextHeading>
      <PrefsGroundRules />
    </div>
  )
}
