import React from 'react'
import { RadioGroup, Radio, Typography, makeStyles, createStyles, Theme } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorText } from '@rse/uikit'

const useStyles = makeStyles(({ palette, shape, spacing, shadows }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
      '& .MuiIconButton-root': {
        borderRadius: shape.borderRadius,
        backgroundColor: palette.primary.main,
        transition: 'background-color .1s',
        color: palette.common.white,
        width: 105,
        height: 105,
        boxShadow: shadows[2],
        '@media (hover: hover)': {
          '&:hover': {
            backgroundColor: palette.primary.light,
            transition: 'background-color .1s',
          },
        },
        '& .MuiIconButton-label': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
        '&.Mui-checked': {
          backgroundColor: palette.primary.dark,
          transition: 'background-color .1s',
        },
      },
    },
  })
)

const femaleIcon = (
  <>
    <Typography variant="h2" component="span" gutterBottom>
      &#9792;
    </Typography>
    <Typography variant="button">Female</Typography>
  </>
)

const maleIcon = (
  <>
    <Typography variant="h2" component="span" gutterBottom>
      &#9794;
    </Typography>
    <Typography variant="button">Male</Typography>
  </>
)

export type formGenderRoommatesProps = {
  roommateGender: string
  lgbtqFriendly: boolean
}
export type formGenderProps = {
  sex: string
  gender: string
}
type GenderProps = {
  name: keyof (formGenderProps & formGenderRoommatesProps)
  title: string
  nonbinary?: {
    label: string
    value: string
  }
}
export function GenderButtons(props: GenderProps): React.ReactElement {
  const styles = useStyles()
  const { control, errors } = useFormContext<formGenderProps & formGenderRoommatesProps>()
  const { title, name } = props

  const nonbinaryLabel = props.nonbinary?.label || 'Nonbinary'
  const nonbinaryValue = props.nonbinary?.value || 'nonbinary'

  const nonbinaryIcon = (
    <>
      <Typography variant="h2" component="span" gutterBottom>
        &#9893;
      </Typography>
      <Typography variant="button">{nonbinaryLabel}</Typography>
    </>
  )

  return (
    <>
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        defaultValue={''}
        render={({ onChange, onBlur, value }) => (
          <RadioGroup
            name={name}
            className={styles.root}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            aria-label={title}
          >
            <Radio value="female" icon={femaleIcon} checkedIcon={femaleIcon} />
            <Radio value="male" icon={maleIcon} checkedIcon={maleIcon} />
            <Radio value={nonbinaryValue} icon={nonbinaryIcon} checkedIcon={nonbinaryIcon} />
          </RadioGroup>
        )}
      />
      {errors[name] && <ErrorText>Please select one</ErrorText>}
    </>
  )
}
