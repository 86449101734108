import React from 'react'
import { Box, styled, Typography } from '@material-ui/core'
import { Chip } from '@rse/uikit'
import { QuestionChoice } from '@rse/core'

export type RadioGroupOption = QuestionChoice & {
  value: string
}

type Props = {
  title: string
  name: string
  value: string
  options: RadioGroupOption[]
  onChange: (value: string) => void
  readOnly?: boolean
}

export function RadioGroup({ title, value: groupValue, options, name, onChange, readOnly = false }: Props) {
  return (
    <Box>
      <Box display="none">
        {options.map(({ value }, index) => (
          <input key={index} type="radio" name={name} value={value} defaultChecked={value === groupValue} />
        ))}
      </Box>
      <Box mb={2}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <GroupItem>
        {options.map((option, index) => (
          <Box key={index} onClick={() => onChange(option.value)}>
            <Chip option={option} checked={option.value === groupValue} readOnly={readOnly} />
          </Box>
        ))}
      </GroupItem>
    </Box>
  )
}

const GroupItem = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.8em',
})
