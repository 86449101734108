import { useMemo } from 'react'
import { UserMessageThread } from '@rse/core'

import { firebaseInstance } from '../firebaseInstance'
import { firebaseCollectionNames } from '../firebaseConstants'
import { usePersistentCollection } from './usePersistentCollection'
import { PersistentCollectionFilter } from '.'

const transform = (doc: any) => {
  return {
    ...doc,
    lastMessageSentDate: doc.lastMessageSentDate?.toDate(),
  }
}

export function useUserMessageThreads(uid: string) {
  const collectionRef = firebaseInstance.db.collection(firebaseCollectionNames.userProfiles)
    .doc(uid)
    .collection(firebaseCollectionNames.messageThreads)



  const filters: PersistentCollectionFilter[] = [{ fieldPath: "isArchived", opStr: '!=', value: true }]
  const { collection } = usePersistentCollection<UserMessageThread>(collectionRef, filters, undefined, transform)

  return collection
}
