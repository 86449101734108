import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AppRoutes } from '../../../../App/routeUtils'
import { IconPencil } from '../../../../components/icons'
import { ButtonFullWidthSecondary } from '@rse/uikit'

export const CmUserProfileEditButton = () => {
  const { id } = useParams<{ id?: string }>()
  const clubMemberUrl = id && AppRoutes.clubMemberEdit(id)

  const history = useHistory()

  const redirectToClubMember = () => {
    if (clubMemberUrl) {
      history.push(clubMemberUrl)
    }
  }

  return (
    <ButtonFullWidthSecondary onClick={redirectToClubMember}>
      <IconPencil />
      &nbsp;&nbsp;
      <span>Edit Profile</span>
    </ButtonFullWidthSecondary>
  )
}
