import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { AppLayout, CenteredBox, Header } from '../../components/layout'
import { ButtonLarge } from '@rse/uikit'
import { ReactComponent as NotFoundImg } from './404.svg'

function Body() {
  const location = useLocation()
  // TODO prevent display of back button if there is no history
  const history = useHistory()
  const { pathname, search, hash } = location
  const url = `${pathname}${search && search}${hash && hash}`
  console.log('404 NotFoundPage', location, url)

  return (
    <Container maxWidth="xs">
      <CenteredBox py={2}>
        <NotFoundImg />
      </CenteredBox>
      <CenteredBox py={2}>
        <ButtonLarge
          aria-label="Return to homepage"
          onClick={() => { history.replace('/') }}>
            Take me home please
        </ButtonLarge>
      </CenteredBox>
      <CenteredBox py={2}>
        <ButtonLarge
          aria-label="Return to homepage"
          onClick={() => history.goBack()}>
            Resume Application
        </ButtonLarge>
      </CenteredBox>
      <CenteredBox py={2}>
      URL: ${url}
      </CenteredBox>
    </Container>
  )
}

function NotFoundPage() {
  return (
    <AppLayout
      header={<Header title="Not Found" />}
      body={<Body />}
    />
  )
}

export default NotFoundPage
