import React from 'react'
import { Box, Chip } from '@material-ui/core'

export function TagsView({ tags }: { tags: string[] | undefined }) {
  const style = {
    fontWeight: 700,
    margin: 5,
    backgroundColor: 'transparent',
  }
  return (
    <>
      {tags?.map((tag) => (
        <Box key={tag} display="inline-block" mr={0.5}>
          <Chip label={tag.toUpperCase()} variant="outlined" size="small" style={style} />
        </Box>
      ))}
    </>
  )
}
