import { useApiFetch } from '@rse/frontend-dal'
import { firebaseInstance } from '../../firebase'
import { LoadingSpinner } from '@rse/uikit'
import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useApiPrefix } from '../../common/hooks'
import { BodyContainer } from '../../components/layout/BodyContainer'


type UrlParams = { peerId: string }

interface ChatShortcutProps {
  isGroup: boolean  
}

export function ChatShortcut({ isGroup }: ChatShortcutProps) {
  const { peerId } = useParams<UrlParams>()
  const [chatId, setChatId] = useState<string>('loading')
  const chatApiPrefix = useApiPrefix('chat')
  
  let fetchType = "user"
  if(isGroup){
    fetchType = "group"
  }

  const fetchChatId = useApiFetch(`${chatApiPrefix}/peer/${fetchType}/${peerId}`, true, 'GET', true, firebaseInstance.auth)

  useEffect(() => {
    fetchChatId.callApi(undefined).then(e => {
      console.log(e)
      setChatId(e.id)
    })
  }, [])

  if (chatId === 'loading') return <BodyContainer><LoadingSpinner reason="operating switchboard" /></BodyContainer>
  else return <Redirect to={`/chat/${chatId}`} />
}