import { styled } from '@material-ui/core'

export const BoxNoticeArea = styled('div')(({ theme }) => ({
  height: '100%',
  background: theme.palette.type === 'light' ? '#EFEEEA' : theme.palette.common.white,
  // color: theme.palette.common.black,
  borderRadius: 2,
  padding: '10px 20px 20px 20px',
  overflow: 'auto',
}))
