/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import { DynamicWizardConfigData, DynamicWizardUserData, WizardPage, Dictionary } from '../types'
import DynamicWizardPage from './DynamicWizardPage'
import { DynamicWizardStepsProgressLine } from './DynamicWizardStepsProgressLine'

interface DynamicWizardBodyProps {
  userData: DynamicWizardUserData
  configData: DynamicWizardConfigData
  Slots?: Record<string, React.ReactElement>
  savePageDataAction: (pageIndex: number, pageAnswers: Dictionary<string[]>) => Promise<void>
}

export default function DynamicWizardBody({ userData, configData, Slots, savePageDataAction }: DynamicWizardBodyProps) {
  const [isLoading, setIsLoading] = useState(true)
  const [currentPageIndex, setCurrentPageIndex] = useState(userData.lastPageIndex)
  const [currentPageAnswers, setCurrentPageAnswers] = useState<Dictionary<string[]>>({})
  const [displayQuestionErrors, setDisplayQuestionErrors] = useState(false)
  const [isCurrentPageValid, setIsCurrentPageValid] = useState(true)
  const [currentPageConfigData, setCurrentPageConfigData] = useState<WizardPage>({
    Id: '',
    Title: '',
    ShowTitle: true,
    Sections: [],
  })
  const [allPageAnswers, setAllPageAnswes] = useState<Dictionary<string[]>>(userData.AllPageAnswers)

  useEffect(() => {
    setIsLoading(true)
    setIsCurrentPageValid(true)
    setDisplayQuestionErrors(false)
    setAllPageAnswes(userData.AllPageAnswers)

    const currentPageAnswersInit = getCurrentPageAnswers(userData.AllPageAnswers)
    setCurrentPageAnswers(currentPageAnswersInit)
    setIsLoading(false)
  }, [configData])

  useEffect(() => {
    setIsLoading(true)
    setDisplayQuestionErrors(false)
    setIsCurrentPageValid(true)
    let fixedCurrentPageIndex = currentPageIndex
    if (currentPageIndex < 0 || currentPageIndex > configData.Pages.length - 1) {
      fixedCurrentPageIndex = 0
      setCurrentPageIndex(fixedCurrentPageIndex)
    }

    setCurrentPageConfigData(configData.Pages[fixedCurrentPageIndex])
    const newCurrentPageAnswers = getCurrentPageAnswers(allPageAnswers)
    setCurrentPageAnswers(newCurrentPageAnswers)

    setIsLoading(false)
  }, [currentPageIndex])

  const getCurrentPageAnswers = (answers: Dictionary<string[]>) => {
    const currentPageAnswersNew: Dictionary<string[]> = {}

    configData.Pages[currentPageIndex].Sections.forEach((x) => {
      x.Questions.forEach((q) => {
        if (!q.IsReadOnly) {
          currentPageAnswersNew[q.QuestionId] = answers[q.QuestionId]
        }
      })
    })

    return currentPageAnswersNew
  }

  const nextPageButtonHandler = () => {
    if (!isCurrentPageValid) {
      setDisplayQuestionErrors(true)
      return
    }

    let nextPageIndex = currentPageIndex + 1
    if (nextPageIndex > configData.Pages.length - 1) {
      nextPageIndex = configData.Pages.length - 1
    }
    savePageDataAction(nextPageIndex, currentPageAnswers)
    setCurrentPageIndex(nextPageIndex)
  }

  const prevPageButtonHandler = () => {
    let prevPageIndex = currentPageIndex - 1
    if (prevPageIndex < 0) {
      prevPageIndex = 0
    }
    savePageDataAction(prevPageIndex, currentPageAnswers)
    setCurrentPageIndex(prevPageIndex)
  }

  const setCurrentPageValidStatus = (isValid: boolean) => {
    setIsCurrentPageValid(isValid)
    if (isValid) {
      setDisplayQuestionErrors(false)
    }
  }

  const updateCurrentPageAnswersState = (pageAnswers: Dictionary<string[]>) => {
    const answers = Object.assign({}, pageAnswers)
    setCurrentPageAnswers(answers)

    const clonedAllPageAnswers = Object.assign({}, allPageAnswers, answers)
    setAllPageAnswes(clonedAllPageAnswers)
  }

  const currentProgress = ((currentPageIndex + 1) / configData.Pages.length) * 100

  return (
    <>
      {isLoading && <p>Loading Wiazard...</p>}
      {!isLoading && (
        <>
          {currentPageConfigData.ShowTitle && <h1>{currentPageConfigData.Title}</h1>}
          {configData.ShowProgressLine && (
            <DynamicWizardStepsProgressLine currentProgress={currentProgress} stepsCount={configData.Pages.length} />
          )}
          <DynamicWizardPage
            pageConfigData={currentPageConfigData}
            currentPageUserAnswers={currentPageAnswers}
            setCurrentPageValidStatus={setCurrentPageValidStatus}
            displayQuestionErrors={displayQuestionErrors}
            updateCurrentPageAnswersState={updateCurrentPageAnswersState}
            Slots={Slots}
          />

          {!currentPageConfigData.HidePrevButton && (
            <button type="button" onClick={prevPageButtonHandler}>
              Previous
            </button>
          )}
          {!currentPageConfigData.HideNextButton && (
            <button type="button" onClick={nextPageButtonHandler}>
              Next
            </button>
          )}
        </>
      )}
    </>
  )
}
