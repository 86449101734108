import React from 'react'
import { NotificationMessage, NotificationType } from '@rse/core'
import { AppLayout, Header } from '../../components/layout'
import { Box } from '@material-ui/core'
import { DashboardBanner } from '../../components/layout/DashboardBanner'
import { ButtonLarge } from '@rse/uikit'
import { TextContent } from '../../common/textContent'

type Props = {
    notification: NotificationMessage
    back: () => Promise<void>
}

function InvitationToJoin({ back }: Props) {
  return <>
        <DashboardBanner
          viewBox="0 0 42 42"
          title="You have been invited!"
          titleKey="invitationToJoin.title"
          subtext="We are now accepting applications from special people in your area. You are one of these special people!"
          subtextKey="invitationToJoin.subtext"
        >
          <ButtonLarge onClick={back}>
            <TextContent resourceKey="invitationToJoin.actionButtonText" defaultValue="Click here to apply" />
          </ButtonLarge>
        </DashboardBanner>
  </>
}

function CanGroupUp({ back }: Props) {
  return <>
        <DashboardBanner
          viewBox="0 0 42 42"
          title="You can now network with other members!"
          titleKey="canGroupUp.title"
          subtext="You are one of our lucky beta testers and can use this application to network with other Social Equity club members. Please reach out to support if you need help. We are here to help you find a home."
          subtextKey="canGroupUp.subtext"
        >
          <ButtonLarge onClick={back}>
            <TextContent resourceKey="canGroupUp.actionButtonText" defaultValue="Click here to find your homemates" />
          </ButtonLarge>
        </DashboardBanner>
  </>
}

function NotificationTempView({ notification, back }: Props) {
  const header = (
        <Header
          back={back}
          title="Notification"
          hideMenu
        ></Header>
  )

  function body() {
    switch(notification.nType) {
        case NotificationType.InvitationToJoin: return <InvitationToJoin {...{notification, back}} />
        case NotificationType.MembershipApproved: 
          // disabled because UI already shows something here
          back()
          return null
        case NotificationType.CanGroupUp: return <CanGroupUp {...{notification, back}} />
        default: return `Your device received a notification type that this version of the app cannot display: type="${notification.nType}". Please contact support`
    }
  }

  const notificationBody = (
    <Box margin="auto">
      {body()}
    </Box>
  )

  return <AppLayout header={header} body={notificationBody} />
}

export { NotificationTempView }