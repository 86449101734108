import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { usePreferencesQuestions } from '../../common/hooks'

type PreferencesType = {
  preferences: Record<string, string>
  activePreferences?: Array<{ name: string; value: string }>
}

type Props<T> = {
  object: T | undefined
  loadingObject: boolean
  errorLoadingObject: firebase.FirebaseError | undefined
  refinePreferences?: boolean
}

export function useActivePreferences<T extends PreferencesType>({
  object,
  loadingObject,
  errorLoadingObject,
  refinePreferences = false,
}: Props<T>) {
  const [objectWithActivePreferences, setObjectWithActivePreferences] = useState(object)
  const [isActivePrefsSet, setIsActivePrefsSet] = useState<boolean>(false)

  const {
    value: preferencesQuestions,
    loading: loadingQuestions,
    error: errorLoadingQuestions,
  } = usePreferencesQuestions()

  useEffect(() => {
    if (loadingQuestions || errorLoadingQuestions) {
      setObjectWithActivePreferences(object)
      setIsActivePrefsSet(false)
      return
    }

    if (refinePreferences && object && preferencesQuestions) {
      const activePreferences = preferencesQuestions
        .map((q) => ({
          name: q.label,
          value: q.enabledChoices?.find((ch) => ch.id === (object.preferences || {})[q.id])?.label,
        }))
        .filter((item) => item.value)

      setObjectWithActivePreferences({ ...object, activePreferences })
      setIsActivePrefsSet(true)
    } else {
      setObjectWithActivePreferences(object)
      setIsActivePrefsSet(false)
    }
  }, [refinePreferences, object, preferencesQuestions, loadingQuestions, loadingObject, errorLoadingQuestions])

  return {
    value: objectWithActivePreferences,
    loading: loadingObject || loadingQuestions,
    error: errorLoadingObject || errorLoadingQuestions,
    isActivePrefsSet
  }
}
