import { useContext } from 'react'
import { UserContext } from '../../App'

/*
 * This hook is supposed to be used in authorized paths only, otherwise will throw an exception
 */

export function useCurrentUserId() {
  const user = useContext(UserContext)

  if (!user?.id) {
    throw new Error('User must be authorized')
  }

  return user.id
}
