import React from 'react'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { SectionHeader, Spacing } from '@rse/uikit'
import { InputText } from '../components/InputText'

type Props = {
  showSectionHeader: boolean
}

export const ProfileLegalName: React.FC<Props> = ({ showSectionHeader }) => {
  const textContent = useTextContent()

  return (
    <section>
      <Spacing value={28} />
      {showSectionHeader && (
        <SectionHeader>
          <TextContent resourceKey="app-wizard.legal-name" defaultValue="Legal Name" />
        </SectionHeader>
      )}
      <Spacing value={20} />
      <InputText id="firstName" label={getTextContent(textContent, 'app-wizard.field-first-name', 'First Name *')} />
      <Spacing value={20} />
      <InputText id="lastName" label={getTextContent(textContent, 'app-wizard.field-last-name', 'Last Name *')} />
    </section>
  )
}
