/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Dictionary, WizardPage } from '../types'
import DynamicWizardPageSection from './DynamicWizardPageSection'
import { Spacer } from './layoutComponents/Spacer'

interface DynamicWizardPageProps {
  pageConfigData: WizardPage
  currentPageUserAnswers: Dictionary<string[]>
  displayQuestionErrors: boolean
  Slots?: Record<string, React.ReactElement>
  setCurrentPageValidStatus: (isValid: boolean) => void
  updateCurrentPageAnswersState: (pageAnswers: Dictionary<string[]>) => void
}

export default function DynamicWizardPage({
  pageConfigData,
  currentPageUserAnswers,
  setCurrentPageValidStatus,
  displayQuestionErrors,
  updateCurrentPageAnswersState,
  Slots,
}: DynamicWizardPageProps) {
  const [isLoading, setIsLoading] = useState(true)
  const [isPageValid, setIsPageValid] = useState(true)
  const [pageAnswers, setPageAnswers] = useState(currentPageUserAnswers)
  const [pageControlIsValid, setPageControlIsValid] = useState<Dictionary<boolean>>({})

  const tempValidControlStatus: Dictionary<boolean> = {}

  useEffect(() => {
    setIsLoading(true)
    setPageAnswers(currentPageUserAnswers)

    const validStatus: Dictionary<boolean> = {}

    pageConfigData.Sections.forEach((s) => {
      s.Questions.forEach((x) => {
        validStatus[x.QuestionId] = true
      })
    })

    setPageControlIsValid(validStatus)
    setIsLoading(false)
  }, [pageConfigData])

  useEffect(() => {
    let isValid = true
    for (const [key, value] of Object.entries(pageControlIsValid)) {
      if (!value) {
        isValid = false
      }
    }
    setIsPageValid(isValid)
    setCurrentPageValidStatus(isValid)
  }, [pageControlIsValid])

  const questionAnswerChangeHandler = (questionId: string, answer: string[]) => {
    const clonedPageAnswers = Object.assign({}, pageAnswers)
    clonedPageAnswers[questionId] = answer
    setPageAnswers(clonedPageAnswers)
    updateCurrentPageAnswersState(clonedPageAnswers)
  }

  const questionControlValidStatusUpdate = (questionId: string, isValid: boolean) => {
    const clonedPageControlIsValid = Object.assign({}, pageControlIsValid)

    for (const [key, value] of Object.entries(tempValidControlStatus)) {
      clonedPageControlIsValid[key] = value
    }

    clonedPageControlIsValid[questionId] = isValid
    tempValidControlStatus[questionId] = isValid

    setPageControlIsValid(clonedPageControlIsValid)
  }

  return (
    <>
      {isLoading && <p>Loading Page...</p>}

      {!isLoading && (
        <div>
          {pageConfigData.Sections.map((section, i) => (
            <>
              <DynamicWizardPageSection
                key={`section-${section.Id}`}
                displayQuestionErrors={displayQuestionErrors}
                pageAnswers={pageAnswers}
                sectionConfig={section}
                questionAnswerChangeHandler={questionAnswerChangeHandler}
                questionControlValidStatusUpdate={questionControlValidStatusUpdate}
                Slots={Slots}
              />
              {section.SpacingAfter && <Spacer height={section.SpacingAfter} />}
              {section.DividerAfter && <Divider />}
            </>
          ))}
        </div>
      )}
    </>
  )
}
