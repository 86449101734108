import { UserProfileEntity } from '@rse/core'
import { IUserProfileRepo } from './IUserProfileRepo'
import app from 'firebase/app'
import { AddOrUpdateFireStoreRecord, GetFireStoreRecord } from '../../firebase/fireStoreDataAccess'

export const UserProfileRepoFireStore = (db: app.firestore.Firestore): IUserProfileRepo => {
  return {

    getUserProfile: async (uid: string) => {
      const profile = await GetFireStoreRecord<UserProfileEntity>(db, 'userProfiles', uid)
      return profile
    },

    setUserProfile: async (profile: UserProfileEntity) => {
      await AddOrUpdateFireStoreRecord(db, 'userProfiles', profile.id, true, profile)
      return
    }

  }
}
