import { Theme, withStyles } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@material-ui/lab'
import React from 'react'
import { useFormData } from '../helpers/useFormData'
import { InputId, InputOptions } from '../types/components'
import { TextError } from './TextError'

type Props = InputId & Omit<ToggleButtonGroupProps, 'value' | 'onChange'> & InputOptions

export const InputSelectPills: React.VFC<Props> = ({ id, options, ...rest }) => {
  const { formErrors, formData, onFieldChange } = useFormData()
  const buttons = options.map((o) => (
    <ToggleButton key={o.id} value={o.id} name={id}>
      {o.title}
    </ToggleButton>
  ))

  return (
    <>
      <StyledBoolButtonGroup value={formData[id]} onChange={(e, val) => onFieldChange(id, val)} {...rest}>
        {buttons}
      </StyledBoolButtonGroup>
      <input name={id} hidden={true} value={(formData[id] as string[] | string) || ''} type="hidden" />
      {formErrors[id] && <TextError>{formErrors[id]}</TextError>}
    </>
  )
}

const bgColorUnselected = 'rgba(43, 39, 97, 0.07)'
const StyledBoolButtonGroup = withStyles(({ palette, shape }: Theme) => ({
  root: {
    display: 'block',
  },
  grouped: {
    width: '100%',
    height: '50px',
    border: 'none',
    marginBottom: '10px',

    backgroundColor: bgColorUnselected,

    color: '#8C8AAC',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textTransform: 'initial',
    '&.Mui-selected': {
      backgroundColor: palette.primary.main,
      color: palette.type === 'light' ? palette.common.white : palette.common.black,
    },
    '@media (hover: hover)': {
      '&:hover, &.Mui-selected:hover': {
        backgroundColor: palette.primary.main,
        color: 'white',
      },
    },
    '@media (hover: none)': {
      '&.Mui-selected:hover': {
        backgroundColor: palette.primary.main,
      },
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: bgColorUnselected,
      },
    },
    '&:not(:first-child)': {
      borderRadius: shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)
