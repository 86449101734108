import React, { useContext } from 'react'
import { Box, createStyles, makeStyles, styled, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { ThemeContext } from '../../../common/theme/ThemeContext'
import { InputId } from '../types/components'
import { useFormData } from '../helpers/useFormData'
import { TextError } from './TextError'
import { Chip, Icon } from '@rse/uikit'
import { QuestionChoice } from '@rse/core'

export type RadioGroupOption = QuestionChoice & {
  value: string
}

type PropsRadioGroup = {
  title: string
  options: RadioGroupOption[]
} & InputId

export function InputSelectRadioCircles({ title, options, id }: PropsRadioGroup) {
  const { formData, formErrors, onFieldChange } = useFormData()
  const value = formData[id]
  return (
    <Box>
      <Box display="none">
        {options.map((o) => (
          <input
            key={o.value}
            type="radio"
            name={id}
            value={o.value}
            checked={o.value === value}
            onChange={() => undefined}
          />
        ))}
      </Box>
      <Box mb={2}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <GroupItem>
        {options.map((option) => (
          <Box key={option.value} onClick={() => onFieldChange(id, option.value)}>
            <Chip option={option} checked={option.value === value} />
          </Box>
        ))}
      </GroupItem>
      {formErrors[id] && <TextError>{formErrors[id]}</TextError>}
    </Box>
  )
}

const GroupItem = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.8em',
})

type PropsButton = {
  option: RadioGroupOption
  checked: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '68px',
      height: '68px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      cursor: 'pointer',
      userSelect: 'none',

      '& img': {
        width: 'auto',
        height: 'auto',
      },

      '&.dark': {
        background: 'rgba(212, 190, 75, 0.08)',
      },

      '&.checked': {
        background: '#692BF0',
        cursor: 'initial',
      },

      '&.dark.checked': {
        background: '#692BF0',
        cursor: 'initial',
      },
    },
    label: {
      maxWidth: '80px',
    },
  })
)
