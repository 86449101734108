import React from 'react'
import { Box, Fab, styled } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

export function PaginationBar() {
  return (
    <Box display="flex" justifyContent="space-between" p={1}>
      <FabStyled>
        <ChevronLeft />
      </FabStyled>
      <FabStyled>
        <ChevronRight />
      </FabStyled>
    </Box>
  )
}

const FabStyled = styled(Fab)({
  boxShadow: 'none',
  backgroundColor: 'white',
})
