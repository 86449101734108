import { useState } from 'react'
import { firebaseInstance } from '../../firebase'
import settings from '../../settings'

/*
 * See the following StackOverflow articles that explains the approach used here:
 * 1. https://stackoverflow.com/questions/41541887
 * 2. https://stackoverflow.com/questions/62410552
 * 3. https://stackoverflow.com/questions/41874910
 * 
 * TODO: remove it if Firebase user becomes reactive.
 */

export function useCurrentUserEmailVerified() {
  const [value, setValue] = useState<boolean>(firebaseInstance.auth.currentUser?.emailVerified || false)
  const [intervalId, setIntervalId] = useState<number | undefined>(undefined)

  const start = () => {
    if (intervalId) {
      return
    }

    setIntervalId(window.setInterval(() => {
      const user = firebaseInstance.auth.currentUser
      if (user) {
        user.reload().then(() => {
          setValue(user.emailVerified || settings.firebaseUseEmulators || false)
        })
      } else {
        setValue(false)
      }
    }, 2000))
  }

  const stop = () => {
    if (intervalId) {
      window.clearInterval(intervalId)
      setIntervalId(undefined)
    }
  }

  return {
    value,
    start,
    stop
  }
}
