import React from 'react'
import { SvgIcon, makeStyles, createStyles, Theme, Box } from '@material-ui/core'

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    iconStyles: {
      width: 50,
      height: 50,
      fill: palette.text.primary,
    },
    containerStyles: {
      width: 100,
      height: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      borderWidth: 2,
      borderColor: palette.grey[300],
      borderStyle: 'solid',
      marginBottom: spacing(5),
    },
  })
)

interface Props {
  component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  viewBox: string
}

export default function DashIcon({ component, viewBox }: Props) {
  const { iconStyles, containerStyles } = useStyles()

  return (
    <Box className={containerStyles}>
      <SvgIcon color="primary" component={component} className={iconStyles} viewBox={viewBox} />
    </Box>
  )
}
