import { Box, makeStyles, createStyles } from '@material-ui/core'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { StandardText } from '@rse/uikit'

const useStyles = makeStyles((theme) =>
  createStyles({
    elipsis: {
      position: 'absolute',
      display: 'inline-block',
      bottom: 0,
      right: 0,
      cursor: 'pointer',
      background: 'inherit',
      color: theme.palette.primary.main,
      textAlign: 'right',
      minWidth: '1.5em',
    },
  })
)

type Props = {
  linesMax?: number
}

export const CardText: React.FC<Props> = ({ children, linesMax = 3 }) => {
  const styles = useStyles()
  const [overflow, setOverflow] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const textHeight = textRef.current?.offsetHeight
    setOverflow((textHeight || 0) > linesMax * 17)
  })
  return (
    <Box maxHeight={`${linesMax + 1}em`} overflow="hidden" position="relative">
      <div ref={textRef}>
        <StandardText>{children}</StandardText>
      </div>
      {children && overflow && <span className={styles.elipsis}>&#8230;</span>}
    </Box>
  )
}
