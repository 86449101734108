import React from 'react'
import { Badge } from '@material-ui/core'
import { UNAVAILABLE_DOC_ID } from '../../common/constants/stubs'
import { useUserProfile } from '../../firebase'
import { useCurrentUserId } from '../../common/hooks'

export const UnreadMessagesBadge: React.FC = ({ children }) => {
  const user = useCurrentUserId()
  const profile = useUserProfile(user || UNAVAILABLE_DOC_ID)

  return (
    <Badge
      color="error"
      badgeContent={profile && profile.unreadMessagesCount}
      invisible={!profile || !profile.unreadMessagesCount}
    >
      {children}
    </Badge>
  )
}
