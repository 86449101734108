import React, { useState } from 'react'
import { Box, createStyles, makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    tagsArea: {},
    tag: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #F0F0F4',
      borderRadius: '20px',
      background: '#FFFFFF',
      padding: '4px 4px 4px 15px',
      marginTop: '10px',
    },
    tagName: {
      marginRight: '10px',
    },
    closeIcon: {
      cursor: 'pointer',
      color: '#8C8AAC',
    },
  })
)

type Props = {
  title: string
  options: string[]
  selectedTags: string[]
  selectTag: (tag: string) => void
  removeTag: (tag: string) => void
}

export function TagsSelect({ title, options, selectedTags, selectTag, removeTag }: Props) {
  const styles = useStyles()
  const [inputValue, setInputValue] = useState<string>('')

  const handleSelectTag = (tag: string | null) => {
    if (tag) {
      selectTag(tag)
      setTimeout(() => setInputValue(''), 0)
    }
  }

  return (
    <Box className={styles.container}>
      <Autocomplete
        id="tags-select"
        freeSolo
        autoComplete={true}
        autoSelect={true}
        options={options}
        inputValue={inputValue}
        onInputChange={(e, value) => setInputValue(value)}
        onChange={(e, value) => handleSelectTag(value)}
        renderInput={(params) => <TextField {...params} label={title} variant="outlined" />}
      />
      <Box className={styles.tagsArea}>
        {selectedTags.map((tag, index) => (
          <span className={styles.tag} key={index}>
            <Box className={styles.tagName}>{tag}</Box>
            <CancelIcon className={styles.closeIcon} onClick={() => removeTag(tag)} />
          </span>
        ))}
      </Box>
    </Box>
  )
}
