import { Box, Checkbox, FormControlLabel, styled } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { QuestionComponentProps } from '.'
import { TextError } from '../../../../pages/Application/components/TextError'
import { Dictionary } from '../../types'

export default function CheckBoxInput({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  QuestionChoices,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const options = QuestionChoices || []
  const isRequired: boolean = QuestionConfig.IsRequired || false
  const color = otherProps.color || 'primary'

  const [choiceValues, setChoiceValues] = useState<Dictionary<boolean>>({})
  const [isQuestionAnswerValid, setIsQuestionAnswerValid] = useState(true)
  const [choicesLoaded, setChoicesLoaded] = useState(false)

  useEffect(() => {
    const currentchoinces: Dictionary<boolean> = {}

    options.map((x, i) => (currentchoinces[i] = Answer.includes(x.Value)))
    setChoiceValues(currentchoinces)
    setChoicesLoaded(true)
  }, [])

  const checkInputIsValid = (answers: string[]) => {
    const isValid = false

    if (!isRequired) {
      return true
    } else {
      if (answers.length == 0) {
        return false
      } else {
        return true
      }
    }
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const clonedChoiceValues = Object.assign({}, choiceValues)
    clonedChoiceValues[e.target.value] = e.target.checked
    setChoiceValues(clonedChoiceValues)

    const newAnswers: string[] = []

    options.forEach((x, i) => {
      if (clonedChoiceValues[i]) {
        newAnswers.push(x.Value)
      }
    })

    AnswerUpdateHandler(newAnswers)

    const isValid = checkInputIsValid(newAnswers)
    ValidStatusUpdateHandler(isValid)
    setIsQuestionAnswerValid(isValid)
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      {choicesLoaded &&
        options.map((x, i) => (
          <Box key={`db${QuestionConfig.QuestionId}_${i}`}>
            <FormControlLabel
              control={
                <Checkbox
                  id={`cb${QuestionConfig.QuestionId}_${i}`}
                  name={`cb${QuestionConfig.QuestionId}_${i}`}
                  value={i}
                  onChange={changeHandler}
                  checked={choiceValues[i]}
                  color={color}
                  {...otherProps}
                />
              }
              label={<LabelText>{x.Label}</LabelText>}
            />
          </Box>
        ))}
      {displayQuestionErrors && !isQuestionAnswerValid && <TextError>Select atleast once item</TextError>}
    </>
  )
}

const LabelText = styled('span')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary,
}))
