import { useEffect, useState } from 'react'
import { defaultPreferenceQuestionIds, defaultQuestions, Question } from '@rse/core'

import { usePublicConfig, useQuestions } from '../../firebase'

export function usePreferencesQuestions() {
  const [value, setValue] = useState<Question[]>(
    defaultPreferenceQuestionIds.map((id) => defaultQuestions[id])
  )
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()

  const {
    document: {
      value: publicConfig,
      loading: publicConfigLoading,
      error: publicConfigError,
    },
  } = usePublicConfig()

  const {
    value: questions,
    loading: questionsLoading,
    error: questionsError,
  } = useQuestions(true)

  useEffect(() => {
    const hooksError = publicConfigError ?? questionsError
    const hooksLoading = publicConfigLoading || questionsLoading

    // error or loading
    if (hooksError || hooksLoading) {
      if (hooksError && !error) {
        // error
        setError(hooksError)
        setLoading(false)
      } else if (hooksLoading && !loading) {
        // loading
        setLoading(true)
      }
    } else {
      // loaded
      const preferencesQuestionIds = publicConfig?.preferencesQuestions ?? defaultPreferenceQuestionIds
      const preferencesQuestions = preferencesQuestionIds.map(id => questions?.find(item => item.id === id) ?? defaultQuestions[id])
      setValue(preferencesQuestions)
      setError(undefined)
      setLoading(false)
    }
  }, [publicConfig, publicConfigLoading, publicConfigError, questions, questionsLoading, questionsError])

  return {
    value,
    loading,
    error
  }
}
