import { Box, styled, useTheme } from '@material-ui/core'
import React from 'react'

type Props = {
  stepsCount: number
  currentProgress: number
}

export const StepsProgressLine = ({ stepsCount, currentProgress }: Props) => {
  const { palette } = useTheme()
  const stepsArr = Array.from(Array(stepsCount - 1))
  return (
    <LinesContainer>
      <LineTrack />
      <LineDots>
        {stepsArr.map((_, i) => (
          <Box key={i} width={4} height={4} borderRadius="50%" bgcolor={palette.primary.main} />
        ))}
      </LineDots>
      <Box height={4} width={`${currentProgress}%`} minWidth={15} bgcolor={palette.primary.main} />
    </LinesContainer>
  )
}

const LinesContainer = styled('div')({
  position: 'relative',
  height: 4,
})

const LineBase = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
})

const LineTrack = styled(LineBase)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  opacity: 0.1,
}))

const LineDots = styled(LineBase)({
  display: 'flex',
  justifyContent: 'space-evenly',
})
