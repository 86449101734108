import { useEffect, useState } from 'react'
import { getDateFromFirestoreTimestampOrString } from '../utils'
import { UserProfilePublicEntity } from '@rse/core'
import settings from '../../settings'
import { firebaseInstance } from '../firebaseInstance'
import { useApiFetch } from '@rse/frontend-dal'






function setUserPublicProfileBirthday(value: UserProfilePublicEntity): UserProfilePublicEntity {
  if (value) {
    value.birthday = getDateFromFirestoreTimestampOrString(value.birthday)
  }

  return value
}

export function useUserPublicProfileApiSimple(uid?: string) {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [userPublicProfileData, setUserPublicProfileData] = useState<UserProfilePublicEntity | undefined>(undefined)

  const loadUserProfilePublicApi = useApiFetch(`${settings.firebaseFunctionsBaseUrl}/api/user-profile/public`, true, 'GET', true, firebaseInstance.auth)

  const loadData = async () => {
    setIsLoading(true)
    if (uid) {
      let profile: UserProfilePublicEntity | null = await loadUserProfilePublicApi.callApi(undefined, uid)
      if (profile) {
        profile = setUserPublicProfileBirthday(profile)
        setUserPublicProfileData(profile)
      } else {
        setUserPublicProfileData(undefined)
      }
    } else {
      setUserPublicProfileData(undefined)
    }
    setIsLoading(false)
  }


  useEffect(() => {
    const fetchData = async () => {
      await loadData()
    }
    fetchData().catch(console.error)
  }, [uid])






  return { value: userPublicProfileData, isLoading, loadData }
}
