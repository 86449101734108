import React from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

interface Props {
  color?: string
  back?: 'history' | (() => Promise<void>)
}

export const NavBack = ({ color, back }: Props) => {
  const history = useHistory()

  async function goBack() {
    if (typeof back === 'function') await back()
    else history.goBack()
  }

  return (
    <IconButton onClick={goBack}>
      <ArrowBack style={{ fill: color }} />
    </IconButton>
  )
}
