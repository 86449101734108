// Quick prototype for interfacing with the userProfiles collection in Firestore
// TODO generalize this to any collection that is solely nullable fields
import { User, UserProfileEntity } from '@rse/core'
import { firebaseInstance } from './firebaseInstance'
import { UserProfileRepoFireStore } from '../repositories/UserProfile/UserProfileRepoFireStore'

export async function firebaseGetUserProfile(user?: User): Promise<UserProfileEntity | undefined> {
  if (!user) {
    return undefined
  }

  const userProfileRepo = UserProfileRepoFireStore(firebaseInstance.db)
  const profile = await userProfileRepo.getUserProfile(user.id)

  return profile || undefined
}

export async function firebaseSaveUserProfile(user: User, profile: UserProfileEntity) {
  const userProfileRepo = UserProfileRepoFireStore(firebaseInstance.db)
  profile.id = user.id
  await userProfileRepo.setUserProfile(profile)  
}
