// Hooks similar to React.useState, but uses Web Storage API to persist
// the data to the browser.
// See https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API
// TODO move to @rse/core

import { useState } from 'react'

function makeStorageHook(storage: Storage) {
  // The code here is modified from https://usehooks.com/useLocalStorage/
  function storageHook<T>(key: string, initialValue: T): [T, (value: T) => void] {
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
      try {
        const item = storage.getItem(key)
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue
      } catch (error) {
        // If error also return initialValue
        // TODO handle error or allow caller to inject handler
        console.log(error)
        return initialValue
      }
    })

    // Wrap useState's setter
    const setValue = (value: T | ((val: T) => T)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value
        setStoredValue(valueToStore)
        storage.setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        // TODO handle error or allow caller to inject handler
        console.log(error)
      }
    }

    return [storedValue, setValue]
  }

  return storageHook
}

export const useLocalStorage = makeStorageHook(window.localStorage)
export const useSessionStorage = makeStorageHook(window.sessionStorage)
