import { IconButton, useTheme } from '@material-ui/core'
import { Favorite, FavoriteBorderOutlined } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useCurrentUserId, useErrorHandler } from '../../common/hooks'
import { firebaseToggleClubMemberFavorite } from '../../firebase'

type Props = {
  memberId: string
  isFab?: boolean
  isDark?: boolean
}

const ClubMemberFavIconInner = ({ memberId, isFab }: Props) => {
  const [isFav, setIsFav] = useState(!!isFab)
  const userId = useCurrentUserId()
  const { errorHandler } = useErrorHandler('adding / removing from favorites')
  const { palette } = useTheme()
  const iconColor = isFav ? palette.primary.main : palette.grey[300]

  const toggleFavorite = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      firebaseToggleClubMemberFavorite(userId, memberId)
      setIsFav((isFav) => !isFav)
    } catch (err) {
      errorHandler(err)
    }
  }

  useEffect(() => {
    setIsFav(!!isFab)
  }, [isFab])

  return (
    <IconButton onClick={toggleFavorite} style={{ color: iconColor }}>
      {isFav ? <Favorite /> : <FavoriteBorderOutlined />}
    </IconButton>
  )
}

export const ClubMemberFavIcon = React.memo(ClubMemberFavIconInner)
