import React from 'react'

import { firebaseStorage } from '../../../../firebase/firebaseStorage'
import { useCurrentUserId } from '../../../../common/hooks/useCurrentUserId'
import { TextContent } from '../../../../common/textContent'
import { QuestionComponentProps } from '.'
import { useState } from 'react'
import { UplaodImage } from './photoControls/UplaodImage'

export default function PhotoInput({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const userId = useCurrentUserId()
  const [isValid, setIsValid] = useState(true)
  const [imgUrl, setImgUrl] = useState('')
  const getImage = async () => await firebaseStorage.getProfileImageUrlByUser(userId)
  const saveImage = (file: File) => firebaseStorage.UploadPicture(file)

  const isValidAnswer = (currentImgUrl: string) => {
    if (!imgUrl) {
      if (QuestionConfig.IsRequired) {
        return false
      }
    }

    return true
  }

  const imgChangeHandler = (currentImgUrl: string) => {
    setImgUrl(currentImgUrl)
    AnswerUpdateHandler([currentImgUrl])
    const isCurrentImgValid = isValidAnswer(currentImgUrl)
    setIsValid(isCurrentImgValid)
    ValidStatusUpdateHandler(isCurrentImgValid)
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      <UplaodImage getImage={getImage} saveImage={saveImage} imgChangeHandler={imgChangeHandler}>
        <TextContent resourceKey="app-wizard.field-add-photo" defaultValue="+ Add Photo" />
      </UplaodImage>
      {!isValid && displayQuestionErrors && <p style={{ color: 'red' }}>Image required</p>}
    </>
  )
}
