import { UserProfilePublicEntity } from '@rse/core'
import React, { createContext } from 'react'


export type selectedPublicProfileType = {
  selectedPublicProfile: UserProfilePublicEntity | undefined
  setSelectedPublicProfile: undefined | ((profile: UserProfilePublicEntity) => void)
  loadValueFromContext: boolean
  setLoadValueFromContext: undefined | ((loadFromContext: boolean) => void)
}

export const selectedPublicProfileContext = createContext<selectedPublicProfileType>({
  selectedPublicProfile: undefined,
  setSelectedPublicProfile: undefined,
  loadValueFromContext: false,
  setLoadValueFromContext: undefined
})
