import { HomesListParams, HomesListResult } from '@rse/core'
import { firebaseInstance } from '.'

export async function getAvailableHomes(args: HomesListParams): Promise<HomesListResult> {
  try {
    const func = firebaseInstance.firebaseFunction.httpsCallable('getHomesList')
    const result = await func(args)
    console.log(result)
    return result.data
  } catch (e) {
    console.log(e)
  }

  return []
}
