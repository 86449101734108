import React from 'react'
import { ReactComponent as PhoneIcon } from '../../Dashboard/assets/verifiedPhone.svg'
import { DashboardBanner } from '../../../components/layout/DashboardBanner'
import { DigitFields } from '../../SMSVerify/components/DigitFields'
import { ErrorText, StandardText, AppLink, LoadingSpinner, ButtonLarge } from '@rse/uikit'
import { Box, ButtonBase } from '@material-ui/core'
import { useHistory } from 'react-router'

type Props = {
  titleKey: string
  subtextKey: string
  inputCode: string
  setInputCode: (val: string) => void
  verificationError: string
  verifyCode: (inputCode: string, setInputCode: any) => Promise<any>
  statusPending: boolean
  invalidCodeSent: boolean
}

export const VerifyNumberContent = ({
  titleKey,
  subtextKey,
  inputCode,
  setInputCode,
  verificationError,
  verifyCode,
  statusPending,
  invalidCodeSent,
}: Props) => {
  const history = useHistory()

  return (
    <>
      <DashboardBanner
        title="Check your phone"
        titleKey={titleKey}
        subtext="Enter the code that was sent to your phone"
        subtextKey={subtextKey}
        icon={PhoneIcon}
        viewBox="0 0 42 42"
      >
        <Box mb={2} display="flex" justifyContent="space-between">
          <DigitFields value={inputCode} onChange={setInputCode} />
        </Box>
        {verificationError && <ErrorText>{verificationError}</ErrorText>}
        <Box mb={2}>
          <ButtonLarge
            id="submit-code"
            fullWidth
            disabled={!(inputCode.length === 6)}
            onClick={() => {
              verifyCode(inputCode, setInputCode)
            }}
          >
            {statusPending ? <LoadingSpinner height={20} color="inherit" /> : 'Next'}
          </ButtonLarge>
        </Box>

        {invalidCodeSent && (
          <StandardText align="center" color="error">
            Invalid code sent.
          </StandardText>
        )}

        <StandardText align="center">
          Haven't received a code?{' '}
          <AppLink inline to="#">
            <ButtonBase onClick={() => history.go(0)}>Resend</ButtonBase>
          </AppLink>
        </StandardText>
      </DashboardBanner>
    </>
  )
}
