import React from 'react'
import { getTextContent, useTextContent } from '../../../common/textContent'
import { SectionHeader, Spacing } from '@rse/uikit'
import { InputSelectPills } from '../components/InputSelectPills'
import { InputOption } from '../types/components'

export const PreApprovalEmploymentType: React.VFC = () => {
  const textContent = useTextContent()

  const options: InputOption[] = [
    {
      id: 'I work for someone',
      title: getTextContent(textContent, 'app-wizard.employment-work-for-someone', 'I work for someone'),
    },
    {
      id: 'I work for myself',
      title: getTextContent(textContent, 'app-wizard.employment-work-for-myself', 'I work for myself'),
    },
    {
      id: 'I have other income',
      title: getTextContent(textContent, 'app-wizard.employment-other-input', 'I have other income'),
    },
  ]

  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>Employment type</SectionHeader>
      <Spacing value={20} />
      <InputSelectPills id="employmentTypes" options={options} orientation="vertical" />
    </section>
  )
}
