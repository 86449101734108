import React, { LegacyRef, useCallback } from 'react'
import { useFormData } from '../helpers/useFormData'
import { validateFields } from '../helpers/validation'
import { ApplicationFormKey } from '../types/application'

export const ApplicationForm: React.FC<{
  formRef?: LegacyRef<HTMLFormElement>
  onSubmit: <T extends {}>(formData: T) => void
}> = ({ children, formRef, onSubmit }) => {
  const { formData, onValidationChange: onValidationErrors } = useFormData()

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const formKeys = getFormKeys(e.currentTarget)
      const errors = validateFields(formData, formKeys)
      onValidationErrors(errors)

      if (Object.values(errors).find((v) => !!v)) {
        console.info(errors)
        // HAVE ERRORS
        const firstError = formKeys.find((fk) => !!errors[fk])
        firstError && scrollToField(firstError)
      } else {
        // NO ERRORS
        onSubmit(formData)
      }
    },
    [onSubmit, formData, onValidationErrors]
  )

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

// UTILS
const getFormKeys = (formElement: HTMLFormElement): ApplicationFormKey[] => {
  const allInputs = formElement.querySelectorAll('input')
  const formKeys = new Set<ApplicationFormKey>()
  for (const inputNode of allInputs) {
    inputNode.name && formKeys.add(inputNode.name as ApplicationFormKey)
  }
  // console.info('getFormKeys', formKeys)
  return [...formKeys]
}

const scrollToField = (errorFieldName: string) => {
  const elem = document.querySelector(`*[name='${errorFieldName}']`) as HTMLElement
  if (elem) {
    elem.focus()
    elem.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  } else {
    console.warn('Cannot find elem with name', errorFieldName)
  }
}
