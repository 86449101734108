// Prototype of a field that automatically encrypts its contents, given a public key.
// This is useful for highly sensitive fields, like credit card number, where we only want a secure worker to
// have the capacity to decrypt. Thus, it's encrypted in transmission and in the persistence store.
// @author Justis Peters
// TODO move to config file
import { JSEncrypt } from 'jsencrypt'
const publicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAyduWf0gsXRK943/8FkQa
OEq0CJZFt/rmBcU4E9KYK9+qIH9NQKRt3JNGL4ZbLzBq7O2JmOQvIiSTPrau/rYn
df1mh2czDZHgC81beB4J/dSyOto1UVFBEDS7DZ7PI21ak0wq6MvilbHIZL1hEprM
M1zcHD1+P3OpyrEqAkMfu3o8sYuuzw+FfuVwjjVV8shMYWsMFMYG2SXVHrR8kKam
e4byZXPlKLv6otCVWAvAp1JeFlUxg9Ryq4jJPaFvcX2I9CWd2crHUS+FTvmyce/w
hKEqxjb2lexgeGEMyl3AH/QyTy3VGoXVIsBRzXUlcMeVc4HSm5gVU8vnqJQ9x060
gscH/T5txxSUPpkaNY1jU4rQ18PxyKoamYDD5eJ6O3U6UgwSdzggBUazI91DqZFK
qQcvVTe93YXp3TRFdbP82ekqt/ye5AXK6CuaCfBekIz1gIYu37CeEkTF2CrmXysE
aa8O7Uv4A9827YkRAawH/fbS8k1UGJmEKsJLMssbFhvZh4OA1kgQx9FoaVJm9gUu
PBPpNArBu29XtgpI4P5iaQbN55QPt/gostnJsAdV8S3206oiyNP+ACV+4qM5ZUq5
H9rgfXbLnBddt4YsALzWuQ5Dgl0CaC6N967iPqqkaNLr8d92vURDhUFzk/34lr5U
EuJTKNIrnPgaZgLh7ckYTCMCAwEAAQ==
-----END PUBLIC KEY-----`
const jsencrypt = new JSEncrypt()
jsencrypt.setPublicKey(publicKey)

export interface EncryptedStringOptions {
  mask?: (v: string) => string
}

export class EncryptedString {
  public encryptedValue = ''
  public maskedValue = ''

  constructor(value: string, o?: EncryptedStringOptions) {
    const defaultMask = (v: string): string => v.replace(/./g, '*')

    Object.defineProperty(this, 'mask', {
      enumerable: false,
      writable: true,
      value: o?.mask || defaultMask,
    })

    Object.defineProperty(this, 'realValueGetter', {
      enumerable: false,
      writable: true,
      value: (): string => value,
    })

    this.value = value
  }

  private mask(v: string): string {
    return v.replace(/./g, '*')
  }
  private realValueGetter(): string {
    return 'placeholder'
  }

  public get value(): string {
    return this.realValueGetter()
  }
  public set value(newValue: string) {
    this.realValueGetter = () => newValue
    this.maskedValue = this.mask(newValue)
    this.encryptedValue = jsencrypt.encrypt(newValue)
  }
}

