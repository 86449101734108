import { Box } from '@material-ui/core'
import React from 'react'
import { TextContent } from '../../../common/textContent'
import { SectionHeader } from '@rse/uikit'
import { InputText } from '../components/InputText'

export const ProfileCurrentRent: React.FC = () => {
  return (
    <section>
      <Box pt="28px">
        <SectionHeader>
          <TextContent
            resourceKey="app-wizard.title-current-rent-payment"
            defaultValue="How much do you currently pay for rent?"
          />
        </SectionHeader>
      </Box>
      <Box pt="20px">
        <InputText id="currentRent" label="$ *" type="number" />
      </Box>
    </section>
  )
}
