import { Box, Mark, Slider, styled, TextField, withStyles } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { QuestionComponentProps } from '.'
import { TextError } from '../../../../pages/Application/components/TextError'
import { validateSingleField } from '../../../../pages/Application/helpers/validation'

export default function RangeSliderInput({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const [currentValue, setCurrentValue] = useState([0, 0])
  const [currentAnswer, setcurrentAnswer] = useState<string[]>([])
  const [helperText, setHelperText] = useState('')
  const [isQuestionAnswerValid, setIsQuestionAnswerValid] = useState(true)

  useEffect(() => {
    const answerValues: number[] = []
    if (!Answer[0]) {
      if (QuestionConfig.MinValue) {
        Answer[0] = QuestionConfig.MinValue.toString()
      } else {
        Answer[0] = '0'
      }
    }
    if (!Answer[1]) {
      if (QuestionConfig.MaxValue) {
        Answer[1] = QuestionConfig.MaxValue.toString()
      } else {
        Answer[1] = '0'
      }
    }
    answerValues.push(parseInt(Answer[0], 10))
    answerValues.push(parseInt(Answer[1], 10))
    setCurrentValue(answerValues)

    setcurrentAnswer(Answer)
    setErrorState(answerValues)
  }, [])

  const isRequired: boolean = QuestionConfig.IsRequired || false

  let bottomMarks: [string, string] | undefined = undefined
  if (otherProps.leftBottomMark && otherProps.rightBottomMark) {
    bottomMarks = [otherProps.leftBottomMark, otherProps.rightBottomMark]
  }

  let step: number | undefined = undefined
  if (otherProps.step) {
    step = parseInt(otherProps.step)
  }
  otherProps.valueLabelDisplay = otherProps.valueLabelDisplay || 'on'
  let defaultValue = undefined
  if (QuestionConfig.DefaultMaxValue && QuestionConfig.DefaultMinValue) {
    defaultValue = [QuestionConfig.DefaultMinValue, QuestionConfig.DefaultMaxValue]
  }

  let marks = undefined
  if (QuestionConfig.QuestionChoices) {
    marks = QuestionConfig.QuestionChoices.map((x) => ({ value: x.Value, label: x.Label }))
  }
  otherProps.marks = marks

  const setErrorState = (currentValue: number[]) => {
    const errors = validateSingleField(currentValue, QuestionConfig.QuestionName)
    const isValid = errors ? false : true

    ValidStatusUpdateHandler(isValid)
    setIsQuestionAnswerValid(isValid)
    if (!isValid) {
      setHelperText(errors || '')
    } else {
      setHelperText('')
    }
  }

  const changeHandler = (value: any) => {
    const updatedValue: number[] = [value[0], value[1]]
    const updatedAnswer: string[] = [value[0].toString(), value[1].toString()]
    setCurrentValue(updatedValue)
    setcurrentAnswer(updatedAnswer)

    AnswerUpdateHandler(updatedAnswer)

    setErrorState(updatedValue)
  }

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      <Box position="relative">
        <Box px={1}>
          <StyledSlider
            onChange={(_, val: number | number[]) => {
              changeHandler(val)
            }}
            name={QuestionConfig.QuestionName}
            value={currentValue || []}
            max={QuestionConfig.MaxValue}
            min={QuestionConfig.MinValue}
            step={step}
            defaultValue={defaultValue}
            {...otherProps}
          />
        </Box>
        {bottomMarks && (
          <Box display="flex" justifyContent="space-between">
            <BottomMark>{bottomMarks[0]}</BottomMark>
            <BottomMark>{bottomMarks[1]}</BottomMark>
          </Box>
        )}
        {!isQuestionAnswerValid && displayQuestionErrors && <TextError>{helperText}</TextError>}
      </Box>
    </>
  )
}

const BottomMark = styled('div')({
  fontSize: '14px',
  lineHeight: '143%',
  color: '#8C8AAC',
})

const StyledSlider = withStyles({
  root: {
    marginTop: '35px',
  },
  active: {},
  valueLabel: {
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#6B2EF5',
      fontSize: '14px',
    },
  },
  rail: {
    opacity: 0.1,
  },
  markActive: {
    opacity: 1,
    color: 'red',
    backgroundColor: 'currentColor',
  },
})(Slider)
