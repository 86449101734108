import { firebaseAuth } from '../../firebase'

/*
declare global {
    interface Window { fb: any; }
}

window.fb = firebase
*/

class FirebaseAuthService {
  public async SendResetPasswordEmail(email: string): Promise<void> {
    return await firebaseAuth.SendResetPasswordEmail(email)
  }

  public async ChangePassword(password: string, newPassword: string): Promise<void> {
    try {
      await firebaseAuth.ValidateNewPassword(newPassword)
      await firebaseAuth.ReAuthenticate(password)
      await firebaseAuth.ChangePassword(newPassword)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export { FirebaseAuthService }