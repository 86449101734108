import { Container } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { URL_DASHBOARD, URL_MEMBERSHIP_WIZARD } from '../../common/constants/routes'
import { useErrorHandler } from '../../common/hooks'
import { Spacing } from '@rse/uikit'
import { saveApplication } from '../../firebase'
import { ApplicationForm } from './components/ApplicationForm'
import { LayoutHeader } from './components/layout/LayoutHeader'
import { StepButtons } from './components/layout/StepButtons'
import { useFormData } from './helpers/useFormData'
import { useProgressInfo } from './helpers/useProgressData'

export function ApplicationStep({ stepComponent }: { stepComponent: React.ComponentType }) {
  const history = useHistory()
  const { formData } = useFormData()
  const { currentStep, isLastStep } = useProgressInfo()
  const { errorHandler } = useErrorHandler('ApplicationStep')

  const onSubmit = useCallback(async (): Promise<void> => {
    if (isLastStep) {
      history.push(`${URL_DASHBOARD}`)
    } else {
      try {
        formData.lastWizardStep = currentStep
        await saveApplication(formData)
        history.push(`${URL_MEMBERSHIP_WIZARD}/${currentStep + 1}`)
      } catch (error: unknown) {
        errorHandler(error)
      }
    }
  }, [formData, currentStep, isLastStep])

  return (
    <>
      <LayoutHeader />
      <Container maxWidth="xs" style={{ padding: '0 20px' }}>
        <ApplicationForm onSubmit={onSubmit}>
          {React.createElement(stepComponent)}
          <Spacing value={20} />
          <StepButtons />
        </ApplicationForm>
      </Container>
      <Spacing value={50} />
    </>
  )
}
