import app from 'firebase/app'


export const filterUndefined = <T, K extends keyof T>(source: T) =>
    Object.entries(source)
        .reduce(
            (accumulator, [key, value]) => value !== undefined ? { ...accumulator, [key]: value } : accumulator,
            {} as Pick<T, K>
        )


export async function GetFireStoreRecord<T>(db: app.firestore.Firestore, collectionPath: string, docId: string): Promise<T | null> {
    let doc: app.firestore.DocumentSnapshot
    try {
        doc = await db.doc(`${collectionPath}/${docId}`).get()
    } catch (error) {
        throw new Error('Could not connect to FireStore.')
    }

    if (doc.exists) {
        const docData = doc.data()
        if (!docData) {
            return null
        }

        docData.id = docId
        const dataItem = <T>{ ...docData }

        return dataItem
    } else {
        return null
    }
}

export async function AddOrUpdateFireStoreRecord(db: app.firestore.Firestore, collectionPath: string, docId: string, mergeRecord: boolean, record: any): Promise<void> {
    record.id = docId
    const recordUndefinedRemoved = filterUndefined(record)
    await db.collection(collectionPath).doc(docId).set(recordUndefinedRemoved, { merge: mergeRecord })
}
