import { MemberBuyingGroupMatchEntity } from '@rse/core'
import { usePersistentCollectionItem } from '.'

export function useBuyingGroupFavStatus(myUid: string, groupId: string) {

  const group = usePersistentCollectionItem<MemberBuyingGroupMatchEntity>(`profileMatches/${myUid}/buyingGroupMatches/${groupId}`)
  const isFav = group?.document?.value?.isFab || false

  return isFav
}
