import React from 'react'
import { Box, makeStyles, createStyles, Typography, SvgIcon, ListItemSecondaryAction } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    container: {
      position: 'absolute',
      zIndex: 2000,
      background: palette.common.white,
      color: palette.common.black,
      boxShadow: '0px 2px 10px rgba(0,0,0,.05)',
      width: 300,

      '& .MuiListItemText-root': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    menuLabel: {
      marginLeft: spacing(1),
    },
    inlineIcon: {
      fontSize: 14,
      marginRight: 2,
    },
  })
)

export type FilterListOption = {
  title: string
  icons: React.FunctionComponent[]
  checked: boolean
}

type Props = {
  options: FilterListOption[]
  onChange: (index: number, value: boolean) => void
  top?: number
  right?: number
  bottom?: number
  left?: number
}

export function MessageFilter({ top, right, left, bottom, onChange, options }: Props) {
  const { container, inlineIcon, menuLabel } = useStyles()

  return (
    <Box className={container} style={{ top, right, left, bottom }}>
      <List>
        {options.map((item, index) => (
          <ListItem dense key={index}>
            <ListItemText>
              <Typography color="textSecondary">
                {item.icons.length > 0 && (
                  item.icons.map((icon) => (
                    <SvgIcon component={icon} color="primary" fontSize="small" key={index} className={inlineIcon} />
                  ))
                )}
                <span className={menuLabel}>{item.title}</span>
              </Typography>
            </ListItemText>
            <ListItemSecondaryAction>
              <Checkbox
                color="primary"
                size="small"
                checked={item.checked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(index, event.target.checked)}
                inputProps={{ 'aria-label': item.title }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
