import { FormControl, InputLabel, Select, MenuItem, makeStyles, createStyles, SelectProps } from '@material-ui/core'
import React from 'react'
import { useFormData } from '../helpers/useFormData'
import { InputId, InputLabelProp, InputOmits, InputOptions } from '../types/components'
import { validateFields } from '../helpers/validation'

type Props = InputId & Omit<SelectProps, InputOmits> & Required<InputLabelProp> & InputOptions

export const InputSelect: React.VFC<Props> = ({ id, label, options = [], ...rest }) => {
  const classes = useStyles()
  const labelId = `${id}-label`
  const { formData, formErrors, onFieldChange, onValidationChange: onValidationErrors } = useFormData()
  const handleBlur = () => {
    onValidationErrors(validateFields(formData, [id]))
  }
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        name={id}
        value={formData[id] || ''}
        onChange={(e) => onFieldChange(id, e.target.value as string | number)}
        onBlur={handleBlur}
        label={label}
        error={!!formErrors[id]}
        {...rest}
      >
        {options.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            {o.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 150,
    },
  })
)
