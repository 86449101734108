import React from 'react'
import { MemberMatchEntity } from '@rse/core'
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { useHistory, Link } from 'react-router-dom'
import { PreviewTitle } from '@rse/uikit'
import { IconConnect } from '../../../components/icons'
import { AppRoutes } from '../../../App/routeUtils'
import { CardText } from '../../../components/common/CardText'
import { UserAvatarPublic } from '../../../components/common'
import { ClubMemberFavIcon } from '../../../components/common'
import { calculateAge } from '../../../common/utils'

export const useStyles = makeStyles(({ palette }: Theme) =>
  createStyles({
    // override for mui styles
    secondaryActionStyle: {
      position: 'static',
      transform: 'translateY(-10px)',
      alignSelf: 'normal',
    },
    listStyle: {
      borderBottom: `1px solid ${palette.grey[300]}`,
      paddingLeft: 0,
      display: 'flex',
    },
    iconStyle: {
      minWidth: 0,
    },
  })
)

// FIXME: remove when datamodel is extended
type ClubMemberInfoExtended = {
  age?: string
  gender?: string
  groupId?: string
}

type ClubMemberInfo = {
  member: MemberMatchEntity & ClubMemberInfoExtended
}

export function ClubMemberCard({ member }: ClubMemberInfo) {
  const { id, displayName, profileImageUrl, age, birthday, groupId, description } = member
  const { secondaryActionStyle, listStyle, iconStyle } = useStyles()
  const { palette } = useTheme()
  const history = useHistory()

  const ageVal = age || calculateAge(birthday || '') || ''
  const hasGroup = groupId ?? Math.random() > 0.7
  
  const groupIndicator =
    hasGroup && groupId ? (
      <Link to={AppRoutes.group(groupId)}>
        <IconConnect style={{ height: '1em' }} fill={palette.primary.main} />
      </Link>
    ) : null

  const buttonNav = () => {
    history.push(AppRoutes.clubMember(id))
  }

  return (
    <ListItem button className={listStyle} onClick={buttonNav}>
      <ListItemSecondaryAction className={secondaryActionStyle}>
        <ClubMemberFavIcon memberId={id} isFab={member.isFab} isDark={!profileImageUrl} />
      </ListItemSecondaryAction>
      <ListItemAvatar>
        <Box pr={2}>
          <UserAvatarPublic uid={id} size="large" />
        </Box>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <PreviewTitle gutterBottom>
            {displayName}, {ageVal} {groupIndicator}
          </PreviewTitle>
        }
        secondary={<CardText linesMax={2}>{description || 'No description given'}</CardText>}
      />
      <ListItemIcon className={iconStyle}>
        <ChevronRight color="disabled" />
      </ListItemIcon>
    </ListItem>
  )
}
