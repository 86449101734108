import React from 'react'
import { Box, List, ListItem, ListItemText } from '@material-ui/core'
import { useHistory } from 'react-router'

import { AppRoutes } from '../../../../App/routeUtils'
import { SectionHeader } from '@rse/uikit'
import settings from '../../../../settings'


export default function SandboxIndex() {
  const history = useHistory()
  const routeTo = (pageSubPath: string) => {
    history.push(AppRoutes.sandboxPage(pageSubPath))
  }

  return (
    <Box>
      <SectionHeader>Sandbox Content</SectionHeader>
      <p>Client version: {settings.clientVersion}</p>
      <p>Client app code: {settings.clientAppCode}</p>
      <List component="nav" aria-label="sandbox items list">
        <ListItem button component="a" onClick={() => routeTo('icons')}>
          <ListItemText primary="Icons" />
        </ListItem>
        <ListItem button component="a" onClick={() => routeTo('page')}>
          <ListItemText primary="Page" />
        </ListItem>
        <ListItem button component="a" onClick={() => routeTo('Dynamicwizard')}>
          <ListItemText primary="Dynamic Wizard Demo" />
        </ListItem>
        <ListItem button component="a" onClick={() => routeTo('dw/groups/12345')}>
          <ListItemText primary="Dynamic Wizard Group View Page" />
        </ListItem>
        <ListItem button component="a" onClick={() => routeTo('authtest')}>
          <ListItemText primary="Auth Test" />
        </ListItem>
        <ListItem button component="a" onClick={() => routeTo('simulatedError')}>
          <ListItemText primary="Simulated Error" />
        </ListItem>
        <ListItem button component="a" onClick={() => routeTo('fcm')}>
          <ListItemText primary="Firebase Cloud Messaging" />
        </ListItem>
        <ListItem button component="a" onClick={() => routeTo('cascading-buckets')}>
          <ListItemText primary="Cascading Buckets" />
        </ListItem>
      </List>
    </Box>
  )
}
