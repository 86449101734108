import React from 'react'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'
import { ReactComponent as ClockIcon } from '../../assets/clock.svg'

export function ApplicationPending() {
  return (
    <DashboardBanner
      icon={ClockIcon}
      viewBox="0 0 50 50"
      title="Application pending"
      titleKey="dashboard.application-pending-title"
      subtext={'Our "Happiness Heroes" will contact you as soon as possible.'}
      subtextKey="dashboard.application-pending-subtext"
    ></DashboardBanner>
  )
}
