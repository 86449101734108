/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import DynamicWizardBody from './components/DynamicWizardBody'
import { useDynamicWizard } from './hooks/useDynamicWizard'

interface DynamicWizardProps {
  ConfigID: string
  Slots?: Record<string, React.ReactElement>
}

export default function DynamicWizard({ ConfigID, Slots }: DynamicWizardProps) {
  const dynamicWizardService = useDynamicWizard(ConfigID)

  return (
    <>
      {dynamicWizardService.isLoaded && !dynamicWizardService.configDocument && <div>No config data</div>}
      {!dynamicWizardService.isLoaded && <p>Loading..</p>}

      {dynamicWizardService.isLoaded && dynamicWizardService.configDocument && (
        <DynamicWizardBody
          userData={dynamicWizardService.userData}
          configData={dynamicWizardService.configDocument}
          savePageDataAction={dynamicWizardService.SavePageAnswersAction}
          Slots={Slots}
        />
      )}
    </>
  )
}
