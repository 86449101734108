import { FormControlLabel, styled, Switch, TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { QuestionComponentProps } from '.'
import { validateSingleField } from '../../../../pages/Application/helpers/validation'

export default function ToggleSwitchInput({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const [currentValue, setCurrentValue] = useState(false)

  const color = otherProps.color || 'primary'

  useEffect(() => {
    let currentAnswer = false

    if (Answer && Answer[0]) {
      if (Answer[0].toLowerCase() == 'true') currentAnswer = true
    }
    setCurrentValue(currentAnswer)
    if (currentAnswer) {
      AnswerUpdateHandler(['true'])
    } else {
      AnswerUpdateHandler(['false'])
    }
    ValidStatusUpdateHandler(true)
  }, [])

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const currentAnswer = event.target.checked
    setCurrentValue(currentAnswer)
    if (currentAnswer) {
      AnswerUpdateHandler(['true'])
    } else {
      AnswerUpdateHandler(['false'])
    }
  }

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            name={QuestionConfig.QuestionName}
            checked={currentValue}
            onChange={changeHandler}
            color={color}
            {...otherProps}
          />
        }
        label={<LabelText>{QuestionConfig.Title}</LabelText>}
      />
    </>
  )
}

const LabelText = styled('span')(({ theme }) => ({
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.15px',
  color: theme.palette.text.primary,
}))
