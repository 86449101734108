import React from 'react'
import { Container, ContainerTypeMap } from '@material-ui/core'
import { DefaultComponentProps } from '@material-ui/core/OverridableComponent'

export const BodyContainer: React.FC<DefaultComponentProps<ContainerTypeMap<{}, 'div'>>> = ({
  children,
  style = {},
  ...rest
}) => (
  <Container maxWidth="xs" style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', ...style }} {...rest}>
    {children}
  </Container>
)
