import React from 'react'
import { Box } from '@material-ui/core'
import { SectionHeader, Spacing } from '@rse/uikit'
import states from '../../../common/constants/USStates'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { InputSelect } from '../components/InputSelect'
import { InputText } from '../components/InputText'
import { InputOption } from '../types/components'

const stateOptions: InputOption[] = states.map((s) => ({
  id: s.code,
  title: s.state,
}))

export const PreApprovalCurrentAddress = () => {
  const textContent = useTextContent()

  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent resourceKey="app-wizard.field-current-address" defaultValue="Current Address" />
      </SectionHeader>
      <Spacing value={20} />
      <InputText id="street" label={getTextContent(textContent, 'app-wizard.field-street', 'Street *')} />
      <Spacing value={20} />
      <InputText
        id="streetOptional"
        label={getTextContent(textContent, 'app-wizard.field-street-optional', 'Street (optional)')}
      />
      <Spacing value={20} />
      <InputText id="city" label={getTextContent(textContent, 'app-wizard.field-city', 'City')} />
      <Spacing value={20} />
      <Box display="flex">
        <Box flexGrow={1}>
          <InputText
            id="zipCode"
            label={getTextContent(textContent, 'app-wizard.field-zip-code', 'Zip Code *')}
            type="number"
          />
        </Box>
        <Box ml={1}>
          <InputSelect
            id="state"
            label={getTextContent(textContent, 'app-wizard.field-state', 'State *')}
            options={stateOptions}
          />
        </Box>
      </Box>
    </section>
  )
}
