import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { ButtonFullWidthSecondary } from '@rse/uikit'
import { TextContent } from '../../../common/textContent'
import { ReactComponent as LogoutIcon } from '@rse/uikit/lib/icons/logout.svg'
import { useAuthService } from '../../../common/hooks'
import { AuthServiceContext } from '../../../App'

interface Props {
  buttonType?: 'icon' | undefined
  callBack?: () => void
}

export function LogoutButton({ buttonType, callBack }: Props) {
  const authContext = useContext(AuthServiceContext)
  const { logout, loading } = useAuthService()
  const history = useHistory()

  const handleFormLogout = () => {
    if (callBack) callBack()
    logout().then(() => console.log('logout complete'))
    authContext.authDispatch({type:'logout', postLogoutUrl:'/'})
    history.push('/')
  }

  if (loading) return null
  else if (buttonType === 'icon') return (
    <IconButton onClick={handleFormLogout} color="inherit">
      <LogoutIcon width={24} height={24} color="inherit" />
    </IconButton>
  )
  else return (
    <ButtonFullWidthSecondary onClick={handleFormLogout} fullWidth startIcon={<LogoutIcon />}>
      <TextContent resourceKey="menu.logout" defaultValue="Log out" />
    </ButtonFullWidthSecondary>
  )
}
