import { Box, Container, useTheme } from '@material-ui/core'
import React, { useContext } from 'react'
import { ThemeContext } from '../../../../common/theme/ThemeContext'
import { LogoutButton } from '../../../../components/inputs'
import { HeaderBox } from '../../../../components/layout/appLayout/Header/HeaderBox'
import { StepsProgress } from './StepsProgress'
import { Header } from '../../../../components/layout'

export const LayoutHeader = () => {
  const theme = useTheme()
  const { type } = useContext(ThemeContext)

  return (
    <>
      <HeaderBox bgcolor={type === 'light' ? theme.palette.primary.main : 'transparent'} color="white">
        <Header title="Apply To Join" hideMenu={true}>
          <Box display="flex" alignItems="center">
            <LogoutButton buttonType="icon" />
          </Box>
        </Header>
      </HeaderBox>
      <Container maxWidth="xs">
        <StepsProgress />
      </Container>
    </>
  )
}
