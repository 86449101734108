import React from 'react'
import { TextContent } from '../../../common/textContent'
import { SectionHeader, Spacing } from '@rse/uikit'
import { InputRangeSlider } from '../components/InputRangeSlider'

const marks = [
  { value: 18, label: '18' },
  { value: 25, label: '25' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 60, label: '60' },
  { value: 80, label: '80' },
  { value: 100, label: '100' },
]

export const PrefsMateAgeRange: React.VFC = () => {
  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent resourceKey="app-wizard.title-age-range" defaultValue="Age Range" />
      </SectionHeader>
      <InputRangeSlider
        id="ageRange"
        defaultValue={[23, 50]}
        valueLabelDisplay="on"
        step={1}
        min={18}
        max={100}
        marks={marks}
      />
    </section>
  )
}
