import React, { useState } from 'react'
import { StepId } from '../types/components'

export type OnClickButtonHandler = {
  (): boolean
}

export type ApplicationFormProgressState = {
  currentStep: StepId
  latestStep: StepId
  latestProgress: number
  nextButtonBlocked: boolean
  blockNextButton: () => void
  unblockNextButton: () => void
  onClickPrevButton?: () => boolean
  onClickNextButton?: () => boolean
  setOnClickPrevButton: (handler: OnClickButtonHandler | undefined) => void
  setOnClickNextButton: (handler: OnClickButtonHandler | undefined) => void
}

const initialProgressState: ApplicationFormProgressState = {
  currentStep: 1,
  latestStep: 1,
  latestProgress: 4,
  nextButtonBlocked: false,
  blockNextButton: () => {},
  unblockNextButton: () => {},
  onClickPrevButton: undefined,
  onClickNextButton: undefined,
  setOnClickPrevButton: () => {},
  setOnClickNextButton: () => {},
}

export const ApplicationProgressDataContext = React.createContext<
  [ApplicationFormProgressState, React.Dispatch<React.SetStateAction<ApplicationFormProgressState>> | undefined]
>([initialProgressState, undefined])

export const ApplicationFormProgressProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<ApplicationFormProgressState>(initialProgressState)

  const blockNextButton = () => setState((state) => ({ ...state, nextButtonBlocked: true }))
  const unblockNextButton = () => setState((state) => ({ ...state, nextButtonBlocked: false }))

  const setOnClickPrevButton = (onClickPrevButton: OnClickButtonHandler | undefined) =>
    setState((state) => ({ ...state, onClickPrevButton }))
  const setOnClickNextButton = (onClickNextButton: OnClickButtonHandler | undefined) =>
    setState((state) => ({ ...state, onClickNextButton }))

  return (
    <ApplicationProgressDataContext.Provider
      value={[{ ...state, blockNextButton, unblockNextButton, setOnClickPrevButton, setOnClickNextButton }, setState]}
    >
      {children}
    </ApplicationProgressDataContext.Provider>
  )
}
