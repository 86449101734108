import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Container, makeStyles, createStyles, TextField } from '@material-ui/core'
import { useFormik } from 'formik'

import { AppLayout, Footer, Header } from '../../../components/layout'
import { UserContext } from '../../../App'
import { ButtonLarge, Popup, Spacing } from '@rse/uikit'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { FormikScrollToError } from '../../../components/common'
import { FormValues, validate } from './common/formAssets'
import exclamationIcon from '@rse/uikit/lib/icons/exclamation.svg'
import { useAuthService } from '../../../common/hooks'

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    password: {},
    validationErrors: {
      color: 'red',
    },
  })
)

export function ChangePasswordPage() {
  const { changePassword } = useAuthService()
  const classes = useStyles()
  const history = useHistory()
  const textContent = useTextContent()

  const user = useContext(UserContext)
  if (!user) {
    throw new Error('Authorization required')
  }

  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [changePasswordError, setChangePasswordError] = useState<any>(null)

  const formik = useFormik<FormValues>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPassword2: '',
    },
    validate,
    onSubmit: async (values) => {
      const { currentPassword, newPassword } = values

      setChangePasswordError(null)
      setPopupOpen(false)

      try {
        await changePassword(currentPassword, newPassword)
        setPopupOpen(true)
      } catch (error) {
        setChangePasswordError(error)
        setPopupOpen(true)
      }
    },
  })

  const container = (
    <Container maxWidth="xs">
      <Popup open={popupOpen} setOpen={setPopupOpen}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img src={exclamationIcon} alt="exclamation icon" />
          {changePasswordError && (
            <>
              <h3>
                <TextContent
                  resourceKey="change-password.change-password-error"
                  defaultValue="Error changing password."
                />
              </h3>
              {changePasswordError && <Box mb={3}>{changePasswordError.message}</Box>}
            </>
          )}
          {!changePasswordError && (
            <h3>
              <TextContent
                resourceKey="change-password.change-password-success"
                defaultValue="Password has been changed."
              />
            </h3>
          )}
          <Box width="100%">
            <ButtonLarge
              fullWidth
              isUppercase={false}
              onClick={() => {
                setPopupOpen(false)
                if (!changePasswordError) {
                  history.goBack()
                }
              }}
            >
              <TextContent resourceKey="buttons.close" defaultValue="Close" />
            </ButtonLarge>
          </Box>
        </Box>
      </Popup>
      <Box my={3} className={classes.container}>
        <form onSubmit={formik.handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
          <FormikScrollToError formik={formik} />
          <Box className={classes.password}>
            <TextField
              variant="outlined"
              name="currentPassword"
              fullWidth
              label={getTextContent(textContent, 'change-password.field-current-password', 'Current password *')}
              type="password"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.currentPassword && formik.errors.currentPassword ? (
              <Box className={classes.validationErrors}>{formik.errors.currentPassword}</Box>
            ) : null}
          </Box>
          <Spacing />
          <Box className={classes.password}>
            <TextField
              variant="outlined"
              name="newPassword"
              fullWidth
              label={getTextContent(textContent, 'change-password.field-new-password', 'New password *')}
              type="password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.newPassword && formik.errors.newPassword ? (
              <Box className={classes.validationErrors}>{formik.errors.newPassword}</Box>
            ) : null}
          </Box>
          <Spacing />
          <Box className={classes.password}>
            <TextField
              variant="outlined"
              name="newPassword2"
              fullWidth
              label={getTextContent(textContent, 'change-password.field-confirm-password', 'Confirm password *')}
              type="password"
              value={formik.values.newPassword2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.newPassword2 && formik.errors.newPassword2 ? (
              <Box className={classes.validationErrors}>{formik.errors.newPassword2}</Box>
            ) : null}
          </Box>
          <Spacing />
          <Box mb={2}>
            <ButtonLarge fullWidth type="submit">
              <TextContent resourceKey="buttons.change-password" defaultValue="Change Password" />
            </ButtonLarge>
          </Box>
        </form>
      </Box>
    </Container>
  )

  return <AppLayout header={<Header title="Change Password" back="history" />} body={container} footer={<Footer />} />
}
