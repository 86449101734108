import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { BuyingGroupCreate } from './BuyingGroupCreate'
import { BuyingGroupList } from './BuyingGroupList'
import { BuyingGroupDetails } from './BuyingGroupDetails'
import { BuyingGroupMembers } from './BuyingGroupMembers'

export default function BuyingGroups() {
  return (
    <Switch>
      <Route path="/groups" exact>
        <BuyingGroupList />
      </Route>
      <Route path="/groups/create">
        <BuyingGroupCreate />
      </Route>
      <Route path="/groups/:groupId/view">
        <BuyingGroupDetails />
      </Route>
      <Route path="/groups/:groupId/members">
        <BuyingGroupMembers />
      </Route>
    </Switch>
  )
}
