import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { DateRange, LocationOn, AttachMoney, SvgIconComponent } from '@material-ui/icons'
import { QuestionComponentProps } from '.'
import { StandardText } from '@rse/uikit'

const iconComponents: Record<string, SvgIconComponent> = {
  DateRange: DateRange,
  LocationOn: LocationOn,
  AttachMoney: AttachMoney,
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    iconStyle: {
      height: 13,
      width: 'auto',
      marginRight: spacing(1),
    },
  })
)

export default function IconText({
  QuestionConfig,
  Answer,
  displayQuestionErrors,
  AnswerUpdateHandler,
  ValidStatusUpdateHandler,
  ...otherProps
}: QuestionComponentProps & Record<string, any>) {
  const styles = useStyles()
  const { icon: iconName } = QuestionConfig.RenderControlProps

  console.log(iconName)

  const IconComponent = iconComponents[iconName as string]

  return (
    <>
      {QuestionConfig.ShowTitle && <p>{QuestionConfig.Title}</p>}

      <StandardText>
        {IconComponent && <IconComponent className={styles.iconStyle} />}
        {Answer[0]}
      </StandardText>
    </>
  )
}
