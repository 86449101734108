import React, { createContext } from 'react'
import { firebaseCollectionNames, usePersistentCollection } from '../../firebase'
import { TextContentType } from './types'

const initialTextContent: TextContentType[] = []

export const TextContentContext = createContext<TextContentType[]>(initialTextContent)

export const TextContentProvider: React.FC<{ loadingIndicator: JSX.Element }> = ({ loadingIndicator, children }) => {
  const {
    collection: { value: textContent, loading },
  } = usePersistentCollection<TextContentType>(firebaseCollectionNames.textContent)

  if (loading || textContent === undefined) {
    return loadingIndicator
  }

  return <TextContentContext.Provider value={textContent}>{children}</TextContentContext.Provider>
}
