import React from 'react'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { SectionHeader, Spacing } from '@rse/uikit'
import { InputText } from '../components/InputText'

export const PreApprovalMonthlyIncome = () => {
  const textContent = useTextContent()

  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent resourceKey="app-wizard.title-total-monthly-income" defaultValue="Total Monthly Income" />
      </SectionHeader>
      <Spacing value={14} />
      <InputText
        id="monthlyIncome"
        label="$ *"
        type="number"
        helperText={getTextContent(
          textContent,
          'app-wizard.monthly-income-includes',
          'Includes child support, alimony, investments'
        )}
      />
    </section>
  )
}
