import React from 'react'
import { ListItem, ListItemAvatar, ListItemText, Box, Badge, Avatar, useTheme, Typography } from '@material-ui/core'
import { formatDistanceToNow } from 'date-fns'
import { useHistory } from 'react-router-dom'

import { AppRoutes } from '../../App/routeUtils'
import { GroupAvatarPublic, UserAvatarPublic } from '../../components/common'
import { IconMatch } from '../../components/icons/'
import { firebaseSetUserNotificationRead } from '../../firebase'
import { notificationTypeToString } from '../../models'
import { useStyles } from './styles'
import { getNotificationIcon } from './utils/getNotificationIcon'

export type NotificationProps = {
  item: any,
  userId: string
}

export function Notification({ item, userId }: NotificationProps) {
  const history = useHistory()
  const { palette } = useTheme()
  const dateString = item.incomingDate && !isNaN(item.incomingDate) ? formatDistanceToNow(item.incomingDate) : ''
  const accepted = notificationTypeToString(item).indexOf('Accepted') != -1
  const badgeBG = accepted ? palette.success.main : palette.primary.main
  const badgeIcon = getNotificationIcon(item)

  const { dotStyle, badgeIconStyle, listItemStyle } = useStyles({ badgeBG })

  let avatar, route: string

  if (item.gid) {
    route = AppRoutes.group(item.gid)
    avatar = <GroupAvatarPublic groupId={item.gid} />
  } else if (item.originUid) {
    route = AppRoutes.clubMember(item.originUid)
    avatar = <UserAvatarPublic uid={item.originUid} />
  }

  const isItemUnread = !item.readDate

  const buttonNav = async () => {
    if (isItemUnread) {
      await firebaseSetUserNotificationRead(userId, item.id)
    }

    history.push(route)
  }

  return (
    // TODO: this is actually a link to a user or group (but does not look like this) - needs to be considered in terms of accessibility
    <ListItem button onClick={buttonNav} className={listItemStyle}>
      {avatar && (
        <Badge
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={badgeIcon}
          className={badgeIconStyle}
        >
          <ListItemAvatar>{avatar}</ListItemAvatar>
        </Badge>
      )}
      {!avatar && (
        <ListItemAvatar>
          <GenericAvatar />
        </ListItemAvatar>
      )}
      <ListItemText
        disableTypography
        primary={<Typography variant="body2">{item.message}</Typography>}
        secondary={
          <Typography variant="caption" color="textSecondary">
            {dateString}
          </Typography>
        }
      />
      {isItemUnread && <Box className={dotStyle}></Box>}
    </ListItem>
  )
}

const GenericAvatar = () => {
  const theme = useTheme()

  return (
    <Avatar style={{ backgroundColor: theme.palette.warning.main }}>
      <IconMatch />
    </Avatar>
  )
}
