import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import { MessageThreadType } from '@rse/core'
import { ChatMessage } from './ChatMessage'
import { ChatMessageSet } from './ChatMessageSet'
import type { ChatThreadSection } from '../../../../models'
import { firebaseInstance } from '../../../../firebase'

type Props = {
  sections: ChatThreadSection[]
  type: MessageThreadType
  threadId: string
}

export const ChatTread: React.VFC<Props> = ({ sections, type, threadId }) => {
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, [sections])

  useEffect(() => {
    const func = firebaseInstance.firebaseFunction.httpsCallable('markMessageThreadAsRead')
    func({ messageThreadId: threadId }).catch(console.error)
  }, [sections])

  return (
    <Box>
      {sections.map(({ sectionTitle, sectionType, messages }, i) => (
        <ChatMessageSet key={i} sectionTitle={sectionTitle} sectionType={sectionType}>
          {messages.map((message, i) => (
            <ChatMessage key={i} type={type} {...message} />
          ))}
        </ChatMessageSet>
      ))}
    </Box>
  )
}
