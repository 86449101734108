import React, { useContext, useEffect, useState } from 'react'
import { Container } from '@material-ui/core'
import { AccountForm } from './components'
import { UserProfileEntity } from '@rse/core'
import { LoadingSpinner } from '@rse/uikit'

import { AppLayout, Footer, Header } from '../../../components/layout'
import { firebaseGetUserProfile, firebaseSaveUserProfile } from '../../../firebase'
import { UserContext } from '../../../App'
import { BodyContainer } from '../../../components/layout/BodyContainer'

export function SettingsPage() {
  const currentUser = useContext(UserContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (currentUser) {
      firebaseGetUserProfile(currentUser).finally(() => setLoading(false))
    }
  }, [currentUser])

  if (loading || !currentUser) {
    return <BodyContainer><LoadingSpinner /></BodyContainer>
  }

  return (
    <AppLayout
      header={<Header title="Account Settings" />}
      body={
        <Container maxWidth="xs">
          <AccountForm
            get={async () => firebaseGetUserProfile(currentUser)}
            save={async (profile: UserProfileEntity) => firebaseSaveUserProfile(currentUser, profile)}
          />
        </Container>
      }
      footer={<Footer />}
    />
  )
}
