import React from 'react'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { SectionHeader, Spacing } from '@rse/uikit'
import { InputSelectPills } from '../components/InputSelectPills'
import { InputOption } from '../types/components'

export const PreApprovalIncomeDuration = () => {
  const textContent = useTextContent()

  const options: InputOption[] = [
    {
      id: 'Less than 3 months',
      title: getTextContent(textContent, 'app-wizard.income-duration-less-than-3m', 'Less than 3 months'),
    },
    {
      id: 'Less than 6 months',
      title: getTextContent(textContent, 'app-wizard.income-duration-less-than-6m', 'Less than 6 months'),
    },
    {
      id: '1 year',
      title: getTextContent(textContent, 'app-wizard.income-duration-1y', '1 year'),
    },
    {
      id: '2 years or more',
      title: getTextContent(textContent, 'app-wizard.income-duration-2y+', '2 years or more'),
    },
  ]

  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent resourceKey="app-wizard.income-for" defaultValue="I've had this income for" />
      </SectionHeader>
      <Spacing value={20} />
      <InputSelectPills id="incomeDuration" options={options} orientation="vertical" exclusive />
    </section>
  )
}
