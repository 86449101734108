import { Radio, RadioGroup, styled } from '@material-ui/core'
import React from 'react'
import { useFormData } from '../helpers/useFormData'
import { InputOptions, InputId } from '../types/components'
import { TextError } from './TextError'

type Props = InputId & InputOptions

export const InputSelectRadioPills: React.VFC<Props> = ({ id, options }) => {
  const { formData, formErrors, onFieldChange } = useFormData()
  const style = {
    flexBasis: `${Math.floor(100 / options.length) - 1}%`,
  }
  return (
    <>
      <StyledRadioGroup name={id} onChange={(e) => onFieldChange(id, e.target.value)} value={formData[id] || ''}>
        {options.map((o) => (
          <StyledRadio key={o.id} value={o.id} icon={o.title} checkedIcon={o.title} style={style} />
        ))}
      </StyledRadioGroup>
      {formErrors[id] && <TextError>{formErrors[id]}</TextError>}
    </>
  )
}

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const StyledRadio = styled(Radio)(({ theme: { palette } }) => ({
  borderRadius: '5px',
  backgroundColor: 'rgba(43, 39, 97, 0.07)',
  transition: 'background-color .1s',
  color: '#8C8AAC',
  height: 50,
  '&:hover': {
    backgroundColor: palette.primary.dark,
    color: palette.type === 'light' ? palette.common.white : palette.common.black,
    transition: 'background-color .1s',
  },
  '& .MuiIconButton-label': {
    display: 'flex',
  },
  '&.Mui-checked.MuiButtonBase-root': {
    backgroundColor: palette.primary.main,
    transition: 'background-color .1s',
    color: palette.type === 'light' ? palette.common.white : palette.common.black,
  },
}))
