import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BuyingGroupDetail from '../components/BuyingGroupDetail'
import { useBuyingGroup, useBuyingGroupMembers } from '../../../firebase'
import { BuyingGroupWithFab } from '../models'
import { useCurrentUserId, useErrorHandler } from '../../../common/hooks'
import { TextContent } from '../../../common/textContent'
import { LoadingSpinner } from '@rse/uikit'
import { useContext } from 'react'
import { UserContext } from '../../../App'
import { AppRoutes } from '../../../App/routeUtils'
import { useBuyingGroupFavStatus } from '../../../firebase/hooks/useBuyingGroupFavStatus'
import { BodyContainer } from '../../../components/layout/BodyContainer'

interface urlParams {
  groupId: string
}

export function BuyingGroupDetails() {
  const { groupId } = useParams<urlParams>()
  const userId = useCurrentUserId()
  const user = useContext(UserContext)
  const history = useHistory()
  const {
    collection: { value: members },
  } = useBuyingGroupMembers(groupId)

  const {
    document: { value: group, loading: groupLoading, error: groupError },
    handlers: { deleteItem: deleteBuyingGroup, leaveGroup, finalizeGroup },
  } = useBuyingGroup(groupId, true)
  const userIsGroupHost = (!groupLoading && group && group.hostId == userId) ?? false
  // FIXME this is wrong way to determine group membership
  const userIsGroupMember = (user && user.buyingGroupId === groupId) ?? false

  const [groupIsDeleting, setGroupIsDeleting] = useState(false)
  const { errorHandler: errorHandlerForBuyingGroupDeletion } = useErrorHandler('deleting the group')

  const [groupWithFavorite, setGroupWithFavorite] = useState<BuyingGroupWithFab>()

  const isFab = useBuyingGroupFavStatus(userId, groupId)

  useEffect(() => {
    if (group) {
      setGroupWithFavorite({ ...group, isFab: isFab })
    }
  }, [group, isFab])

  if (groupLoading || groupIsDeleting) {
    return <BodyContainer><LoadingSpinner /></BodyContainer>
  }

  if (!group || !groupWithFavorite) {
    return (
      <h1>
        <TextContent resourceKey="bgroups.no-group-exists" defaultValue="No such group exists" />
      </h1>
    )
  }

  if (groupError) {
    return (
      <h1>
        <TextContent resourceKey="bgroups.error-loading-group" defaultValue="Error loading group" />
      </h1>
    )
  }

  // const directJoin = async () => {
  //   // Direct save without approval
  //   if (userId && groupMembers && !groupMembers.some((item) => item.id === userId)) {
  //     await firebaseAddNewGroupMember(groupId, userId)
  //   }
  // }

  const deleteGroup = userIsGroupHost
    ? async () => {
      setGroupIsDeleting(true)
      try {
        await deleteBuyingGroup(groupId)
        history.push(AppRoutes.groupRoot())
      } catch (error) {
        errorHandlerForBuyingGroupDeletion(error)
      } finally {
        setGroupIsDeleting(false)
      }
    }
    : undefined

  return (
    <BuyingGroupDetail
      group={groupWithFavorite}
      groupMembers={members || []}
      leaveGroup={userIsGroupMember && !userIsGroupHost ? async () => await leaveGroup(groupId) : undefined}
      finalizeGroup={userIsGroupHost ? async () => await finalizeGroup(groupId) : undefined}
      deleteGroup={deleteGroup}
    />
  )
}
