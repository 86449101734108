const defaultPreferenceQuestionIds = [
    'cleanliness',
    'drinking',
    'smoking',
    'vaping',
    'pets',
    'noise',
];

const defaultQuestions = (function () {
    const result = {
        'cleanliness': {
            id: 'cleanliness',
            subtype: 'multiple',
            label: 'Cleanliness',
            exclusive: true,
            choices: [
                {
                    id: 'Spotless and neat',
                    label: 'Neat Freak',
                    icon: 'neatFreak',
                },
                {
                    id: 'Fairly clean',
                    label: 'Clean',
                    icon: 'clean',
                },
                {
                    id: 'average',
                    label: 'Average',
                    icon: 'average',
                },
                {
                    id: 'A bit messy',
                    label: 'Messy',
                    icon: 'messy',
                },
            ],
            enabledChoiceIds: [
                'Spotless and neat',
                'Fairly clean',
                'average',
                'A bit messy',
            ],
        },
        'drinking': {
            id: 'drinking',
            subtype: 'multiple',
            label: 'Drinking',
            exclusive: true,
            choices: [
                {
                    id: 'No drinking',
                    label: 'No Drinking',
                    icon: 'noDrinking',
                },
                {
                    id: 'Casual drinking is fine',
                    label: 'Casual driking is fine',
                    icon: 'casualDrinking',
                },
                {
                    id: 'People can do what they want',
                    label: 'People can do what they want',
                    icon: 'peopleCanDoWhatTheyWant',
                },
            ],
            enabledChoiceIds: [
                'No drinking',
                'Casual drinking is fine',
                'People can do what they want'
            ],
        },
        'smoking': {
            id: 'smoking',
            subtype: 'multiple',
            label: 'Smoking',
            exclusive: true,
            choices: [
                {
                    id: 'No smoking',
                    label: 'No smoking',
                    icon: 'noSmoking',
                },
                {
                    id: 'Outdoors only',
                    label: 'Outdoors only',
                    icon: 'outdoorsOnly',
                },
                {
                    id: 'Anywhere is okay',
                    label: 'Anywhere is okay',
                    icon: 'anywhereIsOk',
                },
            ],
            enabledChoiceIds: [
                'No smoking',
                'Outdoors only',
                'Anywhere is okay',
            ],
        },
        'vaping': {
            id: 'vaping',
            subtype: 'multiple',
            label: 'Vaping',
            exclusive: true,
            choices: [
                {
                    id: 'No vaping',
                    label: 'No vaping',
                    icon: 'noVaping',
                },
                {
                    id: 'Outdoors only',
                    label: 'Outdoors only',
                    icon: 'outdoorsOnly',
                },
                {
                    id: 'Anywhere is okay',
                    label: 'Anywhere is okay',
                    icon: 'vapingAnywhereIsOkay',
                },
            ],
            enabledChoiceIds: [
                'No vaping',
                'Outdoors only',
                'Anywhere is okay',
            ]
        },
        'pets': {
            id: 'pets',
            subtype: 'multiple',
            label: 'Pets',
            exclusive: true,
            choices: [
                {
                    id: 'No pets',
                    label: 'No pets',
                    icon: 'noVaping',
                },
                {
                    id: 'Dogs only',
                    label: 'Dogs only',
                    icon: 'dogsOnly',
                },
                {
                    id: 'Cats only',
                    label: 'Cats only',
                    icon: 'catsOnly',
                },
                {
                    id: 'Caged animals only',
                    label: 'Caged animals only',
                    icon: 'cagedAnimalsOnly',
                },
                {
                    id: 'Any',
                    label: 'Any',
                    icon: 'petsAny',
                },
            ],
            enabledChoiceIds: [
                'No pets',
                'Dogs only',
                'Cats only',
                'Caged animals only',
                'Any',
            ]
        },
        'noise': {
            id: 'noise',
            subtype: 'multiple',
            label: 'Noise Levels',
            exclusive: true,
            choices: [
                {
                    id: 'Quiet & serene',
                    label: 'Quiet',
                    icon: 'noise1',
                },
                {
                    id: 'Some noise is okay',
                    label: 'Some noise is okay',
                    icon: 'noise2',
                },
                {
                    id: 'All in for a loud house',
                    label: 'All in for a loud house',
                    icon: 'noise3',
                },
            ],
            enabledChoiceIds: [
                'Quiet & serene',
                'Some noise is okay',
                'All in for a loud house',
            ]
        }
    };
    for (const key of Object.keys(result)) {
        const question = result[key];
        if (question.choices) {
            question.enabledChoices = question.enabledChoiceIds.map(id => question.choices.find(item => item.id === id));
        }
    }
    return result;
})();

const apiNames = ['content', 'user-init-application'];
// TODO lift into core and then configure backend to provide it at CONFIG_URI
function configFromRoot(root) {
    return {
        api: {
            'content': { prefix: `${root}/content` },
            'user-init-application': { prefix: `${root}/user-init-application` }
        }
    };
}
const AdminAppConfigTools = {
    configFromRoot,
    apiNames
};

var LocationGateName;
(function (LocationGateName) {
    LocationGateName["JoinUp"] = "Join Up";
    LocationGateName["GroupUp"] = "Group Up";
})(LocationGateName || (LocationGateName = {}));

const SystemUserID = "system";

const memberAppApiNames = ['startup', 'chat', 'user', 'buyingGroup'];

/**
 * @description Push notification device info
 */
var NotificationType;
(function (NotificationType) {
    NotificationType["InvitationToJoin"] = "InvitationToJoin";
    NotificationType["MembershipApproved"] = "MembershipApproved";
    NotificationType["CanGroupUp"] = "CanGroupUp";
    NotificationType["GroupIsReadyToBuy"] = "GroupIsReadyToBuy";
    NotificationType["Message"] = "Message";
    NotificationType["MessageRequest"] = "MessageRequest";
    NotificationType["MessageThreadArchived"] = "MessageThreadArchived";
})(NotificationType || (NotificationType = {}));

function PV(rate, periods, payment, future, type) {
    // Initialize type
    type = typeof type === 'undefined' ? 0 : type;
    // Return present value
    if (rate === 0) {
        return -payment * periods - future;
    }
    else {
        return ((((1 - Math.pow(1 + rate, periods)) / rate) * payment * (1 + rate * type) - future) / Math.pow(1 + rate, periods));
    }
}
// Justis simplified the PV function above, since our usage always had future=0 and type=0
function PV2(rate, periods, payment, periodicFeesRate) {
    const entireRate = Math.pow(1 + rate, periods); // the rate for all periods put together, as though it's a single period
    const f = periodicFeesRate || 0;
    const y = entireRate;
    const p = payment;
    const r = rate;
    if (rate === 0) {
        return -payment * periods;
    }
    else {
        /*
      const x*entireRate/(payment - (x*periodicFeesRate)) = (1 - entireRate) / rate
      const x*entireRate = (1 - entireRate)*(payment - (x*periodicFeesRate)) / rate
      const x*entireRate*rate = (1 - entireRate)*(payment - (x*periodicFeesRate))
      const x*y*r = (1 - y)*(p - (x*f))
      */
        const x = (p - p * y) / (f * (-y) + f + r * y);
        return x;
        /*
        return (
          (((1 - entireRate) / rate) * payment) / entireRate
        )
        */
    }
}
function FV(rate, nper, pmt, pv, type) {
    const pow = Math.pow(1 + rate, nper);
    let fv;
    pv = pv || 0;
    type = type || 0;
    if (rate) {
        fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
    }
    else {
        fv = -1 * (pv + pmt * nper);
    }
    return fv;
}
function PMT(rate_per_period, number_of_payments, present_value, future_value, type) {
    /*
    rate_per_period - interest rate per month
    number_of_payments - number of periods (months)
    present_value - present value
    future_value - future value (residual value)
    */
    future_value = typeof future_value !== 'undefined' ? future_value : 0;
    type = typeof type !== 'undefined' ? type : 0;
    if (rate_per_period != 0.0) {
        // Interest rate exists
        const q = Math.pow(1 + rate_per_period, number_of_payments);
        return -(rate_per_period * (future_value + q * present_value)) / ((-1 + q) * (1 + rate_per_period * type));
    }
    else if (number_of_payments != 0.0) {
        // No interest rate, but number of payments exists
        return -(future_value + present_value) / number_of_payments;
    }
    return 0;
}

function getFirebaseApiUrl(firebaseFunctionsBaseUrl, apiRelativeUrl) {
    let firebaseFunctionsBaseUrlFixed = firebaseFunctionsBaseUrl.trim();
    let apiRelativeUrlFixed = apiRelativeUrl.trim();
    firebaseFunctionsBaseUrlFixed = firebaseFunctionsBaseUrlFixed.endsWith('/') ? firebaseFunctionsBaseUrlFixed.slice(0, -1) : firebaseFunctionsBaseUrlFixed;
    apiRelativeUrlFixed = apiRelativeUrlFixed.startsWith('/') ? apiRelativeUrlFixed : "/" + apiRelativeUrlFixed;
    return `${firebaseFunctionsBaseUrlFixed}${apiRelativeUrlFixed}`;
}

const getThreadTitle = (userThread, peerProfile, group) => {
    let threadTitle = '';
    if (userThread.type === 'Group') {
        if (group) {
            threadTitle = group.headline;
        }
        else {
            threadTitle = 'Untitled Group';
        }
    }
    else if (userThread.type === 'GroupCourtship') {
        if (peerProfile) {
            threadTitle = `Exploring: ${peerProfile.firstName} ${peerProfile.lastName}`;
        }
        else {
            if (group) {
                threadTitle = `Exploring: ${group.headline}`;
            }
            else {
                threadTitle = `Exploring: Undefined`;
            }
        }
    }
    else if (userThread.type === 'UserToUser') {
        if (peerProfile) {
            threadTitle = `${peerProfile.firstName} ${peerProfile.lastName}`;
        }
        else {
            threadTitle = `Undefined Name`;
        }
    }
    return threadTitle;
};

export { AdminAppConfigTools, FV, LocationGateName, NotificationType, PMT, PV, PV2, SystemUserID, defaultPreferenceQuestionIds, defaultQuestions, getFirebaseApiUrl, getThreadTitle, memberAppApiNames };
