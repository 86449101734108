import React from 'react'
import { Box } from '@material-ui/core'
import { ErrorText, LoadingSpinner, TextError } from '@rse/uikit'

import { RadioGroup } from './RadioGroup'
import { TextContent } from '../../../common/textContent'
import { usePreferencesQuestions } from '../../../common/hooks'

type Props = {
  values: Record<string, string>
  errors?: Record<string, string | undefined>
  onChange: (question: string, value: string) => void
  readOnly?: boolean
}

export function GroundRulesPane({ values, errors, onChange, readOnly = false }: Props) {
  const { value, loading, error } = usePreferencesQuestions()

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <ErrorText>
        <TextContent
          resourceKey="errors.loading-ground-rules"
          defaultValue="Error loading home lifestyle preferences"
        />
      </ErrorText>
    )
  }

  return (
    <Box>
      <Box>
        {value.map((question) => {
          if (question.enabledChoices?.length) {
            return (
              <Box key={question.id} my={4}>
                <RadioGroup
                  title={question.label}
                  name={question.id}
                  value={values[question.id]}
                  options={question.enabledChoices.map((item) => ({ ...item, value: item.id }))}
                  onChange={(value: string) => onChange(question.id, value)}
                  readOnly={readOnly}
                />
                {errors && errors[question.id] && <TextError>{errors[question.id]}</TextError>}
              </Box>
            )
          }
        })}
      </Box>
    </Box>
  )
}
