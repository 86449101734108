import React from 'react'
import { Box, Container, Paper, makeStyles, createStyles, Theme } from '@material-ui/core'
import { ButtonLarge } from '@rse/uikit'
import { LegalPageContent } from '../../LegalPages'
import { TextContent } from '../../../common/textContent'

const useStyles = makeStyles(({ spacing, palette, shape }: Theme) =>
  createStyles({
    scrollBox: {
      padding: spacing(2),
      borderRadius: shape.borderRadius,
      backgroundColor: palette.background.paper,
      overflow: 'auto',
      wordBreak: 'break-word',
    },
  })
)

interface Props {
  type: 'tos' | 'privacyPolicy'
  height?: number
  closeLink: React.Dispatch<React.SetStateAction<false | 'tos' | 'privacyPolicy'>>
}

export function LegalDocsContainer({ type, height, closeLink }: Props) {
  const { scrollBox } = useStyles()
  const maxHeight = height ? height : 225
  const heightStyle = `calc(100vh - ${maxHeight}px)`

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box flexGrow={1}>
        <Container maxWidth="xs">
          <Paper elevation={0} className={scrollBox} style={{ maxHeight: heightStyle }}>
            <LegalPageContent contentType={type === 'tos' ? 'terms-of-use' : 'privacy-policy'} />
          </Paper>
        </Container>
      </Box>

      <Box pt={3} position="sticky" bottom={0}>
        <Container maxWidth="xs">
          <ButtonLarge fullWidth onClick={() => closeLink(false)}>
            <TextContent resourceKey="signup.backButton" defaultValue="Back to Sign Up" />
          </ButtonLarge>
        </Container>
      </Box>
    </Box>
  )
}
