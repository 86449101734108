import React from 'react'
import { Box, Grid, Button, makeStyles, useTheme } from '@material-ui/core'
import { AppLayout, Header } from '../../components/layout'
import { Favorite, FavoriteBorderOutlined } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  flexDisplay: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 8px',
    '& .MuiButton-root': {
      marginBottom: '16px',
    },
  },
})

function StylesDemo() {
  const styles = useStyles()
  const { palette } = useTheme()

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4} className={styles.flexDisplay}>
          <Button variant="contained">Default</Button>
          <Button variant="contained" color="primary">
            Primary
          </Button>
          <Button variant="contained" color="secondary">
            Secondary
          </Button>
          <Button variant="contained" disabled>
            Disabled
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box bgcolor={palette.primary.main} p={2} className={styles.flexDisplay}>
            <Button variant="outlined" className="primaryInverted">
              Default
            </Button>
            <Button variant="outlined" disabled className="primaryInverted">
              Disabled
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={4} className={styles.flexDisplay}>
          <Button variant="outlined">Default</Button>
          <Button variant="outlined" color="primary">
            Primary
          </Button>
          <Button variant="outlined" color="secondary">
            Secondary
          </Button>
          <Button variant="outlined" disabled>
            Disabled
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default function StylesDemoPage() {
  const history = useHistory()

  async function back() {
    console.log('styles demo back')
    const result = await new Promise((resolve) => {
      setTimeout(resolve, 1500, 'the timeout has completed!')
    })
    console.log('result', result)

    history.goBack()
  }

  return (
    <AppLayout
      header={
        <Header title="Styles Demo Page" back={back}>
          <Favorite />
          <FavoriteBorderOutlined />
        </Header>
      }
      footer={<h1>footer goes here</h1>}
      body={<StylesDemo />}
    />
  )
}
