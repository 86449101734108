import React from 'react'
import { TextContent } from '../../../common/textContent'
import { SectionHeader, StandardText, Spacing } from '@rse/uikit'
import { InputSelectRadioPills } from '../components/InputSelectRadioPills'

const options = [
  { id: 'ASAP', title: <StandardText>ASAP</StandardText> },
  { id: '3-6M', title: <StandardText>3-6 Months</StandardText> },
  { id: '6-12M', title: <StandardText>6-12 Months</StandardText> },
  { id: '12M+', title: <StandardText>12+ Months</StandardText> },
]

export const ProfileMoveInPeriod: React.VFC = () => {
  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent resourceKey="app-wizard.title-when-to-move" defaultValue="When do you want to move?" />
      </SectionHeader>
      <Spacing value={20} />
      <InputSelectRadioPills id="timeRange" options={options} />
    </section>
  )
}
