import { firebaseCollectionNames } from './firebaseConstants'
import { firebaseInstance } from './firebaseInstance'
import { setUserPublicProfileBirthday } from './hooks'
import { UserProfilePublicEntity } from '@rse/core'

export function firebaseGetUserPublicProfilePath(uid: string): string {
  return `${firebaseCollectionNames.userProfiles}/${uid}/public/profile`
}

export async function firebaseGetUserPublicProfile(uid: string): Promise<UserProfilePublicEntity | undefined> {
  const doc = await firebaseInstance.db.doc(firebaseGetUserPublicProfilePath(uid)).get()
  if (doc.exists) {
    const result = doc.data() as UserProfilePublicEntity
    setUserPublicProfileBirthday(result)
    return result
  }

  throw new Error(`Missing user public profile for user ${uid}`)
}
