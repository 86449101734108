export enum firebaseOnCallFunctions {
  createUserFromUser = 'userOperations-createUserFromUser',
  createBuyingGroup = 'createBuyingGroup',
  deleteBuyingGroup = 'deleteBuyingGroup',
  finalizeMembership = 'finalizeMembership',
  getInitApplicationData = 'getInitApplicationData',
  leaveBuyingGroup = 'leaveBuyingGroup',
  refreshBannedPasswords = 'refreshBannedPasswords',
  removeGroupMember = 'removeGroupMember',
  sendPhoneCode = 'userOperations-sendPhoneCode',
  validateNewPassword = 'userOperations-validateNewPassword',
  verifyCode = 'userOperations-verifyCode',
  estimatePurchasingPowerForMember = 'estimatePurchasingPowerForMember',
  registerPushNotificationDevice = 'registerDevice'
}
