import React, { useState } from 'react'
import { ButtonLarge, LoadingSpinner, AppLink, PageTitle, ErrorText } from '@rse/uikit'
import { Container, Box, TextField, makeStyles, Theme, useTheme } from '@material-ui/core'
import { ResetPasswordNav, SignUpNav } from '../../common/constants/routes'
import * as EmailValidator from 'email-validator'
import { useForm, SubmitHandler } from 'react-hook-form'
import { ErrorType, AppErrorMessages } from '../../common/constants/errorTypes'
import { TextContent } from '../../common/textContent'
import { CenteredBox, AppLayout, Header } from '../../components/layout'
import { useAuthService } from '../../common/hooks'
import { HeaderBox } from '../../components/layout/appLayout/Header/HeaderBox'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: spacing(3),
  },
  marginBottom: {
    marginBottom: spacing(2),
  },
}))

type loginFormData = {
  email: string
  password: string
}

function LoginHeader() {
  const { palette } = useTheme()

  return (
    <HeaderBox bgcolor={palette.primary.main}>
      <Header title="Log In" hideMenu={true}>
        <Box display="flex" alignItems="center" color={palette.primary.main}>
        </Box>
      </Header>
    </HeaderBox>
  )
}

function LoginPage() {
  const { login } = useAuthService()

  const { register, handleSubmit, errors } = useForm<loginFormData>()
  const [isPending, setIsPending] = useState(false)
  const [isLoginError, setIsLoginError] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState('')

  const classes = useStyles()

  const onSubmit: SubmitHandler<loginFormData> = async (data: loginFormData, e?:React.BaseSyntheticEvent) => {
    setIsPending(true)

    // This is an attempt to stop redirect to '/' before login is complete
    // The correct solution is probably to upgrade react-router to v6
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    try {
      console.debug('before login await')
      await login(data)
      console.debug('after login await')
    } catch (error:any) {
      if (error.message) setLoginErrorMessage(error.message)
      else setLoginErrorMessage(AppErrorMessages[ErrorType.AuthServiceError])
      setIsLoginError(true)
      setIsPending(false)
    }
    return false
  }

  // This is an attempt to stop redirect to '/' before login is complete
  // The correct solution is probably to upgrade react-router to v6
  function submitHandler(e:React.BaseSyntheticEvent) {
    e.preventDefault()
    e.stopPropagation()
    const handler = handleSubmit(onSubmit)
    handler(e)
  }

  const isEmail = (email: string) => {
    return EmailValidator.validate(email)
  }

  return (
    <AppLayout
      header={<LoginHeader />}
      body={
        <CenteredBox textAlign="center" width="100%" my={2}>
          <Container maxWidth="xs">
            <PageTitle>
              <TextContent resourceKey="titles.login-page" defaultValue="Log In" />
            </PageTitle>
            <form className={classes.form} noValidate onSubmit={submitHandler}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus
                inputRef={register({ required: true, validate: isEmail })}
                className={classes.marginBottom}
              />
              {errors.email && errors.email.type === 'required' && (
                <ErrorText>
                  <TextContent resourceKey="errors.email-is-required" defaultValue="Email is required" />
                </ErrorText>
              )}
              {errors.email && errors.email.type === 'validate' && (
                <ErrorText>
                  <TextContent resourceKey="errors.email-is-invalid" defaultValue="Email is invalid" />
                </ErrorText>
              )}

              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputRef={register({ required: 'Password is required' })}
                className={classes.marginBottom}
              />
              {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
              <Box textAlign="right">
                <AppLink to={ResetPasswordNav.path}>
                  <TextContent resourceKey="login.having-trouble" defaultValue="Forgot password?" />
                </AppLink>
              </Box>
              <Box mt={5} mb={4}>
                {!isPending && (
                  <ButtonLarge type="submit" fullWidth>
                    <TextContent resourceKey="buttons.login" defaultValue="Log In" />
                  </ButtonLarge>
                )}
                {isPending && <LoadingSpinner height={50} />}
              </Box>
              {isLoginError && <ErrorText>{loginErrorMessage}</ErrorText>}
              <Box>
                Don't have an account?{' '}
                <AppLink inline to={SignUpNav.path}>
                  <TextContent resourceKey="login.dont-have-account" defaultValue="Sign Up" />
                </AppLink>
              </Box>
            </form>
          </Container>
        </CenteredBox>
      }
    />
  )
}

export default LoginPage
