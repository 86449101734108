import { usePersistentCollection } from '.'
import { UserNotification } from '../../models'
import { firebaseCollectionNames } from '../firebaseConstants'
import { getDateFromFirestoreTimestampOrString } from '../utils'

function transformUserNotification(value: UserNotification): UserNotification {
  if (value) {
    value.incomingDate = getDateFromFirestoreTimestampOrString(value.incomingDate)
    value.readDate = getDateFromFirestoreTimestampOrString(value.readDate)
  }

  return value
}

export function useUserNotifications(userId: string) {
  const { collection } = usePersistentCollection<UserNotification>(
    `${firebaseCollectionNames.userProfiles}/${userId}/${firebaseCollectionNames.notifications}`,
    undefined,
    undefined,
    transformUserNotification
  )

  if (!collection || !collection.value) return {}

  const unread: number = collection.value.reduce((acc, curr) => (curr.readDate ? acc : acc + 1), 0)

  return { data: collection.value, unread: unread }
}
