import { Box, styled } from '@material-ui/core'
import React from 'react'
import DoneIcon from '@material-ui/icons/Done'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import { ChatThreadMessageStatus } from '../../../../models'

type Props = {
  dateString: string
  status: ChatThreadMessageStatus
}

export const ChatMessageStatus: React.FC<Props> = ({ dateString, status = 0 }) => {
  return (
    <Box textAlign="right" display="flex" alignItems="center" justifyContent="flex-end">
      <MessageTime>{dateString}</MessageTime>
      {status > 0 && <StatusContainer>{status === 1 ? <DoneIcon /> : <DoneAllIcon />}</StatusContainer>}
    </Box>
  )
}

const StatusContainer = styled('span')({
  display: 'inline-block',
  marginLeft: '7px',
  fontSize: '16px',
  width: 16,
  height: 16,
  '& svg': {
    height: '100%',
    width: '100%',
  },
})

const MessageTime = styled('span')({
  fontSize: 12,
})
