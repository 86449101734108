import React from 'react'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { SectionHeader, Spacing } from '@rse/uikit'
import { InputText } from '../components/InputText'

type Props = {
  showSectionHeader: boolean
}

export const ProfileBriefDescription: React.FC<Props> = ({ showSectionHeader }) => {
  const textContent = useTextContent()

  return (
    <section>
      <Spacing value={28} />
      {showSectionHeader && (
        <SectionHeader>
          <TextContent resourceKey="app-wizard.profile-description" defaultValue="Profile Description" />
        </SectionHeader>
      )}
      <Spacing value={20} />
      <InputText id="description" label={getTextContent(textContent, 'app-wizard.field-description', 'Description')} multiline={true} />      
    </section>
  )
}
