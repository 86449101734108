import React, { Component } from 'react'
import { styled } from '@material-ui/core'
import { TextContent } from '../../common/textContent'

type Props = {
  children?: React.ReactNode
  text?: string
}

type State = {
  hasError: boolean
}
class InternalErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
  }

  componentDidCatch(error:any, errorInfo:any): void {
    console.error(error, errorInfo)
    this.setState({ hasError: true })
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Container>
          <Heading>Site Error</Heading>
          <Message>{defaultText}</Message>
        </Container>
      )
    }

    return this.props.children
  }
}

const defaultText = 'Oops. It just so happens a bad thing happend. Apologies.'

export const ErrorMessage = ({ text = defaultText }: { text?: string }) => {
  return (
    <InternalErrorBoundary>
      <Container>
        <Heading>Site Error</Heading>
        <Message>
          <TextContent resourceKey="errors.500" defaultValue={text} />
        </Message>
      </Container>
    </InternalErrorBoundary>
  )
}

const Container = styled('div')({
  height: '73vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const Heading = styled('h1')({
  marginBottom: '50px',
  // fontFamily: '\'Lato\', sans-serif',
  fontSize: '50px',
})

const Message = styled('div')({
  padding: '0 10px',
  textAlign: 'center',
})
