import app from 'firebase/app'

export function getDateFromFirestoreTimestampOrString(date: any): any {
  if (date) {
    if (date instanceof app.firestore.Timestamp) {
      return (date as app.firestore.Timestamp).toDate()
    }

    if (typeof date === 'string') {
      return new Date(date)
    }
  }

  return date
}
