import { Box, useTheme } from '@material-ui/core'
import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { URL_DASHBOARD, URL_MEMBERSHIP_WIZARD } from '../../common/constants/routes'
import ScrollToTop from '../../components/layout/ScrollToTop'
import { ResumeApplication } from './ResumeApplication'
import {
  StepProfile,
  StepPreferences,
  StepVerification,
  StepIdentityVerification,
  StepMortgagePreapproval,
  StepApplicationSummary,
  StepFinalizeApplication,
} from './steps'
import { UserContext } from '../../App'
import {
  ApplicationApproved,
  ApplicationError,
  ApplicationFormDataProvider,
  ApplicationFormProgressProvider,
  ApplicationLoading,
  ApplicationPending,
  ApplicationSubmitted,
  SignupLayout,
} from './components'
import { ApplicationStep } from './ApplicationStep'
import { useJoinUpData } from '../../common/hooks/useJoinUpData'
import { useFormData } from './helpers/useFormData'
// import { StepImportanceFactors } from './steps/StepImportanceFactors'

export const Application = () => {
  const user = useContext(UserContext)
  const { getJoinUpData } = useJoinUpData()
  const { palette } = useTheme()

  // TODO this should be blocked higher up the component tree
  if (!user) {
    return <SignupLayout content={<ApplicationError />} />
  }

  switch (user.initApplicationStatus) {
    case 'Member':
      return <Redirect to="/" />
    case 'Approved':
      return <SignupLayout content={<ApplicationApproved />} /> // TODO add redirect to matches
    case 'In Review':
      return <SignupLayout content={<ApplicationSubmitted />} />
    case 'Pending':
      break
    case 'Error':
      return <SignupLayout content={<ApplicationPending />} />
    case 'Rejected': // for now we treat as though it's a backend error
      return <SignupLayout content={<ApplicationPending />} />
    default:
      if (user.initApplicationStatus !== null) console.log(`unexpected status ${user.initApplicationStatus}`)
      break
  }

  return (
    <Box bgcolor={palette.background.default} minHeight="100vh" style={{ scrollBehavior: 'smooth' }}>
      <ScrollToTop />
      <ApplicationFormDataProvider getData={getJoinUpData}>
        <ApplicationFormProgressProvider>
          <ApplicationRoutes initApplicationStatus={user.initApplicationStatus} />
        </ApplicationFormProgressProvider>
      </ApplicationFormDataProvider>
    </Box>
  )
}

// TODO: sync with useProgressData's stepNameMap so no need to change in two places
export const steps: React.ComponentType[] = [
  StepProfile,
  StepPreferences,
  // StepImportanceFactors,
  StepVerification,
  StepIdentityVerification,
  StepMortgagePreapproval,
  StepApplicationSummary,
  StepFinalizeApplication,
]

const ApplicationRoutes = ({ initApplicationStatus }: { initApplicationStatus: string }) => {
  const { formData, loaded, loading } = useFormData()

  if (loading) {
    return <SignupLayout content={<ApplicationLoading />} />
  }

  if (!loaded) {
    return <SignupLayout content={<ApplicationError />} />
  }

  const resumePath = 'resume'
  const { lastWizardStep } = formData
  const nextWizardStep =
    typeof lastWizardStep === 'number' && lastWizardStep > 0 && lastWizardStep <= steps.length ? resumePath : '1'

  return (
    <Switch>
      {initApplicationStatus === 'New' && <Redirect to={URL_DASHBOARD} />}

      <Route exact path={`${URL_MEMBERSHIP_WIZARD}/${resumePath}`} component={ResumeApplication} />
      {steps.map((component, idx) => (
        <Route
          key={idx}
          path={`${URL_MEMBERSHIP_WIZARD}/${idx + 1}`}
          render={() => <ApplicationStep stepComponent={component} />}
        />
      ))}

      <Redirect to={`${URL_MEMBERSHIP_WIZARD}/${nextWizardStep}`} />
    </Switch>
  )
}
