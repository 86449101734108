import { FormikErrors } from 'formik'

export const GROUP_VISION_MIN = 100
export const GROUP_VISION_MAX = 300

export const roomMatesCountMarks = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6+' },
]

export const predefinedTags = ['Yogi', 'Foodie', 'Pet Lovers', 'Coffee Advocates', 'Students', 'Entrepreneurs']

export type FormValues = {
  groupTitle: string
  groupVision: string
  groupQuestions: string[]
  groupTags?: string[]
  location?: string[]
  roommateGender: string
  lgbtqFriendly: boolean
  preferences: Record<string, string>
}

export const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {}
  if (!values.groupTitle.trim()) {
    errors.groupTitle = 'This field is required'
  }

  if (!values.groupVision.trim()) {
    errors.groupVision = 'This field is required'
  } else if (values.groupVision.length < GROUP_VISION_MIN || values.groupVision.length > GROUP_VISION_MAX) {
    errors.groupVision = `Group Vision should be from ${GROUP_VISION_MIN} up to ${GROUP_VISION_MAX} symbols`
  }

  if (!values.groupQuestions[0].trim()) {
    errors.groupQuestions = errors.groupQuestions || []
    if (Array.isArray(errors.groupQuestions)) {
      errors.groupQuestions[0] = 'This field is required'
    }
  }

  if (Object.keys(values.preferences).some((pref) => !values.preferences[pref])) {
    errors.preferences = errors.preferences || {}

    Object.keys(values.preferences).forEach((pref) => {
      if (!values.preferences[pref]) {
        errors!.preferences![pref] = 'Please choose your preference'
      }
    })
  }

  return errors
}
