import React from 'react'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'
import { ReactComponent as EmailIcon } from '../../assets/sendMail.svg'
import { LoadingSpinner } from '@rse/uikit'

export function ApplicationSubmitted() {
  return (
    <DashboardBanner
      icon={EmailIcon}
      viewBox="0 0 50 30"
      title="Application Submitted"
      titleKey="dashboard.application-submitted-title"
      subtext="After confirmation, we will notify you when your application is approved"
      subtextKey="dashboard.application-submitted-subtext"
    >
      {/* <LoadingSpinner /> */}
    </DashboardBanner>
  )
}
