/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Dictionary, WizardPageSection, WizardQuestion } from '../types'
import { Spacer } from './layoutComponents/Spacer'
import AvatarList from './questionComponents/AvatarList'
import CheckBoxInput from './questionComponents/CheckBoxInput'
import ChipsInput from './questionComponents/ChipsInput'
import DropDownSelectInput from './questionComponents/DropDownSelectInput'
import Favorite from './questionComponents/Favorite'
import IconText from './questionComponents/IconText'
import InfoSection from './questionComponents/InfoSection'
import ParamValue from './questionComponents/ParamValue'
import PhotoInput from './questionComponents/PhotoInput'
import PillsInput from './questionComponents/PillsInput'
import RangeSliderInput from './questionComponents/RangeSliderInput'
import Slot from './questionComponents/Slot'
import TagList from './questionComponents/TagList'
import TermsAcceptInput from './questionComponents/TermsAcceptInput'
import TextInput from './questionComponents/TextInput'
import ToggleSwitchInput from './questionComponents/ToggleSwitchInput'

interface DynamicWizardPageSectionProps {
  sectionConfig: WizardPageSection
  pageAnswers: Dictionary<string[]>
  displayQuestionErrors: boolean
  Slots?: Record<string, React.ReactElement>
  questionAnswerChangeHandler: (questionId: string, answer: string[]) => void
  questionControlValidStatusUpdate: (questionId: string, isValid: boolean) => void
}

export default function DynamicWizardPageSection({
  sectionConfig,
  pageAnswers,
  displayQuestionErrors,
  questionAnswerChangeHandler,
  questionControlValidStatusUpdate,
  Slots,
}: DynamicWizardPageSectionProps) {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    setIsLoading(false)
  }, [])

  const getQuestionComponent = (questionConfig: WizardQuestion, answer: string[]) => {
    if (!answer) {
      answer = []
    }
    switch (questionConfig.RenderControl) {
      case 'Slot':
        return (
          <>
            {Slots && Slots[questionConfig.QuestionName] ? <Slot Content={Slots[questionConfig.QuestionName]} /> : null}
          </>
        )
      case 'InfoSection':
        return (
          <InfoSection
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'TextInput':
        return (
          <TextInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'PillsInput':
        return (
          <PillsInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'ChipsInput':
        return (
          <ChipsInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'DropDownSelectInput':
        return (
          <DropDownSelectInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'ToggleSwitchInput':
        return (
          <ToggleSwitchInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'PhotoInput':
        return (
          <PhotoInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'CheckBoxInput':
        return (
          <CheckBoxInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            QuestionChoices={questionConfig.QuestionChoices}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'TermsAcceptInput':
        return (
          <TermsAcceptInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            QuestionChoices={questionConfig.QuestionChoices}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'RangeSliderInput':
        return (
          <RangeSliderInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            QuestionChoices={questionConfig.QuestionChoices}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'Favorite':
        return (
          <Favorite
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'IconText':
        return (
          <IconText
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'AvatarList':
        return (
          <AvatarList
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'TagList':
        return (
          <TagList
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
      case 'ParamValue':
        return (
          <ParamValue
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )

      default:
        return (
          <TextInput
            key={questionConfig.QuestionId + 'q'}
            QuestionConfig={questionConfig}
            Answer={answer}
            displayQuestionErrors={displayQuestionErrors}
            AnswerUpdateHandler={(answer: string[]) => questionAnswerChangeHandler(questionConfig.QuestionId, answer)}
            ValidStatusUpdateHandler={(isValid: boolean) =>
              questionControlValidStatusUpdate(questionConfig.QuestionId, isValid)
            }
            {...questionConfig.RenderControlProps}
          />
        )
    }
  }

  type questionRowData = { component: JSX.Element; question: WizardQuestion }

  const createPageQuestionLayout = (questions: WizardQuestion[]) => {
    let tempRowStore: questionRowData[] = []
    const result: JSX.Element[] = []

    questions.forEach((question, i) => {
      const currentQuestionAnswer = pageAnswers[question.QuestionId]
      const currentQuestionComponent = getQuestionComponent(question, currentQuestionAnswer)
      const currentSkipLine = question.SkipNewLine || false
      const spacing = question.Spacing || 20

      if (currentSkipLine) {
        tempRowStore.push({ question, component: currentQuestionComponent })
      } else {
        if (tempRowStore.length > 0) {
          tempRowStore.push({ question, component: currentQuestionComponent })
          const rowQuestionComponents = makeRow(tempRowStore)
          if (i != 0) {
            const spacing = tempRowStore[0].question.Spacing || 20
            result.push(<Spacer height={spacing} />)
          }
          result.push(rowQuestionComponents)
          tempRowStore = []
        } else {
          result.push(
            <>
              {i != 0 && <Spacer height={spacing} />}
              {currentQuestionComponent}
            </>
          )
        }
      }
    })

    if (tempRowStore.length > 0) {
      //This probably means that the last item somehow has skipLine when it should not.
      const rowQuestionComponents = makeRow(tempRowStore)
      if (result.length > 0) {
        const spacing = tempRowStore[0].question.Spacing || 20
        result.push(<Spacer height={spacing} />)
      }
      result.push(rowQuestionComponents)
    }

    return result
  }

  const makeRow = (rowData: questionRowData[]) => {
    const rowItemList: JSX.Element[] = []
    console.log(rowData)
    rowData.forEach((row, i) => {
      const flexGrow = row.question.FlexGrow ?? 1
      const rowItem = (
        <Box flexGrow={flexGrow} ml={i > 0 ? 1 : 0}>
          {row.component}
        </Box>
      )
      rowItemList.push(rowItem)
    })

    return <Box display="flex">{rowItemList}</Box>
  }

  return (
    <>
      {isLoading && <p>Loading Section...</p>}
      {!isLoading && (
        <>
          {sectionConfig.ShowTitle && <h2>{sectionConfig.Title}</h2>}
          {createPageQuestionLayout(sectionConfig.Questions)}
        </>
      )}
    </>
  )
}
