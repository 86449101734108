import React, { useState } from 'react'
import {
  Box,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  makeStyles,
  Container,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { DashboardNav, LoginNav } from '../../common/constants/routes'
import { useForm, Controller } from 'react-hook-form'
import * as EmailValidator from 'email-validator'
import { RaleighLocation } from '../../common/constants'
import { ButtonLarge, AppLink, ErrorText, PageTitle, LoadingSpinner } from '@rse/uikit'
import { TextContent } from '../../common/textContent'
import { CenteredBox, AppLayout, SignUpHeader } from '../../components/layout'
import { LegalDocsLinks } from './components/LegalDocsLinks'
import { LegalDocsContainer } from './components/LegalDocsContainer'
import { useLocations } from '../../common/hooks/useLocations'
import { useAuthService } from '../../common/hooks'
import { SignUpFormData } from '../../models'
import { BodyContainer } from '../../components/layout/BodyContainer'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}))

const defaultLocations = [
  { label: RaleighLocation.label, value: RaleighLocation.value },
  { label: 'Elsewhere in North Carolina', value: 'NC' },
]

function SignUpPage() {
  const { signUp } = useAuthService()
  const { collection: locationsHook } = useLocations()
  const locations = locationsHook.value || defaultLocations

  if (locationsHook.error) {
    console.log('locations error', locationsHook.error)
  }

  const classes = useStyles()

  const history = useHistory()
  const [isSignUpError, setIsSignUpError] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [signupErrorMessage, setSignupErrorMessage] = useState('')
  const [tosDialogue, setTosDialogue] = useState<'tos' | 'privacyPolicy' | false>(false)

  const { register, handleSubmit, errors, control } = useForm<SignUpFormData>({
    shouldUnregister: false,
  })

  const onSubmit = (data: SignUpFormData) => {
    setIsPending(true)
    signUp(data)
      .then(() => {
        setIsPending(false)
        setIsSignUpError(false)
        console.log('SignUp success')
        history.push(DashboardNav.path)
      })
      .catch((e: Error) => {
        console.error('SignUp error', e)
        setIsPending(false)
        setIsSignUpError(true)
        // TODO determine if the type coercion was necessary with ==
        // eslint-disable-next-line
        setSignupErrorMessage(e.message)
      })
  }

  const isEmail = (email: string) => {
    return EmailValidator.validate(email)
  }

  if (isPending) return <BodyContainer><LoadingSpinner reason="creating account" /></BodyContainer>
  if (locationsHook.loading) return <BodyContainer><LoadingSpinner reason="loading locations" /></BodyContainer>

  return (
    <AppLayout
      header={<SignUpHeader />}
      body={
        <CenteredBox>
          <CenteredBox my={2}>
            <Container maxWidth="xs">
              {tosDialogue && <LegalDocsContainer type={tosDialogue} closeLink={setTosDialogue} />}
              <Box display={tosDialogue ? 'none' : 'block'}>
                <PageTitle align="center">
                  <TextContent resourceKey="titles.signup-page" defaultValue="Sign up" />
                </PageTitle>
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="fname"
                        name="firstName"
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                        inputRef={register({ required: true })}
                      />
                      {errors.firstName && (
                        <ErrorText>
                          <TextContent resourceKey="errors.first-name-required" defaultValue="First Name is required" />
                        </ErrorText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lname"
                        inputRef={register({ required: true })}
                      />
                      {errors.lastName && (
                        <ErrorText>
                          <TextContent resourceKey="errors.last-name-required" defaultValue="Last Name is required" />
                        </ErrorText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="location"
                        control={control}
                        defaultValue="Raleigh"
                        rules={{ required: true }}
                        render={({ value, onChange }) => (
                          <FormControl fullWidth required variant="outlined">
                            <InputLabel id="locationLabel">Location</InputLabel>
                            <Select
                              id="location"
                              value={value}
                              onChange={onChange}
                              label="Location*"
                              labelId="locationLabel"
                            >
                              {locations.map(({ value, label }: { value: string; label: string }) => (
                                <MenuItem key={value} value={value}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                      {errors.location && (
                        <ErrorText>
                          <TextContent resourceKey="errors.location-required" defaultValue="Please select a city" />
                        </ErrorText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        inputRef={register({ required: true, validate: isEmail })}
                      />
                      {errors.email && errors.email.type === 'required' && (
                        <ErrorText>
                          <TextContent resourceKey="errors.email-required" defaultValue="Email is required" />
                        </ErrorText>
                      )}
                      {errors.email && errors.email.type === 'validate' && (
                        <ErrorText>
                          <TextContent resourceKey="errors.email-invalid" defaultValue="Email is invalid" />
                        </ErrorText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        inputRef={register({
                          required: 'Password is required',
                          minLength: { value: 8, message: 'Minimum password length is 8 characters' },
                        })}
                      />
                      {errors.password && <ErrorText>{errors.password.message}</ErrorText>}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="I want to receive inspiration, marketing promotions and updates via email"
                        control={
                          <Controller
                            name="allowExtraEmails"
                            defaultValue={false}
                            control={control}
                            render={({ value, onChange }) => (
                              <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
                            )}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Controller
                            name="acceptTOS"
                            control={control}
                            defaultValue={false}
                            rules={{ required: true }}
                            render={({ value, onChange }) => (
                              <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value} />
                            )}
                          />
                        }
                        label={<LegalDocsLinks openLink={setTosDialogue} />}
                      />
                      {errors.acceptTOS && (
                        <ErrorText>
                          <TextContent
                            resourceKey="errors.acceptTOS"
                            defaultValue="Please accept the terms of service"
                          />
                        </ErrorText>
                      )}
                    </Grid>
                  </Grid>
                  <Box mt={5} mb={4}>
                    <ButtonLarge type="submit" fullWidth>
                      <TextContent resourceKey="buttons.signup" defaultValue="Sign Up" />
                    </ButtonLarge>
                    {isSignUpError && <ErrorText>{signupErrorMessage}</ErrorText>}
                  </Box>
                  <Grid container justify="space-around" alignItems="center">
                    <Grid item>
                      Already have an account?{' '}
                      <AppLink inline to={LoginNav.path}>
                        <TextContent resourceKey="signup.already-have-account" defaultValue="Log in" />
                      </AppLink>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Container>
          </CenteredBox>
        </CenteredBox>
      }
    />
  )
}

export default SignUpPage
