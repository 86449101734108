import { styled } from '@material-ui/core'

export const TextError = styled('p')({
  marginLeft: '14px',
  marginRight: '14px',
  color: '#f44336',
  fontSize: '12px',
  marginTop: '3px',
  fontWeight: 400,
  lineHeight: '1.66',
  letterSpacing: '0.4px',
})
