import app from 'firebase/app'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useApiPrefix } from '.'
import { AuthServiceContext } from '../../App'
import { getActionCodeSettings } from '../../firebase/firebaseAuth'
import { SignUpFormData } from '../../models'
import { useMobileMode } from './useMobileMode'
import { useApiFetch } from '@rse/frontend-dal'
import { firebaseInstance } from '../../firebase'

//import { useErrorHandler } from './useErrorHandler'

// Intended to replace AuthServiceContext
//
// Primary improvement is a `loading` state to indicated transitions in auth state.
// This was initially created to solve a race condition between login/logout state
// and which router (pre-auth or post-auth) was rendering the current route.
export function useAuthService() {
  const { authState, authDispatch } = useContext(AuthServiceContext)
  const { service: authService } = authState
  const [loading, setLoading] = useState<boolean>(true)
  const currentUid = authState.uid
  //const { errorHandler: loadUserErrorHandler } = useErrorHandler('loading user record')
  const history = useHistory()
  const userApiPrefix = useApiPrefix('user')
  const userApi = useApiFetch(`${userApiPrefix}/${currentUid}/`, true, 'POST', true, firebaseInstance.auth)
  const isMobile = useMobileMode()

  useEffect(() => {
    setLoading(!authState.initialized)

    const currentUser = authState.fb.auth.currentUser
    if (currentUser) {
      authDispatch({ type: 'setFirebaseUser', user: currentUser })
    }

    console.log('useAuthService Initialized', authState)
  }, [authState.initialized])

  useEffect(() => {
    const postLogoutUrl = authState.postLogoutUrl
    if (postLogoutUrl && !authState.authenticated) {
      console.log('navigating to', postLogoutUrl)
      authDispatch({ type: 'setPostLogoutUrl' }) // clear the value
      history.push(postLogoutUrl)
    }
  }, [authState.postLogoutUrl, authState.authenticated])


  /*
  useEffect(()=> {
    const {stragglingSignUpData, authenticated, uid} = authState

    if (authenticated && uid && stragglingSignUpData) {
      console.log('straggling data', authState)
      saveStragglingData()
    }
  }, [authState.stragglingSignUpData])
  */

  async function login({ email, password }: { email: string, password: string }) {
    setLoading(true)

    try {
      console.debug('before authService.login', history.location.pathname)

      if (!isMobile) {
        // This is the default, but we switched it from SESSION
        // so that it will persist across multiple tabs.
        // This is especially important when verifying email, as the
        // user often opens the link from the email in a new tab.
        await authState.fb.auth.setPersistence(app.auth.Auth.Persistence.LOCAL)
      }

      const creds: app.auth.UserCredential = await authState.fb.auth.signInWithEmailAndPassword(email, password)
      console.debug('login successful', creds)
      authDispatch({ type: 'setFirebaseUser', user: creds.user })
      console.debug('before history.push', history.location.pathname)
      setLoading(false)
      //history.push('/')
      console.debug('after history.push', history.location.pathname)
    } catch (error: any) {
      console.error('login error', error)
      throw (error)
      //setLoading(false)
    }
  }

  async function logout() {
    setLoading(true)

    try {
      await authState.fb.auth.signOut()
      authDispatch({ type: 'logout', postLogoutUrl: '/' })
      setLoading(false)
    } catch (error: any) {
      console.error('error while logging out', error)
      // TODO consider setLoading(false)
    }
  }

  async function sendVerificationEmail() {
    const user = authState.fb.auth.currentUser
    if (!user) throw new Error('must be logged in to send verification email')
    console.debug('attempting to send verification email', user)

    setLoading(true)
    try {
      const actionCodeSettings = getActionCodeSettings(user)
      await user?.sendEmailVerification(actionCodeSettings)
    } catch (error: any) {
      /* TODO consider import of these custom types
      if (error.code === 'auth/too-many-requests') {
        return Promise.reject(GetError(ErrorType.QuotaExceeded))
      }
      return Promise.reject(GetError(ErrorType.AuthServiceError))
      */
      console.error('error sending verification email', error)
    } finally {
      setLoading(false)
    }
  }

  async function saveStragglingData() {
    console.log('saveStragglingData started')
    const data = authState.stragglingSignUpData
    if (!data) {
      console.error('cannot save straggling data that does not exist')
      console.trace()
      return
    }


    console.log('saveStragglingData', data)
    try {
      const res = await userApi.callApi(data)
      console.log('save result', res)
    } catch (error) {
      console.error('saveExtendedData', error)
    }
    authDispatch({ type: 'setStragglingSignUpData', data: undefined })
    console.log('saveStragglingData finished')
  }

  // TODO save first/last name and desired location
  async function signUp(data: SignUpFormData) {
    const { email, password, firstName, lastName, location } = data
    const displayName = `${firstName} ${lastName}`
    //await firebaseAuth.ValidateNewPassword(password)
    const res: app.auth.UserCredential = await authState.fb.auth.createUserWithEmailAndPassword(email, password)
    console.log('signUp success', res, data)
    const user = res.user
    if (!user) {
      throw new Error('newly created user does not exit')
      return
    }

    authDispatch({
      type: 'setStragglingSignUpData', data: {
        firstName,
        lastName,
        displayName,
        location,
        allowExtraEmails: data.allowExtraEmails || false
      }
    })

    authDispatch({ type: 'setFirebaseUser', user: user })
    return { uid: user.uid }
    //const userRecord = await authState.fb.db.doc(`/users/${user.uid}`).get()
    /*
    const snap = await authState.fb.db.collection('users').doc(user.uid).update({
      displayName: displayName,
      allowExtraEmails: data.allowExtraEmails || false
    })
    console.log('snap', { snap })
    */
    //console.log('user data', { displayName, userData, snap })
    // await firebaseInstance.auth.currentUser?.updateProfile({ displayName })
    //await authService.SendVerificationEmail()
  }

  async function sendResetPasswordEmail(email: string): Promise<void> {
    console.log('sendPasswordResetEmail', email)
    await authService.SendResetPasswordEmail(email)
  }

  async function changePassword(password: string, newPassword: string) {
    await authService.ChangePassword(password, newPassword)
  }

  return { currentUid, currentUser: authState.user, loading, login, logout, saveStragglingData, signUp, sendVerificationEmail, changePassword, sendResetPasswordEmail }
}