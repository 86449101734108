import React, { useState } from 'react'
import { useMobileMode } from '.'
import settings from '../../settings'
import { MobileBuildInfo, WebBuildInfo } from '../models'

type Ret = MobileBuildInfo | WebBuildInfo
declare const BuildInfo: MobileBuildInfo | null | undefined

const placeholderBuildInfo: WebBuildInfo = {
    version: 'loading',
    buildType: 'loading'
}

function useBuildInfo() {
    const isMobileMode = useMobileMode()
    const [loading, setLoading] = useState<boolean>(true)
    const [buildInfo, setBuildInfo] = useState<Ret>(placeholderBuildInfo)

    React.useEffect(() => {
        function handleNewBuildInfo() {
            const info = BuildInfo as MobileBuildInfo
            console.log('new info', info)
            setBuildInfo(info)
            setLoading(false)
        }

        if (isMobileMode) {
            document.addEventListener('deviceready', handleNewBuildInfo, false)
        } else {
            setBuildInfo({
                buildType: 'react-dom',
                version: settings.clientVersion
            })
            setLoading(false)
        }
    }, [])

    return { loading, buildInfo }
}

export { useBuildInfo }