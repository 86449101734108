import React, { useEffect, useState } from 'react'
import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core'
import { firebaseStorage, useUserPublicProfile } from '../../firebase'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    medium: {}, // use defaults
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    border: {
      border: '1px solid white',
    },
  })
)
interface Props {
  uid: string
  size?: 'small' | 'medium' | 'large'
  bordered?: Boolean
}

export function UserAvatarPublic({ uid, size = 'medium', bordered }: Props) {
  const [imgUrl, setImgUrl] = useState<string>(),
    classes = useStyles(),
    useBorder = bordered ? classes.border : ''
  const { value: userProfile } = useUserPublicProfile(uid)

  useEffect(() => {
    if(userProfile){
      setImgUrl(userProfile?.profileImageUrl)
    }    
  }, [userProfile])

  return <Avatar alt={userProfile?.firstName} src={imgUrl} className={`${classes[size]} ${useBorder}`} />
}
