import firebase from 'firebase/app'
import 'firebase/firestore'

export const documentIdFieldPath = firebase.firestore.FieldPath.documentId()

export const firebaseCollectionNames = {
  publicConfig: 'lookup/public',
  questions: 'lookup/public/questions',
  locations: 'lookup/public/locations',
  buyingGroups: 'buyingGroups',
  buyingGroupsArchive: '/archive/buyingGroups/groups',
  buyingGroupMembers: 'members',
  userProfiles: 'userProfiles',
  profileMatches: 'profileMatches',
  buyingGroupMatches: 'buyingGroupMatches',
  favoriteGroups: 'favoriteGroups',
  favoriteClubMembers: 'favoriteClubMembers',
  users: 'users',
  userInitApplication: 'userInitApplication',
  userInitApplicationSubmitted: 'userInitApplicationSubmitted',
  memberMatches: 'memberMatches',
  notifications: 'notifications',
  messageThreads: 'messageThreads',
  messages: 'messages',
  textContent: 'StringResources',
  userIdentityVerifications: 'userIdentityVerifications',
  signUpCohorts: 'signUpCohorts',
}
