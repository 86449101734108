import { UserIdentityVerification } from '@rse/core'

import { firebaseCollectionNames } from '../firebaseConstants'
import { usePersistentCollectionItem } from '.'

export function useUserIdentityVerification(uid: string) {
  const { document } = usePersistentCollectionItem<UserIdentityVerification>(
    `${firebaseCollectionNames.userIdentityVerifications}/${uid}`
  )

  console.log(document)

  return document?.value
}
