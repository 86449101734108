import { useEffect, useState } from 'react'
import { useApiPrefix } from './useApiPrefix'
import { useBuildInfo } from './useBuildInfo'

export function useClientVersionSupported() {
  const [isLoading, setIsLoading] = useState(true)
  const [isVersionSupported, setIsVersionSupported] = useState(false)
  const { loading: buildInfoLoading, buildInfo } = useBuildInfo()
  const startupApiPrefix = useApiPrefix('startup')

  useEffect(() => {
    async function clientVersionVerify(clientVersion: string) {
      console.log('clientVersionVerify', clientVersion)

      const res = await fetch(`${startupApiPrefix}/clientversion/check`, {
        headers: {
          'Content-Type': 'text/plain'
        },
        method: 'POST',
        mode: 'cors',
        body: clientVersion
      })

      let result = 'false'
      if (res.ok) {
        result = await res.text()
      }
      console.log('clientVersionVerify', { result, res })

      const isSupported = (result === 'true')
      setIsVersionSupported(isSupported)
      setIsLoading(false)
    }

    if (buildInfoLoading) return
    else clientVersionVerify(buildInfo.version)
  }, [buildInfoLoading])

  return { isLoading, isClientVersionSupported: isVersionSupported }
}
