import React from 'react'
import { DashboardBanner } from '../../../../components/layout/DashboardBanner'

export function JoinUpGate() {
  return (
    <DashboardBanner
      viewBox="0 0 42 42"
      title="Thanks for signing up!"
      titleKey="dashboard.joinup-gate-title"
      subtext="We will contact you once we begin accepting members in your city."
      subtextKey="dashboard.joinup-gate-subtext"
    ></DashboardBanner>
  )
}
