import { useContext } from 'react'
import { AppConfigContext } from '../../App/config'
import { MemberAppConfig } from '@rse/core'

function getApiPrefix(apiName: string, config: MemberAppConfig) {
  switch(apiName) {
    case 'startup': return config.api.startup.prefix
    case 'buyingGroup': return config.api.buyingGroup.prefix
    case 'chat': return config.api.chat.prefix
    case 'user': return config.api.user.prefix
    default: throw new Error(`no known api ${apiName}`)
  }
}

// TODO convert to dictionary
// TODO lift into reusable hook
export function useApiPrefix(apiName: string) {
  const config = useContext(AppConfigContext)
  return getApiPrefix(apiName, config)
}
