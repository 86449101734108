import React from 'react'
import { SummaryAgreeToClubMember } from '../fieldsets/SummaryAgreeToClubMember'
import { SummaryMortgagePreapproval } from '../fieldsets/SummaryMortgagePreapproval'
import { SummaryPreferences } from '../fieldsets/SummaryPreferences'
import { SummaryProfile } from '../fieldsets/SummaryProfile'

export const StepApplicationSummary: React.FC = () => {
  return (
    <div>
      <SummaryAgreeToClubMember />
      <SummaryProfile />
      <SummaryPreferences />
      <SummaryMortgagePreapproval />
    </div>
  )
}
