import React from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import PreferencesIcon from '@material-ui/icons/Tune'
import { Favorite, FavoriteBorderOutlined } from '@material-ui/icons'

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    container: {
      width: '100%',
      padding: spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: palette.primary.main,
    },
    searchGroup: {
      position: 'relative',
      '--icon-size': '24px',

      '& input': {
        height: '1.9em',
        border: 'none',
        borderRadius: '11px',
        outline: 'none',
        background: 'rgba(255, 255, 255, 0.77)',
        paddingLeft: 'calc(var(--icon-size) * 1.4)',
      },
      '& .searchIconBox': {
        position: 'absolute',
        top: '2px',
        left: 'calc(var(--icon-size) / 4)',
        display: 'flex',
        alignItems: 'center',

        '& .searchIcon': {
          color: '#ffffff',
        },
      },
    },
    controls: {
      display: 'flex',
      lineHeight: 1,
      color: '#ffffff',

      '& .preferences': {
        cursor: 'pointer',
      },

      '& .favorite': {
        cursor: 'pointer',
      },

      '& > * + *': {
        marginLeft: 3,
      },
    },
  })
)

interface SearchBoxProps {
  searchContent: string
  onChangeSearchContent: (value: string) => void
  onClickPreferences: () => void
  onClickFavorite: () => void
  favoritesOnly?: boolean
  extraButtons?: () => JSX.Element
}

export function SearchBox({
  searchContent,
  onChangeSearchContent,
  onClickPreferences,
  onClickFavorite,
  favoritesOnly,
  extraButtons,
}: SearchBoxProps) {
  const styles = useStyles()

  return (
    <Box className={styles.container}>
      <Box className={styles.searchGroup}>
        <input
          type="text"
          className="searchField"
          value={searchContent}
          onChange={(ev) => onChangeSearchContent(ev.target.value)}
        />
        <Box className="searchIconBox">
          <SearchIcon className="searchIcon" />
        </Box>
      </Box>
      <Box className={styles.controls}>
        <Box className="preferences" onClick={onClickPreferences}>
          <PreferencesIcon />
        </Box>
        <Box className="favorite" onClick={onClickFavorite}>
          {favoritesOnly ? <Favorite /> : <FavoriteBorderOutlined />}
        </Box>
        {extraButtons && <Box>{extraButtons()}</Box>}
      </Box>
    </Box>
  )
}
