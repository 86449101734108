import React from 'react'
import { Switch } from 'react-router-dom'
import { Container, useTheme } from '@material-ui/core'
import SandboxIndex from './components/Sandbox'
import IconDemo from './components/ButtonDemo'
import PageDemo from './components/PageDemo'
import DynamicWizard from '../../components/DynamicWizard'
import { ButtonFullWidthSecondary } from '@rse/uikit'
import { IconPencil } from '../../components/icons'
import { BuyingGroupViewWizard } from '../../components/DynamicWizard/BuyingGroupViewWIzard'
import { ProtectedRoute } from '../../App/routeUtils'
import AuthTest from './components/AuthTest'
import { SimpleCascadedApplication as Demo } from '../../components/common/ImportanceFactors/Demo2'
import { FirebaseCloudMessaging } from './components/FirebaseCloudMessaging'

const DynamicWizardComponent = () => (
  <DynamicWizard
    ConfigID={'Sample'}
    Slots={{
      editButton: (
        <ButtonFullWidthSecondary href={'#'}>
          <IconPencil />
          &nbsp;&nbsp;
          <span>Edit Profile</span>
        </ButtonFullWidthSecondary>
      ),
    }}
  />
)

const BuyingGroupViewWizardComponent = () => (
  <BuyingGroupViewWizard
    ConfigID={'BuyingGroupViewWizard'}
    Slots={{
      editButton: (
        <ButtonFullWidthSecondary href={'#'}>
          <IconPencil />
          &nbsp;&nbsp;
          <span>Edit group</span>
        </ButtonFullWidthSecondary>
      ),
    }}
  />
)

function ErrorDemo() {
  throw new Error('Simulated error, to test the error handler')
  return null
}

export default function SandboxPage() {
  const theme = useTheme()

  return (
    <Container style={{ paddingTop: theme.spacing(5), paddingBottom: theme.spacing(5) }}>
      <Switch>
        <ProtectedRoute exact path="/sandbox" component={SandboxIndex} allowedRoles="admin" />
        <ProtectedRoute exact path="/sandbox/fcm" component={FirebaseCloudMessaging} allowedRoles="admin" />
        <ProtectedRoute path="/sandbox/icons" component={IconDemo} allowedRoles="admin" />
        <ProtectedRoute path="/sandbox/simulatedError" component={ErrorDemo} allowedRoles="admin" />
        <ProtectedRoute path="/sandbox/page" component={PageDemo} allowedRoles="admin" />
        <ProtectedRoute path="/sandbox/Dynamicwizard" component={DynamicWizardComponent} allowedRoles="admin" />
        <ProtectedRoute path="/sandbox/authtest" component={AuthTest} allowedRoles="admin" />        
        <ProtectedRoute
          path="/sandbox/dw/groups/:groupId"
          component={BuyingGroupViewWizardComponent}
          allowedRoles="admin"
        />
        <ProtectedRoute path="/sandbox/cascading-buckets" component={Demo} allowedRoles={['admin', 'member']} />
      </Switch>
    </Container>
  )
}
