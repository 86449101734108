import { useEffect, useState } from 'react'
import { useErrorHandler, usePhoneVerification } from '.'
import { parsePhone } from '../utils'

export function useSmsVerify() {
  const phoneVerification = usePhoneVerification()
  const [statusPending, setStatusPending] = useState(false)
  const [verificationError, setVerificationError] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const { errorHandler } = useErrorHandler('validating phone number')
  const { errorHandler: codeSendErrorHandler } = useErrorHandler('sending verification code')
  const [invalidCodeSent, setInvalidCodeSent] = useState(false)
  const [smsVerified, setSmsVerified] = useState(false)

  useEffect(() => {
    if (phoneVerification.status.codeSentToPhone) {
      setCodeSent(true)
    }
  }, [phoneVerification.status.codeSentToPhone])

  useEffect(() => {
    if (phoneVerification.status.sendPhoneCodeHookError) {
      codeSendErrorHandler(Error(''))
    }
  }, [phoneVerification.status.sendPhoneCodeHookError])

  const sendCode = async (phoneNum: string) => {
    setStatusPending(true)
    const parsedPhone = parsePhone(phoneNum).number as string
    console.log(`sending code to ${parsedPhone}`)

    await phoneVerification.sendVerificationCode(parsedPhone)
    setStatusPending(false)
  }

  const verifyCode = async (inputCode: string, setInputCode: any) => {
    setStatusPending(true)
    setVerificationError('')

    try {
      const isCodeValid: boolean = await phoneVerification.verifycode(inputCode) // TODO: this needs to be reworked as sendVerificationCode

      if (isCodeValid) {
        // Approve number and route to dashboard
        setStatusPending(false)
        setInvalidCodeSent(false)
        setSmsVerified(true)
      } else {
        setStatusPending(false)
        setInvalidCodeSent(true)
        setSmsVerified(false)
      }
    } catch (error: any) {
      setStatusPending(false)
      setInputCode('')

      if (error.code === 'auth/invalid-verification-code') {
        setVerificationError('Code was incorrect')
      } else if (error.code === 'auth/code-expired') {
        setVerificationError('SMS code has expired. Please send again.')
      } else {
        errorHandler(error)
      }
    }
  }

  return {
    statusPending,
    verificationError,
    codeSent,
    invalidCodeSent,
    smsVerified,
    sendCode,
    verifyCode,
  }
}
