import React from 'react'
import { Box, Avatar, makeStyles, createStyles, Theme, styled } from '@material-ui/core'
import { MessageThreadType, SystemUserID } from '@rse/core'
import { StandardText, SystemText } from '@rse/uikit'
import { useCurrentUserId } from '../../../../common/hooks'
import { ChatMessageStatus } from './ChatMessageStatus'
import { ChatThreadMessage } from '../../../../models'
import { UserAvatarPublic } from '../../../../components/common'

export const ChatMessage = ({
  uid,
  message,
  dateString,
  status,
  avatarUrl,
  type,
}: ChatThreadMessage & { type: MessageThreadType }) => {
  const { meStyle, themStyle } = useStyles()
  const currentUID = useCurrentUserId()
  const isMe = currentUID === uid

  const direction = isMe ? 'row-reverse' : 'row'
  const userStyle = isMe ? meStyle : themStyle

  return (
    <Box display="flex" style={{ flexDirection: direction }} mb="10px">
      <UserAvatarPublic uid={uid} size="small" />
      {type === 'GroupCourtship' && !isMe && <MessageAvatar src={avatarUrl} />}
      <MessageContainer className={userStyle}>
        <Box p="10px" px="15px" pb="7px">

          {uid != SystemUserID && message && <StandardText>{message}</StandardText>}
          {uid == SystemUserID && message && <SystemText>{message}</SystemText>}
          {dateString && <ChatMessageStatus dateString={dateString} status={isMe ? status : 0} />}

        </Box>
      </MessageContainer>
    </Box>
  )
}

const MessageContainer = styled('div')({
  borderRadius: 15,
  width: 275,
})

const MessageAvatar = styled(Avatar)({
  width: 20,
  height: 20,
  paddingLeft: '0.05px',
  paddingBottom: '0.1px',
  marginLeft: '7px',
})

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    meStyle: {
      backgroundColor: palette.primary.light,
      marginLeft: spacing(1),
      color: palette.primary.contrastText
    },
    themStyle: {
      backgroundColor: palette.primary.main,
      border: 'solid 1px black',
      marginRight: spacing(1),
      color: palette.primary.contrastText,
    },
  })
)
