declare global {
  interface Window {
    env: {
      clientVersion: string
      clientAppCode: string
      siteHostUrl: string
      firebaseApiKey: string
      firebaseAuthDomain: string
      firebaseAuthActionCodeSettingsUrlForMobile: string,
      firebaseDBUrl: string
      firebaseProjectID: string
      firebaseStorageBucket: string
      firebaseMessagingSenderID: string
      firebaseFunctionsBaseUrl: string
      myRunTimeEnvSetting: string
      featureFlagApplyToJoin: string
      firebaseUseEmulators: string
      featureFlagContactUs: string
      CONFIG_URI: string // a URI where the app config can be fetched
    }
    recaptchaVerifier: any
    smsConfirmation: any
  }
}

const settings = {
  clientVersion: window.env.clientVersion,
  clientAppCode: window.env.clientAppCode,
  firebaseUseEmulators: window.env.firebaseUseEmulators === 'true' && process.env.REACT_APP_CORDOVA !== 'true',
  siteHostUrl: window.env.siteHostUrl,
  firebaseApiKey: window.env.firebaseApiKey,
  firebaseAuthDomain: window.env.firebaseAuthDomain,
  firebaseAuthActionCodeSettingsUrlForMobile: window.env.firebaseAuthActionCodeSettingsUrlForMobile,
  firebaseDBUrl: window.env.firebaseDBUrl,
  firebaseProjectID: window.env.firebaseProjectID,
  firebaseStorageBucket: window.env.firebaseStorageBucket,
  firebaseMessagingSenderID: window.env.firebaseMessagingSenderID,
  firebaseFunctionsBaseUrl: window.env.firebaseFunctionsBaseUrl,
  myRunTimeEnvSetting: window.env.myRunTimeEnvSetting,
  myAppSettingStatic: 'Static app setting value',
}

export const featureFlags = {
  featureFlagApplyToJoin: window.env.featureFlagApplyToJoin === 'true',
  featureFlagContactUs: window.env.featureFlagContactUs === 'true',
}

export default settings
