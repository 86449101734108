import React, { Component } from 'react'
import { ErrorMessage } from '../ErrorMessage'

type Props = {
  children?: React.ReactNode
  text?: string
}

type State = {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
  }

  componentDidCatch(): void {
    this.setState({ hasError: true })
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorMessage text={this.props.text} />
    }

    return this.props.children
  }
}
