import { Box } from '@material-ui/core'
import React from 'react'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import { BoxNoticeArea } from '../components/BoxNoticeArea'
import { InputCheckbox } from '../components/InputCheckbox'
import { Spacing } from '@rse/uikit'
import { TextNotice } from '../components/TextNotice'

export const SummaryAgreeToClubMember: React.VFC = () => {
  const textContent = useTextContent()

  return (
    <section>
      <Spacing value={20} />
      <Box height={180}>
        <BoxNoticeArea>
          <TextNotice>
            <TextContent
              resourceKey="app-wizard.agree-to-rules-description"
              defaultValue={`By checking the box below you authorize Social Equity to order a consumer credit report and verify other
            credit information, including past and present mortgage and landlord references. You further authorize for
            Social Equity to verify your past and present employment earnings records, bank accounts, stock holdings,
            and other asset balances that are needed to process your`}
            />
          </TextNotice>
        </BoxNoticeArea>
      </Box>
      <Spacing value={20} />
      <Box>
        <InputCheckbox
          id="agreeToClubMemberRules"
          label={getTextContent(textContent, 'app-wizard.field-agree-to-rules', 'I agree to club member rules')}
          errorMessage={getTextContent(
            textContent,
            'app-wizard.error-agree-to-rules',
            'You must accept this check to proceed'
          )}
        />
      </Box>
    </section>
  )
}
