import React from 'react'
import { Box, BoxProps } from '@material-ui/core'
import { useMobileDevice } from '../../../../common/hooks'

export function HeaderBox(props: BoxProps) {
  const mobileDevice = useMobileDevice()
  const iosProps = mobileDevice?.isIOS ? { paddingTop: 'env(safe-area-inset-top)' } : {}

  return (
    <Box {...iosProps} {...props}>
      {props.children}
    </Box>
  )
}
