import React from 'react'
import { withStyles, Theme } from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'

export const StyledBoolButtonGroup = withStyles(({ palette }: Theme) => ({
  grouped: {
    color: palette.secondary.main,
    backgroundColor: palette.common.white,
    border: `1px solid ${palette.secondary.main}`,
    '&.Mui-selected': {
      backgroundColor: palette.secondary.main,
      color: 'white',
    },
    '@media (hover: hover)': {
      '&:hover, &.Mui-selected:hover': {
        backgroundColor: palette.secondary.light,
        color: 'white',
      },
    },
    '@media (hover: none)': {
      '&.Mui-selected:hover': {
        backgroundColor: palette.secondary.main,
      },
    },
    '&:hover': {
      '@media (hover: none)': {
        backgroundColor: palette.secondary.main,
      },
    },
    '&:first-child': {
      borderRight: `2px solid ${palette.secondary.main}`,
    },
  },
}))(ToggleButtonGroup)

interface BoolProps {
  name?: string
  value: string
  onChange: (e: React.MouseEvent<HTMLElement, MouseEvent>, v: string) => void
  label?: string
}

const BoolButtonGroup = (props: BoolProps) => {
  const { name, value, onChange, label } = props

  return (
    <StyledBoolButtonGroup value={value} onChange={onChange} aria-label={label} exclusive>
      <ToggleButton name={name} value="yes">
        Yes
      </ToggleButton>
      <ToggleButton value="no">No</ToggleButton>
    </StyledBoolButtonGroup>
  )
}

export default BoolButtonGroup
