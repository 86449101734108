import React, { useEffect, useState } from 'react'
import { Container, makeStyles, createStyles, Box, Button, TextField, Slider } from '@material-ui/core'
import { BuyingGroup } from '@rse/core'
import { AppLayout, Header } from '../../../components/layout'
import noGroupPhotoImage from '../BuyingGroupCreate/images/no-group-photo.svg'
import { getTextContent, TextContent, useTextContent } from '../../../common/textContent'
import {
  PageTitle,
  SectionHeader,
  StandardText,
  ButtonLarge,
  ButtonLargeSecondary,
  InputOption,
  InputSelectRadioPills,
  InputToggle,
  Spacing,
  Popup,
  LoadingSpinner,
} from '@rse/uikit'
import { useCurrentUserId, useErrorHandler, usePreferencesQuestions } from '../../../common/hooks'
import { useFormik } from 'formik'
import { FormikScrollToError, TagsSelect } from '../../../components/common'
import { GroundRulesPane } from '../../../components/common/GroundRules/GroundRulesPane'
import { firebaseUpdateGroupData, firebaseUploadGroupImage } from '../../../firebase'
import exclamationIcon from '@rse/uikit/lib/icons/exclamation.svg'
import {
  FormValues,
  GROUP_VISION_MAX,
  GROUP_VISION_MIN,
  predefinedTags,
  roomMatesCountMarks,
  validate,
} from '../common/formAssets'
import { FormikErrorArrayField } from '../common/FormikErrorArrayField'
import { BodyContainer } from '../../../components/layout/BodyContainer'

type Props = {
  group: BuyingGroup
  cancelEdit: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      display: 'flex',
      justifyContent: 'center',
      margin: '40px 0 20px 0',
      cursor: 'pointer',
    },
    addPhoto: {
      display: 'flex',
      marginBottom: '20px',
      justifyContent: 'center',

      '& .group-image-load-btn': {
        // TODO: extract common component
        background: '#F0F0F4',
        color: '#8C8AAC',
        display: 'block',
        textAlign: 'center',
        margin: '0 auto',
        maxWidth: '200px',
      },
    },
    validationErrors: {
      color: 'red',
    },
    cancelButton: {
      // TODO: extract common component
      background: '#F0F0F4',
      color: '#8C8AAC',
      paddingTop: '1em',
      paddingBottom: '1em',
    },
  })
)

const roomMateGenderOptions: InputOption[] = [
  {
    id: 'female',
    title: (
      <StandardText>
        <TextContent resourceKey="bg-edit.mate-gender-female" defaultValue="&#9792; Female" />
      </StandardText>
    ),
  },
  {
    id: 'male',
    title: (
      <StandardText>
        <TextContent resourceKey="bg-edit.mate-gender-male" defaultValue="&#9794; Male" />
      </StandardText>
    ),
  },
  {
    id: 'nonbinary',
    title: (
      <StandardText>
        <TextContent resourceKey="bg-edit.mate-gender-nonbinary" defaultValue="&#9893; Co-Ed" />
      </StandardText>
    ),
  },
]

export function BuyingGroupDatailsEdit({ group, cancelEdit }: Props) {
  const classes = useStyles()
  const uid = useCurrentUserId()
  const textContent = useTextContent()
  const [tempImageUrl, setTempImageUrl] = useState<string>(group.groupProfileImageUrl || '')
  const [tempImageFile, setTempImageFile] = useState<File | null>(null)
  const [ageRange, setAgeRange] = useState<[number, number]>(group.ageRange)
  const [priceRange, setPriceRange] = useState<[number, number]>(group.priceRange)
  const [roommates, setRoommates] = useState<[number, number]>(group.roommates)
  const [selectedTags, setSelectedTags] = useState<string[]>(group.tags || [])
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)
  const { errorHandler: editGroupErrorHandler } = useErrorHandler('editing buying group details')

  const { value: questions, loading: loadingQuestions, error: errorLoadingQuestions } = usePreferencesQuestions()

  const formik = useFormik<FormValues>({
    initialValues: {
      groupTitle: group.headline,
      groupVision: group.description || '',
      groupQuestions: group.questions?.map((question) => question.title) || ['', '', ''],
      roommateGender: group.gender,
      lgbtqFriendly: !!group.lgbtqFriendly,
      preferences: group.preferences,
    },
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      const { groupTitle, groupVision, groupQuestions, roommateGender, lgbtqFriendly, preferences } = values

      let groupProfileImageUrl = ''

      try {
        groupProfileImageUrl = tempImageFile
          ? await firebaseUploadGroupImage(uid, tempImageFile)
          : group.groupProfileImageUrl
      } catch (err) {
        console.log(err)
      }

      firebaseUpdateGroupData({
        ...group,
        id: uid,
        headline: groupTitle,
        description: groupVision,
        roommates,
        ageRange,
        priceRange,
        tags: selectedTags,
        gender: roommateGender,
        lgbtqFriendly,
        questions: groupQuestions.map((question, index) => ({ id: '' + index, title: question })),
        preferences
      })
        .catch(editGroupErrorHandler)
        .finally(() => cancelEdit())
    },
  })

  useEffect(() => {
    if (questions && !loadingQuestions && !errorLoadingQuestions) {
      const updatedPreferences: Record<string, string | undefined> = { ...formik.values.preferences }
      for (const question of questions) {
        if (!updatedPreferences[question.id]) {
          updatedPreferences[question.id] = undefined
        }
      }
      formik.setFieldValue('preferences', updatedPreferences)
    }
  }, [questions, loadingQuestions, errorLoadingQuestions])

  if (loadingQuestions) {
    return <BodyContainer><LoadingSpinner /></BodyContainer>
  }

  if (errorLoadingQuestions) {
    editGroupErrorHandler('failed to load preferences questions')
    return null
  }

  const chooseFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    URL.revokeObjectURL(tempImageUrl)

    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setTempImageFile(file)
      setTempImageUrl(URL.createObjectURL(file))
    } else {
      setTempImageFile(null)
      setTempImageUrl('')
    }

    setUnsavedChanges(true)
  }

  return (
    <AppLayout
      header={
        <Header
          back={async () => {
            if (unsavedChanges || formik.dirty) {
              setPopupOpen(true)
            } else {
              cancelEdit()
            }
          }}
          title="Edit group"
        />
      }
      body={
        <Container maxWidth="xs">
          <Box>
            <Popup open={popupOpen} setOpen={setPopupOpen}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <img src={exclamationIcon} alt="exclamation icon" />
                <h3>
                  <TextContent resourceKey="bg-edit.save-your-changes" defaultValue="Save your changes?" />
                </h3>
                <Box width="100%">
                  <ButtonLarge
                    fullWidth
                    isUppercase={false}
                    onClick={() => {
                      setPopupOpen(false)
                      formik.submitForm()
                    }}
                  >
                    <TextContent resourceKey="buttons.save-bg-edit-changes" defaultValue="Save" />
                  </ButtonLarge>
                  <Box mt={1}>
                    <ButtonLargeSecondary
                      fullWidth
                      isUppercase={false}
                      onClick={() => {
                        setPopupOpen(false)
                        cancelEdit()
                      }}
                    >
                      <TextContent resourceKey="buttons.dont-save-bg-edit-changes" defaultValue="Do not save" />
                    </ButtonLargeSecondary>
                  </Box>
                </Box>
              </Box>
            </Popup>
            <form onSubmit={formik.handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
              <FormikScrollToError formik={formik} />
              <Box className={classes.addPhoto}>
                <input
                  type="file"
                  accept="image/*"
                  id="group-image-load"
                  style={{ display: 'none' }}
                  onChange={chooseFile}
                />
                <label htmlFor="group-image-load">
                  <Box className={classes.avatar}>
                    {tempImageUrl ? (
                      <Box borderRadius={12} minHeight={128} overflow="hidden">
                        <img src={tempImageUrl} width="100%" />
                      </Box>
                    ) : (
                      <img src={noGroupPhotoImage} alt="no group image photo" />
                    )}
                  </Box>
                  <Button component="span" className="group-image-load-btn">
                    <TextContent resourceKey="edit-bg.add-group-photo" defaultValue="+ Add Group Photo" />
                  </Button>
                </label>
              </Box>
              <Box>
                <TextField
                  variant="outlined"
                  name="groupTitle"
                  fullWidth
                  label={getTextContent(textContent, 'edit-bg.field-group-title', 'Title *')}
                  value={formik.values.groupTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.groupTitle && formik.errors.groupTitle ? (
                  <Box className={classes.validationErrors}>{formik.errors.groupTitle}</Box>
                ) : null}
              </Box>
              <Spacing />
              <Box>
                <TextField
                  multiline={true}
                  placeholder={`We recommend min ${GROUP_VISION_MIN} characters (max ${GROUP_VISION_MAX})`}
                  rows={4}
                  variant="outlined"
                  name="groupVision"
                  fullWidth
                  label={getTextContent(textContent, 'edit-bg.field-group-vision', 'Vision *')}
                  value={formik.values.groupVision}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.groupVision && formik.errors.groupVision ? (
                  <Box className={classes.validationErrors}>{formik.errors.groupVision}</Box>
                ) : null}
              </Box>
              <Spacing />
              <Box>
                <Spacing />
                <SectionHeader>
                  <TextContent
                    resourceKey="edit-bg.title-price-range"
                    defaultValue={getTextContent(
                      textContent,
                      'edit-bg.field-price-range',
                      'What would be a comfortable monthly payment for you?'
                    )}
                  />
                </SectionHeader>
                <Spacing value={50} />
                <Slider
                  onChange={(e: React.ChangeEvent<{}>, val) => {
                    setPriceRange(val as [number, number])
                    setUnsavedChanges(true)
                  }}
                  name="priceRange"
                  value={priceRange}
                  valueLabelDisplay="on"
                  marks={[
                    { value: 500, label: '$500' },
                    { value: 5000, label: '$5000' },
                  ]}
                  step={100}
                  min={500}
                  max={5000}
                  style={{ width: '90%', display: 'block', margin: '0 auto 20px' }}
                />
              </Box>
              <Spacing />
              <Box>
                <TagsSelect
                  title={getTextContent(textContent, 'edit-bg.field-tags', 'What\'s Important to Me')}
                  options={predefinedTags}
                  selectedTags={selectedTags}
                  selectTag={(tag: string) => {
                    if (!selectedTags.includes(tag)) setSelectedTags((tags) => [...tags, tag])
                  }}
                  removeTag={(tag: string) => {
                    if (selectedTags.includes(tag)) {
                      setSelectedTags((tags) => tags.filter((t) => t !== tag))
                      setUnsavedChanges(true)
                    }
                  }}
                />
              </Box>
              <Spacing />
              <Box>
                <Box mb={1}>
                  <SectionHeader>
                    <TextContent
                      resourceKey="edit-bg.questions"
                      defaultValue={getTextContent(
                        textContent,
                        'edit-bg.field-questions-to-answer',
                        'Questions I want new members to answer'
                      )}
                    />
                  </SectionHeader>
                </Box>
                <Spacing />
                <Box>
                  {formik.values.groupQuestions.map((question, i) => (
                    <Box key={i} mb={1}>
                      <TextField
                        name={`groupQuestions[${i}]`}
                        variant="outlined"
                        fullWidth
                        label={`Question ${i + 1}${i === 0 ? ' *' : ''}`}
                        value={question}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {i === 0 && <FormikErrorArrayField formik={formik} field="groupQuestions" index={0} />}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Spacing />
              <Box>
                <Spacing />
                <PageTitle>
                  <TextContent resourceKey="edit-bg.title-people-preferences" defaultValue="HouseMate preferences" />
                </PageTitle>
                <Box>
                  <Spacing />
                  <SectionHeader>
                    <TextContent resourceKey="edit-bg.title-age-range" defaultValue="Age Range" />
                  </SectionHeader>
                  <Spacing value={50} />
                  <Slider
                    onChange={(e: React.ChangeEvent<{}>, val) => {
                      setAgeRange(val as [number, number])
                      setUnsavedChanges(true)
                    }}
                    name="ageRange"
                    value={ageRange}
                    valueLabelDisplay="on"
                    marks={[
                      { value: 18, label: '18' },
                      { value: 70, label: '70' },
                    ]}
                    step={1}
                    max={70}
                    min={18}
                  />
                </Box>
                <Spacing />
                <Box>
                  <SectionHeader>
                    <TextContent resourceKey="edit-bg.title-want-to-live-with" defaultValue="I want to live with..." />
                  </SectionHeader>
                  <Spacing value={20} />
                  <InputSelectRadioPills
                    name="roommateGender"
                    options={roomMateGenderOptions}
                    onChange={formik.handleChange}
                    value={formik.values.roommateGender}
                  />
                  <Spacing value={20} />
                  <InputToggle
                    name="lgbtqFriendly"
                    label={getTextContent(textContent, 'edit-bg.field-lgbtq-friendly', 'I\'m LGBTQ friendly')}
                    checked={formik.values.lgbtqFriendly}
                    onChange={() => {
                      formik.setFieldValue('lgbtqFriendly', !formik.values.lgbtqFriendly)
                      setUnsavedChanges(true)
                    }}
                    color="primary"
                  />
                </Box>
                <Spacing />
                <Box>
                  <SectionHeader>
                    <TextContent
                      resourceKey="edit-bg.title-how-many-home-mates"
                      defaultValue="How many home mates do you want to live with?"
                    />
                  </SectionHeader>
                  <Spacing value={50} />
                  <Slider
                    name="roommates"
                    onChange={(e: React.ChangeEvent<{}>, val) => {
                      setRoommates(val as [number, number])
                      setUnsavedChanges(true)
                    }}
                    value={roommates}
                    defaultValue={[18, 50]}
                    valueLabelDisplay="on"
                    step={1}
                    min={1}
                    max={6}
                    marks={roomMatesCountMarks}
                  />
                </Box>
                <Spacing />
                <Box>
                  <PageTitle>
                    <TextContent resourceKey="edit-bg.title-ground-rules" defaultValue="Home Lifestyle preferences" />
                  </PageTitle>
                  <Spacing />
                  <GroundRulesPane
                    values={formik.values.preferences}
                    errors={formik.errors.preferences}
                    onChange={(question: string, value: string) => {
                      formik.setFieldValue('preferences', { ...formik.values.preferences, [question]: value })
                      setUnsavedChanges(true)
                    }}
                  />
                </Box>
              </Box>
              <Spacing />
              <Box mb={2}>
                <ButtonLarge fullWidth type="submit">
                  <TextContent resourceKey="buttons.edit-group-save" defaultValue="Save" />
                </ButtonLarge>
                <Spacing />
                <ButtonLarge
                  fullWidth
                  className={classes.cancelButton}
                  onClick={() => {
                    cancelEdit()
                  }}
                >
                  <TextContent resourceKey="buttons.edit-group-cancel" defaultValue="Cancel" />
                </ButtonLarge>
              </Box>
            </form>
          </Box>
        </Container>
      }
    />
  )
}
