import React from 'react'
import { useCurrentUserId } from '../../../common/hooks'
import { TextContent } from '../../../common/textContent'
import { SectionHeader, Spacing } from '@rse/uikit'
import { firebaseStorage } from '../../../firebase'
import { InputImage } from '../components/InputImage'


type Props = {
  onChangeImgValue?: (imgUrl: string) => Promise<void>
}
export const ProfileUploadPhoto: React.FC<Props> = ({onChangeImgValue}) => {
  const userId = useCurrentUserId()
  const getImage = async () => await firebaseStorage.getProfileImageUrlByUser(userId)
  const saveImage = (file: File) => firebaseStorage.UploadPicture(file)

  return (
    <section>
      <Spacing value={28} />
      <SectionHeader>
        <TextContent resourceKey="app-wizard.title-upload-photo" defaultValue="Upload Profile Photo" />
      </SectionHeader>
      <Spacing value={20} />
      <InputImage id="profileImageUrl" getImage={getImage} saveImage={saveImage} onChangeImgValue={onChangeImgValue}>
        <TextContent resourceKey="app-wizard.field-add-photo" defaultValue="+ Add Photo" />
      </InputImage>
    </section>
  )
}
