import React, { useContext } from 'react'
import { URL_MEMBERSHIP_WIZARD } from '../../common/constants/routes'
import { useFormData } from './helpers/useFormData'
import { ApplicationError, ApplicationLoading, ApplicationProgress, ApplicationProgressDataContext, SignupLayout } from './components'

export function ResumeApplication() {
  return <SignupLayout content={getPageContent()} />
}

function getPageContent(): JSX.Element {
  const { formData, loaded, loading } = useFormData()

  if (loading) {
    return <ApplicationLoading />
  }

  if (!loaded) {
    return <ApplicationError />
  }

  const [{ latestStep }] = useContext(ApplicationProgressDataContext)

  const sublink = loaded && typeof formData.lastWizardStep === 'number'
    ? formData.lastWizardStep + 1
    : latestStep
  const link = `${URL_MEMBERSHIP_WIZARD}/${sublink}`

  return <ApplicationProgress link={link} />
}
