import React from 'react'
import { PreApprovalAmountDeposited } from '../fieldsets/PreApprovalAmountDeposited'
import { PreApprovalCurrentAddress } from '../fieldsets/PreApprovalCurrentAddress'
import { PreApprovalEmploymentType } from '../fieldsets/PreApprovalEmploymentType'
import { PreApprovalIncomeDuration } from '../fieldsets/PreApprovalIncomeDuration'
import { PreApprovalTargetPayment } from '../fieldsets/PreApprovalTargetPayment'
import { PreApprovalMonthlyIncome } from '../fieldsets/PreApprovalMonthlyIncome'
import { PreApprovalCurrentPayments } from '../fieldsets/PreApprovalCurrentPayments'

export const StepMortgagePreapproval: React.VFC = () => {
  return (
    <section>
      <PreApprovalCurrentAddress />
      <PreApprovalEmploymentType />
      <PreApprovalMonthlyIncome />
      <PreApprovalIncomeDuration />
      <PreApprovalAmountDeposited />
      <PreApprovalCurrentPayments />
      <PreApprovalTargetPayment />
    </section>
  )
}
