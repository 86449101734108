import { styled } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

const id = 'UplaodImage-image'

type Props = {
  imgChangeHandler: (currentImgUrl: string) => void
  getImage: () => Promise<string | undefined>
  saveImage: (file: File) => Promise<string>
}

export const UplaodImage: React.FC<Props> = ({ children, getImage, saveImage, imgChangeHandler }) => {
  const [currentImageUrl, setCurrentImageUrl] = useState<string>()
  const [loading, setLoading] = useState(true)

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      setLoading(true)
      try {
        const downloadImgUrl = await saveImage(file)
        setCurrentImageUrl(URL.createObjectURL(file))
        imgChangeHandler(downloadImgUrl)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const profileImage = await getImage()
        setCurrentImageUrl(profileImage)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <div>
      <StyledLabel htmlFor={id}>
        {loading && 'Loading...'}
        {!loading && !currentImageUrl && children}
        {!loading && currentImageUrl && (
          <img alt="Avatar" style={{ maxWidth: '100px', maxHeight: '100px' }} src={currentImageUrl} />
        )}
      </StyledLabel>
      <input accept="image/*" style={{ height: 0 }} id={id} type="file" onChange={handleFileChange} />
    </div>
  )
}

const StyledLabel = styled('label')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed rgba(43, 39, 97, 0.38)',
  borderRadius: '4px',
  minHeight: '60px',
  fontSize: '20px',
  fontWeight: 700,
  cursor: 'pointer',
  padding: '10px',
})
