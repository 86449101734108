import React, { useContext, useEffect, useRef, useState } from 'react'
import { Box, Container } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory, useParams } from 'react-router-dom'
import { useCurrentUserId, useErrorHandler } from '../../../common/hooks'
import { TextContent } from '../../../common/textContent'
import { Popup, Spacing, ButtonLarge, ButtonLargeSecondary, LoadingSpinner } from '@rse/uikit'
import exclamationIcon from '@rse/uikit/lib/icons/exclamation.svg'
import { AppLayout, Header } from '../../../components/layout'
import { ApplicationForm } from '../../Application/components/ApplicationForm'
import {
  ApplicationFormDataProvider,
  ApplicationFormState,
} from '../../Application/components/ApplicationFormDataProvider'
import { TextHeading } from '../../Application/components/TextHeading'
import { preferencesPrefix, PrefsGroundRules } from '../../Application/fieldsets/PrefsGroundRules'
import { PrefsMateAgeRange } from '../../Application/fieldsets/PrefsMateAgeRange'
import { PrefsMateGender } from '../../Application/fieldsets/PrefsMateGender'
import { PrefsMatesCount } from '../../Application/fieldsets/PrefsMatesCount'
import { ProfileBirthday } from '../../Application/fieldsets/ProfileBirthday'
import { ProfileComfyPay } from '../../Application/fieldsets/ProfileComfyPay'
import { ProfileLegalName } from '../../Application/fieldsets/ProfileLegalName'
import { ProfileMoveInPeriod } from '../../Application/fieldsets/ProfileMoveInPeriod'
import { ProfileUploadPhoto } from '../../Application/fieldsets/ProfileUploadPhoto'
import { ProfileDescription } from './components/ProfileDescription'
import { ProfileFormButtons } from './components/ProfileFormButtons'
import { WhatsImportant } from './components/WhatsImportant'
import { UserProfilePublicEntity } from '@rse/core'
import { BodyContainer } from '../../../components/layout/BodyContainer'
import { selectedPublicProfileContext } from '../selectedPublicProfileContext'
import { useUserPublicProfileApi } from '../../../firebase/hooks/useUserPublicProfileApi'

export const ClubMemberEdit = () => {
  const history = useHistory()
  const { selectedPublicProfile, setSelectedPublicProfile, loadValueFromContext, setLoadValueFromContext } = useContext(selectedPublicProfileContext)
  const { errorHandler } = useErrorHandler('ClubMemberEdit')
  const { id } = useParams<{ id: string }>()
  const formRef = useRef(null as HTMLFormElement | null)

  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)
  const [currentMember, setCurrentMember] = useState<UserProfilePublicEntity | undefined>()


  const myUid = useCurrentUserId()
  const userIsViewingSelf = id === myUid

  const { value: member, loading, saveUserProfilePublic, isSaving } = useUserPublicProfileApi(myUid, false)

  useEffect(() => {
    setCurrentMember(member)
  }, [member])

  const onFormCancel = () => history.goBack()

  useEffect(() => {
    if (isSubmitted && isSaving) {
      onFormCancel()
    }
  }, [isSaving])




  if (!userIsViewingSelf) {
    return <Alert severity="error">No data found</Alert> // TODO: abstract to a common component
  }

  if (loading) {
    return (
      <BodyContainer>
        <Box display="flex" justifyContent="center" alignItems="center"><LoadingSpinner reason="profile data" /></Box>
      </BodyContainer>)
  }

  if (isSaving) {
    return (
      <BodyContainer>
        <Box display="flex" justifyContent="center" alignItems="center"><LoadingSpinner reason="updated profile data" /></Box>
      </BodyContainer>)
  }


  const getUserProfilePublic = async () => { return currentMember }

  const onFormSetState = (formState: ApplicationFormState) => formState?.loaded && setUnsavedChanges(true)

  const onFormSubmit = async (formData: any) => {
    if (!member) {
      throw new Error('Current member is undefined.  Unable to save.')
    }
    const userProfile: UserProfilePublicEntity = {
      id: member.id,
      sex: member.sex,
      gender: member.gender,
      state: member.state,
      maxMonthlyMortgagePayment: member.maxMonthlyMortgagePayment,
      ageRange: formData.ageRange,
      birthday: formData.birthday,
      description: formData.description ?? '',
      firstName: formData.firstName,
      lastName: formData.lastName,
      location: formData.location,
      lgbtqFriendly: formData.lgbtqFriendly ?? false,
      priceRange: formData.priceRange,
      roommates: formData.roommates,
      roommateGender: formData.roommateGender,
      timeRange: formData.timeRange,
      whatsImportant: formData.whatsImportant ?? [],
      profileImageUrl: formData.profileImageUrl,
      preferences: {}
    }

    Object.keys(formData).forEach((pref) => {
      if (pref.startsWith(preferencesPrefix)) {
        userProfile.preferences![pref.substring(preferencesPrefix.length)] = formData[pref]
      }
    })

    console.log(userProfile)

    try {
      saveUserProfilePublic(userProfile)
      setUnsavedChanges(false)
      setIsSubmitted(true)

      if (setLoadValueFromContext) {
        setLoadValueFromContext(true)
      }
      if (setSelectedPublicProfile) {
        setSelectedPublicProfile(userProfile)
      }

    } catch (error: unknown) {
      errorHandler(error)
    }
  }

  return (
    <AppLayout
      header={
        <Header
          back={async () => {
            if (unsavedChanges) {
              setPopupOpen(true)
            } else {
              onFormCancel()
            }
          }}
          title="Connect"
        />
      }
      body={
        <Container maxWidth="xs">
          <Popup open={popupOpen} setOpen={setPopupOpen}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <img src={exclamationIcon} alt="exclamation icon" />
              <h3>
                <TextContent resourceKey="profile.edit.save-your-changes" defaultValue="Save your changes?" />
              </h3>
              <Box width="100%">
                <ButtonLarge
                  fullWidth
                  isUppercase={false}
                  onClick={() => {
                    setPopupOpen(false)
                    formRef.current!.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                  }}
                >
                  <TextContent resourceKey="buttons.save-profile-edit-changes" defaultValue="Save" />
                </ButtonLarge>
                <Box mt={1}>
                  <ButtonLargeSecondary
                    fullWidth
                    isUppercase={false}
                    onClick={() => {
                      setPopupOpen(false)
                      onFormCancel()
                    }}
                  >
                    <TextContent resourceKey="buttons.dont-save-profile-edit-changes" defaultValue="Do not save" />
                  </ButtonLargeSecondary>
                </Box>
              </Box>
            </Box>
          </Popup>
          <ApplicationFormDataProvider getData={getUserProfilePublic} onSetState={onFormSetState}>
            <ApplicationForm
              formRef={(form) => {
                formRef.current = form
              }}
              onSubmit={onFormSubmit}
            >
              <ProfileUploadPhoto />
              <ProfileLegalName showSectionHeader={false} />
              <ProfileBirthday />
              <Spacing value={20} />
              <ProfileDescription />
              <Spacing value={20} />
              <Spacing value={20} />
              <ProfileComfyPay />
              <ProfileMoveInPeriod />
              <Spacing value={28} />
              <WhatsImportant />
              <Spacing value={28} />
              <TextHeading>
                <TextContent resourceKey="profile.edit.section.prefs.title" defaultValue="HouseMate preferences" />
              </TextHeading>
              <Spacing value={11} />
              <PrefsMateAgeRange />
              <PrefsMateGender />
              <PrefsMatesCount />
              <Spacing value={28} />
              <TextHeading>
                <TextContent resourceKey="profile.edit.section.ground-rules.title" defaultValue="Home Lifestyle preferences" />
              </TextHeading>
              <Spacing value={11} />
              <PrefsGroundRules />
              <Spacing value={11} />
              <ProfileFormButtons onCancel={onFormCancel} />
              <Spacing value={20} />
            </ApplicationForm>
          </ApplicationFormDataProvider>
        </Container>
      }
    />
  )
}
