import React, { useContext, useEffect } from 'react'
import { Container } from '@material-ui/core'
import { UserContext } from '../../App'
import { EmailVerifiedContent, JoinUpGate, UnverifiedEmail } from './components/signupStepViews'
import { AppLayout, CenteredBox, SignUpHeader } from '../../components/layout'
import { Redirect } from 'react-router-dom'
import { useCurrentUserEmailVerified } from './useCurrentUserEmailVerified'

function DashboardPage() {
  const user = useContext(UserContext)

  const {
    value: emailVerified,
    start: startWatchingEmailVerified,
    stop: stopWatchingEmailVerified,
  } = useCurrentUserEmailVerified()

  // TODO move this to useAuthService hook
  useEffect(() => {
    if (!user) return
    if (emailVerified) stopWatchingEmailVerified()
    else startWatchingEmailVerified()

    // make sure to unsubscribe when component unmounts
    return stopWatchingEmailVerified
  }, [user, emailVerified])

  return (
    <AppLayout
      header={<SignUpHeader />}
      body={
        <Container maxWidth="xs">
          <CenteredBox height="100%">
            {!emailVerified && <UnverifiedEmail />}
            {emailVerified && !user!.isPhoneVerified && <EmailVerifiedContent />}
            {emailVerified && user!.isPhoneVerified && (
              <>{user!.initApplicationStatus === 'New' ? <JoinUpGate /> : <Redirect to="/" />}</>
            )}
          </CenteredBox>
        </Container>
      }
    />
  )
}

export default DashboardPage
